import { Link, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';

import axios from 'axios';

import HomeNavBar from '../home/HomeNavBar';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import ReLogin from '../home/ReLogin';
import ReVerifyEmail from '../home/ReVerifyEmail';
import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

function Dashhome() {
    document.title="Dashboard | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified,loggedrole, setLoggedRole} =useContext(LoginContext);

    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    
    const [loadingpage,setLoadingPage]=useState(false);
    const [dash,setDash]=useState([]);
    const [loggedoff,setLoggedOff]=useState(false);
    const [emailnotverified,setEmailNotVerified]=useState(false);

    const [active,setActive]=useState('')
    const [show,setShow] =useState(false)

    // useEffect(()=>{
    //     if(!localStorage.getItem("auth_token_ut")){
    //         setAuthName('');
    //         // setRedirect(true);
    //         // setUrl('/login');
    //     }
    //     else{
    //         setAuthName(localStorage.getItem("auth_name_ut"));
    //     }
    //   },[authname])


      let loc=useLocation()
    //   console.log(loc.pathname)
    useEffect( () =>{
        const path=loc.pathname;
        const splitArray = path.split("/");
        if(path==='/dashboard'){
            setActive('')
            // setShow(false)
        }
        else{
            setActive(splitArray[2])
            // setShow(false)
        }
    },[loc])

    useEffect(()=>{
        let doneloading=true;
        
        const getDash = async (e) => { 
               await axios.get(`/api/load/dashbills`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setDash(response.data.stats);
                        }
                        else{
                            setDash([]);
                        }
                        
                    }
                }) 
                .catch((error)=>{
                    // console.log(error['response'].data.message)
                    // console.log(error['response'].statusText)
                    // Unauthorized
                    // Unauthenticated.
                    if(error['response'].data.message==='Your email address is not verified.'){
                        setEmailNotVerified(true)
                    }

                    if(!localStorage.getItem("auth_token_ut")){
                        let ex=error['response'].data.message;
                        // console.log(ex)
                        if(ex==='Unauthenticated.'){
                            if(!localStorage.getItem("auth_token_ut")){
                                setLoggedOff(true); 
                                setLoggedToken('');
                                setLoggedName(''); 
                            }  
                            else{ 
                                setLoggedOff(true); 
                                localStorage.removeItem('auth_token_ut');
                                localStorage.removeItem('auth_name_ut');
                                setLoggedToken('');
                                setLoggedName(''); 
                            }              
                        }
                        else{
                            setDash([]);
                        }
                    }
                    else{
                        setDash([]);
                    }
                    setDash([]);
                })
        };
        getDash();

        return ()=>{
            doneloading=false;
            
        }
    },[dash])

    if(redirect){
        return <Navigate to={url} />
    }

  return (
    <>
    <HomeNavBar active='dashhome'/>
    <main style={{"paddingTop": "81px","minHeight": "calc(100vh - 3rem)"}}>
    {!loggedoff && emailnotverified ?
        <ReVerifyEmail setEmailNotVerified={setEmailNotVerified} emailnotverified={emailnotverified} setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
    :
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
            :
            <>
                <div className={`toppedmenu ${show?'active':''}`}>
                    <ul>
                        {!show &&<li className='side-menu'>
                            <button className="btn btn-indigo" type="button" onClick={()=>{setShow(!show)}} >
                            <span className="fa fa-bars text-white"></span>
                        </button>
                        </li>}

                        {show && <li className='side-menu'>
                            <button className="btn btn-danger" type="button" onClick={()=>{setShow(!show)}} >
                            <span className="fa fa-times text-white"></span>
                        </button>
                        </li>}
                        <li>
                            <Link to="/dashboard" className={`${active===''?'active':''}`}>
                                <i className="fa fa-home"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        {loggedrole ==='Admin' &&
                            <>
                            {/* slides whatsh lesson truth beliefs biblestudy bibleresources tithes */}
                                <li>
                                    <Link to="/dashboard/homeslides" className={`${active==='homeslides'?'active':''}`}>
                                        <i className="fa fa-video"></i>
                                        <span>Welcome Slides</span>
                                        <sup>{dash[0] && dash[0].events}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/whatshappeningslides" className={`${active==='whatshappeningslides'?'active':''}`}>
                                        <i className="fa fa-film"></i>
                                        <span>Whats Happening</span>
                                        <sup>{dash[0] && dash[0].whatsh}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/lessons" className={`${active==='lessons'?'active':''}`}>
                                        <i className="fa fa-info-circle"></i>
                                        <span>Lessons</span>
                                        <sup>{dash[0] && dash[0].lesson}</sup>
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/dashboard/sabathschool" className={`${active==='sabathschool'?'active':''}`}>
                                        <i className="fa fa-video"></i>
                                        <span>Sabbath School</span>
                                    </Link>
                                </li> */}

                                <li>
                                    <Link to="/dashboard/thetruth" className={`${active==='thetruth'?'active':''}`}>
                                        <i className="fa fa-image"></i>
                                        <span>The Truth</span>
                                        <sup>{dash[0] && dash[0].truth}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/tithes" className={`${active==='tithes'?'active':''}`}>
                                        <i className="fa fa-gift"></i>
                                        <span>Tithes & Offerings</span>
                                        <sup>{dash[0] && dash[0].tithes}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/visitus" className={`${active==='visitus'?'active':''}`}>
                                        <i className="fa fa-map"></i>
                                        <span>Visit Us</span>
                                        <sup>{dash[0] && dash[0].visitus}</sup>
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/dashboard/beliefs" className={`${active==='beliefs'?'active':''}`}>
                                        <i className="fa fa-hands"></i>
                                        <span>Beliefs</span>
                                        <sup>{dash[0] && dash[0].beliefs}</sup>
                                    </Link>
                                </li>
                                {/* events programs sermons */}
                                <li>
                                    <Link to="/dashboard/events" className={`${active==='events'?'active':''}`}>
                                        <i className="fa fa-calendar"></i>
                                        <span>Events</span>
                                        <sup>{dash[0] && dash[0].events}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/sermons" className={`${active==='sermons'?'active':''}`}>
                                        <i className="fa fa-microphone"></i>
                                        <span>Sermons</span>
                                        <sup>{dash[0] && dash[0].sermons}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/programs" className={`${active==='programs'?'active':''}`}>
                                        <i className="fa fa-play"></i>
                                        <span>Programs</span>
                                        <sup>{dash[0] && dash[0].programs}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/ministries" className={`${active==='ministries'?'active':''}`}>
                                        <i className="fa fa-church"></i>
                                        <span>Ministries</span>
                                        <sup>{dash[0] && dash[0].ministries}</sup>
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/dashboard/groups" className={`${active==='groups'?'active':''}`}>
                                        <i className="fa fa-video"></i>
                                        <span>Groups</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/forums" className={`${active==='forums'?'active':''}`}>
                                        <i className="fa fa-video"></i>
                                        <span>Forums</span>
                                    </Link>
                                </li> */}
                                
                                <li>
                                    <Link to="/dashboard/biblestudy" className={`${active==='biblestudy'?'active':''}`}>
                                        <i className="fa fa-book-reader"></i>
                                        <span>Bible Study</span>
                                        <sup>{dash[0] && dash[0].biblestudy}</sup>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dashboard/bibleresources" className={`${active==='bibleresources'?'active':''}`}>
                                        <i className="fa fa-book"></i>
                                        <span>Bible Resources</span>
                                        <sup>{dash[0] && dash[0].bibleresources}</sup>
                                    </Link>
                                </li>


                                {/* <li>
                                    <Link to="/dashboard/blog" className={`${active==='blog'?'active':''}`}>
                                        <i className="fa fa-video"></i>
                                        <span>Blog</span>
                                    </Link>
                                </li> */}

                                <li>
                                    <Link to="/dashboard/users" className={`${active==='users'?'active':''}`}>
                                        <i className="fa fa-users"></i>
                                        <span>Manage Users</span>
                                        <sup>{dash[0] && dash[0].users}</sup>
                                    </Link>
                                </li>

                                
                                <li>
                                    <Link to="/dashboard/contactus" className={`${active==='contactus'?'active':''}`}>
                                        <i className="fa fa-envelope"></i>
                                        <span>Contactus</span>
                                        <sup>{dash[0] && dash[0].contactus}</sup>
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/dashboard/opportunities" className={`${active==='opportunities'?'active':''}`}>
                                        <i className="fa fa-handshake"></i>
                                        <span>Opportunities</span>
                                        <sup>{dash[0] && dash[0].opportunities}</sup>
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/dashboard/members" className={`${active==='members'?'active':''}`}>
                                        <i className="fa fa-book"></i>
                                        <span>Manage Members</span>
                                    </Link>
                                </li> */}
                            </>
                        }
                        
                    
                        
                    </ul>
                </div>

                <div className={`toppedcontainer ${show?'active':''}`}>
                    <Outlet />
                </div>
            </>
        } 
    </>
    }

    </main>
      
    </>
  );
}


export default Dashhome;