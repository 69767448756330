
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';





import LoadWhatsHappening from './LoadWhatsHappening';
import SabbathSchoolAnimated from './SabbathSchoolAnimated';
import TheTruth from './TheTruth';
import Events from './Events';
import TheBelief from './TheBelief';
import Programs from './Programs';

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function ProgramLists() {
    document.title="Program Lists | Utawala Satellite";

  return (
    <>
    <HomeNavBar active='programlist'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className=" text-white text-center" >
                        <p className='m-0 p-3 mb-0 pb-0 beliefs-p'>Utawala Satellite SDA Programs</p>
                    </div>
                </div>
                <div className="row justify-content-center p-3 pt-2">
                    <Programs />
                </div>
            </div>
            
        </div>



        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default ProgramLists;