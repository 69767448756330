
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';
import { useEffect, useState, useContext } from 'react';

import TheBelief from './TheBelief';

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function Beliefs() {
    document.title="Beliefs | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

    // const items = [
    //     { type: 'image', src: 'image1.jpg', alt: 'Slide 1' },
    //     { type: 'video', src: 'video1.mp4', alt: 'Slide 2' },
    //     { type: 'image', src: 'image2.jpg', alt: 'Slide 3' },
    // ];

  return (
    <>
    <HomeNavBar active='beliefs'/>
    <main className='paddingtop'  style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className=" p-3 pt-4 text-white text-center" >
                        <p className='m-2 p-3 pt-4 mb-0 pb-0 beliefs-p'>Official Beliefs of the Seventh-day Adventist Church</p>
                    </div>
                </div>
                <div className="row justify-content-center p-5 pt-3">
                    <TheBelief />
                </div>
            </div>
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}




{/* The Book of Mark - Sabbath School Lesson 2: "A Day in the Ministry of Jesus"
Study this lesson for the week of July 6, 2024, to July 12, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/07/sabbath-school-lesson-2-day-in-ministry.html For 3rd quarter Sabbath School Lessons got to: https://the-undercover-adventist.blogspot.com/2024/06/the-book-of-mark-3rd-quarter-sabbath.html #sabbathschool #lesson2 #thebookofmark #3rddquarter #2024 #adventist #adayintheministryofJesus
The Book of Mark - Sabbath School Lesson 1: "The Beginning of the Gospel"

14:37
The Book of Mark - Sabbath School Lesson 1: "The Beginning of the Gospel"
Study this lesson for the week of June 29, 2024, to July 5, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/06/sabbath-school-lesson-1-beginning-of.html For 3rd quarter Sabbath School Lessons got to: https://the-undercover-adventist.blogspot.com/2024/06/the-book-of-mark-3rd-quarter-sabbath.html #sabbathschool #lesson1 #thebookofmark #3rddquarter #2024 #adventist #thebeginningofthegospel
The Great Controversy - Sabbath School Lesson 13: "The Triumph of God's Love"

17:49
The Great Controversy - Sabbath School Lesson 13: "The Triumph of God's Love"
Study this lesson for the week of June 22, 2024, to June 28, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/06/sabbath-school-lesson-13-triumph-of.html Will we ever have to stand without Jesus in the end times? https://the-undercover-adventist.blogspot.com/2024/06/will-we-ever-have-to-stand-alone.html 2nd quarter lessons: https://the-undercover-adventist.blogspot.com/2024/03/the-great-controversy-2nd-quarter.html Inspirational Bible Verses set to nature and music: https://www.youtube.com/@UC5dTUjRbXKua4uNBeRm_ykQ #sabbathschool #lesson13 #thegreatcontroversy #2ndquarter #2024 #adventist #thetriumphofgodslove
The Great Controversy - Sabbath School Lesson 11: "The Impending Conflict"

15:42
The Great Controversy - Sabbath School Lesson 11: "The Impending Conflict"
Study this lesson for the week of June 8, 2024, to June 14, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/06/sabbath-school-lesson-11-impending.html GC Comment on Roman Catholic Church: https://www.adventist.org/official-statements/how-seventh-day-adventists-view-roman-catholicism/ 2nd quarter lessons: https://the-undercover-adventist.blogspot.com/2024/03/the-great-controversy-2nd-quarter.html Inspirational Bible Verses set to nature and music: https://www.youtube.com/@UC5dTUjRbXKua4uNBeRm_ykQ #sabbathschool #lesson11 #thegreatcontroversy #2ndquarter #2024 #adventist #theimpendingconflict
The Great Controversy - Sabbath School Lesson 10: "Spiritualism Exposed"

16:46
The Great Controversy - Sabbath School Lesson 10: "Spiritualism Exposed"
Study this lesson for the week of June 1, 2024, to June 7, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/05/sabbath-school-lesson-10-spiritualism.html 2nd quarter lessons: https://the-undercover-adventist.blogspot.com/2024/03/the-great-controversy-2nd-quarter.html Inspirational Bible Verses set to nature and music: https://www.youtube.com/@UC5dTUjRbXKua4uNBeRm_ykQ #sabbathschool #lesson10 #thegreatcontroversy #2ndquarter #2024 #adventist #SpiritualismExposed
The Great Controversy - Sabbath School Lesson 9: "The Foundation of God's Government"

16:48
The Great Controversy - Sabbath School Lesson 9: "The Foundation of God's Government"
Study this lesson for the week of May 25, 2024, to May 31, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/05/sabbath-school-lesson-9-foundation-of.html 2nd quarter lessons: https://the-undercover-adventist.blogspot.com/2024/03/the-great-controversy-2nd-quarter.html Inspirational Bible Verses set to nature and music: https://www.youtube.com/@UC5dTUjRbXKua4uNBeRm_ykQ #sabbathschool #lesson9 #thegreatcontroversy #2ndquarter #2024 #adventist #thefoundationofgodsgovernment
The Great Controversy - Sabbath School Lesson 8: "Light from the Sanctuary""

16:25
The Great Controversy - Sabbath School Lesson 8: "Light from the Sanctuary""
Study this lesson for the week of May 18, 2024, to May 24, 2024. For all notes from this summary and the current Sabbath School lesson, go to: https://the-undercover-adventist.blogspot.com/2024/05/sabbath-school-lesson-8-light-from.html 2nd quarter lessons: https://the-undercover-adventist.blogspot.com/2024/03/the-great-controversy-2nd-quarter.html Inspirational Bible Verses set to nature and music: https://www.youtube.com/@UC5dTUjRbXKua4uNBeRm_ykQ #sabbathschool #lesson8 #thegreatcontroversy #2ndquarter #2024 #adventist #lightfromthesanctuary
 */}
export default Beliefs;