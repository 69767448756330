import Button from 'react-bootstrap/Button';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import Swal from 'sweetalert';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

function SignUp() {

    document.title="Sign Up";
    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');


    const [formdata,setFormData]=useState({
        fname:'',
        lname:'',
        username:'',
        userrole:'Guest',
        email:'',
        phone:'',
        password:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }


    const newUser= (e)=>{
        e.preventDefault();

        
        // Swal("Enter Email Address",(((formdata.phone).trim()).length) ,"error");
        //     return;
        if((formdata.email).trim() ===''){
            Swal("Enter Email Address","Please Enter Users Email Address","error");
            return;
        }
        if((formdata.fname).trim() ===''){
            Swal("Enter First Name","Please Enter Users First Name","error");
            return;
        }
        if((formdata.lname).trim() ===''){
            Swal("Enter Last Name","Please Enter Users Last Name","error");
            return;
        }
        
        if((formdata.username).trim() ===''){
            Swal("Username is Required","Please Enter Your Username","error");
            return;
        }

        if((formdata.password).trim() ===''){
            Swal("New Password is Required","Please Enter New Password","error");
            return;
        }

        if((formdata.confirmpassword).trim() ===''){
            Swal("Confirm New Password is Required","Please Enter Confirm New Password","error");
            return;
        }

        if((formdata.password).trim() !== (formdata.confirmpassword).trim()){
            Swal("Password Must Match","Please Enter Matching password","error");
            return;
        }

        if(formdata.phone !=='' && ((formdata.phone).trim() !=='' && isNaN(formdata.phone)) && (((formdata.phone).trim()).length) !==12 ){
            Swal("Correct Phone Number","Please Specify Phone Number like 254712345678","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            fname:formdata.fname,
            lname:formdata.lname,
            username:formdata.username,
            userrole:formdata.userrole,
            email:formdata.email,
            password:formdata.password,
            phone:formdata.phone,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/signup',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    // localStorage.setItem('auth_token',response.data.token);
                    // localStorage.setItem('auth_name',response.data.username);
                    // setRedirect(true);
                    // setUrl('/dashboard');

                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

    if(redirect){
        return <Navigate to={url} />
    }
  return (
    <>
    <HomeNavBar active='login'/>

    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
    <div className="container mt-3">
        <div className="row justify-content-center mt-3">
            <div className="col-md-8 mt-3">
                <div className="card border-white-b">
                    <div className="card-header bg-indigo text-center text-lg p-0 m-0">
                        <h1 style={{"textAlign": "center"}}>Create a new Account</h1>
                    </div>

                    <div className="card-body">
                       
                        <form className='justify-content-center' onSubmit={newUser}>
                            {!loading && loadingresok==='' &&
                                <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>Please fill all details below to create account</p>
                                  
                                    <div className="form-group row">
                                        <label for="Fname" className="col-md-4 col-form-label text-md-right">First Name <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Fname" type="text" className="form-control" name="fname" placeholder="First Name" value={formdata.fname} onChange={handleInputChange} required autoComplete="Fname" autoFocus/>
                                            {formdata.error_list && formdata.error_list.fname && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.fname}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="Lname" className="col-md-4 col-form-label text-md-right">Last Name <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Lname" type="text" className="form-control" name="lname" placeholder="Last Name" value={formdata.lname} onChange={handleInputChange} required autoComplete="Lname" autoFocus/>
                                            {formdata.error_list && formdata.error_list.lname && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.lname}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="Username" className="col-md-4 col-form-label text-md-right">Username <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Username" type="text" className="form-control" name="username" placeholder="Username" value={formdata.username} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                            {formdata.error_list && formdata.error_list.username && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.username}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                
                                    <div className="form-group row">
                                        <label for="Email" className="col-md-4 col-form-label text-md-right">Email Address <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Email" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Email" autoFocus/>
                                            {formdata.error_list && formdata.error_list.email && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.email}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="Phone" className="col-md-4 col-form-label text-md-right">Phone Number</label>
                                        <div className="col-md-6 ">
                                            <input id="Phone" type="text" className="form-control" name="phone" placeholder="254700000000" value={formdata.phone} onChange={handleInputChange} required autoComplete="Phone" autoFocus/>
                                            {formdata.error_list && formdata.error_list.phone && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.phone}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="password" className="col-md-4 col-form-label text-md-right">Password <sub className='text-red text-sm'>*</sub></label>

                                        <div className="col-md-6">
                                            <input id="password" type="password" className="form-control @error('password') is-invalid @enderror" name="password" placeholder="Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                            {formdata.error_list && formdata.error_list.password && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.password}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="confirmpassword" class="col-md-4 col-form-label text-md-right">Confirm New Password</label>

                                        <div class="col-md-6">
                                            <input id="confirmpassword" type="password" className="form-control @error('confirmpassword') is-invalid @enderror" name="confirmpassword" placeholder="Confirm New Password" value={formdata.confirmpassword} onChange={handleInputChange} required/>
                                            
                                        </div>
                                    </div>

                                </>
                            }

                            {loadingresok!=='' && 
                                <div className="col-md-12 elevation-0 mb-2 p-2 text-center border-ok">
                                    <span className="help-block text-success">
                                        <strong>{loadingresok!=='' && loadingresok}</strong>
                                    </span>
                                    <p className='m-0 p-2'>Please <Link className="btn btn-link " to="/login"> 
                                        Click here to Login?
                                    </Link></p>
                                    
                                </div>
                            }

                            {loading && 
                                <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>Creating Account...</span>
                                        
                                        
                                        
                                </div>
                            }

                                    {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }


                                    {!loading && loadingresok ==='' && 
                                        <div className="row text-center m-0 mb-3 p-0">
                                            
                                            <div className="col-12 m-0 p-0">
                                                <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    {!loading && loadingresok ==='' && 
                                        <div class="form-group mb-0 pt-3">
                                            <div class="d-flex justify-content-center">
                                                <span className='p-1 pr-0'>Do you have an Account?,</span>
                                                <Link className="btn btn-link" to="/login">
                                                    Login Instead
                                                </Link>
                                            </div>
                                            
                                        </div>
                                    }

                                    

                                    

                                    {/* {!loading && loadingresok ==='' && 
                                        <div className="form-group d-flex mb-0">
                                            <div className="">
                                                <Link className="btn btn-link " to="/login"> 
                                                    Login?
                                                </Link>
                                            </div>

                                            <div className="justify-items-right ml-auto">
                                                <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    } */}

                                    {/* {!loading && loadingresok ==='' && 
                                        <div className="form-group row mb-0">
                                            <div className="col-8 offset-5 offset-lg-8">
                                                <Link className="btn btn-link " to="/password/request"> 
                                                    Forgot Your Password?
                                                </Link>
                                            </div>
                                        </div>
                                    } */}

                                    {/* {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    } */}

                        </form>

                        {!loading && 
                            <>
                                <h2 className='border-bottom text-center text-great mt-3 p-2 pb-3 font-bold-700'>OR:</h2>
                                <p className='border-bottom text-center text-great p-2 pb-3 font-bold-700'>Use other accounts to Login</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    </main>
      
    </>
  );
}

export default SignUp;