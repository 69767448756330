import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from '../home/LoadPrograms';

import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashBibleStudy(props) {
    document.title="Dashboard Bible Study Resources | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('verseoftheday');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [verseoftheday,setVerseOfTheDay]=useState([]);
    const [nextafterverseoftheday,setNextAfterVerse]=useState([]);
    const [hopeinGodsword,setHopeinGodsWord]=useState([]);
    const [featuredwebsites,setFeaturedWebsites]=useState([]);
    const [sabbathtruth,setSabbathTruth]=useState([]);
    const [others,setOthers]=useState([]);

    const [books,setBooks]=useState([]);
    const [addbible, setAddbible]=useState(false);
    const [addbook, setAddbook]=useState(false);
    const [currentbible,setCurrentBible]=useState('');
    const [currentbook,setCurrentBook]=useState('');

    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');
    const [loadingpage,setLoadingPage]=useState(false);

   
    // title verse versequote image biblev 
    const [formdata,setFormData]=useState({
        title:'',
        image:'',
        biblev:'',
        verse:'',
        versequote:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_verse==='' || verselinks.verselinks_link==='' || verselinks.verselinks_text===''){
            Swal("Error","Please Fill all required for verse");
        }
        else if(isNaN(verselinks.verselinks_verse.trim())){
            Swal("Error","Verse Must be a Number");
        }
        else{
            const {verselinks_link,verselinks_text,verselinks_verse}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link || 
                        verselinks.verselinks_verse===lk.verselinks_verse ||
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link && 
                        verselinks.verselinks_verse===lk.verselinks_verse &&
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_verse:link.verselinks_verse,
            verselinks_link:link.verselinks_link,
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const setVerseActive=(status)=>{
        console.log(status)
        setAddbook(!addbook);
        setAddbible(false);
        setCurrentBook('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            biblev:status,
            versequote:'',
            verse:'',
            image:'',
            error_list:[],
        })
        setFiles([])
        setLoadingRes('');
    }

    

    const newBook= (e)=>{
        e.preventDefault();

        if((formdata.title).trim() ===''){
            Swal("Enter Section Title","Please Enter Section Title","error");
            return;
        }

        if(formdata.biblev ==='verseoftheday' && (formdata.verse).trim() ===''){
            Swal("Enter Section Verse","Please Enter Section Verse","error");
            return;
        }
        else if(formdata.biblev ==='verseoftheday' && (formdata.versequote).trim() ===''){
            Swal("Enter Section Verse Text","Please Enter Section Verse Text","error");
            return;
        }
        else if(formdata.biblev !='verseoftheday' && (formdata.verse).trim() ===''){
            Swal("Enter Section Link","Please Enter Section Link","error");
            return;
        }


        if(files ===''){
            Swal("Select Image","Please Select Image to represent A section","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentbook!=='')?currentbook.id:'',
            verse:formdata.verse,
            biblev:formdata.biblev,
            title:formdata.title,
            versequote:formdata.versequote,
            image:files,
        }
            axios.post('/api/save/biblestudysection',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadBibleStudySections();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }

    const loadBibleStudySections= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/biblestudysections`)
        .then(response=>{
            if(response.data.status=== 200){
                
                if(response.data.verseoftheday ===null){
                    setVerseOfTheDay('')
                }
                else{
                    setVerseOfTheDay(response.data.verseoftheday);
                }
                if(response.data.sabbathtruth ===null){
                    setSabbathTruth('')
                }
                else{
                    setSabbathTruth(response.data.sabbathtruth);
                }
                setNextAfterVerse([response.data.nextafterverseoftheday]);
                setHopeinGodsWord([response.data.hopeinGodsword]);
                setFeaturedWebsites([response.data.featuredwebsites]);
                setOthers([response.data.others]);
                setLoadingPage(false)

            }
            else if(response.data.status=== 401){
                setLoggedOff(true);    
                Swal("Error1",response.data.message,"error");
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
            else if(response.data.status=== 500){
                Swal("Error2",response.data.message,"error");
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
            else{
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            // console.log(error.message)
                setLoadingPage(false)
            setVerseOfTheDay('');
            setNextAfterVerse([]);
            setHopeinGodsWord([]);
            setFeaturedWebsites([]);
            setSabbathTruth('');
            setOthers([]);
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/biblestudysections`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        
                        if(response.data.verseoftheday ===null){
                            setVerseOfTheDay('')
                        }
                        else{
                            setVerseOfTheDay(response.data.verseoftheday);
                        }
                        if(response.data.sabbathtruth ===null){
                            setSabbathTruth('')
                        }
                        else{
                            setSabbathTruth(response.data.sabbathtruth);
                        }
                        setNextAfterVerse([response.data.nextafterverseoftheday]);
                        setHopeinGodsWord([response.data.hopeinGodsword]);
                        setFeaturedWebsites([response.data.featuredwebsites]);
                        setOthers([response.data.others]);
                setLoadingPage(false)

                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                setLoadingPage(false)
                    }
                    else{
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setLoadingPage(false)
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    



    // title verse versequote image biblev 
    const updateBook = (serv) => {
        setAddbook(true)
        setCurrentBook(serv)
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            biblev:serv.biblev,
            image:serv.image,
            title:serv.title,
            verse:serv.verse,
            versequote:serv.versequote,
            error_list:[],
        })
        
    };

  

    const setShowbibles = (ev)=> {
        setAddbible(false);
        setAddbook(false);
        setCurrentBook('')
        setCurrentBible('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            biblev:'',
            versequote:'',
            verse:'',
            image:'',
            error_list:[],
        })
        loadBibleStudySections();
        setFiles([])
    }
    

    const setAddbooks = (ev)=> {
        setAddbook(!addbook);
        setAddbible(false);
        setCurrentBook('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            biblev:'',
            versequote:'',
            verse:'',
            image:'',
            error_list:[],
        })
        setFiles([])
    }


    const deleteBook= (service)=>{
        const form={
            id:service.id,
        }

        let title='Delete '+service.title;
        let text="This will remove this Section from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/biblestudysection',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadBibleStudySections()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadBibleStudySections();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Bible Study Page</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {(addbook)?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setShowbibles()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Show Bible Study Sections Page 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddbooks()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> Create Bible Study Section Page 
                                    </button>
                                </p>
                                
                            </>
                        }
                        

                        {addbook ?
                            <form className='justify-content-center' onSubmit={newBook}>
                                {!loading && loadingresok==='' && 
                                    <>
                                        <div className="form-group row">
                                            <label for="biblev" className="col-md-3 col-form-label text-md-right">Section  <sub className='text-red text-sm'>*</sub></label>
                                            {/* title verse versequote image biblev */}
                                            <div className="col-md-7">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.biblev==="verseoftheday"?"true":""} onChange={handleInputChange} className="" name="biblev" value="verseoftheday" autoComplete="biblev"/> Verse of the day
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="nextafterverseoftheday"?"true":""} onChange={handleInputChange} className="" name="biblev" value="nextafterverseoftheday" autoComplete="biblev"/> Next After Verse
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="hopeinGodsword"?"true":""} onChange={handleInputChange} className="" name="biblev" value="hopeinGodsword" autoComplete="biblev"/> Hope in God's Word
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="featuredwebsites"?"true":""} onChange={handleInputChange} className="" name="biblev" value="featuredwebsites" autoComplete="biblev"/> Featured Websites
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="sabbathtruth"?"true":""} onChange={handleInputChange} className="" name="biblev" value="sabbathtruth" autoComplete="biblev"/> Sabbath Truth
                                                </label>
                                                
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="others"?"true":""} onChange={handleInputChange} className="" name="biblev" value="others" autoComplete="biblev"/> Other Sections
                                                </label>
                                                {formdata.error_list && formdata.error_list.type && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.type}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                    
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Section Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" required placeholder="Section Title" value={formdata.title} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        {formdata.biblev==='verseoftheday' ?
                                        <>
                                            <div className="form-group row">
                                                <label for="verse" className="col-md-3 col-form-label text-md-right">Verse <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="verse" type="text" className="form-control" name="verse" placeholder="Verse of the Day" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                    {formdata.error_list && formdata.error_list.verse && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verse}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label for="versequote" className="col-md-3 col-form-label text-md-right">Verse Text <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                <textarea id="versequote" className="form-control" name='versequote' placeholder="Verse of the Day Text" value={formdata.versequote} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                    {formdata.error_list && formdata.error_list.versequote && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.versequote}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div> 
                                        </>
                                        :
                                            <div className="form-group row">
                                                <label for="verse" className="col-md-3 col-form-label text-md-right">Link <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="verse" type="text" className="form-control" name="verse" placeholder="Section Link" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                    {formdata.error_list && formdata.error_list.verse && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verse}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        
                                        {formdata.biblev==='featuredwebsites' &&
                                            <div className="form-group row">
                                                <label for="versequote" className="col-md-3 col-form-label text-md-right">Website Summary <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                <textarea id="versequote" className="form-control" name='versequote' placeholder="Enter Website Summary" value={formdata.versequote} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                    {formdata.error_list && formdata.error_list.versequote && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.versequote}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div> 
                                        }

                                        <div className="form-group row">
                                            <label for="image" className="col-md-3 col-form-label text-md-right">Photo/ Image <sub className='text-red text-sm'>*</sub></label>

                                            <div className="col-md-7">

                                                 <div className='dropArea' {...getRootProps({style})}>
                                                     <input {...getInputProps()} type='file' accept='image/*' />
                                                     <p>
                                                         Click or Drop Photo / Image Here
                                                     </p>
                                                    
                                                 </div>

                                                 {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }
                                                    

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentbook !=='' &&
                                                        <>
                                                            {currentbook.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+currentbook.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddbook()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Section':'Creating Section'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Section':'Save Section'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            // <form className='justify-content-center' onSubmit={newBook}>
                            //     {!loading && loadingresok==='' && 
                            //         <>
                            //             <div className="form-group row">
                            //                 <label for="link" className="col-md-3 col-form-label text-md-right">Book Link <sub className='text-red text-sm'>*</sub> </label>
                            //                 <div className="col-md-7">
                            //                     <input id="link" type="text" className="form-control" name="link" placeholder="Book Online Link here..." value={formdata.link} onChange={handleInputChange} autoFocus/>
                            //                     {formdata.error_list && formdata.error_list.link && 
                            //                         <span className="help-block text-danger">
                            //                             <strong>{formdata.error_list.link}</strong>
                            //                         </span>
                            //                     }
                            //                 </div>
                            //             </div>

                            //             <div className="form-group row">
                            //                 <label for="title" className="col-md-3 col-form-label text-md-right">Book Title </label>
                            //                 <div className="col-md-7">
                            //                     <input id="title" type="text" className="form-control" name="title" placeholder="Book Title if any..." value={formdata.title} onChange={handleInputChange} autoFocus/>
                            //                     {formdata.error_list && formdata.error_list.title && 
                            //                         <span className="help-block text-danger">
                            //                             <strong>{formdata.error_list.title}</strong>
                            //                         </span>
                            //                     }
                            //                 </div>
                            //             </div>

                            //             <div className="form-group row">
                            //                 <label for="img" className="col-md-3 col-form-label text-md-right">Photo/ Image <sub className='text-red text-sm'>*</sub></label>

                            //                 <div className="col-md-7">

                            //                     <div className='dropArea' {...getRootProps({style})}>
                            //                         <input {...getInputProps()} type='file' accept='image/*' />
                            //                         <p>
                            //                             Click or Drop Photo / Image Here
                            //                         </p>
                                                    
                            //                     </div>

                            //                     {formdata.error_list && formdata.error_list.img && 
                            //                         <span className="help-block text-danger">
                            //                             <strong>{formdata.error_list.img}</strong>
                            //                         </span>
                            //                     }
                                                    

                            //                     <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                            //                         <div>
                            //                             {files && files.map((file,key) =>(
                            //                                 <div className='m-0 p-1 border-bottom forum-div-h'>
                            //                                     <p className='m-0 p-0'>Selected Image</p>
                            //                                     <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                            //                                 </div>
                            //                             ))}                                 
                            //                         </div>

                            //                         <div>
                            //                             {currentbook !=='' &&
                            //                             <>
                            //                                 {currentbook.image !==null &&
                            //                                     <div className='m-0 p-1 border-bottom forum-div-h'>
                            //                                         <p className='m-0 p-0'>Uploaded Image</p>
                            //                                         <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudybooks/'+currentbook.img} alt='Does Not Have Image' className='' width="100%" />
                            //                                     </div>
                            //                                 }
                            //                             </>
                                                            
                            //                             }                                 
                            //                         </div>

                            //                     </div>

                            //                 </div>
                            //             </div>
                                        

                            //         </>
                            //     }

                            //     {loadingresok!=='' && 
                            //         <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                            //             <span className="help-block text-success">
                            //                 <strong>{loadingresok!=='' && loadingresok}</strong>
                            //             </span>

                            //             <button type="button" onClick={()=>{setShowbibles()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                            //                 <span className='fa fa-chevron-left'></span> Back 
                            //             </button>
                            //         </div>
                            //     }

                            //     {loading && 
                            //         <div className="col-md-12 text-center text-white">
                            //             <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                            //             <br/>
                            //             <Spinner
                            //                 as="span"
                            //                 variant='info'
                            //                 animation="border"
                            //                 size="lg"
                            //                 role="status"
                            //                 aria-hidden="true"
                            //             />
                            //             <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Bible Study Book Page':'Saving Bible Study Book Page'}...</span>
                                            
                            //         </div>
                            //     }

                            //         {loadingres!=='' && 
                            //             <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                            //                 <span className="help-block text-danger">
                            //                     <strong>{loadingres!=='' && loadingres}</strong>
                            //                 </span>
                            //             </div>
                            //         }
                            //         {!loading && loadingresok ==='' && 
                            //             <div className="form-group d-flex mb-0">
                            //                 <div className="mx-auto">
                            //                     <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                            //                         {currentbook!==''?'Update Bible Study Book Page':'Save Bible Study Book Page'}
                            //                     </button>
                            //                 </div>
                            //             </div>
                            //         }


                            // </form>
                        :

                        <>
                            <div className="row justify-content-center p-3 pt-2">
                                <Tabs
                                    id="fill-tab-example"
                                    className="m-0 p-0 bg-success"
                                    activeKey={key}
                                    onSelect={(k)=> setKey(k)}
                                    fill>
                                        {/* nextafterverseoftheday hopeinGodsword featuredwebsites others */}
                                        <Tab eventKey="verseoftheday"
                                            title="Verse of the Day">
                                            <div className="row m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-0'>
                                                        {verseoftheday === '' ?
                                                                <>
                                                                    <p className='m-0 p-4 text-great'>Please Create Verse of the Day Section Page</p>
                                                                    <button type="button" onClick={()=>{setVerseActive('verseoftheday')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                        <span className='fa fa-plus-circle'></span> Create Verse of the Day Page 
                                                                    </button>
                                                                </>
                                                            :
                                                                <div className='text-center m-0 mt-3 p-2'>
                                                                    <h3 className='text-xl font-bold-700'>
                                                                        {verseoftheday['title']}
                                                                    </h3>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verseoftheday['image']}  width="100%" height="350px"  alt="First Image" />
                                                                    
                                                                    <div className="row justify-content-center p-3 pt-3">
                                                                        <div className='bg-primary m-0 p-1 border-info'>
                                                                            <h3 className='text-center font-bold-700 text-white'>
                                                                                {verseoftheday['verse']}
                                                                            </h3>
                                                                            <p className='text-center'>
                                                                                “{verseoftheday['versequote']}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {deleteBook && updateBook &&
                                                                        <div className='m-0 p-0 text-muted mx-auto'>
                                                                            <div className='text-left mx-auto text-center'>
                                                                                <div>
                                                                                    <button type="button" onClick={()=>{updateBook(verseoftheday)}} className="btn btn-primary m-1 p-1">
                                                                                        <span className='fa fa-edit'></span> Edit
                                                                                    </button>
                                                                                    
                                                                                    <button type="button" onClick={()=>{deleteBook(verseoftheday)}} className="btn btn-danger m-1 p-1">
                                                                                        <span className='fa fa-trash'></span> Delete
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div> 
                                                        }
                                                    </div>   
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="secondverse"
                                            title="Next Page After Verse of Day ">
                                            <div className="row justify-content-center p-0 pt-2">
                                                {/* nextafterverseoftheday */}
                                                {nextafterverseoftheday[0] && nextafterverseoftheday[0].length ===0 &&
                                                    <>
                                                    <div className='text-center m-0 p-3'>
                                                        <p className='m-0 p-4 text-great'>Please create Next section after Verse of the Day Section Page</p>
                                                        <button type="button" onClick={()=>{setVerseActive('nextafterverseoftheday')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                            <span className='fa fa-plus-circle'></span> Create Next Page after Verse Section Page 
                                                        </button>
                                                    </div>
                                                    </>
                                                }
                                                    {nextafterverseoftheday && nextafterverseoftheday[0] && nextafterverseoftheday[0].map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <i className='text-black text-muted'>{verse.title}</i>
                                                                    <a className='link-image' href={verse.verse}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='m-0 p-2' width="100%" height="150px"  alt="First Image" /></a>
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-left p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{updateBook(verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteBook(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                            </div>

                                        </Tab>
                                        <Tab eventKey="findhopeinGod"
                                            title="Find Hope in God's Word">
                                            <div className="row justify-content-center p-0 pt-2">
                                                {/* hopeinGodsword */}
                                                {hopeinGodsword[0] && hopeinGodsword[0].length ===0 &&
                                                    <>
                                                    <div className='text-center m-0 p-3'>
                                                        <p className='m-0 p-4 text-great'>Please create Finding Hope in God's Word Section Page</p>
                                                        <button type="button" onClick={()=>{setVerseActive('hopeinGodsword')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                            <span className='fa fa-plus-circle'></span> Create Finding Hope Section Page 
                                                        </button>
                                                    </div>
                                                    </>
                                                }
                                                    {hopeinGodsword && hopeinGodsword[0] && hopeinGodsword[0].map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <i className='text-black text-muted'>{verse.title}</i>
                                                                    <a className='link-image' href={verse.link}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='m-0 p-2' width="100%" height="150px"  alt="First Image" /></a>
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-left p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{updateBook(verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteBook(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="featuredwebsites"
                                            title="Featured Websites">
                                            <div className="row justify-content-center p-0 pt-2">
                                             {/* featuredwebsites */}
                                             {featuredwebsites[0] && featuredwebsites[0].length ===0 &&
                                                    <>
                                                    <div className='text-center m-0 p-3'>
                                                        <p className='m-0 p-4 text-great'>Please create Featured Websites Section Page</p>
                                                        <button type="button" onClick={()=>{setVerseActive('featuredwebsites')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                            <span className='fa fa-plus-circle'></span> Create Featured Website Section Page 
                                                        </button>
                                                    </div>
                                                    </>
                                                }
                                                    {featuredwebsites && featuredwebsites[0] && featuredwebsites[0].map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className=" bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <h4 className='text-black'>{verse.title}</h4>
                                                                    <div>{verse.versequote}</div>
                                                                    <a className='link-image' href={verse.link}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='m-0 p-2' width="100%" height="150px"  alt="First Image" /></a>
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-center p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{updateBook(verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteBook(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                            </div>
                                        </Tab>

                                        <Tab eventKey="discoversabbathtruth"
                                            title="Discover Sabbath Truth">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        {sabbathtruth === '' ?
                                                                <>
                                                                    <p className='m-0 p-4 text-great'>Please Create Sabbath Truth and Discover Section Page</p>
                                                                    <button type="button" onClick={()=>{setVerseActive('sabbathtruth')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                        <span className='fa fa-plus-circle'></span> Create the Sabbath Truth Discover Page 
                                                                    </button>
                                                                </>
                                                            :
                                                                <div className='text-center m-0 mt-3 p-2'>
                                                                    <h3 className='text-xl font-bold-700'>
                                                                        {sabbathtruth['title']}
                                                                    </h3>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+sabbathtruth['image']}  width="100%" height="350px"  alt="First Image" />
                                                                    
                                                                    <div className="row justify-content-center p-3 pt-3">
                                                                        <div className='bg-primary m-0 p-1 border-info'>
                                                                            <h3 className='text-center font-bold-700 text-white'>
                                                                                {sabbathtruth['verse']}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {deleteBook && updateBook &&
                                                                        <div className='m-0 p-0 text-muted mx-auto'>
                                                                            <div className='mx-auto text-center'>
                                                                                <div>
                                                                                    <button type="button" onClick={()=>{updateBook(sabbathtruth)}} className="btn btn-primary m-1 p-1">
                                                                                        <span className='fa fa-edit'></span> Edit
                                                                                    </button>
                                                                                    
                                                                                    <button type="button" onClick={()=>{deleteBook(sabbathtruth)}} className="btn btn-danger m-1 p-1">
                                                                                        <span className='fa fa-trash'></span> Delete
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div> 
                                                        }
                                                    </div>    
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="others"
                                            title="Other Sections">
                                            <div className="row justify-content-center p-0 pt-2">
                                            {/* others */}
                                                {others[0] && others[0].length ===0 &&
                                                    <>
                                                    <div className='text-center m-0 p-3'>
                                                        <p className='m-0 p-4 text-great'>Please create Other Section Page</p>
                                                        <button type="button" onClick={()=>{setVerseActive('others')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                            <span className='fa fa-plus-circle'></span> Create any Other Section Page 
                                                        </button>
                                                    </div>
                                                    </>
                                                }
                                                    {others && others[0] && others[0].map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <i className='text-black text-muted'>{verse.title}</i>
                                                                    <a className='link-image' href={verse.link}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='m-0 p-2' width="100%" height="150px"  alt="First Image" /></a>
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-left p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{updateBook(verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteBook(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                            </div>
                                        </Tab>

                                        
                                </Tabs>
                                </div>  

                            </>

                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashBibleStudy;