import { useEffect, useState,useContext } from 'react';
import {LoginContext} from "../contexts/LoginContext";
import axios from 'axios';
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';


import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function Sermons() {
    document.title="Sermons | Utawala Satellite";
    const [sermons,setSermons]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getMins = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/sermons`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setSermons(response.data.sermons);
                            setLoadingPage(false);
                        }
                        else{
                            setSermons();
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setSermons();
                            setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setSermons();
                            setLoadingPage(false);
            })
        };
        getMins();

        return ()=>{
            doneloading=false;
            
        }
    },[])


  return (
    <>
    <HomeNavBar active='sermons'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className=" p-3 pt-4">
                    <div className=" text-white text-center" >
                        <p className='m-2 p-3 mb-0 pb-0 beliefs-p'>Our Sermons</p>
                    </div>
                </div>
                {loadingpage?
                <LoadingDetailsSpinner />
                :
                <div className="row justify-content-center p-5 pt-3">
                    {sermons && sermons.map((truth,key) => (
                        <div className="row justify-content-center bg-indigo  bg-opacity border-ok m-1 p-0">
                            
                            <div className="col-9 col-lg-6 m-0 p-2 ">
                                {(truth.mediatype ==='uploadedvideo' || truth.mediatype ==='uploadedaudio' ) &&
                                    <div className='m-1 p-1'>
                                        {truth.mediatype ==='uploadedvideo'?
                                            <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/sermons/'+truth.media} className='border-white-b video-sermon' width="90%" controls loop />
                                            :
                                            <audio src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/sermons/'+truth.media} className='border-white-b audio-sermon' width="350px" controls />
                                        }
                                    </div>
                                }

                                {(truth.mediatype ==='videourllink' || truth.mediatype ==='audiourllink' ) &&
                                    <>
                                    <div className='m-0 p-1 border-bottom events-div-h'>
                                        {truth.mediatype ==='videourllink'?
                                            <video src={truth.media} width="90%" controls loop />
                                            :
                                            <audio src={truth.media} width="90%" controls loop />
                                        }
                                    </div>
                                    </>
                                }

                                {truth.mediatype ==='youtubeid' &&
                                    <div className='m-0 p-1 border-bottom forum-div-h'> 
                                        <iframe
                                            width="100%"
                                            src={`https://www.youtube.com/embed/`+truth.media}
                                            frameborder="0"
                                            allow=""
                                            allowFullScreen
                                            title=" The Sermon Youtube Videos"
                                        />
                                        
                                    </div>
                                }

                            </div>
                            <div className='col-9 col-lg-3 m-0 p-0 my-auto mx-auto'>
                                <h4 className='text-center font-bold-700'>
                                    <span className='text-center text-warning font-bold-700 text-xl'></span> {truth.sermon}
                                </h4>
                                {truth.summary !==null?
                                    <div className="text-center m-0 p-0">
                                        <div>
                                            {truth.summary !==null  && JSON.parse(truth.summary).map((versel) => (
                                                <p className='m-0 p-1'> {versel.verselinks_text} </p>
                                            ))}
                                        </div>
                                    </div>
                                :""}
                            </div>
                            <div className='col-9 col-lg-3 m-0 p-0 my-auto mx-auto '>
                                <div className='p-3 my-auto mx-auto text-center'>
                                    <div className='text-center my-auto mx-auto'>
                                        <button type="button"  className="btn btn-success m-1 p-1">
                                            <span className='fa fa-info-circle'></span> View
                                        </button>
                                        {(truth.mediatype ==='uploadedvideo' || truth.mediatype ==='uploadedaudio' ) &&
                                        <a href={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/sermons/'+truth.media} target='_blank'>
                                            <button type="button" className="btn btn-info m-1 p-1">
                                                <span className='fa fa-download'></span> Download
                                            </button>
                                        </a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                }
            </div>
            
        </div>

    
    </main>
    <HomeFooter />
      
    </>
  );
}

export default Sermons;