import { useEffect, useState } from 'react';

function LoadPrograms({type,data,updateProgram,deleteProgram}) {

    const [authname,setAuthName]=useState('');
        useEffect(()=>{
        if(!localStorage.getItem("auth_token_ut")){
            setAuthName('');
        }
        else{
            setAuthName(localStorage.getItem("auth_name_ut"));
        }
      },[authname])
    
  return (
    <div className="row justify-content-center p-0 pt-0">
        {data && data.map((p) => (
            <>
            {type==='All Programs'?
                <div className="col-12 col-md-4 m-0 p-0">
                    <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                        <div className='text-center m-0 p-1'>
                            
                            <div className='border-bottom-warning'></div>
                            <h4 className='font-bold-700'>
                                {p.program}
                            </h4>
                            {type==='All Programs'?<p className='m-0 p-0 text-indigo font-bold-700'><u>{p.type}</u></p>:""}
                            <p className='m-0 p-0 text-muted'><i className='font-bold-700'>{p.dateandtime}</i> </p>
                            <p className='m-0 p-1'>{p.venue}</p>
                            {p.notices !=''?<p className="text-left m-0 p-2 text-gray">{p.notices}</p>:""}
                            {p.desc !=''?<div className="border-bottom-top m-0 p-0">
                                <p className="text-left m-0 p-2">{p.desc}</p>
                            </div>:""}
                            {deleteProgram && updateProgram &&
                            <div className='m-0 p-0 text-muted my-auto'>
                                <div className='text-left pl-2'>
                                    <div>
                                        <button type="button" onClick={()=>{updateProgram(p)}} className="btn btn-primary m-1 p-1">
                                            <span className='fa fa-edit'></span> Edit
                                        </button>
                                        
                                        <button type="button" onClick={()=>{deleteProgram(p)}} className="btn btn-danger m-1 p-1">
                                            <span className='fa fa-trash'></span> Del
                                        </button>
                                    </div>
                                </div>
                            </div>
                            }
                            
                        </div>   
                    </div>
                </div>
                :
                    <>
                    {type===p.type && type != 'All Programs' &&
                        <div className="col-12 col-md-4 m-0 p-0">
                            <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                                <div className='text-center m-0 p-1'>
                                    
                                    <div className='border-bottom-warning'></div>
                                    <h4 className='font-bold-700'>
                                        {p.program}
                                    </h4>
                                    {type==='all'?<p className='m-0 p-0 text-indigo font-bold-700'><u>{type}</u></p>:""}
                                    <p className='m-0 p-0 text-muted'><i className='font-bold-700'>{p.dateandtime}</i> </p>
                                    <p className='text-left m-0 p-1'>{p.venue}</p>
                                    {p.notices !=''?<p className="text-left m-0 p-2 text-gray">{p.notices}</p>:""}
                                    {p.desc !=''?<div className="border-bottom-top m-0 p-0">
                                        <p className="text-left m-0 p-2">{p.desc}</p>
                                    </div>:""}
                                    {deleteProgram && updateProgram &&
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <div>
                                                <button type="button" onClick={()=>{updateProgram(p)}} className="btn btn-primary m-1 p-1">
                                                    <span className='fa fa-edit'></span> Edit
                                                </button>
                                                
                                                <button type="button" onClick={()=>{deleteProgram(p)}} className="btn btn-danger m-1 p-1">
                                                    <span className='fa fa-trash'></span> Del
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>   
                            </div>
                        </div>
                    }
                    </>
            }
            </>
            
        ))}

        
    </div>
  );
}

export default LoadPrograms;