import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import ReLogin from '../home/ReLogin';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

import {LoginContext} from "../contexts/LoginContext";

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashProfile(props) {
    document.title="Dashboard Change Password | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [currentuser,setCurrentUser]=useState([]);
    const [addevent, setAddevent]=useState(false);
    const [currentevent,setCurrentEvent]=useState('');


    const [formdata,setFormData]=useState({
        fname:'',
        lname:'',
        email:'',
        image:'',
        phone:'',
        error_list:[],
    });

    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            loadThisUsersLogs();
        }
        return ()=>{
            doneloading=false;
        }
    },[currentuser.id])
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    
    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'video/*,audio/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const newUser= (e)=>{
        e.preventDefault();
        if(formdata.fname ===''){
            Swal("Enter First Name","Please Enter Users First Name","error");
            return;
        }
        if(formdata.lname ===''){
            Swal("Enter Last Name","Please Enter Users Last Name","error");
            return;
        }
        if(formdata.username ===''){
            Swal("Enter Username Name","Please Enter Users Username Name","error");
            return;
        }

        if(formdata.email ===''){
            Swal("Enter Email Address","Please Enter Users Email Address","error");
            return;
        }

        if((formdata.phone !=='' && (formdata.phone).trim() !=='' && isNaN(formdata.phone) && ((formdata.phone).trim()).length !== 12) ){
            Swal("Correct Phone Number","Please Specify Phone Number like 254712345678","error");
            return;
        }
        
        
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")

        const form={
            id:(currentuser!=='')?currentuser.id:'',
            fname:formdata.fname,
            lname:formdata.lname,
            email:formdata.email,
            phone:formdata.phone,
            userrole:formdata.userrole,
            image:files,
        }
            axios.post('/api/save/user',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    loadThisUsersLogs();
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }


    

    const loadThisUsersLogs= ()=>{
        setLoadingPage(true)
        let url=`/api/load/currentuser`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setCurrentUser(response.data.user);
                
                localStorage.setItem('auth_image_ut',(response.data.user['image']===null)?'':response.data.user['image']);
                setFormData({
                    fname:response.data.user['fname'],
                    image:response.data.user['image'],
                    lname:response.data.user['lname'],
                    email:response.data.user['email'],
                    phone:response.data.user['phone'],
                    userrole:response.data.user['userrole'],
                    error_list:[],
                })
                setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
        })

    }

   
    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <Link to="/dashboard" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Home</Link>
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Change Your Account Details</h1>
                    </div>

                    <div className="card-body">
                        <form className='justify-content-center' onSubmit={newUser}>
                        {loadingpage?
                            <LoadingDetailsSpinner />
                            // <div className="bg-secondary p-3 text-center text-lg">
                            //     <h4 className='text-center'>Loading Your Account Details...</h4>
                            // </div>
                            :
                            <>
                            {!loading && 
                                <>
                                    <div className="form-group row">
                                        <label for="Fname" className="col-md-4 col-form-label text-md-right">First Name <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Fname" type="text" className="form-control" name="fname" placeholder="First Name" value={formdata.fname} onChange={handleInputChange} required autoComplete="Fname" autoFocus/>
                                            {formdata.error_list && formdata.error_list.fname && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.fname}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="Lname" className="col-md-4 col-form-label text-md-right">Last Name <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Lname" type="text" className="form-control" name="lname" placeholder="Last Name" value={formdata.lname} onChange={handleInputChange} required autoComplete="Lname" autoFocus/>
                                            {formdata.error_list && formdata.error_list.lname && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.lname}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                
                                    <div className="form-group row">
                                        <label for="Email" className="col-md-4 col-form-label text-md-right">Email Address <sub className='text-red text-sm'>*</sub></label>
                                        <div className="col-md-6 ">
                                            <input id="Email" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Email" autoFocus/>
                                            {formdata.error_list && formdata.error_list.email && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.email}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="phone" className="col-md-4 col-form-label text-md-right">Phone Number <sub className='text-red text-sm'>*</sub> </label>
                                        <div className="col-md-6 ">
                                            <input id="phone" type="text" className="form-control" name="phone" placeholder="254700000000" value={formdata.phone} onChange={handleInputChange} required autoComplete="Phone" autoFocus/>
                                            {formdata.error_list && formdata.error_list.phone && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.phone}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="image" className="col-md-4 col-form-label text-md-right">Photo/ Image </label>

                                        <div className="col-md-6">

                                            <div className='dropArea' {...getRootProps({style})}>
                                                <input {...getInputProps()} type='file' accept='image/*' />
                                                <p>
                                                    Click or Drop Photo / Image Here
                                                </p>
                                                
                                            </div>

                                            {formdata.error_list && formdata.error_list.image && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.image}</strong>
                                                </span>
                                            }
                                                

                                            <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                <div>
                                                    {files && files.map((file,key) =>(
                                                        <div className='m-0 p-1 border-bottom'>
                                                            <p className='m-0 p-0'>Selected Image</p>
                                                            <img src={file.preview} alt='No Photo Selected for Upload' width="150px" height="150px" className="ministry-img-circle  m-0 p-0 mx-auto" />
                                                        </div>
                                                    ))}                                 
                                                </div>

                                                <div>
                                                    {currentuser !=='' &&
                                                    <>
                                                        {currentuser.image !==null &&
                                                            <div className='m-0 p-1 border-bottom'>
                                                                <p className='m-0 p-0'>Uploaded Image</p>
                                                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/users/'+currentuser.image} alt='Does Not Have Image' width="150px" height="150px" className="ministry-img-circle  m-0 p-0 mx-auto"/>
                                                            </div>
                                                        }
                                                    </>
                                                        
                                                    }                                 
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>
                            }

                            {loadingresok!=='' && 
                                <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                    <span className="help-block text-success">
                                        <strong>{loadingresok!=='' && loadingresok}</strong>
                                    </span>
                                </div>
                            }

                            {loading && 
                                <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>Updating Profile...</span>
                                        
                                </div>
                            }

                            {loadingres!=='' && 
                                <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                    <span className="help-block text-danger">
                                        <strong>{loadingres!=='' && loadingres}</strong>
                                    </span>
                                </div>
                            }
                            {!loading && loadingresok ==='' && 
                                <div className="form-group d-flex mb-0">
                                    <div className="mx-auto">
                                        <button type="submit" className="btn btn-outline-success border-info pl-4 pr-4">
                                            Save Profile Chnages
                                        </button>
                                    </div>
                                </div>
                            }


                        </>
                        }
                        </form>

                        
                    </div>
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashProfile;