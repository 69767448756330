import { Link } from 'react-router-dom';
import {useState,useContext, useCallback } from 'react';


import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}




function DashChangePassword(props) {
    document.title="Dashboard Change Password | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [events,setEvents]=useState([]);
    const [addevent, setAddevent]=useState(false);
    const [currentevent,setCurrentEvent]=useState('');


    const [formdata,setFormData]=useState({
        email:'',
        password:'',
        newpassword:'',
        confirmpassword:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newPasswordChange= (e)=>{
        e.preventDefault();

        if((formdata.password).trim() ===''){
            Swal("Current Password is Required","Please Enter Current Password","error");
            return;
        }

        if((formdata.newpassword).trim() ===''){
            Swal("New Password is Required","Please Enter New Password","error");
            return;
        }

        if((formdata.confirmpassword).trim() ===''){
            Swal("Confirm New Password is Required","Please Enter Confirm New Password","error");
            return;
        }

        if((formdata.newpassword).trim() !== (formdata.confirmpassword).trim()){
            Swal("Password Must Match","Please Enter Matching password","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
            password:formdata.password,
            newpassword:formdata.newpassword,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/change/password',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                console.log(error)
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }




   
    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <Link to="/dashboard" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Home</Link>
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Change Your Account Password</h1>
                    </div>

                    <div className="card-body">
                        <form className='justify-content-center' onSubmit={newPasswordChange}>
                            {!loading && 
                                <>
                                    <div class="form-group row">
                                        <label for="password" class="col-md-4 col-form-label text-md-right">Current Password</label>

                                        <div class="col-md-6">
                                            <input id="password" type="password" className="form-control @error('password') is-invalid @enderror" name="password" placeholder="Current Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                            {formdata.error_list && formdata.error_list.password && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.password}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="newpassword" class="col-md-4 col-form-label text-md-right">New Password</label>

                                        <div class="col-md-6">
                                            <input id="newpassword" type="password" className="form-control @error('password') is-invalid @enderror" name="newpassword" placeholder="New Password" value={formdata.newpassword} onChange={handleInputChange} required autoComplete="current-password"/>
                                            {formdata.error_list && formdata.error_list.newpassword && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.newpassword}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="confirmpassword" class="col-md-4 col-form-label text-md-right">Confirm New Password</label>

                                        <div class="col-md-6">
                                            <input id="confirmpassword" type="password" className="form-control @error('confirmpassword') is-invalid @enderror" name="confirmpassword" placeholder="Confirm New Password" value={formdata.confirmpassword} onChange={handleInputChange} required/>
                                            
                                        </div>
                                    </div>
                                </>
                            }

                            {loadingresok!=='' && 
                                <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                    <span className="help-block text-success">
                                        <strong>{loadingresok!=='' && loadingresok}</strong>
                                    </span>
                                </div>
                            }

                            {loading && 
                                <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>Changing Password...</span>
                                        
                                </div>
                            }

                                    {loadingres!=='' && 
                                        <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }
                                    {!loading && loadingresok ==='' && 
                                        <div className="form-group d-flex mb-0">
                                            <div className="mx-auto">
                                                <button type="submit" className="btn btn-outline-success border-info pl-4 pr-4">
                                                    Save New Password
                                                </button>
                                            </div>
                                        </div>
                                    }


                        </form>

                        
                    </div>
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashChangePassword;