
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';


import { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router';
import axios from 'axios';
import queryString from 'query-string';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

function AboutEvent() {
    document.title="Events | Utawala Satellite";
    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified,loggedrole, setLoggedRole} =useContext(LoginContext);
    
    const [event,setEvent]=useState('');
    const [events,setEvents]=useState([]);
    const [eventid,setEventID]=useState('');
    const [currentevent,setCurrentEvent] =useState('');

    const [loading,setLoading]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);

    let loc=useLocation()
    useEffect( () =>{
        const {event,id}=queryString.parse(loc.search);
        setEvent(event)
        setEventID(id)
    },[loc])
    document.title=event+ " | Utawala Satellite";
    
    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
                loadThisEvent();
        }
        return ()=>{
            doneloading=false;
        }
    },[event])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getEvents = async (e) => { 
            // let url=`/api/get/load/event/${(eventid!='')?eventid:''}`;
                axios.get('/sanctum/csrf-cookie').then(res =>{
                    axios.get(`/api/get/load/event/${(eventid!='')?eventid:''}`)
                    .then(response=>{
                        if (doneloading) {
                            if(response.data.status=== 200){
                                setCurrentEvent(response.data.event);
                                setLoadingPage(false);
                            }
                            else{
                                setCurrentEvent([]);
                                setLoadingPage(false);
                            }
                            
                        }
                    })
                    .catch(error=>{
                        setCurrentEvent([]);
                                setLoadingPage(false);
                    })
                })
                .catch((error)=>{
                    setCurrentEvent([]);
                                setLoadingPage(false);
                })
            // }
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[])


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true);
        const getEvents = async (e) => { 
            if(loggedname !==''){
                axios.get(`/api/get/load/eventt`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setEvents(response.data.events);
                            setLoadingPage(false);
                        }
                        else{
                            setEvents([]);
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch((error)=>{
                    setEvents([]) 
                        setLoadingPage(false);
                })
            }
            else{
                axios.get('/sanctum/csrf-cookie').then(res =>{
                    axios.get(`/api/get/load/events`)
                    .then(response=>{
                        if (doneloading) {
                            if(response.data.status=== 200){
                                setEvents(response.data.events);
                                setLoadingPage(false);
                            }
                            else{
                                setEvents([]);
                                setLoadingPage(false);
                            }
                            
                        }
                    })
                    .catch(error=>{
                        setEvents([]);
                                setLoadingPage(false);
                    })
                })
                .catch((error)=>{
                    setEvents([]);
                                setLoadingPage(false);
                })
            }
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    

    const loadThisEvent= ()=>{
        setLoadingPage(true);
        let url=`/api/get/load/event/${(eventid!=='')?eventid:''}`;
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                    if(response.data.status=== 200){
                        setCurrentEvent(response.data.event);
                                setLoadingPage(false);
                    }
                    else{
                        setCurrentEvent('');
                                setLoadingPage(false);
                    }
            })
            .catch(error=>{
                setCurrentEvent('');
                                setLoadingPage(false);
            })
        })
        .catch((error)=>{
            setCurrentEvent('');
                                setLoadingPage(false);
        })
    }


    const setBackToEvent = () =>{
        setEvent('')
        setEventID('')
        setCurrentEvent('')
    }

  return (
    <>
    <HomeNavBar active='events'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>      
        <div className="bg-primary">
            <div className=''>
                {/* <div className='events-container'> */}
                {loadingpage ?
                <LoadingDetailsSpinner />
                :
                <div className="m-0 p-0">
                    <div className=" text-white text-center" >
                        <div className="bg-white border-white-b  elevation-2">
                            <h1 className='text-center text-success mt-2 p-2 text-bold ministryheader-title-center' >{event}
                                <br/>
                            </h1>
                            
                            {/* <button type="button" onClick={()=>{setBackToEvent()}} className="btn btn-outline-secondary border-none m-1 p-1">
                                <i className='fa fa-chevron-left'></i> Back to all Events
                            </button> */}
                            <Link to="/aboutus/events" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Back to all Events</Link>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-9'>
                                <div className="events-container row justify-content-center p-3 pt-3">
                                {eventid!=='' &&
                                    <div className='row justify-content-center mx-auto my-auto'>
                                        <div className='col-12 col-md-8 text-center m-0 p-0'>
                                            {currentevent.image ===null?
                                                <div className='bg-indigo events-date border-light events-center'>
                                                    <p className='events-center events-date-day'>{currentevent.fromday}</p>
                                                    <p className='events-center events-date-month'>{currentevent.frommonth}</p>
                                                </div>
                                                :
                                                <div className='bg-indigo border-light '>
                                                    <p className='p-1 '><span className='events-date-day'>{currentevent.fromday}</span> ,<span className='events-date-day'>{currentevent.frommonth}</span> </p>
                                                </div>
                                            }
                                            <p className='p-2 text-xl'>{currentevent.event}</p>
                                            {currentevent.link !==null &&
                                            <div>
                                                <div className='col-12 p-0 bg-opacity-6 events-center'>
                                                    <a href={currentevent.link} target='_blank'>
                                                        <button className='btn btn-home'>More Info <span className='fa fa-arrow-right'></span></button>
                                                    </a>
                                                </div>
                                            </div>}
                                            {currentevent.image ===null?'':<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+currentevent.image} className='border-none m-0 p-2' width="100%" height="180px"  alt="First Image" />}
                                        </div> 
                                        
                                        <div className='col-12 col-md-4 text-center  bg-opacity-6 text-white mx-auto my-auto'>
                                            {currentevent.fromdate !== currentevent.todate && currentevent.todate !==null &&
                                                <p className='bg-indigo p-2 border-ok'>From:<span className='p-2'>{currentevent.fromdate}</span> To:<span className='p-2'>{currentevent.todate}</span> </p>
                                            }

                                            {currentevent.todate === null &&
                                                <p className='bg-indigo p-2 border-ok'><span className='p-2'>{currentevent.fromdate}</span> </p>
                                            }

                                            {currentevent.fromdate === currentevent.todate && 
                                                <p className='bg-indigo p-2 border-ok'><span className='p-2'>{currentevent.fromdate}</span> </p>
                                            }
                                            <div className='d-flex justify-content-between'>
                                                <div className='m-0 p-0'>
                                                    <img src={time} className='img-events-venue' alt="Time" />
                                                </div>
                                                <div className='m-0 p-0 mx-auto my-auto'>
                                                    <span className='text-sm m-0 p-0'>
                                                        {currentevent.fromtime} - {currentevent.totime}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='m-0 p-0'>
                                                    <img src={venue} className='img-events-venue' alt="Venue" />
                                                </div>
                                                <div className='m-0 p-0 mx-auto my-auto text-sm'>
                                                    {currentevent.venue}
                                                </div>
                                            </div>

                                            
                                            {/* <div>
                                                <div className='col-12 p-2 bg-opacity-6 events-center'>
                                                <Link to='#'> <button type='button' className='btn btn-home'>Register for event comming soon!! </button> </Link>
                                                    <Link to='#'> <button className='btn btn-home'>View Event <span className='fa fa-arrow-right'></span></button> </Link>
                                                </div>
                                            </div> */}
                                        </div> 

                                        {/* <div className="col-12 m-3 p-3 ">
                                            
                                            {currentevent.image ===null?'':<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+currentevent.image} className='border-none m-0 p-2' width="100%" height="180px"  alt="First Image" />}
                                            
                                        </div>
                                        <div className='col-4 bg-opacity-6 events-center'>
                                            <a href='https://tendaysofprayer.org/' target='_blank'>
                                                <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                            </a>
                                        </div> */}
                                    </div>
                                }
                                </div>
                            </div>
                            <div className='col-12 col-md-3 bg-white'>
                                {events && events.map((eventt,key) =>(
                                    <>
                                    {eventt.id !==currentevent.id &&
                                        <div className='col-12 p-2 bg-opacity-6 text-left'>
                                            <Link to={`/aboutus/event?event=${eventt.event}&id=${eventt.id}`}> <button className='btn btn-link'>{eventt.event} </button> </Link>
                                        </div>
                                    }
                                    </>
                                ))}
                            </div>

                        </div>
                        

                    </div>
                </div>
                }

            </div>
            
        </div>

    </main>
        <HomeFooter />
    </>
  );
}

export default AboutEvent;