import { Link, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HomeNavBar from '../home/HomeNavBar';



function Dashnotfound() {
    document.title="Dashboard | Utawala Satellite";

    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');

    useEffect(()=>{
        if(!localStorage.getItem("auth_token_ut")){
            setAuthName('');
            setRedirect(true);
            setUrl('/login');
        }
        else{
            setAuthName(localStorage.getItem("auth_name_ut"));
        }
      },[authname])

    if(redirect){
        return <Navigate to={url} />
    }

  return (
    <>
    <HomeNavBar active='home'/>
    <main style={{"paddingTop": "80px","minHeight": "calc(100vh - 3rem)"}}>
        <div class="container">
            <div className="">
                <Link to="/dashboard" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Home</Link>
                
                <div class="row justify-content-center">
                <div className="col-md-8 m-2 p-2 mt-4 mb-4">
                    <div className="card border-danger" >
                        <div className="card-header bg-danger text-white elevation-2 m-0 p-2">
                            <h4 style={{"textAlign": "center"}}>Oops!!! </h4>
                            <h4 style={{"textAlign": "center"}}>Nothing Found Here </h4>
                        </div>

                        <div className="card-body text-center p-3" style={{"paddingTop": "10px"}}>
                            <p>
                            Its Like what you are looking for could not be found
                            </p>
                            <p>
                                Try Using our navigation links above.
                            </p>
                            <p>
                                Also, use search bar for more 
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            </div>
        </div>
    </main>
    </>
  );
}


export default Dashnotfound;