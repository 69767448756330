import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashTithes(props) {
    document.title="Dashboard Tithes and Offerings | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [tithes,setTithes]=useState([]);
    const [addtithe, setAddtithe]=useState(false);
    const [currenttithe,setCurrentTithe]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);

// verse text image
    const [formdata,setFormData]=useState({
        image:'',
        verse:'',
        text:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newLesson= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // verse text image
        const form={
            id:(currenttithe!=='')?currenttithe['id']:'',
            image:files,
            verse:formdata.verse,
            text:formdata.text,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/tithe',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadTithes();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                console.log(error)
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const loadTithes= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/tithe`)
        .then(response=>{
            if(response.data.status=== 200){
                setTithes(response.data.tithes);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setTithes([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setTithes([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
                setLoadingPage(false)
            setTithes([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getEvents = async (e) => { 
            
            await axios.get(`/api/load/tithe`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setTithes(response.data.tithes);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setTithes([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setTithes([]);
                        setLoadingPage(false)
                    }
                    else{
                        setTithes([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                        setLoadingPage(false)
                setTithes([]);
            })
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );


    const updateTithe = (tithe) => {
        setAddtithe(true)
        setCurrentTithe(tithe)

        setFormData({
            image:tithe['image'],
            verse:tithe['verse'],
            text:tithe['text'],
            error_list:[],
        })
        
    };

    const setAddtithes = (ev)=> {
        setAddtithe(!addtithe);
        setCurrentTithe('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            image:'',
            verse:'',
            text:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteTithe= (tithe)=>{
        const form={
            id:tithe.id,
        }

        let title='Delete '+tithe['verse'];
        let text="This will remove this Tithe and Offering Page from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/tithe',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadTithes();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Tithes and Offerings</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {currenttithe !=='' &&
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddtithes()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-list'></span> Back to Tithes & Offerings 
                                </button>
                            </p>
                        }
                        {!tithes &&
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                Create New Tithe and Offering Page
                            </p> 
                        }

                        {!tithes || currenttithe !==''?
                            <form className='justify-content-center' onSubmit={newLesson}>
                                {!loading && loadingresok==='' &&
                                    <>
                                    {/* // verse text image */}   
                                        <div className="form-group row">
                                            <label for="verse" className="col-md-3 col-form-label text-md-right">Verse <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="verse" type="text" className="form-control" name="verse" placeholder="Lesson Verse" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                {formdata.error_list && formdata.error_list.verse && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.verse}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="text" className="col-md-3 col-form-label text-md-right">Text <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="text" className="form-control" name='text' placeholder="Memory Text here" value={formdata.text} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.text && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.text}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="image" className="col-md-3 col-form-label text-md-right">Photo <sub className='text-red text-sm'>*</sub></label>

                                            <div className="col-md-7">

                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Tithe & Offering Page Image Here
                                                    </p>
                                                    
                                                </div>
                                                    

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currenttithe !=='' &&
                                                        <>
                                                            {currenttithe.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/tithe/'+currenttithe.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddtithes()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currenttithe!==''?'Updating Tithe & Offering Page':'Saving Tithe & Offering Page'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currenttithe!==''?'Update Tithes & Offering Page':'Save Tithes & Offering Page'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center pt-3">
                                    <div className='container m-0 p-2'>
                                        <div className='bg-white text-center p-3'>
                                            {/* <h1 className='p-3'>Tithes and Offerings</h1> */}
                                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/tithe/'+tithes['image']} className='border-white-b' width="50%" height="100px"  alt="First Image" />
                                            <h4 className='p-3 text-black text-xl font-bold-700'>{tithes['verse']}</h4>
                                            <p className='p-3'> 
                                                “{tithes['text']}
                                            </p>
                                            <p>
                                                <Link to='tithes/give' target='_blank'>
                                                    <button className='btn btn-outline-orange ml-3 pl-5 pr-5 font-bold-700'>Give Now</button>
                                                </Link>
                                            </p>
                                        </div>
                                        <div className='justify-content-center m-2 p-3 text-muted mx-auto'>
                                            <div className='bg-light p-3 mx-auto text-center'>
                                                <button type="button" onClick={()=>{updateTithe(tithes)}} className="btn btn-primary m-1 p-1">
                                                    <span className='fa fa-edit'></span> Edit
                                                </button>
                                                <button type="button" onClick={()=>{deleteTithe(tithes)}} className="btn btn-danger m-1 p-1">
                                                    <span className='fa fa-trash'></span> Del
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                
                                    
                                </div>

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashTithes;