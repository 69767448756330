import { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function TheBelief() {
    const [beliefs,setBeliefs]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true);
        const getPrograms = async (e) => { 
            
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/beliefs`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setBeliefs(response.data.beliefs);
                                setLoadingPage(false);
                        }
                        else{
                            setBeliefs([]);
                                setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setBeliefs([]);
                                setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setBeliefs([]);
                                setLoadingPage(false);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  return (
    <>
    {loadingpage?
    <LoadingDetailsSpinner />
    :
    <>
        {beliefs && beliefs.map((belief,key) =>(
            <div className="col-12 col-md-6 m-0 p-0 mb-2">
                {key === 0  || key=== 3 || key===4 || key===7 || key===8 || key===11 ?
                    <div className="bg-indigo texts-black text-center"  style={{"minHeight":"250px"}}>
                        <div className='text-center m-0 p-3'>
                            <h3>{belief.belief}</h3>
                            <a className='link-white font-bold-700 text-xl' href={belief.link} target='_blank'>
                                Explore
                            </a>

                            
                        </div>   
                    </div>

                :
                    <div className="bg-white texts-black text-center"  style={{"minHeight":"250px"}}>
                        <div className='text-center m-0 p-3'>
                            <h3>{belief.belief} </h3>
                            <a className='link-black font-bold-700 text-xl' href={belief.link} target='_blank'>
                                Explore
                            </a>

                            
                        </div>   
                    </div>
                }
                
            </div>
        ))}
    </>
    }   

        {/* <div className="col-12 col-md-6 m-0 p-0 mb-2">
            <div className="bg-indigo texts-black text-center"  style={{"minHeight":"250px"}}>
                <div className='text-center m-0 p-3'>
                    <h3>
                        Seventh-day Adventist Guidelines for Navigating High-Impact Topics and Situations
                    </h3>
                    <a className='link-white font-bold-700 text-xl' href='https://www.adventist.org/beliefs/guidelines/' target='_blank'>
                        Explore
                    </a>
                </div>   
            </div>
        </div>

        <div className="col-12 col-md-6 m-0 p-0 mb-2">
            <div className="bg-white texts-black text-center"  style={{"minHeight":"250px"}}>
                <div className='text-center m-0 p-3'>
                    <h3>
                        Seventh-day Adventist Guidelines for Navigating High-Impact Topics and Situations
                    </h3>
                    <a className='link-black font-bold-700 text-xl' href='https://www.adventist.org/beliefs/' target='_blank'>
                        Explore
                    </a>
                </div>   
            </div>
        </div>


        <div className="col-12 col-md-6 m-0 p-0 mb-2">
            <div className="bg-white texts-black text-center"  style={{"minHeight":"250px"}}>
                <div className='text-center m-0 p-3'>
                    <h3>
                        Seventh-day Adventist Guidelines for Navigating High-Impact Topics and Situations
                    </h3>
                    <a className='link-black font-bold-700 text-xl' href='https://www.adventist.org/official-statements/' target='_blank'>
                        Explore
                    </a>
                </div>   
            </div>
        </div>

        <div className="col-12 col-md-6 m-0 p-0 mb-2">
            <div className="bg-indigo texts-black text-center"  style={{"minHeight":"250px"}}>
                <div className='text-center m-0 p-3'>
                    <h3>
                        Seventh-day Adventist Guidelines for Navigating High-Impact Topics and Situations
                    </h3>
                    <a className='link-white font-bold-700 text-xl' href='https://www.adventist.org/beliefs/documents/' target='_blank'>
                        Explore
                    </a>
                </div>   
            </div>
        </div> */}

    </>
  );
}

export default TheBelief;