import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashBibleManageMinistry({currentministry,setCurrentMinistry,loadMinistries}) {
    document.title="Dashboard Manage "+currentministry.ministry+ " | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('mission');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);

    const [verseoftheday,setVerseOfTheDay]=useState([]);
    const [nextafterverseoftheday,setNextAfterVerse]=useState([]);
    const [hopeinGodsword,setHopeinGodsWord]=useState([]);
    const [featuredwebsites,setFeaturedWebsites]=useState([]);
    const [sabbathtruth,setSabbathTruth]=useState([]);
    const [others,setOthers]=useState([]);

    const [books,setBooks]=useState([]);
    const [addbible, setAddbible]=useState(false);
    const [addbook, setAddbook]=useState(false);
    const [currentbible,setCurrentBible]=useState('');
    const [currentbook,setCurrentBook]=useState('');

    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');
    const [section, setSection]= useState('');
    const [currentsection, setCurrentSection]= useState('');

    const [ministrymembers,setMinistryMembers]= useState([])
    const [ministryprojects,setMinistryProjects]= useState([])
    const [ministryprograms,setMinistryPrograms]= useState([])

    const [currentministrymember,setCurrentMinistryMembers]= useState('')
    const [currentministryproject,setCurrentMinistryProjects]= useState('')
    const [currentministryprogram,setCurrentMinistryPrograms]= useState('')

   
    //salutation position membername gender image summary phone email
    // {/* projectname ministry image summary status */}
    // {/* programname ministry image summary programdate time venue */}
    const [formdata,setFormData]=useState({
        salutation:'',
        image:'',
        position:'',
        membername:'',
        gender:'',
        summary:'',
        phone:'',
        email:'',
        projectname:'',
        programname:'',
        programdate:'',
        time:'',
        venue:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_text===''){
            if(section==='Mission'){
                Swal("Error","Please Enter Ministry Mission Paragraph");
            }
            else if(section==='Vision'){
                Swal("Error","Please Enter Ministry Vision Paragraph");
            }
            
        }
        else{
            const {verselinks_text}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        if(section==='Mission'){
                            Swal("Error","Ministry Mission already Added");
                        }
                        else if(section==='Vision'){
                            Swal("Error","Ministry Vision already Added");
                        }
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        if(section==='Mission'){
                            Swal("Error","Ministry Mission already Added");
                        }
                        else if(section==='Vision'){
                            Swal("Error","Ministry Vision already Added");
                        }
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        
        setVerselinks({
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    
    const setUpdateWhatSection = (value,current) =>{
        setLoadingPage(true)
        setSection(value);
        setCurrentSection(current)
        setLoadingRes("")
        setLoadingResOk("")
        if(value==='mission' && currentministry.mission!==null){
            setLinkItems(JSON.parse(currentministry.mission))
        }
        else if(value==='vision' && currentministry.vision!==null){
            setLinkItems(JSON.parse(currentministry.vision))
        }
        else{
            setLinkItems('');
        }

        if(value==='member' && current !==''){
            setCurrentMinistryMembers(current);
            setFormData({
                salutation:current.salutation,
                image:current.image,
                position:current.position,
                membername:current.membername,
                gender:current.gender,
                summary:current.summary,
                phone:current.phone,
                email:current.email,
                error_list:[],
            })
            setLoadingPage(false)
        }
        else if(value==='project' && current !==''){
            setCurrentMinistryProjects(current);
            setFormData({
                image:current.image,
                projectname:current.projectname,
                summary:current.summary,
                error_list:[],
            })
            setLoadingPage(false)
        }
        else if(value==='program' && current !==''){
            setCurrentMinistryPrograms(current);
            setFormData({
                programdate:current.programdate,
                image:current.image,
                time:current.time,
                programname:current.programname,
                venue:current.venue,
                summary:current.summary,
                error_list:[],
            })
            setLoadingPage(false)
        }
        else{
            setCurrentMinistryMembers('');
            setCurrentMinistryProjects('');
            setCurrentMinistryPrograms('');
            setFormData({
                salutation:'',
                image:'',
                position:'',
                membername:'',
                gender:'',
                summary:'',
                phone:'',
                email:'',
                projectname:'',
                programname:'',
                programdate:'',
                time:'',
                venue:'',
                error_list:[],
            })
            setFiles([])
            setLoadingPage(false)
        }
    }

    const setVerseActive=(status)=>{
        // console.log(status)
        setAddbook(!addbook);
        setAddbible(false);
        setCurrentBook('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            biblev:status,
            versequote:'',
            verse:'',
            image:'',
            error_list:[],
        })
        setFiles([])
        setLoadingRes('');
    }

    

    const newBook= (e)=>{
        e.preventDefault();

        if((formdata.title).trim() ===''){
            Swal("Enter Section Title","Please Enter Section Title","error");
            return;
        }

        if(formdata.biblev ==='verseoftheday' && (formdata.verse).trim() ===''){
            Swal("Enter Section Verse","Please Enter Section Verse","error");
            return;
        }
        else if(formdata.biblev ==='verseoftheday' && (formdata.versequote).trim() ===''){
            Swal("Enter Section Verse Text","Please Enter Section Verse Text","error");
            return;
        }
        else if(formdata.biblev !='verseoftheday' && (formdata.verse).trim() ===''){
            Swal("Enter Section Link","Please Enter Section Link","error");
            return;
        }


        if(files ===''){
            Swal("Select Image","Please Select Image to represent A section","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentbook!=='')?currentbook.id:'',
            verse:formdata.verse,
            biblev:formdata.biblev,
            title:formdata.title,
            versequote:formdata.versequote,
            image:files,
        }
            axios.post('/api/save/biblestudysection',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadBibleStudySections();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }

    const updateMinistryMission= (e)=>{
        e.preventDefault();

        if(linkitems ===''){
            Swal("About Ministry is Missing","Enter About Ministry.","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            ministry:(currentministry!=='')?currentministry.id:'',
            section:section,
            mission:linkitems,
        }
            axios.post('/api/save/ministry/mission',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadThisMinistry();
                    loadMinistries();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }

    const updateMinistryVision= (e)=>{
        e.preventDefault();

        if(linkitems ===''){
            Swal("Ministry Vision is Missing","Enter Ministry Vision.","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            ministry:(currentministry!=='')?currentministry.id:'',
            section:section,
            vision:linkitems,
        }
            axios.post('/api/save/ministry/mission',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadThisMinistry();
                    loadMinistries();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }


    const updateMinistryMember= (e)=>{
        e.preventDefault();

        if((formdata.membername).trim() ===''){
            Swal("Enter Ministry Member Name","Please Enter Ministry Member Name","error");
            return;
        }

        if((formdata.salutation).trim() ===''){
            Swal("Enter Member Salutation","Please Enter Member Salutation","error");
            return;
        }
        if((formdata.position).trim() ===''){
            Swal("Enter Member Salutation","Please Enter Member Salutation","error");
            return;
        }
        if((formdata.gender).trim() ===''){
            Swal("Enter Member Salutation","Please Enter Member Salutation","error");
            return;
        }
        
        if(formdata.phone !=='' && (formdata.phone).trim() !=='' && isNaN(formdata.phone) && ((formdata.phone).trim()).length !== 12 ){
            Swal("Correct Phone Number","Please Specify Phone Number like 254712345678","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            id:(currentsection!=='')?currentsection.id:'',
            ministry:(currentministry!=='')?currentministry.id:'',
            salutation:formdata.salutation,
            position:formdata.position,
            membername:formdata.membername,
            gender:formdata.gender,
            summary:formdata.summary,
            phone:formdata.phone,
            email:formdata.email,
            image:files,
            section:section,
        }
            axios.post('/api/save/ministry/member',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadThisMinistryMembers();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }

    const updateMinistryProject= (e)=>{
        e.preventDefault();

        if((formdata.projectname).trim() ===''){
            Swal("Enter Ministry Project Name","Please Enter Ministry Project Name","error");
            return;
        }

        if((formdata.summary).trim() ===''){
            Swal("Enter Project Summary","Please Enter Project Summary","error");
            return;
        }
        

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            id:(currentsection!=='')?currentsection.id:'',
            ministry:(currentministry!=='')?currentministry.id:'',
            projectname:formdata.projectname,
            summary:formdata.summary,
            image:files,
            section:section,
        }
            axios.post('/api/save/ministry/project',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadThisMinistryProjects();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
                let ex=error['response'].data.message;
                // console.log(ex)
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true);    
                    }                
                }
            })
    }

    const updateMinistryProgram= (e)=>{
        e.preventDefault();

        if((formdata.programname).trim() ===''){
            Swal("Enter Ministry Program Name","Please Enter Ministry Program Name","error");
            return;
        }

        if((formdata.summary).trim() ===''){
            Swal("Enter Program Summary","Please Enter Program Summary","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            id:(currentsection!=='')?currentsection.id:'',
            ministry:(currentministry!=='')?currentministry.id:'',
            programdate:formdata.programdate,
            programname:formdata.programname,
            summary:formdata.summary,
            time:formdata.time,
            venue:formdata.venue,
            image:files,
            section:section,
        }
        axios.post('/api/save/ministry/program',form,{
            headers:{
                'content-type':'multipart/form-data'
            }
        })
        .then(response=>{
            if(response.data.status=== 200){
                setLoadingResOk(response.data.message)
                setLoadingRes("")
                setFormData({...formdata,error_list:[]});
                loadThisMinistryPrograms();
            }
            else if(response.data.status=== 401){
                setLoadingRes(response.data.message)
                setLoadingResOk("")
                setFormData({...formdata,error_list:[]});
            }
            else if(response.data.status=== 500){
                setLoadingRes(response.data.message)
                setLoadingResOk("")
                setFormData({...formdata,error_list:[]});
                Swal("Error","Please Update the Following errors","error");
            }
            else{
                setFormData({...formdata,error_list:response.data.errors});
            }
            setLoading(false);

        })
        .catch((error)=>{
            setLoadingRes(""+error)
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
            let ex=error['response'].data.message;
            // console.log(ex)
            if(ex==='Unauthenticated.'){
                if(!localStorage.getItem("auth_token_ut")){
                    setLoggedOff(true);    
                }                
            }
        })
    }

    const loadBibleStudySections= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/biblestudysections`)
        .then(response=>{
            if(response.data.status=== 200){
                
                if(response.data.verseoftheday ===null){
                    setVerseOfTheDay('')
                }
                else{
                    setVerseOfTheDay(response.data.verseoftheday);
                }
                if(response.data.sabbathtruth ===null){
                    setSabbathTruth('')
                }
                else{
                    setSabbathTruth(response.data.sabbathtruth);
                }
                setNextAfterVerse([response.data.nextafterverseoftheday]);
                setHopeinGodsWord([response.data.hopeinGodsword]);
                setFeaturedWebsites([response.data.featuredwebsites]);
                setOthers([response.data.others]);
                setLoadingPage(false)

            }
            else if(response.data.status=== 401){
                setLoggedOff(true);    
                Swal("Error1",response.data.message,"error");
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
            else if(response.data.status=== 500){
                Swal("Error2",response.data.message,"error");
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
            else{
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                setLoadingPage(false)
            }
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            // console.log(error.message)
            setVerseOfTheDay('');
            setNextAfterVerse([]);
            setHopeinGodsWord([]);
            setFeaturedWebsites([]);
            setSabbathTruth('');
            setOthers([]);
                setLoadingPage(false)
        })

    }

    

    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            loadThisMinistryMembers();
            loadThisMinistryProjects();
            loadThisMinistryPrograms();
            
        }
        return ()=>{
            doneloading=false;
            
        }
    },[currentministry.id])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/biblestudysections`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        
                        if(response.data.verseoftheday ===null){
                            setVerseOfTheDay('')
                        }
                        else{
                            setVerseOfTheDay(response.data.verseoftheday);
                        }
                        if(response.data.sabbathtruth ===null){
                            setSabbathTruth('')
                        }
                        else{
                            setSabbathTruth(response.data.sabbathtruth);
                        }
                        setNextAfterVerse([response.data.nextafterverseoftheday]);
                        setHopeinGodsWord([response.data.hopeinGodsword]);
                        setFeaturedWebsites([response.data.featuredwebsites]);
                        setOthers([response.data.others]);
                        setLoadingPage(false)

                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                        setLoadingPage(false)
                    }
                    else{
                        setVerseOfTheDay('');
                        setNextAfterVerse([]);
                        setHopeinGodsWord([]);
                        setFeaturedWebsites([]);
                        setSabbathTruth('');
                        setOthers([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                        setLoadingPage(false)
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    
    const loadThisMinistry= ()=>{
        setLoadingPage(true)
        let url=`/api/load/ministry/${(currentministry!=='')?currentministry.id:''}`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setCurrentMinistry(response.data.ministry);
            }
            else{
                
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
        })

    }

    const loadThisMinistryMembers= ()=>{
        setLoadingPage(true)
        let url=`/api/load/ministry/member/${(currentministry!=='')?currentministry.id:''}`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setMinistryMembers(response.data.members);
            }
            else{
                
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
        })

    }

    const loadThisMinistryProjects= ()=>{
        setLoadingPage(true)
        let url=`/api/load/ministry/project/${(currentministry!=='')?currentministry.id:''}`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setMinistryProjects(response.data.projects);
            }
            else{
                
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
        })

    }

    const loadThisMinistryPrograms= ()=>{
        setLoadingPage(true)
        let url=`/api/load/ministry/program/${(currentministry!=='')?currentministry.id:''}`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setMinistryPrograms(response.data.programs);
                setLoadingPage(false)
            }
            else{
                
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            
            setLoadingPage(false)
        })

    }


    //salutation position membername gender image summary phone email
    const updateBook = (serv) => {
        setAddbook(true)
        setCurrentBook(serv)
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            biblev:serv.biblev,
            image:serv.image,
            title:serv.title,
            verse:serv.verse,
            versequote:serv.versequote,
            error_list:[],
        })
        
    };


    


    const deleteBook= (service)=>{
        const form={
            id:service.id,
        }

        let title='Delete '+service.title;
        let text="This will remove this Section from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/biblestudysection',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadBibleStudySections()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadBibleStudySections();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const deleteMember= (service)=>{
        const form={
            id:service.id,
            filename:service.image,
        }

        let title='Delete '+service.membername;
        let text="This will remove this Member from the Ministry.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/ministry/member',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadThisMinistryMembers()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadThisMinistryMembers();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const deleteProject= (service)=>{
        const form={
            id:service.id,
            filename:service.image,
        }

        let title='Delete '+service.projectname;
        let text="This will remove this Project from the Ministry.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/ministry/project',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadThisMinistryProjects()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadThisMinistryProjects();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const deleteProgram= (service)=>{
        const form={
            id:service.id,
            filename:service.image,
        }

        let title='Delete '+service.programname;
        let text="This will remove this Program from the Ministry.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/ministry/program',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadThisMinistryPrograms()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadThisMinistryPrograms();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    

                    <div className="card-body">
                        {section ==='mission' &&
                            <>
                                
                                <div className='mx-auto justify-content-center text-center'>
                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn btn-block btn-danger border-info ">
                                        <span className='fa fa-times'></span> Close 
                                    </button>
                                </div>

                                <form className='justify-content-center' onSubmit={updateMinistryMission}>
                                    <div className='m-1 p-2 border-ok'>
                                        <div className="form-group row">
                                            <label for="aboutus" className="col-md-3 col-form-label text-md-right">Ministry Mission <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 p-1">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <textarea id="verselinks_text" className="form-control m-1" name="verselinks_text" placeholder="Ministry Mission Paragraph" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus rows="3"></textarea>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Add'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.ministry && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.ministry}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) : {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                            {!loading && loadingresok !=='' && 
                                            <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>}
                                        </div>
                                            

                                        {loading && 
                                            <div className="col-md-12 text-center text-white">
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Section':'Creating Section'}...</span>
                                                    
                                            </div>
                                        }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-chevron-left'></span> Back 
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Ministry Mission':'Save Ministry Mission'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </form>
                                
                            </>
                        
                        }

                        {section ==='vision' &&
                            <>
                                
                                <div className='mx-auto justify-content-center text-center'>
                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn btn-block btn-danger border-info ">
                                        <span className='fa fa-times'></span> Close 
                                    </button>
                                </div>

                                <form className='justify-content-center' onSubmit={updateMinistryVision}>
                                    <div className='m-1 p-2 border-ok'>
                                        <div className="form-group row">
                                            <label for="aboutus" className="col-md-3 col-form-label text-md-right">Ministry Vision <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 p-1">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <textarea id="verselinks_text" className="form-control m-1" name="verselinks_text" placeholder="Ministry Vision Paragraph" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus rows="3"></textarea>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Add'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.ministry && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.ministry}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) : {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                            
                                            {!loading && loadingresok !=='' && 
                                            <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>}
                                        </div>
                                            

                                        {loading && 
                                            <div className="col-md-12 text-center text-white">
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Vision':'Creating Vision'}...</span>
                                                    
                                            </div>
                                        }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-chevron-left'></span> Back 
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Ministry Vision':'Save Ministry Vision'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </form>
                                
                            </>
                        
                        }

                        {section ==='member' &&
                            <>
                                
                                <div className='mx-auto justify-content-center text-center'>
                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn btn-block btn-danger border-info ">
                                        <span className='fa fa-times'></span> Close 
                                    </button>
                                </div>

                                <form className='justify-content-center' onSubmit={updateMinistryMember}>
                                    <div className='m-1 p-2 border-ok'>
                                        <p className='text-center m-1 p-2 text-lg'>Ministry Member details</p>
                                    {/* salutation position membername gender image summary phone email */}
                                        
                                        <div className="form-group row">
                                            <label for="membername" className="col-md-3 col-form-label text-md-right">Member's Name <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="membername" type="text" className="form-control" name="membername" required placeholder="Member's Name" value={formdata.membername} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.membername && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.membername}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    
                                        <div className="form-group row">
                                            <label for="salutation" className="col-md-3 col-form-label text-md-right">Member Salutation <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="salutation" type="text" className="form-control" name="salutation" required placeholder="Mr., Mrs., Ms., Dr. ,etc" value={formdata.salutation} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.salutation && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.salutation}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="position" className="col-md-3 col-form-label text-md-right">Member Position <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="position" type="text" className="form-control" name="position" required placeholder="Members Position" value={formdata.position} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.position && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.position}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        

                                        <div className="form-group row">
                                            <label for="gender" className="col-md-3 col-form-label text-md-right">Member's Gender  <sub className='text-red text-sm'>*</sub></label>
                                            {/* title verse versequote image gender */}
                                            <div className="col-md-7">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.gender==="Male"?"true":""} onChange={handleInputChange} className="" name="gender" value="Male" autoComplete="gender"/> Male
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.gender==="Female"?"true":""} onChange={handleInputChange} className="" name="gender" value="Female" autoComplete="gender"/> Female
                                                </label>
                                                {formdata.error_list && formdata.error_list.gender && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.gender}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="summary" className="col-md-3 col-form-label text-md-right">Member Summary <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="summary" className="form-control m-1" name="summary" placeholder="About Member" value={formdata.summary} onChange={handleInputChange} autoFocus rows="3"></textarea>
                                                {formdata.error_list && formdata.error_list.summary && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.summary}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="phone" className="col-md-3 col-form-label text-md-right">Member Phone Number </label>
                                            <div className="col-md-7">
                                                <input id="phone" type="text" className="form-control" name="phone" placeholder="254712345678" value={formdata.phone} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.phone && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.phone}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label for="email" className="col-md-3 col-form-label text-md-right">Member Email </label>
                                            <div className="col-md-7">
                                                <input id="email" type="email" className="form-control" name="email" placeholder="Member's Email Address" value={formdata.email} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right"> Member Photo </label>
                                            <div className='col-md-7'>
                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Member Photo Here
                                                    </p>
                                                    
                                                </div>

                                                {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Media</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentsection !=='' &&
                                                        <>
                                                            {currentsection.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Media</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/member/'+currentsection.image} width="90%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>
                                                
                                            </div>
                                        </div>

                                        

                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                            
                                            {!loading && loadingresok !=='' && 
                                            <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>}
                                        </div>

                                        
                                            

                                        {loading && 
                                            <div className="col-md-12 text-center text-white">
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Member':'Creating Member'}...</span>
                                                    
                                            </div>
                                        }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-chevron-left'></span> Back 
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Ministry Member':'Save Ministry Member'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </form>
                                
                            </>
                        
                        }

                        {section ==='project' &&
                            <>
                                <div className='mx-auto justify-content-center text-center'>
                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn btn-block btn-danger border-info ">
                                        <span className='fa fa-times'></span> Close 
                                    </button>
                                </div>

                                <form className='justify-content-center' onSubmit={updateMinistryProject}>
                                    <div className='m-1 p-2 border-ok'>
                                        <p className='text-center m-1 p-2 text-lg'>Ministry Project details</p>
                                    {/* projectname ministry image summary status */}
                                        
                                        <div className="form-group row">
                                            <label for="projectname" className="col-md-3 col-form-label text-md-right">Project Name <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="projectname" type="text" className="form-control" name="projectname" required placeholder="Project's Name" value={formdata.projectname} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.projectname && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.projectname}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                 
                                        <div className="form-group row">
                                            <label for="summary" className="col-md-3 col-form-label text-md-right">Project Summary <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="summary" className="form-control m-1" name="summary" placeholder="About Project" value={formdata.summary} onChange={handleInputChange} autoFocus rows="3"></textarea>
                                                {formdata.error_list && formdata.error_list.summary && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.summary}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right"> Project Photo </label>
                                            <div className='col-md-7'>
                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Project Photo Here
                                                    </p>
                                                    
                                                </div>

                                                {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Media</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentsection !=='' &&
                                                        <>
                                                            {currentsection.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Media</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryproject/'+currentsection.image} width="90%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>
                                                
                                            </div>
                                        </div>

                                        

                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                            
                                            {!loading && loadingresok !=='' && 
                                            <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>}
                                        </div>

                                        
                                            

                                        {loading && 
                                            <div className="col-md-12 text-center text-white">
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentsection!==''?'Updating Project':'Saving Project'}...</span>
                                                    
                                            </div>
                                        }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-chevron-left'></span> Back 
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentsection!==''?'Update Ministry Project':'Save Ministry Project'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </form>
                                
                            </>
                        
                        }


                        {section ==='program' &&
                            <>
                                <div className='mx-auto justify-content-center text-center'>
                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn btn-block btn-danger border-info ">
                                        <span className='fa fa-times'></span> Close 
                                    </button>
                                </div>

                                <form className='justify-content-center' onSubmit={updateMinistryProgram}>
                                    <div className='m-1 p-2 border-ok'>
                                        <p className='text-center m-1 p-2 text-lg'>Ministry Program details</p>
                                    {/* programname ministry image summary programdate time venue */}
                                        
                                        <div className="form-group row">
                                            <label for="programname" className="col-md-3 col-form-label text-md-right">Program Name <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="programname" type="text" className="form-control" name="programname" required placeholder="Program's Name" value={formdata.programname} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.programname && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.programname}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        

                                        <div className="form-group row">
                                            <label for="summary" className="col-md-3 col-form-label text-md-right">Program Summary <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="summary" className="form-control m-1" name="summary" placeholder="About Program" value={formdata.summary} onChange={handleInputChange} autoFocus rows="3"></textarea>
                                                {formdata.error_list && formdata.error_list.summary && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.summary}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="programedate" className="col-md-3 col-form-label text-md-right">Program Date </label>
                                            <div className="col-md-7">
                                                <input id="programedate" type="text" className="form-control" name="programedate" placeholder="254712345678" value={formdata.programedate} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.programedate && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.programedate}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label for="time" className="col-md-3 col-form-label text-md-right">Progame Time </label>
                                            <div className="col-md-7">
                                                <input id="time" type="time" className="form-control" name="time" placeholder="Program time" value={formdata.time} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.time && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.time}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="venue" className="col-md-3 col-form-label text-md-right">Progame Venue </label>
                                            <div className="col-md-7">
                                                <input id="venue" type="text" className="form-control" name="venue" placeholder="Program Venue" value={formdata.venue} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.venue && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.venue}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right"> Program Photo </label>
                                            <div className='col-md-7'>
                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Program Photo Here
                                                    </p>
                                                    
                                                </div>

                                                {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Media</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentsection !=='' &&
                                                        <>
                                                            {currentsection.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Media</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryprogram/'+currentsection.image} width="90%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>
                                                
                                            </div>
                                        </div>

                                        

                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                            
                                            {!loading && loadingresok !=='' && 
                                            <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>}
                                        </div>

                                        
                                            

                                        {loading && 
                                            <div className="col-md-12 text-center text-white">
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Program':'Saving Program'}...</span>
                                                    
                                            </div>
                                        }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('','')}} className="btn ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-chevron-left'></span> Back 
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Ministry Program':'Save Ministry Program'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </form>
                                
                            </>
                        
                        }

                        
                        {addbook ? 
                            <form className='justify-content-center' onSubmit={newBook}>
                                {!loading && loadingresok==='' && 
                                    <>
                                        <div className="form-group row">
                                            <label for="biblev" className="col-md-3 col-form-label text-md-right">Section  <sub className='text-red text-sm'>*</sub></label>
                                            {/*salutation position membername gender image summary phone email*/}
                                            <div className="col-md-7">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.biblev==="verseoftheday"?"true":""} onChange={handleInputChange} className="" name="biblev" value="verseoftheday" autoComplete="biblev"/> Verse of the day
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="nextafterverseoftheday"?"true":""} onChange={handleInputChange} className="" name="biblev" value="nextafterverseoftheday" autoComplete="biblev"/> Next After Verse
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="hopeinGodsword"?"true":""} onChange={handleInputChange} className="" name="biblev" value="hopeinGodsword" autoComplete="biblev"/> Hope in God's Word
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="featuredwebsites"?"true":""} onChange={handleInputChange} className="" name="biblev" value="featuredwebsites" autoComplete="biblev"/> Featured Websites
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="sabbathtruth"?"true":""} onChange={handleInputChange} className="" name="biblev" value="sabbathtruth" autoComplete="biblev"/> Sabbath Truth
                                                </label>
                                                
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.biblev==="others"?"true":""} onChange={handleInputChange} className="" name="biblev" value="others" autoComplete="biblev"/> Other Sections
                                                </label>
                                                {formdata.error_list && formdata.error_list.type && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.type}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                    
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Section Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" required placeholder="Section Title" value={formdata.title} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        {formdata.biblev==='verseoftheday' ?
                                        <>
                                            <div className="form-group row">
                                                <label for="verse" className="col-md-3 col-form-label text-md-right">Verse <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="verse" type="text" className="form-control" name="verse" placeholder="Verse of the Day" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                    {formdata.error_list && formdata.error_list.verse && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verse}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label for="versequote" className="col-md-3 col-form-label text-md-right">Verse Text <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                <textarea id="versequote" className="form-control" name='versequote' placeholder="Verse of the Day Text" value={formdata.versequote} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                    {formdata.error_list && formdata.error_list.versequote && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.versequote}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div> 
                                        </>
                                        :
                                            <div className="form-group row">
                                                <label for="verse" className="col-md-3 col-form-label text-md-right">Link <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="verse" type="text" className="form-control" name="verse" placeholder="Section Link" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                    {formdata.error_list && formdata.error_list.verse && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verse}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group row">
                                            <label for="image" className="col-md-3 col-form-label text-md-right">Photo/ Image <sub className='text-red text-sm'>*</sub></label>

                                            <div className="col-md-7">

                                                 <div className='dropArea' {...getRootProps({style})}>
                                                     <input {...getInputProps()} type='file' accept='image/*' />
                                                     <p>
                                                         Click or Drop Photo / Image Here
                                                     </p>
                                                    
                                                 </div>

                                                 {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }
                                                    

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentbook !=='' &&
                                                        <>
                                                            {currentbook.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+currentbook.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddbook()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Section':'Creating Section'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Section':'Save Section'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            
                        :

                        <>
                        {section ==='' &&
                            <div className="row justify-content-center p-3 pt-2">
                                <Tabs
                                    id="fill-tab-example"
                                    className="m-0 p-0 bg-success"
                                    activeKey={key}
                                    onSelect={(k)=> setKey(k)}
                                    fill>
                                        {/*salutation position membername gender image summary phone email*/}
                                        <Tab eventKey="mission"
                                            title="Mission">
                                            <div className="row m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-0'>
                                                        <h4 className='m-1 p-2'>Ministry Mission</h4>
                                                        {currentministry.mission !==null?
                                                            <div className="text-left m-0 p-0">
                                                                <div>
                                                                    {currentministry.mission !==null  && JSON.parse(currentministry.mission).map((versel) => (
                                                                        <div className='border-white-b m-0 mb-1 p-1'>
                                                                            <p className='m-0 p-1'> {versel.verselinks_text} </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted mx-auto'>
                                                                        <div className='text-left mx-auto text-center'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{setUpdateWhatSection('mission',currentministry)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span> Edit
                                                                                </button>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        :
                                                            <>
                                                                <p className='m-0 p-4 text-danger'>Please Update Ministry Mission</p>
                                                                <button type="button" onClick={()=>{setUpdateWhatSection('mission','')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                    <span className='fa fa-plus-circle'></span> Create Ministry Mission Page 
                                                                </button>
                                                            </>
                                                        }
                                                        
                                                    </div>   
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="vision"
                                            title="Vision ">
                                            <div className="row m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-0'>
                                                        <h4 className='m-1 p-2'>Ministry Vision</h4>
                                                        {currentministry.vision !==null?
                                                            <div className="text-left m-0 p-0">
                                                                <div>
                                                                    {currentministry.vision !==null  && JSON.parse(currentministry.vision).map((versel) => (
                                                                        <div className='border-white-b m-0 mb-1 p-2'>
                                                                            <p className='m-0 p-1'> {versel.verselinks_text} </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted mx-auto'>
                                                                        <div className='text-left mx-auto text-center'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{setUpdateWhatSection('vision',currentministry)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span> Edit
                                                                                </button>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        :
                                                            <>
                                                                <p className='m-0 p-4 text-danger'>Please Update Ministry Vision</p>
                                                                <button type="button" onClick={()=>{setUpdateWhatSection('vision','')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                    <span className='fa fa-plus-circle'></span> Create Ministry Vision Page 
                                                                </button>
                                                            </>
                                                        }
                                                        
                                                    </div>   
                                                </div>
                                            </div>

                                        </Tab>
                                        <Tab eventKey="members"
                                            title="Members">
                                            <div className="row justify-content-center p-0 pt-2">
                                                <h4 className='text-center m-1 p-2'>Ministry Members
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('member','')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-plus-circle'></span> Add New Ministry Member 
                                                    </button>

                                                </h4>
                                                {loadingpage?
                                                <LoadingDetailsSpinner />
                                                :
                                                <>
                                                    {ministrymembers && ministrymembers.map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <p className='text-left m-0 p-1'>{verse.salutation}. {verse.membername}</p>
                                                                    <p className='m-0 p-1'>
                                                                        <i className='text-black text-muted'>{verse.position}</i> <br/>
                                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/member/'+verse.image} width="50px" height="50px" className="brand-image img-circle m-0 p-0 mx-auto"  alt="First Image" />
                                                                    </p>
                                                                    
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-left p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{setUpdateWhatSection('member',verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteMember(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                                </>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab eventKey="projects"
                                            title="Projects">
                                            <div className="row justify-content-center p-0 pt-2">
                                                <h4 className='text-center m-1 p-2'>Ministry Projects
                                                    <button type="button" onClick={()=>{setUpdateWhatSection('project','')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                        <span className='fa fa-plus-circle'></span> Add New Ministry Project 
                                                    </button>

                                                </h4>
                                                {loadingpage?
                                                <LoadingDetailsSpinner />
                                                :
                                                <>
                                                    {ministryprojects && ministryprojects.map((verse,key) =>(
                                                        <div className="col-12 col-md-4 m-0 p-0">
                                                            <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                
                                                                <div className='text-center m-0 p-0'>
                                                                    <p className='text-left m-0 p-1'>{verse.projectname}.</p>
                                                                    <p className='text-center m-0 p-1'>{verse.summary}.</p>
                                                                    <p className='m-0 p-1'>
                                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryproject/'+verse.image}  width="100%" height="180px"  alt="First Image" />
                                                                    </p>
                                                                    
                                                                </div>   
                                                                {deleteBook && updateBook &&
                                                                    <div className='m-0 p-0 text-muted my-auto'>
                                                                        <div className='text-left p-0'>
                                                                            <div>
                                                                                <button type="button" onClick={()=>{setUpdateWhatSection('project',verse)}} className="btn btn-primary m-1 p-1">
                                                                                    <span className='fa fa-edit'></span>
                                                                                </button>
                                                                                
                                                                                <button type="button" onClick={()=>{deleteProject(verse)}} className="btn btn-danger m-1 p-1">
                                                                                    <span className='fa fa-trash'></span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>  
                                                    ))}
                                                </>
                                                }
                                            </div>
                                        </Tab>

                                        <Tab eventKey="programs"
                                            title="Programs">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <h4 className='text-center m-1 p-2'>Ministry Programs
                                                            <button type="button" onClick={()=>{setUpdateWhatSection('program','')}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                <span className='fa fa-plus-circle'></span> Add New Ministry Program 
                                                            </button>

                                                        </h4>
                                                        {loadingpage?
                                                        <LoadingDetailsSpinner />
                                                        :
                                                        <>
                                                            {ministryprograms && ministryprograms.map((verse,key) =>(
                                                                <div className="col-12 col-md-4 m-0 p-0">
                                                                    <div className=" justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                                        
                                                                        <div className='text-center m-0 p-0'>
                                                                            <p className='text-left m-0 p-1'>{verse.programname}.</p>
                                                                            <p className='text-center m-0 p-1'>{verse.summary}.</p>
                                                                            {verse.programdate?<p className='text-center m-0 p-1'>{verse.programdate}.</p>:""}
                                                                            <p className='text-center m-0 p-1'>{verse.time}. at: {verse.venue}</p>
                                                                            <p className='m-0 p-1'>
                                                                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryprogram/'+verse.image}  width="100%" height="180px"  alt="First Image" />
                                                                            </p>
                                                                            
                                                                        </div>   
                                                                        {deleteBook && updateBook &&
                                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                                <div className='text-center p-0 mx-auto'>
                                                                                    <div>
                                                                                        <button type="button" onClick={()=>{setUpdateWhatSection('program',verse)}} className="btn btn-primary m-1 p-1">
                                                                                            <span className='fa fa-edit'></span>
                                                                                        </button>
                                                                                        
                                                                                        <button type="button" onClick={()=>{deleteProgram(verse)}} className="btn btn-danger m-1 p-1">
                                                                                            <span className='fa fa-trash'></span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>  
                                                            ))}
                                                        </>
                                                        }
                                                    </div>    
                                                </div>
                                            </div>
                                        </Tab>

                                        
                                </Tabs>
                                </div>  
                            }

                        </>

                        }

                        
                    </div>
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashBibleManageMinistry;