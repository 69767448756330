
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';


import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import event1 from '../../assets/img/event1 off.jpg';


import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';


import EventsUpcoming from './EventsUpcoming';
import EventsPg from './EventsPg';
import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function AboutEvents() {
    document.title="Events | Utawala Satellite";
    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified,loggedrole, setLoggedRole} =useContext(LoginContext);
    const [events,setEvents]=useState([]);
    const [mainevent,setMainEvent]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true);
        const getEvents = async (e) => { 
            if(loggedname !==''){
                axios.get(`/api/get/load/eventt`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setEvents(response.data.events);
                            setMainEvent((response.data.mainevent===null)?'':response.data.mainevent);
                            setLoadingPage(false);
                        }
                        else{
                            setEvents([]);
                            setMainEvent('');
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch((error)=>{
                    setEvents([]) 
                    setMainEvent('');
                        setLoadingPage(false);
                })
            }
            else{
                axios.get('/sanctum/csrf-cookie').then(res =>{
                    axios.get(`/api/get/load/events`)
                    .then(response=>{
                        if (doneloading) {
                            if(response.data.status=== 200){
                                setEvents(response.data.events);
                                setMainEvent((response.data.mainevent===null)?'':response.data.mainevent);
                                setLoadingPage(false);
                            }
                            else{
                                setEvents([]);
                                setMainEvent('');
                                setLoadingPage(false);
                            }
                            
                        }
                    })
                    .catch(error=>{
                        setEvents([]);
                        setMainEvent('');
                                setLoadingPage(false);
                    })
                })
                .catch((error)=>{
                    setEvents([]);
                    setMainEvent('');
                                setLoadingPage(false);
                })
            }
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[])


  return (
    <>
    <HomeNavBar active='events'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

       
            <div className="bg-primary">
                <div className=''>
                {/* <div className='events-container'> */}
                {loadingpage?
                <LoadingDetailsSpinner />
                :
                <>
                        <div className="m-0 p-0">
                            <div className=" text-white text-center" >
                                {/* <div className="p-1" style={{"opacity":".6","marginBottom":'-80px'}}>
                                    <h1 className='m-0 p-1' style={{"fontWeight":"700"}}>Bible Study</h1>
                                </div> */}
                                <div className="" style={{"opacity":".6","marginBottom":"-90px"}}>
                                    <h1 className='text-center text-success mt-2 p-2 text-bold bg-white border-white-b elevation-2 opacity-none ministryheader-title-center'>Upcoming Events</h1>
                                </div>

                                <div className="events-cover">
                                    <>
                                        <div className='slider-img'>
                                            {mainevent !==''?
                                                <>
                                                    {mainevent['image'] ===null?'':<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+mainevent['image']} className='border-none m-0 p-2' width="100%" alt="Main Event Image" />}
                                                    
                                                </>
                                                // <img src={event1} width="100%" />
                                                :
                                                <>
                                                <img src={event1} width="100%" />
                                                {/* {console.log(mainevent['status'])} */}
                                                </>
                                            }
                                        </div>
                                    </>
                                </div>

                                <div className="events-container row justify-content-center" style={{marginTop:'-20%'}}>
                                    {mainevent !==''?
                                        <div className='container mx-auto my-auto'>
                                            <div className="col-12 m-3 p-3 ">
                                            {mainevent['image'] ===null?
                                                <a className='link-image' href='https://tendaysofprayer.org/' target='_blank'>
                                                    <img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" />
                                                </a>
                                                :
                                                <>
                                                {/* <p>{mainevent['event']}</p> */}
                                                {/* <div className="col-6 col-md-6 m-0 p-0"> */}
                                                    <div className='bg-white border-none mt-2 p-5 opacity-none'>
                                                        <h2 className='text-black text-center text-bold text-xl'>{mainevent['event']}</h2>
                                                    </div>
                                                {/* </div> */}

                                                {/* {mainevent['link'] ===null ?
                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+mainevent['image']} className='border-white-b' width="100%" alt="Main Event Image" />
                                                    :
                                                    <a className='link-image' href={mainevent['link']} target='_blank'>
                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+mainevent['image']} className='border-white-b' width="100%" alt="Main Event Image" />
                                                    </a>
                                                } */}
                                                </>
                                            }

                                                
                                            </div>
                                            <div className='col-4 bg-opacity-6 events-center'>
                                            {mainevent['image'] !==null?
                                            <>
                                                {mainevent['link'] ===null ?
                                                ''
                                                :
                                                    <a href={mainevent['link']} target='_blank'>
                                                        <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                                    </a>
                                                }
                                            </>
                                            :
                                                <a href='https://tendaysofprayer.org/' target='_blank'>
                                                    <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                                </a>
                                            }
                                            </div>
                                        </div>
                                    :
                                        <div className='container mx-auto my-auto'>
                                            <div className="col-12 m-3 p-3 ">
                                                <a className='link-image' href='https://tendaysofprayer.org/' target='_blank'><img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" /></a>
                                            </div>
                                            <div className='col-4 bg-opacity-6 events-center'>
                                                <a href='https://tendaysofprayer.org/' target='_blank'>
                                                    <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>


                        <div className="events-container row justify-content-center p-5 pt-3" style={{marginTop:'10px'}}>
                            <EventsPg events={events} />
                        </div>
                    </>
                }


                    <div className="events-container bg-gray m-2 p-2">
                        <div className="text-white text-center" >
                            <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>More Events Coming Up:</h1>
                        </div>
                    </div>

                    <div className="events-container row justify-content-center p-5 pt-3">
                        <EventsUpcoming />
                    </div>
                </div>
                
            </div>

    </main>
        <HomeFooter />
    </>
  );
}

export default AboutEvents;