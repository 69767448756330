import { Link, Navigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import ReLogin from '../home/ReLogin';
import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

function Dashwelcome() {
    document.title="Dashboard | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified,loggedrole, setLoggedRole} =useContext(LoginContext);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [dash,setDash]=useState([]);
    const [loggedoff,setLoggedOff]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        if(!localStorage.getItem("auth_token_ut")){
            setAuthName('');
            // setRedirect(true);
            // setUrl('/login');
        }
        else{
            setAuthName(localStorage.getItem("auth_name_ut"));
        }
      },[authname])


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        const getDash = async (e) => { 
               await axios.get(`/api/load/dashbills`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setDash(response.data.stats);
                            setLoadingPage(false)
                        }
                        else{
                            setDash([]);
                            setLoadingPage(false)
                        }
                        
                    }
                })
                .catch((error)=>{
                    
                    // console.log(error['response'].data.message)
                    // console.log(error['response'].statusText)
                    // Unauthorized
                    // Unauthenticated.
                    // let ex=error['response'].data.message;
                    // console.log(ex)
                    // if(ex==='Unauthenticated.'){
                    //     setLoggedOff(true);                    
                    // }
                    // else{
                    //     setDash([]);
                    // }
                    setLoadingPage(false)
                    setDash([]);
                })
        };
        getDash();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    if(redirect){
        return <Navigate to={url} />
    }

  return (
    <>
        {/* {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        : */}
        <div className="">
            <div className="">
                <div className=" text-great text-center" >
                    <h1 className='m-2 p-3'>Welcome {loggedrole},  {loggedname}.</h1>
                </div>

                <div className="card-body text-center m-0 p-1 mt-2">
                    <div className="col-md-12 m-0 p-0 mt-2 mb-2 justify-content-center">
                        {loggedrole ==='Admin' &&
                        <>
                        {loadingpage?
                        <LoadingDetailsSpinner />
                        :
                        <div className="row justify-content-center m-0 p-0">
                            <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                        <Link to="/dashboard/events" className="">Events</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].events}
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                        <Link to="/dashboard/messages" className="">Messages</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].messages}
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                        <Link to="/dashboard/sermons" className="">Sermons</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].sermons}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                    <Link to="/dashboard/programs" className="">Programs</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].programs}
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                    <Link to="/dashboard/members" className="">Members</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].members}  
                                    </div>
                                </div>    
                            </div> */}
                            <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                    <Link to="/dashboard/ministries" className="">Ministries</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].ministries}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 col-lg-2 col-md-3 m-0 p-0  mb-1 text-center'>
                                <div className='m-0 p-0 mr-2 border-white-b '>
                                    <div className='elevation-1 border-light p-1'>
                                        <Link to="/dashboard/opportunities" className="">Jobs</Link>
                                    </div>
                                    <div className='bold text-lime text-sm p-1'>
                                        {dash[0] && dash[0].opportunities}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        </>
                    }
                        
                    </div>
                    
                </div>


            </div>
        </div>
        {/* } */}
    </>
  );
}


export default Dashwelcome;