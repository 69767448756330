import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';    


function PageNotFound() {
    document.title="PageNotFound";
  return (
    <>
    <HomeNavBar active='home'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div class="container">
            <div class="row justify-content-center">
                <div className="col-md-8 m-2 p-2 mt-4 mb-4">
                    <div className="card border-danger" >
                        <div className="card-header bg-danger text-white elevation-2 m-0 p-2">
                            <h4 style={{"textAlign": "center"}}>Oops!!! </h4>
                            <h4 style={{"textAlign": "center"}}>Nothing Found Here </h4>
                        </div>

                        <div className="card-body text-center p-3" style={{"paddingTop": "10px"}}>
                            <p>
                            Its Like what you are looking for could not be found
                            </p>
                            <p>
                                Try Using our navigation links above.
                            </p>
                            <p>
                                Also, use search bar for more 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    </main>
    <HomeFooter />
      
    </>
  );
}

export default PageNotFound;