import {useState,useEffect} from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from './LoadPrograms';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function Programs() {
    const [key,setKey] =useState('allprograms');

    const [programs,setPrograms]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getPrograms = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/programs`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setPrograms(response.data.programs);
                            setLoadingPage(false);
                        }
                        else{
                            setPrograms([]);
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setPrograms([]);
                            setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setPrograms([]);
                            setLoadingPage(false);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  return (
    <>
    
    <Tabs
        id="fill-tab-example"
        className="m-0 p-0"
        activeKey={key}
        onSelect={(k)=> setKey(k)}
        fill>
            <Tab eventKey="allprograms"
                title="All programs">
                <div className="row m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-0'>
                            {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <LoadPrograms type='All Programs' data={programs} />
                            }
                        </div>   
                    </div>
                </div>
            </Tab>
            <Tab eventKey="witnessing"
                title="WITNESSING">
                <div className="row m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-3'>
                        {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <LoadPrograms type='Witnessing' data={programs} />
                        }
                        </div>  
                    </div>
                </div>

            </Tab>
            <Tab eventKey="stewardship"
                title="STEWARDSHIP">
                <div className="col-12 m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-3'>
                            <LoadPrograms type='Stewardship' data={programs} />
                        </div>  
                    </div>
                </div>
            </Tab>
            <Tab eventKey="health"
                title="HEALTH AND TEMPERANCE">
                <div className="col-12 m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-3'>
                        {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <LoadPrograms type='Health and Temperance' data={programs} />
                        }
                        </div>  
                    </div>
                </div>
            </Tab>

            <Tab eventKey="bibleprophecy"
                title="BIBLE PROPHECY">
                <div className="col-12 m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-3'>
                        {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <LoadPrograms type='Bible Prophecy' data={programs} />
                        }
                        </div>    
                    </div>
                </div>
            </Tab>

            <Tab eventKey="biblestudy"
                title="BIBLE STUDY">
                <div className="col-12 m-0 p-0">
                    <div className="texts-black text-white text-center">
                        <div className='text-center m-0 p-3'>
                        {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <LoadPrograms type='Bible Study' data={programs} />
                        }
                        </div>  
                    </div>
                </div>
            </Tab>
    </Tabs>
        

    </>
  );
}

const data= [
    {
        type:'Stewardship',
        program:'Program One',
        dateandtime:'8:30am - 12:00pm',
        venue:'Utawala Satellite SDA',
        notices:'',
        desc:'Welcome to Church Service',
    },
    {
        type:'Witnessing',
        program:'Program TWo',
        dateandtime:'9:30am - 10:00am',
        venue:'Utawala Satellite SDA',
        notices:'',
        desc:'Welcome to Church Service',
    },
    {
        type:'Bible Study',
        program:'Program Three',
        dateandtime:'8:30am - 12:00pm',
        venue:'Utawala Satellite SDA',
        notices:'',
        desc:'Welcome to Church Service',
    }
    ,
    {
        type:'Bible Prophecy',
        program:'Program Three',
        dateandtime:'8:30am - 12:00pm',
        venue:'Utawala Satellite SDA',
        notices:'',
        desc:'Welcome to Church Service',
    }
];

export default Programs;