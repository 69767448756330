// import { io } from 'socket.io-client';

// // "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:500';

// export const socket = io(URL, {
//     autoConnect: false
//   });

import io from 'socket.io-client';

const socket = io('http://localhost:5900'); // Replace with your server URL

export { socket };