

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


import axios from 'axios';
import { useEffect, useMemo, useState, useCallback } from 'react';

function TheTruth() {
    const [truths,setTruths]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getSlides = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/thetruths`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setTruths(response.data.truths);
                            setLoadingPage(false);
                        }
                        else{
                            setTruths([]);
                            setLoadingPage(false);
                        }
                    }
                })
                .catch(error=>{
                    setTruths([]);
                            setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setTruths([]);
                            setLoadingPage(false);
            })
        };
        getSlides();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  return (
    <>
    {loadingpage?
    <LoadingDetailsSpinner />
    :
    <>
        {truths && truths.map((truth,key) =>(
            <div className="col-12 col-md-4">
                <div className="bg-primary texts-black text-center p-1" >
                    <div className='row'>
                            <a className='link-image ' href={truth.link}><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/thetruths/'+truth.image} className='img-styles' width="100%"  alt="First Image" /></a>
                        <div className="col-12">
                            <div className='text-left'>
                                <h5 className="text-warning m-2 p-2">{truth.title}</h5>

                                <ul className='text-white' style={{"height":"150px","overflowY":"auto"}}>
                                    {truth.subtitles !==null  && JSON.parse(truth.subtitles).map((versel) => (
                                        <li><a className='link-white p-1' href={versel.verselinks_link}> {versel.verselinks_text}.</a></li>
                                    ))}
                                    {/* <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#origin">Where God’s Law came from</a></li>
                                    <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#commandments">What commandments are in God’s Law</a></li>
                                    <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#purpose">The purpose of God’s Law</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className='m-0 p-2'>
                            <a className='link-black' href={truth.learnmorelink} target='_blank'>
                                Learn More
                            </a>
                        </div>

                        <div className="m-0 p-0 pl-3 pr-3 forum-div-h">
                            <iframe
                                src={`https://www.youtube.com/embed/`+truth.videoid}
                                frameborder="0"
                                allowFullScreen
                                title="The Truths youtube Video"
                                width="100%"
                            />
                        </div>
                    </div>
                            
                </div>
            </div>
        ))}
        </>
        }
        {/* <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/the-law-of-god/'><img src={tencommandments} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">The Law of GOD</h5>

                            <ul className='text-white'>
                                <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#origin">Where God’s Law came from</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#commandments">What commandments are in God’s Law</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-law-of-god/#purpose">The purpose of God’s Law</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/the-law-of-god/' target='_blank'>
                            Learn More
                        </a>
                    </div>

                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                            src={`https://www.youtube.com/embed/I2nAKVLYMSY`}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                            width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div>

        <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/'><img src={thesabbath} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">The Sabbath – Then and Now</h5>

                            <ul className='text-white'>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#1">The origin of the Sabbath at creation</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#2">How Sabbath was intended as a gift from God</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#3">How Sabbath connects you more closely to God</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#4">How Sabbath can bring us closer to others</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#5">How Sabbath is represented throughout the Bible</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#6">How Sabbath is represented throughout history</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#7">Celebrating Sabbath today</a></li>
                            
                            </ul>

                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/the-sabbath/the-sabbath-then-and-now' target='_blank'>
                            Learn More
                        </a>
                    </div>
                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                        src={`https://www.youtube.com/embed/aOSjGzItYuI`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div>

        

        <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/'><img src={lifeanddeeath} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">Confronting the Topics of Death, Grief, and the Afterlife</h5>

                            <ul className='text-white'>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#bibleaboutdeath">What the Bible says about death</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#deathfrom">Where death came from</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#wedie">Where we go when we die</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#whatissoul">What the soul really is</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#grief">How we should handle grief</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/#communicationwiththedead">If communication with the dead is possible</a></li>
                            </ul>

                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/death-and-resurrection/death-grief-and-the-afterlife/' target='_blank'>
                            Learn More
                        </a>
                    </div>
                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                        src={`https://www.youtube.com/embed/_bN0fso1GYs`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div>

        <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/holy-scriptures/understand-bible-prophecy/'><img src={prophecyMadeSimple} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">UNDERSTANDING THE BOOKS OF DANIEL & REVELATION</h5>

                            <ul className='text-white'>
                            <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#prophecy">What a prophecy actually is</a></li>
                            <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#purpose-of-prophecy">The purposes of prophecy</a>
                                <ul className='text-white'>
                                    <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#warn">To warn of potential consequences</a></li>
                                    <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#pitfalls">To expose common pitfalls of human nature</a></li>
                                    <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#future">To help us understand the future</a></li>
                                    <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#hope">To give us hope</a></li>
                                </ul>
                            </li>
                            <li><a className='link-white' href="https://www.adventist.org/holy-scriptures/understand-bible-prophecy/#apply">How to apply these ideas to our lives today</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/holy-scriptures/understand-bible-prophecy/' target='_blank'>
                            Learn More
                        </a>
                    </div>

                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                        src={`https://www.youtube.com/embed/j0L-QGEQMzI`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div>

        <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/'><img src={godslastmessageoflove} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">GODS LAST WARNING TO THE WORLD</h5>

                            <ul className='text-white'>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#1">The origin of the Sabbath at creation</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#2">How Sabbath was intended as a gift from God</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#3">How Sabbath connects you more closely to God</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#4">How Sabbath can bring us closer to others</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#5">How Sabbath is represented throughout the Bible</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#6">How Sabbath is represented throughout history</a></li>
                                <li><a className='link-white' href="https://www.adventist.org/the-sabbath/the-sabbath-then-and-now/#7">Celebrating Sabbath today</a></li>
                            
                            </ul>

                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/the-sabbath/the-sabbath-then-and-now' target='_blank'>
                            Learn More
                        </a>
                    </div>
                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                        src={`https://www.youtube.com/embed/icsEtKS9utk`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div>

        <div className="col-12 col-md-4">
            <div className="bg-primary texts-black text-center p-1 pb-3" >
                <div className='row'>
                        <a className='link-image ' href='https://www.adventist.org/remnant-and-its-mission/'><img src={hqdefault} className='img-styles' width="100%"  alt="First Image" /></a>
                    <div className="col-12">
                        <div className='text-left'>
                            <h5 className="text-warning m-2 p-2">GODS REMNANT CHURCH ON EARTH</h5>
                            
                            <ul className='text-white'>
                                <li><a className='link-white' href="#">How the meaning of “remnant” is used in the Bible</a></li>
                                <li><a className='link-white' href="#">What the remnant is and who will be part of it</a></li>
                                <li><a className='link-white' href="#">The mission of this remnant</a></li>
                                <li><a className='link-white' href="#">Other references to a remnant in the Bible</a></li>
                                <li><a className='link-white' href="#">What the idea of the remnant means for us today</a></li>
                                
                            </ul>

                        </div>
                    </div>
                    <div className='m-0 p-2'>
                        <a className='link-black' href='https://www.adventist.org/remnant-and-its-mission/' target='_blank'>
                            Learn More
                        </a>
                    </div>
                    <div className="m-0 p-3 forum-div-h">
                        <iframe
                        src={`https://www.youtube.com/embed/le4_D8uMfVo`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        width="100%"
                        />
                    </div>
                </div>
                        
            </div>
        </div> */}
    </>
  );
}

export default TheTruth;