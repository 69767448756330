
import { useEffect, useMemo, useState,useContext } from 'react';

import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashBibleResources(props) {
    document.title="Dashboard Bible Study Resources | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('allbibles');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [bibles,setBibles]=useState([]);
    const [books,setBooks]=useState([]);
    const [addbible, setAddbible]=useState(false);
    const [addbook, setAddbook]=useState(false);
    const [currentbible,setCurrentBible]=useState('');
    const [currentbook,setCurrentBook]=useState('');

    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

    // link, img, title verse versequote biblev
    const [formdata,setFormData]=useState({
        title:'',
        img:'',
        link:'',
        verse:'',
        versequote:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_verse==='' || verselinks.verselinks_link==='' || verselinks.verselinks_text===''){
            Swal("Error","Please Fill all required for verse");
        }
        else if(isNaN(verselinks.verselinks_verse.trim())){
            Swal("Error","Verse Must be a Number");
        }
        else{
            const {verselinks_link,verselinks_text,verselinks_verse}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link || 
                        verselinks.verselinks_verse===lk.verselinks_verse ||
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link && 
                        verselinks.verselinks_verse===lk.verselinks_verse &&
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_verse:link.verselinks_verse,
            verselinks_link:link.verselinks_link,
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newBible= (e)=>{
        e.preventDefault();

        if(formdata.title ===''){
            Swal("Enter Title","Please Enter Title","error");
            return;
        }

        if(formdata.verse ===''){
            Swal("Enter Verse","Please Enter Verse","error");
            return;
        }

        if(formdata.versequote ===''){
            Swal("Verse or Quote Missing","Enter Verse Quote to Display.","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        // day time service | verse verseline
        const form={
            id:(currentbible!=='')?currentbible.id:'',
            title:formdata.title,
            verse:formdata.verse,
            versequote:formdata.versequote,
        }
            axios.post('/api/save/biblestudy',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadBibles();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const newBook= (e)=>{
        e.preventDefault();

        if(formdata.link ===''){
            Swal("Enter Book Link","Please Enter Book Link","error");
            return;
        }

        if(files ===''){
            Swal("Select Image","Please Select Image to represent Book","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentbook!=='')?currentbook.id:'',
            link:formdata.link,
            title:formdata.title,
            img:files,
        }
            axios.post('/api/save/biblestudybook',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadBooks();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadBibles= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/biblestudy`)
        .then(response=>{
            if(response.data.status=== 200){
                if(response.data.biblestudy ===null){
                    setBibles([])
                    setLoadingPage(false)
                }
                else{
                    setBibles(response.data.biblestudy);
                    setLoadingPage(false)
                }
            } 
            else if(response.data.status=== 401){
                setBibles([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setBibles([]) 
                setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setBibles([]) 
        })

    }

    const loadBooks= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/biblestudybooks`)
        .then(response=>{
            if(response.data.status=== 200){
                setBooks(response.data.biblestudybooks);
                    setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setBooks([]) 
                Swal("Error",response.data.message,"error");
                    setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setBooks([]) 
                    setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setBooks([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/biblestudy`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        if(response.data.biblestudy ===null){
                            setBibles([])
                    setLoadingPage(false)
                        }
                        else{
                            setBibles(response.data.biblestudy);
                    setLoadingPage(false)
                        }
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setBibles([]);
                    setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setBibles([]);
                    setLoadingPage(false)
                    }
                    else{
                        setBibles([]);
                    setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                    setLoadingPage(false)
                setBibles([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/biblestudybooks`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setBooks(response.data.biblestudybooks);
                    setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setBooks([]);
                    setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setBooks([]);
                    setLoadingPage(false)
                    }
                    else{
                        setBooks([]);
                    setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                    setLoadingPage(false)
                setBooks([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateBible = (verse) => {
        setAddbible(true)
        setCurrentBible(verse)

        setFormData({
            link:'',
            img:'',
            title:verse.title,
            versequote:verse.versequote,
            verse:verse.verse,
            error_list:[],
        })
        
    };

    const updateBook = (serv) => {
        setAddbook(true)
        setCurrentBook(serv)

        setFormData({
            link:serv.link,
            img:serv.img,
            title:serv.title,
            verse:'',
            versequote:'',
            error_list:[],
        })
        
    };

    const setAddbibles = (ev)=> {
        setAddbible(!addbible);
        setAddbook(false);
        setCurrentBible('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            link:'',
            versequote:'',
            verse:'',
            img:'',
            error_list:[],
        })
        setFiles([])
    }

    const setShowbibles = (ev)=> {
        setAddbible(false);
        setAddbook(false);
        setCurrentBook('')
        setCurrentBible('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            link:'',
            versequote:'',
            verse:'',
            img:'',
            error_list:[],
        })
        loadBibles();
        loadBooks();
        setFiles([])
    }
    

    const setAddbooks = (ev)=> {
        setAddbook(!addbook);
        setAddbible(false);
        setCurrentBook('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            link:'',
            versequote:'',
            verse:'',
            img:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteBible= (verse)=>{
        const form={
            id:verse.id,
        }

        let title='Delete '+verse.title;
        let text="This will remove this Bible from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/biblestudy',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadBibles();
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadBibles();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const deleteBook= (service)=>{
        const form={
            id:service.id,
        }

        let title='Delete '+service.link;
        let text="This will remove this Book from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/biblestudybook',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadBooks()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadBooks();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Bible Study Resources Page</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                        <div className="card-body">
                            {(addbible  || addbook)?
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setShowbibles()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-table'></span> Show Bible Study Resources Page 
                                    </button>
                                </p>
                                :
                                <>
                                    <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                        <button type="button" onClick={()=>{setAddbooks()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-plus-circle'></span> Create Bible Study Book Page 
                                        </button>
                                    </p>
                                    
                                </>
                            }
                            {addbible &&
                                <form className='justify-content-center' onSubmit={newBible}>
                                    {!loading && loadingresok==='' && 
                                        <>
                                        {/* verse verseline */}
                                            <div className="form-group row">
                                                <label for="title" className="col-md-3 col-form-label text-md-right">Title <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="title" type="text" className="form-control" name="title" placeholder="Bible Study Title" value={formdata.title} onChange={handleInputChange} autoFocus/>
                                                    {formdata.error_list && formdata.error_list.title && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.title}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="verse" className="col-md-3 col-form-label text-md-right">Verse <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                    <input id="verse" type="text" className="form-control" name="verse" placeholder="Psalms 73:11" value={formdata.verse} onChange={handleInputChange} autoFocus/>
                                                    {formdata.error_list && formdata.error_list.verse && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verse}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="versequote" className="col-md-3 col-form-label text-md-right">Verse Quote <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-7">
                                                <textarea id="versequote" className="form-control" name='versequote' placeholder="Verse Quote here" value={formdata.versequote} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                    {formdata.error_list && formdata.error_list.versequote && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.versequote}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            

                                        </>
                                    }

                                    {loadingresok!=='' && 
                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>

                                            <button type="button" onClick={()=>{setShowbibles()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbible!==''?'Updating Bible Study Bible Page':'Saving Bible Study Bible Page'}...</span>
                                                
                                        </div>
                                    }

                                            {loadingres!=='' && 
                                                <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                    <span className="help-block text-danger">
                                                        <strong>{loadingres!=='' && loadingres}</strong>
                                                    </span>
                                                </div>
                                            }
                                            {!loading && loadingresok ==='' && 
                                                <div className="form-group d-flex mb-0">
                                                    <div className="mx-auto">
                                                        <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                            {currentbible!==''?'Update Bible Study Bible Page':'Save Bible Study Bible Page'}
                                                        </button>
                                                    </div>
                                                </div>
                                            }


                                </form>
                            }

                            {addbook &&
                                <form className='justify-content-center' onSubmit={newBook}>
                                    {!loading && loadingresok==='' && 
                                        <>
                                        {/* verse verseline */}

                                            <div className="form-group row">
                                                <label for="link" className="col-md-3 col-form-label text-md-right">Book Link <sub className='text-red text-sm'>*</sub> </label>
                                                <div className="col-md-7">
                                                    <input id="link" type="text" className="form-control" name="link" placeholder="Book Online Link here..." value={formdata.link} onChange={handleInputChange} autoFocus/>
                                                    {formdata.error_list && formdata.error_list.link && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.link}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="title" className="col-md-3 col-form-label text-md-right">Book Title </label>
                                                <div className="col-md-7">
                                                    <input id="title" type="text" className="form-control" name="title" placeholder="Book Title if any..." value={formdata.title} onChange={handleInputChange} autoFocus/>
                                                    {formdata.error_list && formdata.error_list.title && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.title}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="img" className="col-md-3 col-form-label text-md-right">Photo/ Image <sub className='text-red text-sm'>*</sub></label>

                                                <div className="col-md-7">

                                                    <div className='dropArea' {...getRootProps({style})}>
                                                        <input {...getInputProps()} type='file' accept='image/*' />
                                                        <p>
                                                            Click or Drop Photo / Image Here
                                                        </p>
                                                        
                                                    </div>

                                                    {formdata.error_list && formdata.error_list.img && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.img}</strong>
                                                        </span>
                                                    }
                                                        

                                                    <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                        <div>
                                                            {files && files.map((file,key) =>(
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Selected Image</p>
                                                                    <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                                </div>
                                                            ))}                                 
                                                        </div>

                                                        <div>
                                                            {currentbook !=='' &&
                                                            <>
                                                                {currentbook.image !==null &&
                                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                        <p className='m-0 p-0'>Uploaded Image</p>
                                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudybooks/'+currentbook.img} alt='Does Not Have Image' className='' width="100%" />
                                                                    </div>
                                                                }
                                                            </>
                                                                
                                                            }                                 
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            

                                        </>
                                    }

                                    {loadingresok!=='' && 
                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>

                                            <button type="button" onClick={()=>{setShowbibles()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbook!==''?'Updating Bible Study Book Page':'Saving Bible Study Book Page'}...</span>
                                                
                                        </div>
                                    }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbook!==''?'Update Bible Study Book Page':'Save Bible Study Book Page'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                                </form>
                            }

                            {!addbible && !addbook &&
                            
                            <div className="row justify-content-center p-5 pt-3">
                                <div className="col-12 col-md-6 m-0 p-0">
                                    <div className="texts-black text-center m-1" >
                                        {bibles!==null && 
                                            <>
                                                {bibles ===null ?
                                                        <>
                                                            <p className='m-0 p-4 text-warning'>Please Create New Bible Study Bible Page</p>
                                                            <button type="button" onClick={()=>{setAddbibles()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                                <span className='fa fa-plus-circle'></span> Create Bible Study Bible Verse Page 
                                                            </button>
                                                        </>
                                                    :
                                                    <div className='text-center m-0 p-2'>
                                                        <h3 className='text-xl font-bold-700'>
                                                            {bibles['title']}
                                                        </h3>
                                                        <div className="row justify-content-center p-3 pt-3">
                                                            <div className='bg-primary m-0 p-1 border-info'>
                                                                <h3 className='text-center font-bold-700 text-white'>
                                                                    {bibles['verse']}
                                                                </h3>
                                                                <p className='text-center'>
                                                                    “{bibles['versequote']}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        
                                                        {deleteBible && updateBible &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left pl-2'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateBible(bibles)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span> Edit
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteBible(bibles)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span> Del
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div> 
                                                }
                                            </>
                                            
                                        }  
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 m-0 p-0">
                                    <div className="texts-black text-center m-1" >
                                        <div className='text-center m-0 p-1'>
                                            
                                            <h3 className='text-xl font-bold-700'>
                                                Free Books Online
                                            </h3>

                                            <div className="row justify-content-center p-2">
                                                {books && books.map((serv,key) =>(
                                                    <div className="col-md-12 m-0 p-0">
                                                        <div className="d-flex justify-content-between bg-white texts-black text-center m-2 border-ok">
                                                            
                                                            <div className='text-center m-0 p-0'>
                                                                <i className='text-black text-muted'>{serv.title}</i>
                                                                <a className='link-image' href={serv.link}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudybooks/'+serv.img} className='m-0 p-2' width="100%" height="150px"  alt="First Image" /></a>
                                                            </div>   
                                                            {deleteBook && updateBook &&
                                                                <div className='m-0 p-0 text-muted my-auto'>
                                                                    <div className='text-left p-0'>
                                                                        <div>
                                                                            <button type="button" onClick={()=>{updateBook(serv)}} className="btn btn-primary m-1 p-1">
                                                                                <span className='fa fa-edit'></span>
                                                                            </button>
                                                                            
                                                                            <button type="button" onClick={()=>{deleteBook(serv)}} className="btn btn-danger m-1 p-1">
                                                                                <span className='fa fa-trash'></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            
                                                
                                        </div>   
                                    </div>
                                </div>

                                


                            </div>

                            
                            }
                            
                        </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashBibleResources;