import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function Dashwelcomeslides(props) {
    document.title="Dashboard Welcome Slides | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('allslides');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [slides,setSlides]=useState([]);
    const [addslide, setAddslide]=useState(false);
    const [currentslide,setCurrentSlide]=useState('');
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');


    const [formdata,setFormData]=useState({
        title:'',
        media:'',
        type:'',
        verse:'',
        versequote:'',
        verselinks:[],
        trailing:'',
        alignment:'',
        color:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_verse==='' || verselinks.verselinks_link==='' || verselinks.verselinks_text===''){
            Swal("Error","Please Fill all required for verse");
        }
        else if(isNaN(verselinks.verselinks_verse.trim())){
            Swal("Error","Verse Must be a Number");
        }
        else{
            const {verselinks_link,verselinks_text,verselinks_verse}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link || 
                        verselinks.verselinks_verse===lk.verselinks_verse ||
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    // setFormData({
                    //     title:formdata.title,
                    //     media:formdata.media,
                    //     verse:formdata.verse,
                    //     versequote:formdata.versequote,
                    //     verselinks:linkitems,
                    //     trailing:formdata.trailing,
                    //     alignment:formdata.alignment,
                    //     error_list:[],
                    // })
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link && 
                        verselinks.verselinks_verse===lk.verselinks_verse &&
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    // setFormData({
                    //     title:formdata.title,
                    //     media:formdata.media,
                    //     verse:formdata.verse,
                    //     versequote:formdata.versequote,
                    //     verselinks:linkitems,
                    //     trailing:formdata.trailing,
                    //     alignment:formdata.alignment,
                    //     error_list:[],
                    // })
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
        
        
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        // setFormData({
            // title:formdata.title,
            // media:formdata.media,
            // verse:formdata.verse,
            // versequote:formdata.versequote,
            // verselinks:linkitems,
            // trailing:formdata.trailing,
            // alignment:formdata.alignment,
        //     error_list:[],
        // })
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_verse:link.verselinks_verse,
            verselinks_link:link.verselinks_link,
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newSlide= (e)=>{
        e.preventDefault();

        if(formdata.title ===''){
            Swal("Enter Slide Title","Please Enter the Title of the Slide to be display on the Slide","error");
            return;
        }

        if(formdata.alignment ===''){
            Swal("Enter Slide Title Alignment","Please Enter the Alignment of the Slide to be display on the Slide","error");
            return;
        }

        if(formdata.color ===''){
            Swal("Enter Slide Title Color","Please Enter the Color of the Slide to be display on the Slide","error");
            return;
        }

        if(formdata.type ===''){
            Swal("Display Verse Quote or Verse Link","Please Specify if to use a paragraph like Quote or a Linked quote.","error");
            return;
        }

        if(formdata.type ==='Verse Quote' && formdata.versequote ===''){
            Swal("Verse Quote Missing","Enter Verse Quote or Quote to Display.","error");
            return;
        }

        if(formdata.type ==='Verse Link' && linkitems ===''){
            Swal("Verse Links Missing","Enter Verse Links to Display.","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            id:(currentslide!=='')?currentslide.id:'',
            title:formdata.title,
            type:formdata.type,
            verse:formdata.verse,
            versequote:formdata.versequote,
            verselinks:linkitems,
            trailing:formdata.trailing,
            alignment:formdata.alignment,
            color:formdata.color,
            media:files,
        }
            axios.post('/api/save/slide',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadSlides();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadSlides= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/slides`)
        .then(response=>{
            if(response.data.status=== 200){
                setSlides(response.data.slides);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setSlides([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
                setSlides([]) 
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setSlides([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/slides`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setSlides(response.data.slides);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setSlides([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setSlides([]);
                        setLoadingPage(false)
                    }
                    else{
                        setSlides([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setLoadingPage(false)
                setSlides([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateSlide = (slide) => {
        // setShowAddTenant(true);
        setAddslide(true)
        setCurrentSlide(slide)
        if(slide.verselinks===null){
            setLinkItems('');
        }
        else{
            setLinkItems(JSON.parse(slide.verselinks))
        }

        setFormData({
            title:slide.title,
            type:slide.type,
            media:slide.media,
            verse:slide.verse,
            versequote:slide.versequote,
            verselinks:slide.verselinks,
            trailing:slide.trailing,
            alignment:slide.alignment,
            color:slide.color,
            error_list:[],
        })
        
    };

    const setAddslides = (ev)=> {
        setAddslide(!addslide);
        setCurrentSlide('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            media:'',
            type:'',
            verse:'',
            versequote:'',
            verselinks:[],
            trailing:'',
            alignment:'',
            color:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteSlide= (slide)=>{
        const form={
            id:slide.id,
        }

        let title='Delete '+slide.title;
        let text="This will remove this Slide from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/slide',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadSlides();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Welcome Home Slides</h1>
                    </div>
                    {loadingpage?<LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {addslide?
                            <div>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddslides()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-table'></span> Show Slides 
                                    </button>
                                </p>
                                
                                <p className='text-center bg-danger p-1 border-ok'>Please Start with First Slide</p>
                        </div>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddslides()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Slide 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addslide?
                            <form className='justify-content-center' onSubmit={newSlide}>
                                {!loading && loadingresok==='' && 
                                    <>
                                        <div className="form-group row">
                                            <label for="alignment" className="col-md-3 col-form-label text-md-right">Title Alignment <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 d-flex flex-row justify-content-between">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer"}}>
                                                        <input type="radio" required checked={formdata.alignment==="texthead-title-left"?"true":""} onChange={handleInputChange} className="" name="alignment" value="texthead-title-left" autoComplete="alignment"/> Left
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.alignment==="texthead-title-center"?"true":""} onChange={handleInputChange} className="" name="alignment" value="texthead-title-center" autoComplete="alignment"/> Center
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.alignment==="texthead-title-right"?"true":""} onChange={handleInputChange} className="" name="alignment" value="texthead-title-right" autoComplete="alignment"/> Right
                                                </label>
                                                {formdata.error_list && formdata.error_list.alignment && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.alignment}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                        {/* text-great text-orange text-white text-warning */}
                                            <label for="color" className="col-md-3 col-form-label text-md-right">Title Color <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 d-flex flex-row justify-content-between">
                                                <label className='m-0 p-1 border-white-b text-left bg-indigo' style={{"cursor": "pointer"}}>
                                                        <input type="radio" required checked={formdata.color==="text-great"?"true":""} onChange={handleInputChange} className="" name="color" value="text-great" autoComplete="color"/> Indigo
                                                </label>
                                                <label className='m-0 p-1 border-white-b text-left bg-orange' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.color==="text-orange"?"true":""} onChange={handleInputChange} className="" name="color" value="text-orange" autoComplete="color"/> Orange
                                                </label>
                                                <label className='m-0 p-1 border-white-b text-left bg-white' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.color==="text-white"?"true":""} onChange={handleInputChange} className="" name="color" value="text-white" autoComplete="color"/> White
                                                </label>
                                                <label className='m-0 p-1 border-white-b text-left bg-warning' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.color==="text-warning"?"true":""} onChange={handleInputChange} className="" name="color" value="text-warning" autoComplete="color"/> Yellow
                                                </label>
                                                {formdata.error_list && formdata.error_list.color && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.color}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" placeholder="Title Name" value={formdata.title} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="verse" className="col-md-3 col-form-label text-md-right">Verse </label>
                                            <div className="col-md-7">
                                                <input id="verse" type="text" className="form-control" name="verse" placeholder="Matthew 28:19" value={formdata.verse} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.verse && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.verse}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="trailing" className="col-md-3 col-form-label text-md-right">Trailing </label>
                                            <div className="col-md-7">
                                                <input id="trailing" type="text" className="form-control" name="trailing" placeholder="Bottom Trailing Quote" value={formdata.trailing} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.trailing && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.trailing}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="type" className="col-md-3 col-form-label text-md-right">Show <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 justify-content-between">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer"}}>
                                                        <input type="radio" required checked={formdata.type==="Verse Quote"?"true":""} onChange={handleInputChange} className="" name="type" value="Verse Quote" autoComplete="type"/> Verse Quote
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer"}}>
                                                    <input type="radio" required checked={formdata.type==="Verse Link"?"true":""} onChange={handleInputChange} className="" name="type" value="Verse Link" autoComplete="type"/> Verse Link
                                                </label>
                                                {formdata.error_list && formdata.error_list.type && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.type}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        {formdata.type ==='Verse Quote' &&
                                        <div className="form-group row">
                                            <label for="versequote" className="col-md-3 col-form-label text-md-right">Full Verse or Quote </label>
                                            <div className="col-md-7">
                                            <textarea id="versequote" className="form-control" name='versequote' placeholder="Enter Full Verse or Quote Here" value={formdata.versequote} onChange={handleInputChange} autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.versequote && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.versequote}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        }
                                        {formdata.type ==='Verse Link' &&
                                        <div className="form-group row">
                                            <label for="verselinks" className="col-md-3 col-form-label text-md-right">Verse Links</label>
                                            <div className="col-md-7 p-0">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <input id="verselinks_verse" type="text" className="form-control m-1" name="verselinks_verse" placeholder="1" value={verselinks.verselinks_verse} onChange={handleLinksInputChange} autoFocus/>
                                                    <input id="verselinks_link" type="text" className="form-control m-1" name="verselinks_link" placeholder="link" value={verselinks.verselinks_link} onChange={handleLinksInputChange} autoFocus/>
                                                    <input id="verselinks_text" type="text" className="form-control m-1" name="verselinks_text" placeholder="text" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus/>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Save'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.verselinks && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verselinks}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) {link.verselinks_verse}: {link.verselinks_link}: {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        }

                                        

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Media/ Video <sub className='text-red text-sm'>*</sub> </label>
                                            <div className='col-md-7'>
                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='video/*' />
                                                    <p>
                                                        Click or Drop Slide Video Here
                                                    </p>
                                                    
                                                </div>

                                                {formdata.error_list && formdata.error_list.media && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.media}</strong>
                                                    </span>
                                                }

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Video</p>
                                                                <video src={file.preview} width="180" autoPlay muted loop />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentslide !=='' &&
                                                        <>
                                                            {currentslide.media !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Video</p>
                                                                    <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/slides/'+currentslide.media} width="180" autoPlay muted loop />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>
                                                
                                            </div>
                                        </div>


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddslides()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentslide!==''?'Updating Slide':'Creating Slide'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentslide!==''?'Update Slide':'Save Slide'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-0 pt-2">
                                {slides && slides.map((slide,key) => (
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                                            <div className='text-center m-0 p-1'>
                                            {/* title media type verse versequote verselinks trailing alignment */}
                                                <h4 className='font-bold-700'>
                                                   <span className='text-left font-bold-700 text-xl'>{key+1}.</span> {slide.title}
                                                </h4>
                                                {slide.verse!==null?<p className=' text-left m-0 p-0'>Verse:: <span className='text-indigo'>{slide.verse}</span></p>:""}
                                                {slide.alignment!==null?<p className='text-left m-0 p-0'>Alignment:: <span className='text-indigo'>{slide.alignment}</span></p>:""}
                                                {slide.trailing!==null?<p className=' text-left m-0 p-0'>Trailing Text:: <span className='text-indigo'>{slide.trailing}</span></p>:""}
                                                
                                                {slide.versequote !==''?<div className="border-bottom-top m-0 p-0">
                                                    <p className="text-left m-0 p-2">{slide.versequote}</p>
                                                </div>:""}
                                                {slide.verselinks !==null?
                                                    <div className="border-bottom-top text-left m-0 p-0">
                                                        VerseLinks::
                                                        {slide.verselinks !==null  && JSON.parse(slide.verselinks).map((versel) => (
                                                            <a className='link-white p-1' href={versel.verselinks_link}><span className='font-bold-700'>{versel.verselinks_verse}</span> {versel.verselinks_text}.</a>
                                                        ))}
                                                    </div>
                                                :""}
                                                {slide.media !==null &&
                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                        <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/slides/'+slide.media} width="100%" autoPlay muted loop />
                                                    </div>
                                                }
                                                {deleteSlide && updateSlide &&
                                                <div className='m-0 p-0 text-muted my-auto'>
                                                    <div className='text-left pl-2'>
                                                        <div>
                                                            <button type="button" onClick={()=>{updateSlide(slide)}} className="btn btn-primary m-1 p-1">
                                                                <span className='fa fa-edit'></span> Edit
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteSlide(slide)}} className="btn btn-danger m-1 p-1">
                                                                <span className='fa fa-trash'></span> Del
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                            </div>   
                                        </div>
                                    </div>
                                ))}
                                </div>  

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default Dashwelcomeslides;