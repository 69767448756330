
import Swal from 'sweetalert';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import utawalasda from '../../assets/img/utawalasda.jpg';

function HomeFooter() {

  const [loading,setLoading]=useState(false);
  const [loadingresok,setLoadingResOk]=useState('');
  const [loadingres,setLoadingRes]=useState('');


    const [formdata,setFormData]=useState({
        fullname:'',
        issue:'',
        email:'',
        subject:'',
        error_list:[],
    });
    

    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }


    const newIssue= (e)=>{
        e.preventDefault();

        
        // Swal("Enter Email Address",(((formdata.phone).trim()).length) ,"error");
        //     return;
        if((formdata.email).trim() ===''){
            Swal("Enter Email Address","Please Enter Contact Us Email Address","error");
            return;
        }
        if((formdata.fullname).trim() ===''){
            Swal("Enter Full Name","Please Enter Your Full Name","error");
            return;
        }
        if((formdata.issue).trim() ===''){
            Swal("Enter Issue","Please Enter Your Issue or Comment","error");
            return;
        }

        if(((formdata.issue).trim()).length >500){
            Swal("Issue Too Long","Please Enter Your Issue or Comment with no more than 500 characters","error");
            return;
        }
        
        // if((formdata.subject).trim() ===''){
        //     Swal("Subject is Required","Please Enter Subject","error");
        //     return;
        // }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            fullname:formdata.fullname,
            issue:formdata.issue,
            // subject:formdata.subject,
            email:formdata.email,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/contactus',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({
                        fullname:'',
                        issue:'',
                        email:'',
                        subject:'',
                        error_list:[],
                    })
                    // localStorage.setItem('auth_token',response.data.token);
                    // localStorage.setItem('auth_name',response.data.username);
                    // setRedirect(true);
                    // setUrl('/dashboard');

                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

  return (
    <>
        <footer className="main-footer text-center bg-indigo text-white" style={{"bottom":"1%","width":"100%"}}>
          <div className='bg-indigo m-0 p-5 '>
            <div className='container m-0 p-0'>
              <div className='row m-0 p-0'>
                <div className='col-12 col-md-4'>
                  <p className='text-white font-bold-700 m-auto'>Be Inspired</p>
                </div>

                <div className='col-12 col-md-4'>
                  <div className="form-group">
                      <input type="email" id="inputEmail" placeholder="Your Email Address" className="form-control" />
                  </div>
                </div>

                <div className='col-12 col-md-4'>
                  <button className='btn btn-outline-secondary ml-3 font-bold-700'>Subscribe</button>
                </div>
              </div>
            </div>
          </div>

          <div className='bg-secondary m-0 p-4 footer-gray'>
            <div className='container m-0 mt-3 mr-0 p-0'>
              <div className='row m-0 p-0'>
                <div className='col-12 col-md-6 m-0 p-0'>
                  <div className='justify-content-center mx-auto'>
                  <img src={utawalasda} className='border-white-b' width="50%"  alt="First Image" />
                  </div>
                  <div className='justify-content-center mx-auto'>
                    <p className='m-0 ml-3 mt-3 p-0'>+254726247437</p>
                    <p className='m-0 ml-3 p-0'><a className='link-white' href="mailto:utawalasdasatellite@gmail.com" target="_self">utawalasdasatellite@gmail.com</a></p>
                    <p className='m-0 ml-3 mt-3 p-0'>P.O.BOX 5570-00100</p>
                    <p className='m-0 ml-3 mb-3 p-0'>Nairobi, Kenya</p>
                  </div>

                </div>

                <div className='col-12 col-md-6 m-0 p-0 mx-auto'>
                  <div className="card card-primary texts-info" style={{"marginBottom": "5%","minHeight": "300px","textAlign": "center","border": "none"}}>
                      <div className="card-body">
                          <h3 className="card-title title-black">Write to Us</h3>

                          <form className='justify-content-center' onSubmit={newIssue}>
                            <div className="form-group">
                                <input id="fullname" type="text" className="form-control" name="fullname" placeholder="Your Name" value={formdata.fullname} onChange={handleInputChange} required />
                                {formdata.error_list && formdata.error_list.fullname && 
                                    <span className="help-block text-danger">
                                        <strong>{formdata.error_list.fullname}</strong>
                                    </span>
                                }
                            </div>
                          

                            {/* <div className="form-group">
                                <input id="subject" type="text" className="form-control" name="subject" placeholder="Email Address" value={formdata.subject} onChange={handleInputChange} required />
                                {formdata.error_list && formdata.error_list.subject && 
                                    <span className="help-block text-danger">
                                        <strong>{formdata.error_list.subject}</strong>
                                    </span>
                                }
                            </div> */}


                            <div className="form-group">
                                <input id="email" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required />
                                {formdata.error_list && formdata.error_list.email && 
                                    <span className="help-block text-danger">
                                        <strong>{formdata.error_list.email}</strong>
                                    </span>
                                }
                            </div>

                            <div className="form-group">
                              <textarea id="issue" className="form-control" name='issue' placeholder="Your Message, Comment or Issue" value={formdata.issue} onChange={handleInputChange} required rows="4"></textarea>
                              {formdata.error_list && formdata.error_list.issue && 
                                    <span className="help-block text-danger">
                                        <strong>{formdata.error_list.issue}</strong>
                                    </span>
                                    
                                }
                            </div>
                            {/* <div className="form-group">
                                <input type="submit" className="btn btn-primary" value="Submit"/>
                            </div> */}

                              {loadingresok!=='' && 
                                  <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="submit" className="btn btn-primary border-info pl-4 pr-4">
                                              Submit Another
                                        </button>
                                      
                                  </div>
                              }

                              {loading && 
                                  <div className="col-md-12 text-center text-white">
                                          <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                          <br/>
                                          <Spinner
                                              as="span"
                                              variant='info'
                                              animation="border"
                                              size="lg"
                                              role="status"
                                              aria-hidden="true"
                                          />
                                          <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>Sending...</span>
                                  </div>
                              }

                              {loadingres!=='' && 
                                  <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                      <span className="help-block text-danger">
                                          <strong>{loadingres!=='' && loadingres}</strong>
                                      </span>
                                  </div>
                              }


                              {!loading && loadingresok ==='' && 
                                  <div className="row text-center m-0 mb-3 p-0">
                                      
                                      <div className="col-12 m-0 p-0">
                                          <button type="submit" className="btn btn-primary border-info pl-4 pr-4">
                                              Submit
                                          </button>
                                      </div>
                                  </div>
                              }

                          </form>

                            
                      </div>
                  </div>
                </div>
              </div>

              <p className='mt-2'><strong> &copy; {new Date().getFullYear()} SDA utawala satellite.</strong> </p>
            </div>
          </div>
            
          </footer>  
    </>
  );
}

export default HomeFooter;