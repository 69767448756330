import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext,useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';


import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashLesson(props) {
    document.title="Dashboard Lesson | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [lessons,setLessons]=useState([]);
    const [addlesson, setAddlesson]=useState(false);
    const [currentlesson,setCurrentLesson]=useState('');

// title header image verse memorytext verselink videoid
    const [formdata,setFormData]=useState({
        title:'',
        image:'',
        verse:'',
        header:'',
        memorytext:'',
        verselink:'',
        videoid:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newLesson= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            id:(currentlesson!=='')?currentlesson['id']:'',
            image:files,
            title:formdata.title,
            verse:formdata.verse,
            memorytext:formdata.memorytext,
            verselink:formdata.verselink,
            header:formdata.header,
            videoid:formdata.videoid,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/lesson',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadLessons();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                console.log(error)
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const loadLessons= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/lesson`)
        .then(response=>{
            if(response.data.status=== 200){
                setLessons(response.data.lessons);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setLessons([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setLessons([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
                setLoadingPage(false)
            setLessons([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getEvents = async (e) => { 
            
            await axios.get(`/api/load/lesson`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setLessons(response.data.lessons);
                setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setLessons([]);
                setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setLessons([]);
                setLoadingPage(false)
                    }
                    else{
                        setLessons([]);
                setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setLoadingPage(false)
                setLessons([]);
            })
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );


    const updateLesson = (lesson) => {
        setAddlesson(true)
        setCurrentLesson(lesson)

        setFormData({
            title:lesson['title'],
            image:lesson['image'],
            verse:lesson['verse'],
            header:lesson['header'],
            memorytext:lesson['memorytext'],
            verselink:lesson['verselink'],
            videoid:lesson['videoid'],
            error_list:[],
        })
        
    };

    const setAddlessons = (ev)=> {
        setAddlesson(!addlesson);
        setCurrentLesson('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            title:'',
            image:'',
            verse:'',
            header:'',
            memorytext:'',
            verselink:'',
            videoid:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteLesson= (lesson)=>{
        const form={
            id:lesson.id,
        }

        let title='Delete '+lesson['title'];
        let text="This will remove this Lesson from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/lesson',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadLessons();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Lesson Summary</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {currentlesson !=='' &&
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddlessons()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Back to Lesson 
                                </button>
                            </p>
                        }
                        {/* {console.log(lessons['title'])} */}
                        {!lessons &&
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                Create New Lesson
                            </p> 
                        }

                        {!lessons || currentlesson !==''?
                            <form className='justify-content-center' onSubmit={newLesson}>
                                {!loading && loadingresok==='' &&
                                    <>
                                    {/* // title header image verse memorytext verselink videoid */}
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" placeholder="Title Name" value={formdata.title} onChange={handleInputChange} required autoComplete="title" autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="header" className="col-md-3 col-form-label text-md-right">Leason Header <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="header" type="text" className="form-control" name="header" placeholder="Lesson Header" value={formdata.header} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.header && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.header}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="memorytext" className="col-md-3 col-form-label text-md-right">Memory Text <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="memorytext" className="form-control" name='memorytext' placeholder="Memory Text here" value={formdata.memorytext} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.memorytext && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.memorytext}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="verse" className="col-md-3 col-form-label text-md-right">Verse <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="verse" type="text" className="form-control" name="verse" placeholder="Lesson Verse" value={formdata.verse} onChange={handleInputChange} required autoFocus/>
                                                {formdata.error_list && formdata.error_list.verse && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.verse}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                            
                                        <div className="form-group row">
                                            <label for="verselink" className="col-md-3 col-form-label text-md-right">Verse Link <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="verselink" type="text" className="form-control" name="verselink" placeholder="Verse Link Address" value={formdata.verselink} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.verselink && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.verselink}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>                    

                                        <div className="form-group row">
                                            <label for="image" className="col-md-3 col-form-label text-md-right">Photo <sub className='text-red text-sm'>*</sub></label>

                                            <div className="col-md-7">

                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Lesson Image Here
                                                    </p>
                                                    
                                                </div>
                                                    

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentlesson !=='' &&
                                                        <>
                                                            {currentlesson.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/lesson/'+currentlesson.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="videoid" className="col-md-3 col-form-label text-md-right">Video ID <sub className='text-red text-sm'>*</sub> </label>
                                            <div className="col-md-7">
                                                <input id="videoid" type="text" className="form-control" name="videoid" placeholder="Youtube Video id" value={formdata.videoid} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.videoid && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.videoid}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Video Preview </label>
                                            <div className='col-md-7'>
                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    {formdata.videoid !=='' &&
                                                        <div className='m-0 p-1 border-bottom forum-div-h'>
                                                            <iframe
                                                                width="100%"
                                                                src={`https://www.youtube.com/embed/`+formdata.videoid}
                                                                frameborder="0"
                                                                allow=""
                                                                allowFullScreen
                                                                title="Lesson Youtube Video"
                                                            />
                                                            
                                                        </div>
                                                    }   
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddlessons()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentlesson!==''?'Updating Lesson':'Creating Lesson'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentlesson!==''?'Update Lesson':'Save Lesson'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center pt-3">
                                    <div className="col-12 col-md-6 m-0 p-0">
                                        <div className="bg-purple texts-black text-center p-2" >
                                            <div className='row'>
                                                <div className="col-6">
                                                    <div>
                                                        <h3 className="">{lessons['title']}</h3>

                                                        <h3>{lessons['header']}</h3>

                                                        <p className='p-2'>Memory Text: “{lessons['memorytext']}”</p>

                                                        (<a href={lessons['verselink']} target='_blank'>
                                                            {lessons['verse']}
                                                        </a>).
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/lesson/'+lessons['image']} className='border-white-b' width="100%"  alt="First Image" />
                                                </div>
                                            </div>
                                            <div className='m-0 p-0 text-left'>
                                                <a href='https://www.sabbath.school/LessonBook' target='_blank'>
                                                    <button className='btn btn-home ml-3'>Study Now</button>
                                                </a>
                                            </div>
                                                    
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 m-0 p-0">
                                        <div className='m-1'>
                                            <div className="video-responsive">
                                                <iframe
                                                    width="100%"
                                                    src={`https://www.youtube.com/embed/`+lessons['videoid']}
                                                    frameborder="0"
                                                    allow=""
                                                    allowFullScreen
                                                    title="Lesson Youtube Video"
                                                />
                                            </div>
                                            <div className='m-2 p-3 text-muted mx-auto'>
                                                <div>
                                                    <button type="button" onClick={()=>{updateLesson(lessons)}} className="btn btn-primary m-1 p-1">
                                                        <span className='fa fa-edit'></span> Edit
                                                    </button>
                                                    <button type="button" onClick={()=>{deleteLesson(lessons)}} className="btn btn-danger m-1 p-1">
                                                        <span className='fa fa-trash'></span> Del
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashLesson;