import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashVisitUs(props) {
    document.title="Dashboard Visit US | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [mon,setMon]=useState([]);
    const [tue,setTue]=useState([]);
    const [wed,setWed]=useState([]);
    const [thu,setThu]=useState([]);
    const [fri,setFri]=useState([]);
    const [sat,setSat]=useState([]);
    const [sun,setSun]=useState([]);

    const [key,setKey] =useState('allverses');
    const [loadingpage,setLoadingPage]=useState(false);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [verses,setVerses]=useState([]);
    const [services,setServices]=useState([]);
    const [addverse, setAddverse]=useState(false);
    const [addservice, setAddservice]=useState(false);
    const [currentverse,setCurrentVerse]=useState('');
    const [currentservice,setCurrentService]=useState('');

    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

// day time service | verse verseline
    const [formdata,setFormData]=useState({
        day:'',
        time:'',
        service:'',
        verse:'',
        verselinks:[],
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_verse==='' || verselinks.verselinks_link==='' || verselinks.verselinks_text===''){
            Swal("Error","Please Fill all required for verse");
        }
        else if(isNaN(verselinks.verselinks_verse.trim())){
            Swal("Error","Verse Must be a Number");
        }
        else{
            const {verselinks_link,verselinks_text,verselinks_verse}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link || 
                        verselinks.verselinks_verse===lk.verselinks_verse ||
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link && 
                        verselinks.verselinks_verse===lk.verselinks_verse &&
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Verse Link already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_verse,verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_verse:'',
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_verse:link.verselinks_verse,
            verselinks_link:link.verselinks_link,
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_verse:'',
            verselinks_link:'',
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newVisitUsVerse= (e)=>{
        e.preventDefault();

        if(formdata.verse ===''){
            Swal("Enter Verse","Please Enter Verse","error");
            return;
        }

        if(linkitems ===''){
            Swal("Verse LInes Missing","Enter Verse Lines to Display.","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentverse!=='')?currentverse.id:'',
            verse:formdata.verse,
            verseline:linkitems,
        }
            axios.post('/api/save/visitusverse',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadVisitUsVerse();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const newVisitUsService= (e)=>{
        e.preventDefault();

        if(formdata.day ===''){
            Swal("Enter Day of Service","Please Enter Day of Service","error");
            return;
        }

        if(formdata.time ===''){
            Swal("Enter Time of Day","Please Enter Time of Day for the Service","error");
            return;
        }

        if(formdata.service ===''){
            Swal("Enter Service","Please Enter Service to be Held at specified Time","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentservice!=='')?currentservice.id:'',
            day:formdata.day,
            time:formdata.time,
            service:formdata.service,
        }
            axios.post('/api/save/visitusservice',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadVisitUsService();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadVisitUsVerse= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/visitusverse`)
        .then(response=>{
            if(response.data.status=== 200){
                if(response.data.visitusverse ===null){
                    setVerses([])
                    setLoadingPage(false)
                }
                else{
                    setVerses(response.data.visitusverse);
                    setLoadingPage(false)
                }
            } 
            else if(response.data.status=== 401){
                setVerses([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setVerses([]) 
                setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setVerses([]) 
        })

    }

    const loadVisitUsService= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/visitusservices`)
        .then(response=>{
            if(response.data.status=== 200){
                setServices(response.data.visitusservices);
                        setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setServices([]) 
                Swal("Error",response.data.message,"error");
                        setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setServices([]) 
                        setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setServices([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/visitusverse`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        if(response.data.visitusverse ===null){
                            setVerses([])
                        setLoadingPage(false)
                        }
                        else{
                            setVerses(response.data.visitusverse);
                        setLoadingPage(false)
                        }
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setVerses([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setVerses([]);
                        setLoadingPage(false)
                    }
                    else{
                        setVerses([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setVerses([]);
                setLoadingPage(false)
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/visitusservices`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setServices(response.data.visitusservices);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setServices([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setServices([]);
                        setLoadingPage(false)
                    }
                    else{
                        setServices([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                        setLoadingPage(false)
                setServices([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateVerse = (verse) => {
        setAddverse(true)
        setCurrentVerse(verse)
        if(verse.verseline===null){
            setLinkItems('');
        }
        else{
            setLinkItems(JSON.parse(verse.verseline))
        }

        setFormData({
            day:'',
            time:'',
            service:'',
            verse:verse.verse,
            verselinks:verse.verseline,
            error_list:[],
        })
        
    };

    const updateService = (serv) => {
        setAddservice(true)
        setCurrentService(serv)

        setFormData({
            day:serv.day,
            time:serv.time,
            service:serv.service,
            verse:'',
            verselinks:'',
            error_list:[],
        })
        
    };

    const setAddverses = (ev)=> {
        setAddverse(!addverse);
        setAddservice(false);
        setCurrentVerse('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            day:'',
            time:'',
            service:'',
            verse:'',
            verselinks:[],
            error_list:[],
        })
        setFiles([])
    }

    const setShowverses = (ev)=> {
        setAddverse(false);
        setAddservice(false);
        setCurrentService('')
        setCurrentVerse('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            day:'',
            time:'',
            service:'',
            verse:'',
            verselinks:[],
            error_list:[],
        })
        loadVisitUsVerse();
        loadVisitUsService();
        setFiles([])
    }
    

    const setAddservices = (ev)=> {
        setAddservice(!addservice);
        setAddverse(false);
        setCurrentService('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            day:'',
            time:'',
            service:'',
            verse:'',
            verselinks:[],
            error_list:[],
        })
        setFiles([])
    }

    const deleteVerse= (verse)=>{
        const form={
            id:verse.id,
        }

        let title='Delete '+verse.verse;
        let text="This will remove this Verse from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/visitusverse',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadVisitUsVerse();
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadVisitUsVerse();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const deleteService= (service)=>{
        const form={
            id:service.id,
        }

        let title='Delete '+service.service;
        let text="This will remove this Service from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/visitusservice',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadVisitUsService()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadVisitUsService();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Visit Us Page</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {(addverse  || addservice)?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setShowverses()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Show Visit Us Page 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddservices()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> Create Visit Us Service Page 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addverse &&
                            <form className='justify-content-center' onSubmit={newVisitUsVerse}>
                                {!loading && loadingresok==='' && 
                                    <>
                                    {/* verse verseline */}
                                        <div className="form-group row">
                                            <label for="verse" className="col-md-3 col-form-label text-md-right">Verse </label>
                                            <div className="col-md-7">
                                                <input id="verse" type="text" className="form-control" name="verse" placeholder="Exodus 20:8-11" value={formdata.verse} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.verse && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.verse}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="verselinks" className="col-md-3 col-form-label text-md-right">Verse Links</label>
                                            <div className="col-md-7 p-0">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <input id="verselinks_verse" type="text" className="form-control m-1" name="verselinks_verse" placeholder="1" value={verselinks.verselinks_verse} onChange={handleLinksInputChange} autoFocus/>
                                                    <input id="verselinks_link" type="text" className="form-control m-1" name="verselinks_link" placeholder="link" value={verselinks.verselinks_link} onChange={handleLinksInputChange} autoFocus/>
                                                    <input id="verselinks_text" type="text" className="form-control m-1" name="verselinks_text" placeholder="text" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus/>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Save'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.verselinks && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verselinks}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) {link.verselinks_verse}: {link.verselinks_link}: {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        

                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setShowverses()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentverse!==''?'Updating Visit Us Verse Page':'Saving Visit Us Verse Page'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentverse!==''?'Update Visit Us Verse Page':'Save Visit Us Verse Page'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                        }

                        {addservice &&
                            <form className='justify-content-center' onSubmit={newVisitUsService}>
                                {!loading && loadingresok==='' && 
                                    <>
                                    {/* verse verseline */}
                                        <div className="form-group row">
                                            <label for="day" className="col-md-3 col-form-label text-md-right">Day of Service <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-8">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Sunday"?"true":""} onChange={handleInputChange} className="" name="day" value="Sunday" autoComplete="type"/> Sunday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Monday"?"true":""} onChange={handleInputChange} className="" name="day" value="Monday" autoComplete="type"/> Monday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Tuesday"?"true":""} onChange={handleInputChange} className="" name="day" value="Tuesday" autoComplete="type"/> Tuesday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Wednesday"?"true":""} onChange={handleInputChange} className="" name="day" value="Wednesday" autoComplete="type"/> Wednesday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Thursday"?"true":""} onChange={handleInputChange} className="" name="day" value="Thursday" autoComplete="type"/> Thursday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Friday"?"true":""} onChange={handleInputChange} className="" name="day" value="Friday" autoComplete="type"/> Friday
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.day==="Saturday"?"true":""} onChange={handleInputChange} className="" name="day" value="Saturday" autoComplete="type"/> Saturday
                                                </label>
                                                {formdata.error_list && formdata.error_list.day && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.day}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        

                                        <div className="form-group row">
                                            <label for="time" className="col-md-3 col-form-label text-md-right">Time Of Service <sub className='text-red text-sm'>*</sub> </label>
                                            <div className="col-md-7">
                                                <input id="time" type="text" className="form-control" name="time" placeholder="9:00am, 1200pm- 2:00pm..." value={formdata.time} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.time && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.time}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="service" className="col-md-3 col-form-label text-md-right">Service <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="service" type="text" className="form-control" name="service" placeholder="Morning Service,..." value={formdata.service} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.service && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.service}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        

                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setShowverses()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentservice!==''?'Updating Visit Us Service Page':'Saving Visit Us Service Page'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentservice!==''?'Update Visit Us Service Page':'Save Visit Us Service Page'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                        }

                        {!addverse && !addservice &&
                        
                        <div className="row justify-content-center p-5 pt-3">
                            <div className="col-12 col-md-6 m-0 p-0">
                                <div className="bg-indigo texts-black text-center m-1"  style={{"minHeight":"400px"}}>
                                    {verses && 
                                        <>
                                            {verses.length ===0 ?
                                                    <>
                                                        <p className='m-0 p-4 text-warning'>Please Create New Visit Us Verse Page</p>
                                                        <button type="button" onClick={()=>{setAddverses()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                                            <span className='fa fa-plus-circle'></span> Create VisitUs Verse Page 
                                                        </button>
                                                    </>
                                                :
                                                <div className='text-center m-0 p-2'>
                                                    <div className='border-bottom-warning'></div>
                                                    <h3 className='text-xl font-bold-700'>
                                                        {verses['verse']}
                                                    </h3>
                                                    <p>  
                                                        <>
                                                        {/* {console.log(JSON.parse(verses['verseline']))} */}
                                                            {(verses.length >0 || JSON.parse(verses['verseline']).length >0)  && JSON.parse(verses['verseline']).map((versel) => (
                                                                <>
                                                                    <a className='link-white text-lg font-bold-700' href={versel.verselinks_link} target='_blank'> {versel.verselinks_verse}  </a> 
                                                                    {versel.verselinks_text}.
                                                                </>
                                                            ))}
                                                        </>
                                                    </p>
                                                    <div className='border-bottom-warning'></div>
                                                    {deleteVerse && updateVerse &&
                                                        <div className='m-0 p-0 text-muted my-auto'>
                                                            <div className='text-left pl-2'>
                                                                <div>
                                                                    <button type="button" onClick={()=>{updateVerse(verses)}} className="btn btn-primary m-1 p-1">
                                                                        <span className='fa fa-edit'></span> Edit
                                                                    </button>
                                                                    
                                                                    <button type="button" onClick={()=>{deleteVerse(verses)}} className="btn btn-danger m-1 p-1">
                                                                        <span className='fa fa-trash'></span> Del
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div> 
                                            }
                                        </>
                                        
                                    }  
                                </div>
                            </div>
                            <div className="col-12 col-md-6 m-0 p-0">
                                <div className="bg-indigo texts-black text-center m-1"  style={{"minHeight":"400px"}}>
                                    <div className='text-center m-0 p-1'>
                                        
                                        <div className='border-bottom-warning'></div>
                                        <h3 className='text-xl font-bold-700'>
                                            Sabbath Service:
                                        </h3>
                                        
                                        {services && services[0] && services[0].fri && services[0].fri.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                {services[0] && services[0].fri.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>FRIDAY</span> {serv.time} - {serv.service} </p>
                                                        {/* <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p> */}
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                // <>
                                                //         <p>Every <span className='font-bold-700'>FRIDAY</span> {serv.time} - {serv.service} </p>
                                                //     </>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].sat && services[0].sat.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>SATURDAY</p>
                                                {services[0] && services[0].sat.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].sun && services[0].sun.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>SUNDAY</p>
                                                {services[0] && services[0].sun.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].mon && services[0].mon.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>MONDAY</p>
                                                {services[0] && services[0].mon.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].tue && services[0].tue.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>TUESDAY</p>
                                                {services[0] && services[0].tue.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].wen && services[0].wen.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>WEDNESDAY</p>
                                                {services[0] && services[0].wen.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        {services && services[0] && services[0].thu && services[0].thu.length >0 &&
                                            <div className='m-0 pt-3 pb-1'>
                                                <p className='font-bold-700'>THURSDAY</p>
                                                {services[0] && services[0].thu.map((serv,key) =>(
                                                    <div className='d-flex justify-content-between border-info m-0 mt-1'>
                                                        <p className='m-0 p-1 text-left'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                        {deleteService && updateService &&
                                                            <div className='m-0 p-0 text-muted my-auto'>
                                                                <div className='text-left p-0'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateService(serv)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteService(serv)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        <div className='border-bottom-warning'></div>
                                    </div>   
                                </div>
                            </div>

                            <div className="col-12 m-0 p-0">
                                <div className="bg-indigo texts-black text-center m-1 p-2" >
                                    <div className='text-center m-0 p-1'>
                                        
                                        <div className='border-bottom-warning'></div>

                                        <h3 className='text-xl font-bold-700 pt-4'>Contact Us</h3>
                                        
                                        <p className=''>Address</p>
                                        <p> P.O. BOX 5570 00100, Nairobi, Kenya</p>

                                        <p className='m-0 ml-3 p-0'><a className='link-white' href="mailto:utawalasdasatellite@gmail.com" target="_self">Email:utawalasdasatellite@gmail.com</a></p>
                                            
                                    </div>
                                </div>
                            </div>


                        </div>

                        
                        }
                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashVisitUs;