
import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';
import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import ReLogin from '../home/ReLogin';
import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';



function Events() {

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified,loggedrole, setLoggedRole} =useContext(LoginContext);
    const [events,setEvents]=useState([]);
    const [addevent, setAddevent]=useState(false);
    const [currentevent,setCurrentEvent]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);



    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getEvents = async (e) => { 
            if(loggedname !==''){
                axios.get(`/api/get/load/event`)
                .then(response=>{
                    if(response.data.status=== 200){
                        setEvents(response.data.events);
                                setLoadingPage(false);
                    } 
                    else{
                        setEvents([]) 
                                setLoadingPage(false);
                    }
                })
                .catch((error)=>{
                    setEvents([]) 
                                setLoadingPage(false);
                })
            }
            else{
                axios.get('/sanctum/csrf-cookie').then(res =>{
                    axios.get(`/api/get/load/events`)
                    .then(response=>{
                        if (doneloading) {
                            if(response.data.status=== 200){
                                setEvents(response.data.events);
                                setLoadingPage(false);
                            }
                            else{
                                setEvents([]);
                                setLoadingPage(false);
                            }
                            
                        }
                    })
                    .catch(error=>{
                        setEvents([]);
                                setLoadingPage(false);
                    })
                })
                .catch((error)=>{
                    setEvents([]);
                                setLoadingPage(false);
                })
            }
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  return (
    <>
        <div className="row justify-content-center">
            <div className="texts-black text-center p-1 pb-3" >
                {loadingpage?
                <LoadingDetailsSpinner />
                :
                <>
                    <div className='row p-0 border-white-b m-3'>
                        <div className="col-12 col-md-3 m-0 p-0 events-div-h ">
                            <a className='link-image' href='https://tendaysofprayer.org/' target='_blank'><img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" /></a>
                        </div>
                        <div className="col-12 col-md-9 m-0 p-0">
                            <div className='row text-center bg-gray text-white border-white-b mx-auto my-auto events-div-h'>
                                <div className='col-4 bg-opacity-6 events-center'>
                                    <a href='https://tendaysofprayer.org/' target='_blank'>
                                        <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                    </a>
                                </div>
                                <div className='col-4 row events-center'>
                                    <div className='col-3 text-left'>
                                        <img src={time} className='img-events-time' alt="First Image" />
                                    </div>
                                    <div className='col-9 m-0 p-0 text-left'>
                                        <span className='text-sm m-0 p-0'>
                                            5:00PM - 
                                            <br/>
                                            6:00PM
                                        </span>
                                    </div>
                                </div>
                                <div className='col-4 row events-center border-left'>
                                    <div className='col-3 text-left'>
                                        <img src={venue} className='img-events-time' alt="First Image" />
                                    </div>
                                    <div className='col-9 m-0 p-0 mx-auto my-auto text-left text-sm'>
                                        UTAWALA SATELLITE SDA CHURCH
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {events && events.map((eventt,key) =>(
                        <div className='row p-0 border-white-b m-3'>
                            <div className="col-12 col-md-3 m-0 p-0 forum-div-h ">
                                {eventt.image ===null?
                                    <div className='bg-indigo events-date border-light events-center'>
                                        <p className='events-center events-date-day'>{eventt.fromday}</p>
                                        <p className='events-center events-date-month'>{eventt.frommonth}</p>
                                    </div>
                                    :
                                    <div className='bg-indigo border-light '>
                                        <p className='events-center'>{eventt.fromday} ,{eventt.frommonth} </p>
                                    </div>
                                }
                                {eventt.image !==null?<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+eventt.image} className='border-white-b' width="100%"  alt="First Image" />:''}
                            </div>
                            <div className="col-12 col-md-9 m-0 p-0">
                                <div className='row text-center bg-gray bg-opacity-6 text-white border-white-b p-3 mx-auto my-auto events-div-h'>
                                
                                    <div className='col-4 events-center'>
                                        <span className='events-event m-0 p-0 text-sm'>
                                        {eventt.event}
                                        </span>
                                    </div>
                                    <div className='col-4 row events-center'>
                                        <div className='col-3 text-left'>
                                            <img src={time} className='img-events-time' alt="First Image" />
                                        </div>
                                        <div className='col-9 m-0 p-0 text-left'>
                                            <span className='text-sm m-0 p-0'>
                                                {eventt.fromtime} - 
                                                <br/>
                                                {eventt.totime}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-4 row events-center border-left'>
                                        <div className='col-3 text-left'>
                                            <img src={venue} className='img-events-time' alt="First Image" />
                                        </div>
                                        <div className='col-9 m-0 p-0 mx-auto my-auto text-left text-sm'>
                                            {eventt.venue}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                }

                {/* <div className='row p-0 border-white-b m-3'>
                    <div className="col-12 col-md-3 m-0 p-0 events-div-h ">
                        <div className='bg-indigo events-date border-light events-center'>
                            <p className='events-center events-date-day'>22</p>
                            <p className='events-center events-date-month'>JAN</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 m-0 p-0">
                        <div className='row text-center bg-gray bg-opacity-6 text-white border-white-b p-3 mx-auto my-auto events-div-h'>
                            <div className='col-4 events-center'>
                                <span className='events-event m-0 p-0 text-sm'>CHURCH BOARD MEETING</span>
                            </div>
                            <div className='col-4 row events-center'>
                                <div className='col-3 text-left'>
                                    <img src={time} className='img-events-time' alt="First Image" />
                                </div>
                                <div className='col-9 m-0 p-0 text-left'>
                                    <span className='text-sm m-0 p-0'>
                                        5:00PM - 
                                        <br/>
                                        6:00PM
                                    </span>
                                </div>
                            </div>
                            <div className='col-4 row events-center border-left'>
                                <div className='col-3 text-left'>
                                    <img src={venue} className='img-events-time' alt="First Image" />
                                </div>
                                <div className='col-9 m-0 p-0 text-left text-sm'>
                                    UTAWALA SATELLITE SDA CHURCH
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                         
            </div>
        </div>
        
    </>
  );
}

export default Events;