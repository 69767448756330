import Button from 'react-bootstrap/Button';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import {LoginContext} from "../contexts/LoginContext";
import ReVerifyEmail from './ReVerifyEmail';

function Login() {

    document.title="Login";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified, loggedrole,setLoggedRole} =useContext(LoginContext);

    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    
    const [loggedoff,setLoggedOff]=useState(false);
    const [emailnotverified,setEmailNotVerified]=useState(false);


    const [formdata,setFormData]=useState({
        email:'',
        password:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const [authname,setAuthName]=useState('');
    
    // useEffect(()=>{
    //     if(localStorage.getItem("auth_token_ut")){
    //         setLoggedToken(localStorage.getItem("auth_token_ut"));
    //         setLoggedName(localStorage.getItem("auth_name_ut"));
            
    //         setAuthName(localStorage.getItem("auth_name_ut"))
    //         setRedirect(true);
    //         setUrl('/dashboard');
    //     }
    //     else{
    //         setLoggedToken(localStorage.getItem("auth_token_ut"));
    //         setLoggedName(localStorage.getItem("auth_name_ut"));
    //         setAuthName(localStorage.getItem("auth_name_ut"))
    //     }
    //   },[authname])
      
    // if(!redirect){
    //     localStorage.removeItem('auth_token_ut');
    //     localStorage.removeItem('auth_name_ut');
    // }

   

    const authUser= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
            password:formdata.password,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/login',form)
            .then(response=>{
                if(response.data.status=== 200){
                    if(response.data.message !=="Logged in"){
                        setEmailNotVerified(true)
                        setLoadingRes("")
                        setFormData({...formdata,error_list:[]});
                        localStorage.setItem('auth_image_ut',(response.data.image===null)?'':response.data.image);
                        localStorage.setItem('auth_token_ut',response.data.token);
                        localStorage.setItem('auth_name_ut',response.data.username);
                        localStorage.setItem('auth_role_ut',response.data.role);
                        setLoggedRole(response.data.role);
                        setLoggedToken(response.data.token);
                        setLoggedName(response.data.username);
                    }
                    else{
                        setLoadingResOk("Logged In. Redirecting...")
                        setLoadingRes("")
                        setFormData({...formdata,error_list:[]});
                        localStorage.setItem('auth_token_ut',response.data.token);
                        localStorage.setItem('auth_image_ut',(response.data.image===null)?'':response.data.image);
                        localStorage.setItem('auth_name_ut',response.data.username);
                        localStorage.setItem('auth_role_ut',response.data.role);
                        setLoggedRole(response.data.role);
                        setLoggedToken(response.data.token);
                        setLoggedName(response.data.username);
                        setRedirect(true);
                        setUrl('/dashboard');
                    }
                    
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            setLoadingResOk("")
            setLoading(false);
        })

    }

    if(redirect){
        return <Navigate to={url} />
    }
  return (
    <>
    <HomeNavBar active='login'/>

    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
    {emailnotverified ?
        <ReVerifyEmail setEmailNotVerified={setEmailNotVerified} emailnotverified={emailnotverified} setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
    :
        <div class="container mt-3">
            <div class="row justify-content-center mt-3">
                <div class="col-md-8 mt-3">
                    <div class="card border-white-b">
                        <div class="card-header bg-indigo text-center text-lg p-0 m-0">
                            <h1 style={{"textAlign": "center"}}>Login</h1>
                        </div>

                        <div class="card-body">
                        
                            <form className='justify-content-center' onSubmit={authUser}>
                                {!loading && 
                                    <>
                                    <p className='border-bottom text-center p-2 pb-3 font-bold-700'>Use Your Email Address or Username to Login</p>
                                        <div class="form-group row">
                                            <label for="username" class="col-md-4 col-form-label text-md-right">Username or Email</label>
                                            <div class="col-md-6 ">
                                                <input id="Username" type="text" className="form-control" name="email" placeholder="Username or Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }

                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                            <div class="col-md-6">
                                                <input id="password" type="password" className="form-control @error('password') is-invalid @enderror" name="password" placeholder="Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                                {formdata.error_list && formdata.error_list.password && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.password}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-10 elevation-0 mb-2 p-2 text-center border-ok">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>Signing In...</span>
                                            
                                            
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-12 elevation-0 mt-2 mb-3 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }

                                        {!loading && loadingresok ==='' && 
                                            <div className="row text-center m-0 mb-3 p-0">
                                                
                                                <div className="col-12 m-0 p-0">
                                                    <button type="submit" className="btn btn-outline-primary border-info  pl-4 pr-4">
                                                        Login <i className='fa fa-chevron-right'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        }

                                        {!loading && loadingresok ==='' && 
                                            <div class="form-group mb-0 pt-3">
                                                <div class="d-flex justify-content-center">
                                                    <Link className="btn btn-link " to="/password/request"> 
                                                        Forgot Your Password?
                                                    </Link>
                                                </div>

                                                <div class="d-flex justify-content-center">
                                                    <Link className="btn btn-link " to="/signup"> 
                                                        Create New Account Instead?
                                                    </Link>
                                                </div>

                                                
                                            </div>
                                        }

                                        {/* {!loading && loadingresok ==='' && 
                                            <div class="form-group row mb-0">
                                                <div class="col-8 offset-5 offset-lg-8">
                                                    <Link className="btn btn-link " to="/password/request"> 
                                                        Forgot Your Password?
                                                    </Link>
                                                </div>
                                            </div>
                                        } */}

                                        

                            </form>

                            {!loading && 
                                <>
                                    <h2 className='border-bottom text-center text-great mt-3 p-2 pb-3 font-bold-700'>OR:</h2>
                                    <p className='border-bottom text-center text-great p-2 pb-3 font-bold-700'>Use other accounts to Login</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </main>
      
    </>
  );
}

export default Login;