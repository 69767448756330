import Slider from "react-slick";
import axios from 'axios';
import { useEffect, useMemo, useState, useCallback } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function SabbathSchoolAnimated() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };


    const [playlistItems, setPlaylistItems] = useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    
    //     // Replace with your API key and playlist ID
    //     const apiKey = 'AIzaSyD9XcDD1LVpN62ITaG0gtleNMRvxcvxUok';
    //     const playlistId = 'PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc'
  

    useEffect(()=>{
        let doneloading=true;
        
        const getItems = async (e) => { 
            fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc&maxResults=30&key=AIzaSyD9XcDD1LVpN62ITaG0gtleNMRvxcvxUok`)
                .then(response => response.json())
                .then((data) => setPlaylistItems(data.items))
                .catch(err => setPlaylistItems([]))
        };
        getItems();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    // console.log(playlistItems)

    // videos = []

    // for item in data['items']:
    // video = {
    //   'title': item['snippet']['title'],
    //   'description': item['snippet']['description'],
    //   'thumbnail': item['snippet']['thumbnails']['default']['url'],
    //   'video_id': item['snippet']['resourceId']['videoId']   

    // }
    // videos.append(video)
    // useEffect(()=>{
    //     let doneloading=true;
        
    //     const getSlides = async (e) => { 
    //         axios.get('/sanctum/csrf-cookie').then(res =>{
    //             axios.get(`/api/get/load/sabbathschoolanimations`)
    //             .then(response=>{
    //                 if (doneloading) {
    //                     if(response.data.status=== 200){
    //                         setSlides(response.data.sabbathschoolanimations);
    //                     }
    //                     else{
    //                         setSlides([]);
    //                     }
    //                 }
    //             })
    //             .catch(error=>{
    //                 setSlides([]);
    //             })
    //         })
    //         .catch((error)=>{
    //             setSlides([]);
    //         })
    //     };
    //     getSlides();

    //     return ()=>{
    //         doneloading=false;
            
    //     }
    // },[slides])
  return (
    <div className=' m-0 p-3 pt-3'>
        <p>Sabbath School Animated Presentations</p>
        <div className='m-3 p-0 '>

            {/* <div>
                {playlistItems.map((slide,key) => (
                    <div className="bg-white text-info"> 
                    <p>{slide['snippet']['title']}</p>
                    <p>{slide['snippet']['description']}</p>
                    <p>{slide['snippet']['resourceId']['videoId']}</p>
                    </div>
                    
                ))}
            </div> */}
            <Slider {...settings}>
                {playlistItems.map((slide,key) => (
                    <div className='text-black'>
                        <div className='bg-white m-0 p-0'>
                            <iframe
                                width="100%"
                                src={`https://www.youtube.com/embed/`+slide['snippet']['resourceId']['videoId']}
                                frameborder="0"
                                title="Embedded youtube"
                            />
                        </div>
                        <div className="bg-white m-0 p-2" style={{"height":"150px","overflowY":"auto"}}>
                            <p>{slide['snippet']['title']}</p>
                            <div className="border-bottom-top m-0 p-0">
                                <p className="text-left m-0 p-2">
                                    <i className="text-muted">{slide['snippet']['description']}</i>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                ))}
            </Slider>
        </div>
    </div>
  );
}

const data= [
    {
        title:'The Book of Mark - Sabbath School Lesson 3: "Controversies"',
        video:'https://www.youtube.com/embed/S7HZuAxJKOw',
        description:'Study this lesson for the week of July 13, 2024, to July 19,  2024.',
    },
    {
        title:'The Book of Mark - Sabbath School Lesson 2: "A Day in the Ministry of Jesus"',
        video:'https://www.youtube.com/embed/9diBttHO2Jw',
        description:'Study this lesson for the week of July 6, 2024, to July 12, 2024.',
    },
    {
        title:'The Book of Mark - Sabbath School Lesson 1: "The Beginning of the Gospel"',
        video:'https://www.youtube.com/embed/MGUBCPx7-tk',
        description:'Study this lesson for the week of June 29, 2024, to July 5, 2024',
    },
    {
        title:'The Great Controversy - Sabbath School Lesson 13: "The Triumph of God\'s Love"',
        video:'https://www.youtube.com/embed/9diBttHO2Jw',
        description:'Study this lesson for the week of June 22, 2024, to June 28, 2024',
    },
    {
        title:'The Great Controversy - Sabbath School Lesson 11: "The Impending Conflict"',
        video:'https://www.youtube.com/embed/dzXAxoTvoNU',
        description:'Study this lesson for the week of June 8, 2024, to June 14, 2024',
    },
    {
        title:'The Great Controversy - Sabbath School Lesson 10: "Spiritualism Exposed"',
        video:'https://www.youtube.com/embed/xmW7aySVUwU',
        description:'Study this lesson for the week of June 1, 2024, to June 7, 2024',
    }
    
    // ,
    // {
    //     title:'The Great Controversy - Sabbath School Lesson 9: "The Foundation of God\'s Government"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of May 25, 2024, to May 31, 2024',
    // },
    // {
    //     title:'The Great Controversy - Sabbath School Lesson 8: "Light from the Sanctuary"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of May 18, 2024, to May 24, 2024',
    // },
    // {
    //     title:'The Great Controversy - Sabbath School Lesson 7: "Motivated by Hope"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of May 11, 2024, to May 17, 2024.',
    // },
    // {
    //     title:'The Great Controversy - Sabbath School Lesson 6: "The Two Witnesses"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of May 4, 2024, to May 10, 2024.',
    // },
    // {
    //     title:'The Great Controversy - Sabbath School Lesson 5: "Faith Against All Odds"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 12: "Worship that Never Ends"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Mar. 17, 2024, to Mar. 22, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 11: "Longing for God in Zion"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Mar. 9, 2024, to Mar. 15, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 10: "Lessons of the Past"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Mar. 2, 2024, to Mar. 8, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 9: "Blessed Is He Who Comes in the Name of the Lord"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Feb. 24, 2024, to Mar. 1, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 8: "Wisdom for Righteous Living"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Feb. 17, 2024, to Feb. 23, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 7: "Your Mercy Reaches Unto the Heavens"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Feb. 10, 2024, to Feb. 16, 2024.',
    // },
    // {
    //     title:'Psalms - Sabbath School Lesson 6: "I Will Arise"',
    //     video:'https://www.youtube.com/embed/wR6-VS2Y_2A',
    //     description:'Study this lesson for the week of Feb. 3, 2024, to Feb. 9, 2024.',
    // }

    // https://www.youtube.com/watch?v=9diBttHO2Jw&list=PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc&index=1&pp=iAQB
    // https://www.youtube.com/watch?v=dzXAxoTvoNU&list=PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc&index=4&pp=iAQB
    // https://www.youtube.com/watch?v=xmW7aySVUwU&list=PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc&index=5&pp=iAQB

];

export default SabbathSchoolAnimated;