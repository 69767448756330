
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';
import { useEffect, useState, useContext } from 'react';

import kingjamesbible from '../../assets/img/kingjamesbible.jpeg';
import cover23a from '../../assets/img/cover23a.jpg';
import tithesandoffering from '../../assets/img/to.jpg';



import LoadWhatsHappening from './LoadWhatsHappening';
import SabbathSchoolAnimated from './SabbathSchoolAnimated';
import TheTruth from './TheTruth';
import Events from './Events';
import TheBelief from './TheBelief';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function Groups() {
    document.title="Groups | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

  return (
    <>
    <HomeNavBar active='groups'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className=" text-white text-center" >
                        <p className='m-0 p-3 mb-0 pb-0 beliefs-p'>Groups Feed</p>
                    </div>
                </div>
                <div className="row justify-content-center bg-white p-3 border-light">
                    
                    <div className="col-12 col-md-8 m-0 p-0" style={{"max-height":"100vh","overflowY":"auto"}}>
                        <div className="border-white-b texts-black text-center m-1">
                          <div className='p-0'>
                            <p className='d-flex flex-row m-0 pt-2 pb-2 justify-content-between border-bottom-b'>
                                <p className='m-0 p-1 text-muted'><span className='fa fa-info-circle pr-2'></span>This post is from a suggested group</p>
                                <Link to='#'>
                                    <button className='btn btn-outline-orange ml-3 pl-4 pr-4 font-bold-700'>Join</button>
                                </Link>
                            </p>
                          </div>

                          <div className='p-0'>
                            <p className='m-0 pt-2 pb-2 justify-content-between border-bottom-b'>
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex m-0 p-1 text-muted'>
                                        <div className='user-div bg-primary text-white pr-2'>
                                            <span>M</span>
                                        </div>
                                        <div className='text-left pl-2'>
                                            <p className='p-0 m-0'>Anonymous <span className='fa fa-star'></span></p>
                                            <Link to='#' className='text-sm text-muted'>
                                                January 15, 2023 · <i className='text-great'>posted in</i> Utawala Satellite SD Group
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <Link to='#' className='text-sm text-muted'>
                                            <span className='fa fa-chevron-right'></span>
                                        </Link>
                                        
                                    </div>
                                </div>
                                <div className='text-left m-2 pt-3'>
                                    <p>Welcome to the group! You can connect with other members, get updates and share photos.</p>
                                </div>

                                <div className='text-left m-0'>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div>
                                            <Link to='#' className='link-black p-2 '>
                                                <button className='btn btn-indigo text-xs p-1 pl-2 pr-2 font-bold-700'><span className='fa fa-thumbs-up'></span> <span>0</span></button>
                                            </Link>
                                            <Link to='#' className='link-black p-2 '>
                                                <button className='btn btn-outline-secondary text-xs p-1 pl-2 pr-2 font-bold-700'><span className='fa fa-plus'></span> <span>😊</span></button>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span>0</span> <span>Comments</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </p>
                          </div>


                          <div className='p-2'>
                            <div className="form-group p-2">
                                <textarea id="inputMessage" className="form-control" placeholder="Write a Comment..." ></textarea>
                            </div>
                          </div>
                        </div>  

                        <div className="border-white-b texts-black text-center m-1">
                          <div className='p-0'>
                            <p className='d-flex flex-row m-0 pt-2 pb-2 justify-content-between border-bottom-b'>
                                <p className='m-0 p-1 text-muted'><span className='fa fa-info-circle pr-2'></span>This post is from a suggested group</p>
                                <Link to='#'>
                                    <button className='btn btn-outline-orange ml-3 pl-4 pr-4 font-bold-700'>Join</button>
                                </Link>
                            </p>
                          </div>

                          <div className='p-0'>
                            <p className='m-0 pt-2 pb-2 justify-content-between border-bottom-b'>
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex m-0 p-1 text-muted'>
                                        <div className='user-div bg-primary text-white pr-2'>
                                            <span>M</span>
                                        </div>
                                        <div className='text-left pl-2'>
                                            <p className='p-0 m-0'>Anonymous <span className='fa fa-star'></span></p>
                                            <Link to='#' className='text-sm text-muted'>
                                                January 15, 2023 · <i className='text-great'>posted in</i> Utawala Satellite SD Group
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <Link to='#' className='text-sm text-muted'>
                                            <span className='fa fa-chevron-right'></span>
                                        </Link>
                                        
                                    </div>
                                    
                                </div>
                                <div className='text-left m-2 pt-3'>
                                    <p>Welcome to the group! You can connect with other members, get updates and share photos.</p>
                                </div>

                                <div className='text-left m-0'>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div>
                                            <Link to='#' className='link-black p-2 '>
                                                <button className='btn btn-outline-secondary text-xs p-1 pl-2 pr-2 font-bold-700'><span className='fa fa-thumbs-up'></span> <span>0</span></button>
                                            </Link>
                                            <Link to='#' className='link-black p-2 '>
                                                <button className='btn btn-outline-secondary text-xs p-1 pl-2 pr-2 font-bold-700'><span className='fa fa-plus'></span> <span>😊</span></button>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span>0</span> <span>Comments</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </p>
                          </div>


                          <div className='p-2'>
                            <div className="form-group p-2">
                                <textarea id="inputMessage" className="form-control" placeholder="Write a Comment..." ></textarea>
                            </div>
                          </div>
                        </div>  
                    </div>

                    <div className="col-12 col-md-4 m-0 p-0">
                        <div className="border-white-b texts-black text-center m-1"  style={{"maxHeight":"350px"}}>
                            <div className='p-2'>
                                <Form.Control
                                    type="search"
                                    placeholder="🔍Search"
                                    className="me-1 search border-light"
                                    aria-label="Search"
                                />
                            </div>

                            <h3>Suggested Groups</h3>

                            <div>
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex m-0 p-1 text-muted'>
                                        <div className='user-div bg-primary text-white pr-2'>
                                            <span>M</span>
                                        </div>
                                        <div className='text-left pl-2' style={{"width":"100%","overflowX":"auto","overflow":"hidden","textOverflow":"ellipsis"}}>
                                            <Link to='#' className='text-sm text-muted'>
                                                Utawala Satellite SD Group
                                            </Link>
                                            <p><small className='p-0 m-0'>Public·3 members</small></p>
                                        </div>
                                    </div>
                                    <div>
                                        <Link to='#' className='mr-3'>
                                            <button className='btn btn-outline-orange ml-3 p-3 pl-4 font-bold-700'>Join</button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>  
                    </div>

                </div>
            </div>
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default Groups;