
import { useEffect, useState, useContext, useCallback } from 'react';


import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import ReLogin from '../home/ReLogin';


import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'



function DashBelief(props) {
    document.title="Dashboard Belief | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    

    const [key,setKey] =useState('allverses');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [loadingpage,setLoadingPage]=useState(false);
    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [beliefs,setBeliefs]=useState([]);
    const [addbelief, setAddbelief]=useState(false);
    const [currentbelief,setCurrentBelief]=useState('');


// belief link
    const [formdata,setFormData]=useState({
        belief:'',
        link:'',
        error_list:[],
    });

    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }


    const newBelief= (e)=>{
        e.preventDefault();

        if(formdata.belief ===''){
            Swal("Enter Belief","Please Enter Belief Message","error");
            return;
        }

        if(formdata.link ===''){
            Swal("Enter Belief Link","Please Enter a Link for the Belief","error");
            return;
        }

        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        // day time service | verse verseline
        const form={
            id:(currentbelief!=='')?currentbelief.id:'',
            belief:formdata.belief,
            link:formdata.link,
        }
            axios.post('/api/save/belief',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadVisitUsService();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    

    const loadVisitUsService= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/beliefs`)
        .then(response=>{
            if(response.data.status=== 200){
                setBeliefs(response.data.beliefs);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setBeliefs([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setBeliefs([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setBeliefs([]) 
        })

    }


    

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/beliefs`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setBeliefs(response.data.beliefs);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setBeliefs([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setBeliefs([]);
                        setLoadingPage(false)
                    }
                    else{
                        setBeliefs([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setLoadingPage(false)
                setBeliefs([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const setAddbeliefs = (ev)=> {
        setAddbelief(!addbelief);
        setCurrentBelief('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            belief:'',
            link:'',
            error_list:[],
        })
    }

    const updateBelief = (belief) => {
        setAddbelief(true)
        setCurrentBelief(belief)

        setFormData({
            belief:belief.belief,
            link:belief.link,
            error_list:[],
        })
        
    };

    

    const deleteBelief= (belief)=>{
        const form={
            id:belief.id,
        }

        let title='Delete '+belief.belief;
        let text="This will remove this Belief from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/belief',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                        loadVisitUsService()
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadVisitUsService()
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Belief Page</h1>
                    </div>
                    {loadingpage?
                        <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {addbelief?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddbeliefs()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Show Belief Page 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddbeliefs()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> Create Belief Page 
                                    </button>
                                </p>
                                
                            </>
                        }
                        

                        {addbelief &&
                            <form className='justify-content-center' onSubmit={newBelief}>
                                {!loading && loadingresok==='' && 
                                    <>
                                      
                                        <div className="form-group row">
                                            <label for="belief" className="col-md-3 col-form-label text-md-right">Belief <sub className='text-red text-sm'>*</sub> </label>
                                            <div className="col-md-7">
                                            <textarea id="belief" className="form-control" name='belief' placeholder="Belief Message here" value={formdata.belief} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.belief && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.belief}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="link" className="col-md-3 col-form-label text-md-right">Belief Link <sub className='text-red text-sm'>*</sub> </label>
                                            <div className="col-md-7">
                                                <input id="link" type="text" className="form-control" name="link" placeholder="Enter Belief Link..." value={formdata.link} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.link && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.link}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        
                                        

                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddbeliefs()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentbelief!==''?'Updating Belief Page':'Saving Belief Page'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentbelief!==''?'Update Belief Page':'Save Belief Page'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                        }

                        {!addbelief &&
                        
                        <div className="row justify-content-center p-5 pt-3">
                            
                            
                                    {beliefs && beliefs.map((belief,key) =>(
                                        <div className="col-12 col-md-6 m-0 p-0 mb-2 border-white-b">
                                            {key === 0  || key=== 3 || key===4 || key===7 || key===8 || key===11 ?
                                                <div className="bg-indigo texts-black text-center border-ok"  style={{"minHeight":"250px"}}>
                                                    <div className='text-center m-0 p-3'>
                                                        <h3>{belief.belief}</h3>
                                                        <a className='link-white font-bold-700 text-xl' href={belief.link} target='_blank'>
                                                            Explore
                                                        </a>

                                                        {deleteBelief && updateBelief &&
                                                            <div className='m-0 p-0 text-muted mx-auto'>
                                                                <div className='text-center p-1'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateBelief(belief)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteBelief(belief)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>   
                                                </div>

                                            :
                                                <div className="bg-white texts-black text-center border-ok"  style={{"minHeight":"250px"}}>
                                                    <div className='text-center m-0 p-3'>
                                                        <h3>{belief.belief} </h3>
                                                        <a className='link-black font-bold-700 text-xl' href={belief.link} target='_blank'>
                                                            Explore
                                                        </a>

                                                        {deleteBelief && updateBelief &&
                                                            <div className='m-0 p-0 text-muted mx-auto'>
                                                                <div className='text-center p-1'>
                                                                    <div>
                                                                        <button type="button" onClick={()=>{updateBelief(belief)}} className="btn btn-primary m-1 p-1">
                                                                            <span className='fa fa-edit'></span>
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{deleteBelief(belief)}} className="btn btn-danger m-1 p-1">
                                                                            <span className='fa fa-trash'></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>   
                                                </div>
                                            }
                                            
                                        </div>
                                    ))}

                        </div>

                        
                        }
                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashBelief;