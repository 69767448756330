import { useEffect, useState } from 'react';
import { socket } from '../../socket'; // Assuming socket.js is in the same directory


function ConnectionManager() {
    document.title="ConnectionManager | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

    const [name, setName] =useState('')
    const [messages, setMessages] =useState([])
    const [chats, setChats] = useState([])
    
    const [message, setMessage] = useState('');

    console.log(socket)

    useEffect(() => {
        // Listen for incoming messages
        socket.on('message-received',  (data) => {
            setMessages((prevMessages) => [...prevMessages, data]);
            console.log(data)
        });

        return () => {
            // Cleanup function to disconnect socket on component unmount
            socket.off('message-received');
        };
    }, []);



    const [formdata,setFormData]=useState({
        names:'',
        msg:[],
        error_list:[],
    });

    

    const handleInputChange=(e)=>{
        // e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const handleInputChangeUser=()=>{
        setName(formdata.names)
        setFormData({
            names:'',
            msg:[],
            error_list:[],
        })
    }

    const handleInputLogoutUser=()=>{
        setName('')
        setFormData({
            names:'',
            msg:[],
            error_list:[],
        })
    }


    const sendMessage= (e)=>{
        if(formdata.msg===''){
           return;
        }
        else{
            if(socket.connected){
                socket.emit('message', formdata.msg);
                setMessages((prevMessages) => [...prevMessages, formdata.msg]);
                setFormData({
                    names:'',
                    msg:[],
                    error_list:[],
                })
            }
            else{
                setMessages((prevMessages) => [...prevMessages, "No Connection Established"]);
                setFormData({
                    names:'',
                    msg:[],
                    error_list:[],
                })
            }
        }
    }


  return (
    <>
    <main className='' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events ">
            <div className='events-container m-0  p-0'>
                
                {name === '' ?
                <div className='row justify-content-center bg-white p-2 pt-4'>
                    <div className='d-flex flex-column flex-md-row justify-content-center'>
                        <div>
                            <div className='p-2'>
                                <input id="names" type="text" className="form-control me-1 search border-white-b" name="names" placeholder="Enter Your Name" value={formdata.names} onChange={handleInputChange} required autoFocus/>
                            </div>
                        </div>
                        <div>
                            <div className='p-2'>
                                <button className='btn btn-orange p-1 pl-2 pr-2 font-bold-700' onClick={() => {handleInputChangeUser()}} >Start Chat</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='row justify-content-center bg-white p-2 pt-4'>
                    <div className='d-flex flex-column flex-md-row justify-content-center'>
                        <div>
                            <div className='p-2'>
                                {name}
                            </div>
                        </div>
                        <div>
                            <div className='p-2'>
                                <button className='btn btn-orange p-1 pl-2 pr-2 font-bold-700' onClick={() => {handleInputLogoutUser()}} >Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {name !=='' &&
                <>
                    <div className='row justify-content-center bg-pink text-white p-3'>
                        <div className='d-flex flex-column flex-fill align-items-center my-auto'>
                            <h1 className=''>Welcome, {name}</h1>
                        </div>
                    </div>



                    

                    <div className='row justify-content-center bg-secondary p-1'>
                        <div className='d-flex flex-column justify-content-between my-auto'>
                            {messages && messages.map((message,key) => (
                                <div className=" texts-black text-left m-0 p-0">
                                    <div className='border-white-b p-2 m-1'>
                                        <p className='p-0 m-0 muted'> <i><small>{message} </small></i></p>
                                    </div>
                                </div>  
                            ))}
                        </div>
                    </div>

                    
                    <div className='container'>
                        <div className='justify-content-center'>
                            <div className='d-flex flex-column flex-md-row  flex-fill align-items-center my-auto justify-content-center'>
                                <div>
                                    <div className='p-2'>
                                        <textarea id="msg" className="form-control me-1 search border-white-b" name="msg" placeholder="Enter Message Here" value={formdata.msg} onChange={handleInputChange} autoFocus rows="3"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div className='p-2'>
                                        <button className='btn btn-orange p-1 pl-2 pr-2 font-bold-700' onClick={() => {sendMessage()}} >Send Chat</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }


            </div>
            
        </div>

    
    </main>
      
    </>
  );
}

export default ConnectionManager;