import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function DashContactus(props) {
    document.title="Dashboard Contactus | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [contactuses,setContactuses]=useState([]);
    const [addministry, setAddministry]=useState(false);
    const [currentcontactus,setCurrentContactus]=useState('');
    const [showmanageministry, setShowManageMinistry] = useState(false);
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

// sermon media mediatype medialength mediasize summary
    const [formdata,setFormData]=useState({
        fullname:'',
        issue:'',
        email:'',
        subject:'',
        response:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'video/*,audio/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_text===''){
            Swal("Error","Please Fill Summary Paragraph");
        }
        
        else{
            const {verselinks_link,verselinks_text}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text ){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added.");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    // setLinkItems((prevState) => [...prevState,{verselinks_text}]);
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
        
        
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        setVerselinks({
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newSlide= (e)=>{
        e.preventDefault();
        // uploadedvideo uploadedaudio videourllink audiourllink youtubeid
        if((formdata.response).trim() ===''){
            Swal("Enter Response","Please EnterResponse Information","error");
            return;
        }
        
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")

        const form={
            id:(currentcontactus!=='')?currentcontactus.id:'',
            email:formdata.email,
            response:formdata.response,
        }
            axios.post('/api/save/contactus/response',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    loadContactuses();
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadContactuses= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/contactuses`)
        .then(response=>{
            if(response.data.status=== 200){
                setContactuses(response.data.contactus);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setContactuses([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setContactuses([]) 
                setLoadingPage(false)
            }
            else{
                
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(!localStorage.getItem("auth_token_ut")){
                let ex=error['response'].data.message;
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true); 
                        setLoggedToken('');
                        setLoggedName(''); 
                    }  
                    else{ 
                        setLoggedOff(true); 
                        localStorage.removeItem('auth_token_ut');
                        localStorage.removeItem('auth_name_ut');
                        setLoggedToken('');
                        setLoggedName(''); 
                    }              
                }
                else{
                    Swal("Error",""+error,"error");
                    setContactuses([]);
                }
                setLoadingPage(false)
            }
            else{
                Swal("Error",""+error,"error");
            }
            setContactuses([]) 
                setLoadingPage(false)
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/contactuses`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setContactuses(response.data.contactus);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        Swal("Error1",response.data.message,"error");
                        setContactuses([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setContactuses([]);
                        setLoadingPage(false)
                    }
                    else{
                        setContactuses([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{

                if(!localStorage.getItem("auth_token_ut")){
                    let ex=error['response'].data.message;
                    if(ex==='Unauthenticated.'){
                        if(!localStorage.getItem("auth_token_ut")){
                            setLoggedOff(true); 
                            setLoggedToken('');
                            setLoggedName(''); 
                            setLoadingPage(false)
                        }  
                        else{ 
                            setLoggedOff(true); 
                            localStorage.removeItem('auth_token_ut');
                            localStorage.removeItem('auth_name_ut');
                            setLoggedToken('');
                            setLoggedName(''); 
                            setLoadingPage(false)
                        }              
                    }
                    else{
                        Swal("Error",""+error,"error");
                        setContactuses([]);
                        setLoadingPage(false)
                    }
                }
                else{
                    Swal("Error",""+error,"error");
                    setLoadingPage(false)
                }

                setLoadingPage(false)
                setContactuses([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateContactus = (contactus) => {
        // setShowAddTenant(true);
        setAddministry(true)
        setCurrentContactus(contactus)
        

        setFormData({
            fullname:contactus.fullname,
            issue:contactus.issue,
            email:contactus.email,
            response:contactus.response,
            error_list:[],
        })
        
    };

    const setAddministrys = (ev)=> {
        setAddministry(!addministry);
        setCurrentContactus('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            fullname:'',
            issue:'',
            email:'',
            subject:'',
            response:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteContactus= (truth)=>{
        const form={
            id:truth.id,
            filename:truth.media,
        }

        let title='Delete '+truth.fullname;
        let text="This will remove this Contactus Record for this Individual from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/contactus',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){ 
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadContactuses();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Manage Contactus Records
                            {showmanageministry && <button type="button" onClick={()=>{setShowManageMinistry(false)}} className="btn btn-outline-danger border-info ml-4 p-1 pl-2 pr-2">
                                <span className='fa fa-times'></span> Close 
                            </button>}
                        </h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <>
                    {showmanageministry?
                        ''
                    :
                    <div className="card-body">
                        {addministry &&
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Back to Contact us Records 
                                </button>
                            </p>
                        }
                        {addministry?
                            <form className='justify-content-center' onSubmit={newSlide}>
                                {!loading && loadingresok==='' && 
                                    <>
                                {/* // sermon media mediatype medialength mediasize summary */}
                                    {/* {console.log(files[0])} */}
                                        <div className="form-group row">
                                            <label for="fullname" className="col-md-3 col-form-label text-md-right">FullName </label>
                                            <div className="col-md-7">
                                                <input id="fullname" type="text" className="form-control" name="fullname" placeholder="Fullname" value={formdata.fullname} required disabled onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.fullname && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.fullname}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="email" className="col-md-3 col-form-label text-md-right">Email </label>
                                            <div className="col-md-7">
                                                <input id="email" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} required disabled onChange={handleInputChange} />
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <div className="form-group row">
                                            <label for="issue" className="col-md-3 col-form-label text-md-right">Issue  </label>
                                            <div className="col-md-7 p-1">
                                                <textarea id="issue" className="form-control m-1" name="issue" placeholder="Issue Paragraph" value={formdata.issue} onChange={handleInputChange} disabled  rows="3"></textarea>
                                                                    
                                                {formdata.error_list && formdata.error_list.issue && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.issue}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="response" className="col-md-3 col-form-label text-md-right">Your Response <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 p-1">
                                                <textarea id="response" className="form-control m-1" name="response" placeholder="Issue Paragraph" value={formdata.response} onChange={handleInputChange}  required rows="3"></textarea>
                                                                    
                                                {formdata.error_list && formdata.error_list.response && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.response}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>Sending Response...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        Send Response
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-0 pt-2">
                                {contactuses && contactuses.map((contactus,key) => (
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className="texts-black text-center m-1 bg-opacity border-white-b">
                                            <div className='text-center m-0 p-1'>
                                            {/* title media type verse versequote verselinks trailing alignment */}
                                                <h4 className='font-bold-700'>
                                                   <span className='text-left text-warning font-bold-700 text-xl'></span> {contactus.fullname}
                                                </h4>

                                                <p className='p-1 text-muted'> 
                                                    {contactus.email}
                                                </p>
                                                    
                                                
                                                {contactus.issue !==null?
                                                    <div className="border-top text-left m-0 p-2">
                                                        <p className='m-0 p-1 text-center bg-light'>Issue::</p>
                                                        <div className='p-2'>
                                                            {contactus.issue}
                                                        </div>
                                                    </div>
                                                :""}


                                                {contactus.response !==null?
                                                    <div className="border-top bg-indigo text-left m-0 p-2">
                                                        <p className='m-0 p-1 text-center bg-light text-black'>Response::</p>
                                                        <div className='p-2'>
                                                            {contactus.response}
                                                        </div>
                                                    </div>
                                                :""}
                                                

                                                {deleteContactus && updateContactus &&
                                                <div className='m-0 p-0 text-muted my-auto'>
                                                    <div className='bg-light mt-2 p-3 mx-auto text-center border-white-b'>
                                                        <div>
                                                            <button type="button" onClick={()=>{updateContactus(contactus)}} className="btn btn-success m-1 p-1">
                                                                <span className='fa fa-check'></span> Respond
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteContactus(contactus)}} className="btn btn-danger m-1 p-1">
                                                                <span className='fa fa-trash'></span> Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                            </div>   
                                        </div>
                                    </div>
                                ))}
                                </div>  

                            </>
                        }

                        
                    </div>
                    }
                    </>
                }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashContactus;