
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';

import { useEffect, useState, useContext } from 'react';


import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';

function Members() {
    document.title="Members | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

  return (
    <>
    <HomeNavBar active='members'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className=" text-white text-center" >
                        <h4 className='m-0 p-3 pt-4 text-warning'>Members</h4>
                        <p className='m-2 p-3 mb-0 pb-0 beliefs-p'>Members of the Utawala Satellite Seventh-day Adventist Church</p>
                    </div>
                </div>
                <div className="row justify-content-center p-5 pt-3">
                   
                </div>
            </div>
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default Members;