
import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function VisitUs() {
    document.title="Visit US | Utawala Satellite";

    const [verses,setVerses]=useState([]);
    const [services,setServices]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getPrograms = async (e) => { 

            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/visitusverse`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            if(response.data.visitusverse ===null){
                                setVerses([])
                                setLoadingPage(false);
                            }
                            else{
                                setVerses(response.data.visitusverse);
                                setLoadingPage(false);
                            }
                        }
                        else{
                            setVerses([]);
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setVerses([]);
                    setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setVerses([]);
                setLoadingPage(false);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getPrograms = async (e) => { 
            
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/visitusservices`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setServices(response.data.visitusservices);
                            setLoadingPage(false);
                        }
                        else{
                            setServices([]);
                            setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setServices([]);
                    setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setServices([]);
                setLoadingPage(false);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[])

  return (
    <>
    <HomeNavBar active='visitus'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className=" text-white text-center" >
                        <p className='m-0 p-3 pt-4 mb-0 pb-0 beliefs-p'>Visit Us</p>
                    </div>
                </div>
                
                    <div className="row justify-content-center p-5 pt-3">
                        <div className="col-12 col-md-6 m-0 p-0">
                            {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <div className="bg-indigo texts-black text-center m-1" style={{"minHeight":"350px"}}>
                                {verses && 
                                    <>
                                        {verses.length !==0 &&
                                            <div className='text-center m-0 p-2'>
                                                <div className='border-bottom-warning'></div>
                                                <h3 className='text-xl font-bold-700'>
                                                    {verses['verse']}
                                                </h3>
                                                <p>  
                                                    <>
                                                    {/* {console.log(JSON.parse(verses['verseline']))} */}
                                                        {(verses.length >0 || JSON.parse(verses['verseline']).length >0)  && JSON.parse(verses['verseline']).map((versel) => (
                                                            <>
                                                                <a className='link-white text-lg font-bold-700' href={versel.verselinks_link} target='_blank'> {versel.verselinks_verse}  </a> 
                                                                {versel.verselinks_text}.
                                                            </>
                                                        ))}
                                                    </>
                                                </p>
                                                <div className='border-bottom-warning'></div>
                                                
                                            </div> 
                                        }
                                    </>
                                        
                                }  
                                {/* <div className='text-center m-0 p-2'>
                                    
                                    <div className='border-bottom-warning'></div>
                                    <h3 className='text-xl font-bold-700'>
                                        Exodus 20:8-11
                                    </h3>
                                    <p>
                                        <a className='link-white text-lg font-bold-700' href='https://www.kingjamesbibleonline.org/Exodus-20-8/' target='_blank'> 8 
                                        </a> Remember the sabbath day, to keep it holy. 
                                        <a className='link-white text-lg font-bold-700' href='https://www.kingjamesbibleonline.org/Exodus-20-9/' target='_blank'> 9
                                        </a> Six days shalt thou labour, and do all thy work: 
                                        <a className='link-white text-lg font-bold-700' href='https://www.kingjamesbibleonline.org/Exodus-20-10/' target='_blank'> 10
                                        </a> But the seventh day is the sabbath of the LORD thy God: in it thou shalt not do any work, thou, nor thy son, nor thy daughter, thy manservant, nor thy maidservant, nor thy cattle, nor thy stranger that is within thy gates: 
                                            <a className='link-white text-lg font-bold-700' href='https://www.kingjamesbibleonline.org/Exodus-20-11/' target='_blank'> 11
                                        </a> For in six days the LORD made heaven and earth, the sea, and all that in them is, and rested the seventh day: wherefore the LORD blessed the sabbath day, and hallowed it.
                                    </p>
                                    <div className='border-bottom-warning'></div>
                                </div>    */}
                            </div>
                            }
                        </div>
                        <div className="col-12 col-md-6 m-0 p-0">
                            {loadingpage?
                            <LoadingDetailsSpinner />
                            :
                            <div className="bg-indigo texts-black text-center m-1" style={{"minHeight":"350px"}}>
                                <div className='text-center m-0 p-1'>
                                    
                                    <div className='border-bottom-warning'></div>
                                    <h3 className='text-xl font-bold-700'>
                                        Sabbath Service:
                                    </h3>

                                    {services && services[0] && services[0].fri && services[0].fri.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            {services[0] && services[0].fri.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'>Every <span className='font-bold-700'>FRIDAY</span> {serv.time} - {serv.service} </p>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    {services && services[0] && services[0].sat && services[0].sat.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>SATURDAY</p>
                                            {services[0] && services[0].sat.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    {services && services[0] && services[0].sun && services[0].sun.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>SUNDAY</p>
                                            {services[0] && services[0].sun.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {services && services[0] && services[0].mon && services[0].mon.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>MONDAY</p>
                                            {services[0] && services[0].mon.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {services && services[0] && services[0].tue && services[0].tue.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>TUESDAY</p>
                                            {services[0] && services[0].tue.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {services && services[0] && services[0].wed && services[0].wed.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>WEDNESDAY</p>
                                            {services[0] && services[0].wed.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {services && services[0] && services[0].thu && services[0].thu.length >0 &&
                                        <div className='m-0 pt-3 pb-1'>
                                            <p className='font-bold-700'>THURSDAY</p>
                                            {services[0] && services[0].thu.map((serv,key) =>(
                                                <div className='m-0'>
                                                    <p className='m-0 p-1'><span className='font-bold-700'>{serv.service}:</span> {serv.time}</p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    

                                    
                                    <div className='border-bottom-warning'></div>
                                </div>   
                            </div>
                            }
                        </div>

                        <div className="col-12 m-0 p-0">
                            <div className="bg-indigo texts-black text-center m-1 p-2" >
                                <div className='text-center m-0 p-1'>
                                    
                                    <div className='border-bottom-warning'></div>

                                    <h3 className='text-xl font-bold-700 pt-4'>Contact Us</h3>
                                    
                                    <p className=''>Address</p>
                                    <p> P.O. BOX 5570 00100, Nairobi, Kenya</p>

                                    <p className='m-0 ml-3 p-0'><a className='link-white' href="mailto:utawalasdasatellite@gmail.com" target="_self">Email:utawalasdasatellite@gmail.com</a></p>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
            
            </div>
            
        </div>

        
    </main>
    <HomeFooter />
      
    </>
  );
}

export default VisitUs;