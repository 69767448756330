import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function DashTheTruths(props) {
    document.title="Dashboard The Truths | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);
    const [loadingres,setLoadingRes]=useState('');

    const [truths,setTruths]=useState([]);
    const [addtruth, setAddtruth]=useState(false);
    const [currenttruth,setCurrentTruth]=useState('');
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

// title image link subtitles learnmorelink videoid
    const [formdata,setFormData]=useState({
        title:'',
        image:'',
        link:'',
        learnmorelink:'',
        subtitles:[],
        videoid:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_link==='' || verselinks.verselinks_text===''){
            Swal("Error","Please Fill all required Subtitle");
        }
        
        else{
            const {verselinks_link,verselinks_text}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link || 
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Sub title already Added.");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) => [...prevState,{verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_link===lk.verselinks_link && 
                        verselinks.verselinks_text===lk.verselinks_text
                    ){
                        saved=false;
                        Swal("Error","Sub title already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    setLinkItems((prevState) => [...prevState,{verselinks_link,verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_link:'',
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
        
        
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        setVerselinks({
            verselinks_link:'',
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_link:link.verselinks_link,
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_link:'',
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newSlide= (e)=>{
        e.preventDefault();

        if(formdata.title ===''){
            Swal("Enter Slide Title","Please Enter the Title of the Slide to be display on the Slide","error");
            return;
        }


        if(linkitems ===''){
            Swal("Sub titles Missing","Enter Sub Titles to Display.","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")

        const form={
            id:(currenttruth!=='')?currenttruth.id:'',
            title:formdata.title,
            link:formdata.link,
            subtitles:linkitems,
            learnmorelink:formdata.learnmorelink,
            videoid:formdata.videoid,
            image:files,
        }
            axios.post('/api/save/thetruth',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadSlides();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadSlides= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/thetruths`)
        .then(response=>{
            if(response.data.status=== 200){
                setTruths(response.data.truths);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setTruths([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setTruths([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
                setLoadingPage(false)
            setTruths([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/thetruths`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setTruths(response.data.truths);
                setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setTruths([]);
                setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setTruths([]);
                setLoadingPage(false)
                    }
                    else{
                setLoadingPage(false)
                        setTruths([]);
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setLoadingPage(false)
                setTruths([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateTruth = (truth) => {
        // setShowAddTenant(true);
        setAddtruth(true)
        setCurrentTruth(truth)
        if(truth.subtitles===null){
            setLinkItems('');
        }
        else{
            setLinkItems(JSON.parse(truth.subtitles))
        }

        setFormData({
            title:truth.title,
            link:truth.link,
            image:truth.image,
            learnmorelink:truth.learnmorelink,
            subtitles:truth.subtitles,
            videoid:truth.videoid,
            error_list:[],
        })
        
    };

    const setAddtruths = (ev)=> {
        setAddtruth(!addtruth);
        setCurrentTruth('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            title:'',
            image:'',
            link:'',
            learnmorelink:'',
            subtitles:[],
            videoid:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteTruth= (truth)=>{
        const form={
            id:truth.id,
        }

        let title='Delete '+truth.title;
        let text="This will remove this Truth from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/thetruth',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadSlides();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>The Truths That will Set You Free</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {addtruth?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddtruths()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-list'></span> Show Truths 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddtruths()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Truth 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addtruth?
                            <form className='justify-content-center' onSubmit={newSlide}>
                                {!loading && loadingresok==='' && 
                                    <>
                                {/* // title image link subtitles learnmorelink videoid */}
                                    
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" placeholder="Title Name" value={formdata.title} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="link" className="col-md-3 col-form-label text-md-right">Link <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="link" type="text" className="form-control" name="link" placeholder="Main Link" value={formdata.link} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.link && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.link}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="learnmorelink" className="col-md-3 col-form-label text-md-right">Learn More Link <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="learnmorelink" type="text" className="form-control" name="learnmorelink" placeholder="Learn More Link" value={formdata.learnmorelink} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.learnmorelink && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.learnmorelink}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        

                                        
                                        
                                        <div className="form-group row">
                                            <label for="verselinks" className="col-md-3 col-form-label text-md-right">Sub Titles <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 p-1">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <input id="verselinks_link" type="text" className="form-control m-1" name="verselinks_link" placeholder="link" value={verselinks.verselinks_link} onChange={handleLinksInputChange} autoFocus/>
                                                    <input id="verselinks_text" type="text" className="form-control m-1" name="verselinks_text" placeholder="sub title" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus/>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Save'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.verselinks && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.verselinks}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) {link.verselinks_link}: {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>

                                        

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Media/ Image <sub className='text-red text-sm'>*</sub> </label>
                                            <div className='col-md-7'>
                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Slide Image Here
                                                    </p>
                                                    
                                                </div>

                                                {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                }

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currenttruth !=='' &&
                                                        <>
                                                            {currenttruth.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/thetruths/'+currenttruth.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="videoid" className="col-md-3 col-form-label text-md-right">Video ID <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="videoid" type="text" className="form-control" name="videoid" placeholder="You tube video ID" value={formdata.videoid} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.videoid && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.videoid}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Video Preview </label>
                                            <div className='col-md-7'>
                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    {formdata.videoid !=='' &&
                                                        <div className='m-0 p-1 border-bottom forum-div-h'>
                                                            <iframe
                                                                width="100%"
                                                                src={`https://www.youtube.com/embed/`+formdata.videoid}
                                                                frameborder="0"
                                                                allow=""
                                                                allowFullScreen
                                                                title=" The Truths Youtube Videos"
                                                            />
                                                            
                                                        </div>
                                                    }   
                                                </div>
                                                
                                            </div>
                                        </div>


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddtruths()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currenttruth!==''?'Updating Truth':'Saving Truth'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currenttruth!==''?'Update Slide':'Save Slide'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-0 pt-2">
                                {truths && truths.map((truth,key) => (
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                                            <div className='text-center m-0 p-1'>
                                            {/* title media type verse versequote verselinks trailing alignment */}
                                                <h4 className='font-bold-700'>
                                                   <span className='text-left text-warning font-bold-700 text-xl'></span> {truth.title}
                                                </h4>
                                                {truth.link!==null?<p className=' text-left m-0 p-0'>Link:: <span className='text-indigo'>{truth.link}</span></p>:""}
                                                {truth.learnmorelink!==null?<p className='text-left m-0 p-0'>Learn More:: <span className='text-indigo'>{truth.learnmorelink}</span></p>:""}
                                                
                                                {truth.subtitles !==null?
                                                    <div className="border-bottom-top text-left m-0 p-0">
                                                        Sub Titles::
                                                        <ul>
                                                            {truth.subtitles !==null  && JSON.parse(truth.subtitles).map((versel) => (
                                                                <li className='m-0 p-1'><a className='link-white p-0' href={versel.verselinks_link}> {versel.verselinks_text}.</a></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                :""}
                                                {truth.image !==null &&
                                                    <div className='m-0 p-1 border-bottom events-div-h'>
                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/thetruths/'+truth.image} alt='Does Not Have Image' className='' width="100%" />
                                                    </div>
                                                }

                                                {truth.videois !==null &&
                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                        <iframe
                                                            width="100%"
                                                            src={`https://www.youtube.com/embed/`+truth.videoid}
                                                            frameborder="0"
                                                            allow=""
                                                            allowFullScreen
                                                            title=" The Truths Youtube Videos"
                                                        />
                                                        
                                                    </div>
                                                }

                                                {deleteTruth && updateTruth &&
                                                <div className='m-0 p-0 text-muted my-auto'>
                                                    <div className='bg-light p-3 mx-auto text-center'>
                                                        <div>
                                                            <button type="button" onClick={()=>{updateTruth(truth)}} className="btn btn-primary m-1 p-1">
                                                                <span className='fa fa-edit'></span> Edit
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteTruth(truth)}} className="btn btn-danger m-1 p-1">
                                                                <span className='fa fa-trash'></span> Del
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                            </div>   
                                        </div>
                                    </div>
                                ))}
                                </div>  

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashTheTruths;