import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from '../home/LoadPrograms';

import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function DashBibleManageUser({currentuser,setCurrentUser,loadUsers}) {
    document.title="Dashboard Manage "+currentuser.ministry+ " | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('mission');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [loadingpage,setLoadingPage]=useState(false);
    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    

    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

    const [userlogs,setUserLogs]= useState([])
    

   
    //salutation position membername gender image summary phone email
    // {/* projectname ministry image summary status */}
    // {/* programname ministry image summary programdate time venue */}
    const [formdata,setFormData]=useState({
        salutation:'',
        image:'',
        position:'',
        membername:'',
        gender:'',
        summary:'',
        phone:'',
        email:'',
        projectname:'',
        programname:'',
        programdate:'',
        time:'',
        venue:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_verse:'',
        verselinks_link:'',
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    
    

    

    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            loadThisUsersLogs();
        }
        return ()=>{
            doneloading=false;
        }
    },[currentuser.id])

    

    const loadThisUsersLogs= ()=>{
        setLoadingPage(true)
        let url=`/api/load/user/logs/${(currentuser!=='')?currentuser.id:''}`;
        axios.get(url)
        .then(response=>{
            if(response.data.status=== 200){
                setUserLogs(response.data.logs);
                setLoadingPage(false)
            }
            else{
                setLoadingPage(false)
            }
            
        })
        .catch(error=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-0">
                
                <div className="">
                    <div className="">
                        <div className="row justify-content-center p-0 pt-2">
                            <Tabs
                                id="fill-tab-example"
                                className="m-0 p-0 bg-success"
                                activeKey={key}
                                onSelect={(k)=> setKey(k)}
                                fill>
                                    {/*salutation position membername gender image summary phone email*/}
                                    <Tab eventKey="mission"
                                        title="About User">
                                        <div className="row m-0 p-0">
                                            <div className="text-black text-center">
                                                <div className='text-center m-0 p-0'>
                                                    <h4 className='m-1 p-2'>About User</h4>
                                                    {currentuser.id !==null &&
                                                        <div className="text-center m-1 p-2">
                                                            <div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Name: {currentuser.fname} {currentuser.lname} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Email: {currentuser.email}({currentuser.username}) </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Phone: {currentuser.phone} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Status: {currentuser.status} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Userrole: {currentuser.userrole} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Online Status: {currentuser.user_online?'Online':'Offline'} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Last Activity: {currentuser.current_activity_at} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Current Login: {currentuser.current_login_at} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Last Login: {currentuser.last_login_at} </p>
                                                                </div>
                                                                <div className='border-white-b m-0 mb-1 p-1'>
                                                                    <p className='m-1 p-2'>Verified At: {currentuser.email_verified_at} </p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    }
                                                    
                                                </div>   
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="vision"
                                        title="Manage Logs ">
                                        <div className="row m-0 p-0">
                                            <div className="text-black text-center">
                                                <div className='text-center m-0 p-0'>
                                                    <h4 className='m-1 p-2'>Manage Logs</h4>
                                                    {loadingpage?
                                                    <LoadingDetailsSpinner />
                                                    :
                                                    <>
                                                        {userlogs && userlogs.map((verse,key) =>(
                                                            <div className="text-center m-0 p-0">
                                                                {/* {console.log(JSON.parse(JSON.stringify(verse.message)))} */}
                                                                {/* {"attributes":{},"request":{},"query":{},"server":{},"files":{},"cookies":{},"headers":{}} */}
                                                                <div>
                                                                    <div className='d-flex border-white-b m-0 mb-1 p-1'>
                                                                        {/* {verse.message !==null  && JSON.parse(verse.message).map((versel) => (
                                                                            <div className='border-white-b m-0 mb-1 p-2'>
                                                                                <p className='m-0 p-1'> {versel.ip} </p>
                                                                            </div>
                                                                        ))} */}
                                                                            <div className='border-white-b m-0 mb-1 p-2'>
                                                                                <p className='m-0 p-1'>({key+1}). {verse.message['message']} </p>
                                                                            </div>
                                                                            <p className='m-0 p-2'> {verse.message['ip']}: {verse.message['method']}: {verse.message['url']}: {verse.message['useragent']} </p>
                                                                            
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        </>
                                                    }
                                                    
                                                </div>   
                                            </div>
                                        </div>

                                    </Tab>
                                    
                                    
                            </Tabs>
                        </div>  
                            
                    </div>
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashBibleManageUser;