import React, { useEffect, useState,useContext, useRef } from 'react';
import {LoginContext} from "../contexts/LoginContext";
import Form from 'react-bootstrap/Form';
import logo from '../../assets/img/utawalasda.jpg'

import userlogo from '../../assets/img/avatar.png';

import Swal from 'sweetalert';
import axios from 'axios';

import NavDropdown from 'react-bootstrap/NavDropdown';

import {Link, Navigate} from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';




function HomeNavBar({active}) {

    const [name, setName] =useState('Hey');
    const [room, setRoom] =useState('There');

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);

    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [authname,setAuthName]=useState('');
    const [authimage,setAuthImage]=useState('');
    const [open,setOpen]=useState('')
    const [ministries,setMinistries]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

   
    useEffect(()=>{
        if(!localStorage.getItem("auth_token_ut")){
            setAuthName('');
            setAuthImage('');
            setLoggedToken('');
            setLoggedName('');
        }
        else{
            setAuthName(localStorage.getItem("auth_name_ut"));
            setAuthImage(localStorage.getItem('auth_image_ut'))
            setLoggedToken(localStorage.getItem("auth_token_ut"));
            setLoggedName(localStorage.getItem("auth_name_ut"));
        }
            // setLoadingPage(false);
            // console.log(socket)
            // socket.emit('join', {name, room}, () =>{

            // });
      },[authname])

    //   useEffect(()=>{
    //     let doneloading=true;
    //     setLoadingPage(true);
    //     if(!localStorage.getItem("auth_token_ut")){
    //         const getMins = async (e) => { 
    //             axios.get('/sanctum/csrf-cookie').then(res =>{
    //                 axios.get(`/api/get/load/request`)
    //                 .then(response=>{
    //                     if (doneloading) {
    //                         if(response.data.status=== 200){
    //                             setName(response.data.ip);
    //                             setRoom('Guest');
    //                             setLoadingPage(false);
    //                         }
    //                         else{
    //                             setName('');
    //                             setLoadingPage(false);
    //                         }
                            
    //                     }
    //                 })
    //                 .catch(error=>{
    //                     setName('');
    //                     setLoadingPage(false);
    //                 })
    //             })
    //             .catch((error)=>{
    //                 setName('');
    //                 setLoadingPage(false);
    //             })
    //         };
    //         getMins();
    //     }
    //     else{
    //         setName(localStorage.getItem("auth_name_ut"));
    //         setRoom(localStorage.getItem("auth_role_ut"));
    //         setLoadingPage(false);
    //     }
        

    //     return ()=>{
            
    //         doneloading=false;
    //     }
    // },[])



      const handleLogout=(e) =>{
        e.preventDefault();
        Swal("Logging Out....","Please Wait");
            axios.post('/api/logout')
            .then(response=>{
                if(response.data.status=== 200){
                    localStorage.removeItem('auth_role_ut');
                    localStorage.removeItem('auth_image_ut');
                    localStorage.removeItem('auth_token_ut');
                    localStorage.removeItem('auth_name_ut');
                    setAuthName('');
                    setLoggedToken('');
                    setLoggedName('');
                    setRedirect(true);
                    setUrl('/login');
                    Swal("Success",response.data.message,"success");
                }

            })
            .catch((error)=>{
                // Swal("Not Logged Out",""+error.message,"error");
                    localStorage.removeItem('auth_role_ut');
                    localStorage.removeItem('auth_image_ut');
                    localStorage.removeItem('auth_token_ut');
                    localStorage.removeItem('auth_name_ut');
                setAuthName('');
                setLoggedToken('');
                setLoggedName('');
                setRedirect(true);
                setUrl('/login');
            })
    }

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getMins = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/ministries`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setMinistries(response.data.ministry);
                                setLoadingPage(false);
                        }
                        else{
                            setMinistries([]);
                                setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setMinistries([]);
                                setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setMinistries([]);
                                setLoadingPage(false);
            })
        };
        getMins();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  
  return (
    <>
        <div className='bg-indigo fixed-top mb-3 p-1' style={{"zIndex":"9999","borderBottom":"1px solid"}}>
            <div className='d-flex flex-fill align-items-center'>
                {/* <div className='ml-auto'>
                    <Button bg="dark" variant="dark">GC Calender of Events 2023 <i className="fas fa-calendar"></i></Button>
                </div> */}
                
                <div className='mx-auto d-flex hidden-search'>
                    <Form.Control
                        type="search"
                        placeholder="🔍Search"
                        className="search"
                        aria-label="Search"
                    />
                    <Button bg="info" variant="info" style={{"marginLeft":"-5px"}}><i className="fas fa-search"></i></Button>
                </div>
                
                {/* {name} {room} */}
                <div className='mx-auto d-flex'>
                    {loggedname === '' &&
                        <>
                            <Link className={`nav-link ${active==='login'?'active':''}`} to="/login"> Login</Link>
                            <Link className={`nav-link ${active==='signup'?'active':''}`} to="/signup"> Signup</Link>
                        </>
                    }
                    {/* <li className="nav-item dropdown"> */}
                    {loggedname != '' && 
                        <NavDropdown title={loggedname} id="navbarScrollingDropdown" bg='light'>
                                {/* <span className="dropdown-item dropdown-header">
                                    <img src={userlogo} width="30px" className="brand-image img-circle m-0 p-0 mx-auto" alt="User Image"/> {loggedname}
                                </span> */}
                            {/* {process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/users/'+currentuser.image} */}
                                <Link className="dropdown-item " to="/dashboard/profile">
                                    <img src={(authimage === '')?userlogo:process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/users/'+authimage} width="30px" height="30px" className="brand-image user-circle m-0 p-0 mx-auto" alt="User Image"/> {loggedname}
                                </Link>
                                <div className="dropdown-divider"></div>
                                {/* <Link className="dropdown-item " to="/profile"><i className="fa fa-user text-lime"></i> Profile ({loggedname})</Link> */}
                                <Link className="dropdown-item " to="/dashboard/profile/change-password"><i className="fa fa-lock text-great"></i> Change Password</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item " to="#" onClick={handleLogout}><i className="fa fa-power-off text-danger"></i> Logout </Link>
                        </NavDropdown>
                    }
                    
                {/* </li> */}
                </div>
                        
            </div>
        </div>
        <nav className="navbar navbar-expand-md bg-indigo fixed-top shadow-sm" style={{"marginTop":"35px"}}>
        
               <div className="container text-center">
                   <Link className="" to="/">
                       <img src={logo} alt="Wagitonga Logo" className="brand-image elevation-1 m-0 " style={{"opacity": "1","width":"100px","borderRadius": "10px 10px 1px 1px","marginRight": "1%"}}/>
                   </Link>

                   <ul className="navbar-nav d-flex flex-row mx-auto hidden-search" >
                           <Form.Control
                               type="search"
                               placeholder="🔍Search"
                               className="me-1 search1"
                               aria-label="Search"
                               style={{"marginLeft":"-10px"}}
                           />
                           <Button bg="info" variant="info" style={{"marginLeft":"-10px"}}><i className="fas fa-search"></i></Button>
                   </ul>
                   {/* {name} {room} */}

                   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="{{ __('Toggle navigation">
                       <span className="navbar-toggler-icon"></span>
                   </button>

                 <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                     {/* <!-- Left Side Of Navbar --> */}
                     <ul className="navbar-nav mx-auto" >

                        {loggedname != '' && 
                            <li className="nav-item">
                                <Link onMouseOver={()=>{setOpen('')}} className={`nav-link ${active==='dashhome'?'active':''}`} to="/dashboard">Dashboard</Link>
                            </li>
                        }
                         <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('')}} className={`nav-link ${active==='home'?'active':''}`} to="/">Home</Link>
                         </li>

                         <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('aboutus')}} onClick={()=>{setOpen('aboutus')}}  className={`nav-link text-center ${active==='aboutus' || active==='visitus' || active==='events' || active==='programlist' || active==='groups' || active==='forum' || active==='members' ?'active':''}`} to="#">About</Link>
                               <ul onMouseOver={()=>{setOpen('aboutus')}} onMouseOut={()=>{setOpen('')}} className={`navbar-navs ${open==='aboutus'?'active':''} bg-primary mx-auto `}   >
                                       
                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='visitus'?'active':''}`} to="/aboutus/visitus">Visit Us</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='events'?'active':''}`} to="/aboutus/events">Events</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='programlist'?'active':''}`} to="/aboutus/programlist">Program List</Link>
                                   </li>

                                   {/* <li className="nav-item">
                                       <Link className={`nav-link ${active==='groups'?'active':''}`} to="/aboutus/groups">Groups</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='forum'?'active':''}`} to="/aboutus/forum">Forum</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='members'?'active':''}`} to="/aboutus/members">Members</Link>
                                   </li> */}
                                   
                               </ul>
                         </li>
                         
                         <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('')}} className={`nav-link ${active==='beliefs'?'active':''}`} to="/beliefs">Beliefs</Link>
                         </li>

                         <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('ministries')}} onClick={()=>{setOpen('ministries')}} 
                             className={`nav-link`} to="#">Ministries </Link>
                               <ul onMouseOver={()=>{setOpen('ministries')}} onMouseOut={()=>{setOpen('')}} className={`navbar-navs ${open==='ministries'?'active':''} bg-primary mx-auto `}   >
                                    {ministries && ministries.map((ministry,key) =>(
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active==(ministry.ministry)?'active':''}`}  to={`/ministries?ministry=${ministry.ministry}&ids=${ministry.id}`}>{ministry.ministry} </Link>
                                        </li>
                                    ))}
                                    {/* <li className="nav-item">
                                       <Link className={`nav-link ${active==='womenministry'?'active':''}`}  to={`/ministries?ministry=womenministry`}>Women Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='adventistmen'?'active':''}`} to={`/ministries?ministry=adventistmen`}>Adventist Men</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='adventistyouth'?'active':''}`}  to={`/ministries?ministry=adventistyouth`}>Adventist Youth</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='childrenministry'?'active':''}`}  to={`/ministries?ministry=childrenministry`}>Children Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='personalministry'?'active':''}`}  to={`/ministries?ministry=personalministry`}>Personal Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='evangelismandpublishing'?'active':''}`}  to={`/ministries?ministry=evangelismandpublishing`}>Evangelism & Publishing </Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='stawardship'?'active':''}`}  to={`/ministries?ministry=stawardship`}>Stewardship Department</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='familylife'?'active':''}`}  to={`/ministries?ministry=familylife`}>Family Life Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='singlesministry'?'active':''}`}  to={`/ministries?ministry=singlesministry`}>Singles Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='choirministry'?'active':''}`}  to={`/ministries?ministry=choirministry`}>Choir Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='chaplaincyministry'?'active':''}`}  to={`/ministries?ministry=chaplaincyministry`}>Chaplaincy Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='communication'?'active':''}`}  to={`/ministries?ministry=communication`}>Communication Department</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='healthminisitry'?'active':''}`}  to={`/ministries?ministry=healthminisitry`}>Health Ministry</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='churchdevelopment'?'active':''}`}  to={`/ministries?ministry=churchdevelopment`}>Church Development</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='welfareministry'?'active':''}`}  to={`/ministries?ministry=welfareministry`}>Welfare Ministry</Link>
                                   </li> */}
                                   
                               </ul>
                         </li>

                         <li className="nav-item">
                               <Link onMouseOver={()=>{setOpen('biblestudies')}} onClick={()=>{setOpen('biblestudies')}} className={`nav-link text-center ${active==='biblestudy' || active==='biblestudies' || active==='sermons'?'active':''}`} to="#">Bible Study</Link>
                               <ul onMouseOver={()=>{setOpen('biblestudies')}} onMouseOut={()=>{setOpen('')}} className={`navbar-navs ${open==='biblestudies'?'active':''} bg-primary mx-auto `}   >
                                       
                                    <li className="nav-item">
                                       <Link className={`nav-link ${active==='biblestudy'?'active':''}`} to="/biblestudy/bibles">Bible Study</Link>
                                   </li>
                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='biblestudies'?'active':''}`} to="/biblestudy/resources">Bible Study Resources</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className={`nav-link ${active==='sermons'?'active':''}`} to="/biblestudy/sermons">Sermons</Link>
                                   </li>
                                   
                               </ul>
                         </li>
                         
                         {/* <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('')}} className={`nav-link ${active==='blog'?'active':''}`} to="/blog">Blog</Link>
                         </li> */}
                         
                         <li className="nav-item">
                             <Link onMouseOver={()=>{setOpen('')}} className={`nav-link ${active==='opportunities'?'active':''}`} to="/opportunities">Opportunities</Link>
                         </li>
                         


                     </ul>

                     {/* <ul className="navbar-nav mx-auto" >
                        
                           <Form.Control
                           type="search"
                               placeholder="Search"
                               className="me-1"
                               aria-label="Search"
                           />
                           <Button bg="info" variant="info"><i className="fas fa-search"></i></Button>
                      
                     </ul> */}

                    

                     {/* <!-- Right Side Of Navbar --> */}
                     {/* <ul className="navbar-nav ml-auto">
                         
                               <li className="nav-item">
                                   <Link className={`nav-link ${active==='login'?'active':''}`} to="/login">Login</Link>
                               </li>
                     </ul> */}
                 </div>

                 {/* <ul className="navbar-nav mx-auto" >
                           <Form.Control
                               type="search"
                               placeholder="Search"
                               className="me-1"
                               aria-label="Search"
                           />
                           <Button bg="info" variant="info"><i className="fas fa-search"></i></Button>
                           <li className="nav-item">
                               <Link className={`nav-link ${active==='login'?'active':''}`} to="/login">Login</Link>
                           </li>
                   </ul> */}


                   
               </div>
         </nav>

      {/* </div> */}
    </>
    
  );
}

export default HomeNavBar;