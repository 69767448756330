import { useEffect, useState,useContext } from 'react';
import {LoginContext} from "../contexts/LoginContext";
import axios from 'axios';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';

import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function BibleStudyResources() {
    document.title="Bible Study Resources | Utawala Satellite";
    
    const [ministries,setMinistries]=useState([]);
    const [bibles,setBibles]=useState('');
    const [books,setBooks]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);


    useEffect(()=>{
        setLoadingPage(true)
        let doneloading=true;
        
        const getMins = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/biblestudy`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setBibles((response.data.biblestudy===null)?'':response.data.biblestudy);
                            setLoadingPage(false)
                        }
                        else{
                            setBibles();
                            setLoadingPage(false)
                        }
                        
                    }
                })
                .catch(error=>{
                    setBibles();
                    setLoadingPage(false)
                })
            })
            .catch((error)=>{
                setBibles();
                setLoadingPage(false)
            })
        };
        getMins();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getMins = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/biblestudybooks`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){;
                            setBooks(response.data.biblestudybooks);
                            setLoadingPage(false)
                        }
                        else{
                            setBooks([]);
                            setLoadingPage(false)
                        }
                        
                    }
                })
                .catch(error=>{
                    setBooks([]);
                    setLoadingPage(false)
                })
            })
            .catch((error)=>{
                setBooks([]);
                setLoadingPage(false)
            })
        };
        getMins();

        return ()=>{
            doneloading=false;
            
        }
    },[])

  return (
    <>
    <HomeNavBar active='biblestudies'/>
    <main  className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

    {loadingpage?
            <LoadingDetailsSpinner />
            :
        <div className="events-bible bg-primary">
            <div className=''>
                {(bibles !== '') && 
                    <>
                        <div className="">
                            <div className="m-0 mt-3 p-3 pt-4 text-white text-center" >
                                {/* <h4 className='m-0 p-3 pt-4 text-warning'>Bible Study</h4> */}
                                <p className='m-2 p-3 mb-0 pb-0 beliefs-p'>{bibles['title']}</p>
                            </div>
                        </div>
                        <div className="row justify-content-center p-5 pt-3">
                            <div className='col-10 col-lg-4 col-md-6 bg-primary m-2 p-3 border-info'>
                                <h3 className='text-center font-bold-700 text-white'>
                                    {bibles['verse']}
                                </h3>
                                <p className='text-center'>
                                    {bibles['versequote']}
                                </p>
                            </div>
                        </div>
                    </>
                }

                <div className="bg-gray m-2 p-2">
                    <div className="text-white text-center" >
                        <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>
                        Free Bible Study Resources and Books onlines</h1>
                    </div>
                </div>

                <div className="row justify-content-center p-5 pt-3">
                    {books && books.map((serv,key) =>(
                        <div className="col-12 col-md-4 col-lg-3 m-0 p-0">
                            <div className="bg-white texts-black text-center m-1  border-ok">
                                
                                <div className='text-center m-0 p-3'>
                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudybooks/'+serv.img} className='border-white-b' height="200px" width="100%"  alt="First Image"/>
                                    <div className='' style={{"maxheight":"100px"}}>
                                        <a href={serv.verse} className='p-0 m-0' target='_blank'><p className='p-2 text-xl'>{serv.title}</p></a>
                                    </div>
                                    {/* <i className='text-black text-muted'>{serv.title}</i>
                                    <a className='link-image' href={serv.link}  target='_blank'><img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudybooks/'+serv.img} className='border-white-b' height="250px" width="100%"  alt="First Image" /></a> */}
                                </div>   
                            </div>
                        </div>
                    
                    ))}
                </div>
            </div>
        </div>
        }

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default BibleStudyResources;