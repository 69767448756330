import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import axios from 'axios';
import queryString from 'query-string';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';
import MembersDetailsSpinner from '../spinners/MembersDetailsSpinner';



function Ministries() {
    document.title="Ministries | Utawala Satellite";
    const [ministry,setMinistry]=useState('');
    const [ministryid,setMinistryID]=useState('');
    const [currentministry,setCurrentMinistry] =useState('');

    const [ministrymembers,setMinistryMembers]= useState([]);
    const [ministryprojects,setMinistryProjects]= useState([]);
    const [ministryprograms,setMinistryPrograms]= useState([]);
    const [loadingpage,setLoadingPage]=useState(false);
    const [loadingmemmbers,setLoadingMembers]=useState(false);
    const [loadingprojects,setLoadingProjects]=useState(false);
    const [loadingprograms,setLoadingPrograms]=useState(false);

    let loc=useLocation()
    useEffect( () =>{
        const {ministry,ids}=queryString.parse(loc.search);
        setMinistry(ministry)
        setMinistryID(ids)
        setCurrentMinistry('')
        setMinistryMembers([])
        setMinistryProjects([])
        setMinistryPrograms([])
    },[loc])
    document.title=ministry+ " | Utawala Satellite";
    
    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            loadThisMinistry();
            loadThisMinistryMembers();
            loadThisMinistryProjects();
            loadThisMinistryPrograms();
        }
        return ()=>{
            doneloading=false;
        }
    },[ministryid])

    const loadThisMinistry= ()=>{
        setLoadingPage(true);
        let url=`/api/get/load/ministry/${(ministryid!=='')?ministryid:''}`;
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                    if(response.data.status=== 200){
                        setCurrentMinistry(response.data.ministry);
                                setLoadingPage(false);
                    }
                    else{
                        setCurrentMinistry('');
                                setLoadingPage(false);
                    }
            })
            .catch(error=>{
                setCurrentMinistry('');
                                setLoadingPage(false);
            })
        })
        .catch((error)=>{
            setCurrentMinistry('');
                                setLoadingPage(false);
        })

    }

    const loadThisMinistryMembers= ()=>{
        setLoadingMembers(true);
        let url=`/api/get/load/ministry/member/${(ministryid!=='')?ministryid:''}`;
         axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if(response.data.status=== 200){
                    if(response.data.totals===0){
                        setMinistryMembers('');
                        setLoadingMembers(false);
                    }else{
                        setMinistryMembers(response.data.members);
                        setLoadingMembers(false);
                    }
                }
                else{
                    setMinistryMembers('');
                                setLoadingMembers(false);
                }
                
            })
            .catch(error=>{
                setMinistryMembers('');
                                setLoadingMembers(false);
            })
        })
        .catch((error)=>{
            setMinistryMembers('');
                                setLoadingMembers(false);
        })

    }

    const loadThisMinistryProjects= ()=>{
        setLoadingProjects(true);
        let url=`/api/get/load/ministry/project/${(ministryid!=='')?ministryid:''}`;
         axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if(response.data.status=== 200){
                    if(response.data.totals===0){
                        setMinistryProjects('');
                        setLoadingProjects(false);
                    }else{
                        setMinistryProjects(response.data.projects);
                        setLoadingProjects(false);
                    }
                }
                else{
                    setMinistryProjects('');
                                setLoadingProjects(false);
                }
            })
            .catch(error=>{
                setMinistryProjects('');
                                setLoadingProjects(false);
            })
        })
        .catch((error)=>{
            setMinistryProjects('');
                                setLoadingProjects(false);
        })
    }

    const loadThisMinistryPrograms= ()=>{
        setLoadingPrograms(true);
        let url=`/api/get/load/ministry/program/${(ministryid!=='')?ministryid:''}`;
         axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if(response.data.status=== 200){
                    if(response.data.totals===0){
                        setMinistryPrograms('');
                        setLoadingPrograms(false);
                    }else{
                        setMinistryPrograms(response.data.programs);
                        setLoadingPrograms(false);
                    }
                }
                else{
                    setMinistryPrograms('');
                         setLoadingPrograms(false);
                }
            })
            .catch(error=>{
                setMinistryPrograms('');
                         setLoadingPrograms(false);
            })
        })
        .catch((error)=>{
            setMinistryPrograms('');
                         setLoadingPrograms(false);
        })

    }

  return (
    <>
    <HomeNavBar active={ministry} setMinistry={setMinistry} />
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="">
            {loadingpage?
            <LoadingDetailsSpinner />
            :
                <div className="row justify-content-center p-2 pt-0">
                    {(currentministry !== '' && currentministry.id == ministryid) &&
                        <div  className='container mx-auto ministry-page ' style={{"position":"absolute","marginTop":"60px","zIndex":"99","opacity":".9"}}>
                            <div className=''>
                                <h1 className='text-center text-success p-2 text-bold bg-white border-white-b elevation-2 ministryheader-title-center'>{ministry}</h1>
                            </div>
                            <div className='mt-3 p-2'>
                                <div className="row justify-content-center p-2 pt-0">
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className='bg-success border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                            <h2 className='text-white text-center text-bold text-xl'>About Our Ministry</h2>
                                            {currentministry.aboutus !==null?
                                                <div className="border-top text-center text-white m-0 p-0">
                                                    <div>
                                                        {currentministry.aboutus !==null  && JSON.parse(currentministry.aboutus).map((versel) => (
                                                            <p className='m-1 p-2'> {versel.verselinks_text} </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            :""}
                                        </div>
                                    </div>
                                    {currentministry.mission !==null &&
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className='bg-success border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                            <h2 className='text-white text-center text-bold text-xl'>Our Ministry Mission</h2>
                                            {currentministry.mission !==null?
                                                <div className="border-top text-center text-white m-0 p-0">
                                                    <div>
                                                        {currentministry.mission !==null  && JSON.parse(currentministry.mission).map((versel) => (
                                                            <p className='m-1 p-2'> {versel.verselinks_text} </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            :""}
                                        </div>
                                    </div>}

                                    {currentministry.vision !==null &&
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className='bg-success border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                            <h2 className='text-white text-center text-bold text-xl'>Our Ministry Vision</h2>
                                            {currentministry.vision !==null?
                                                <div className="border-top text-center text-white m-0 p-0">
                                                    <div>
                                                        {currentministry.vision !==null  && JSON.parse(currentministry.vision).map((versel) => (
                                                            <p className='m-1 p-2'> {versel.verselinks_text} </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            :""}
                                        </div>
                                    </div>}
                                </div>


                                
                            </div>
                        </div>
                    }

                   
                    
                    <div className="ministry-cover">
                        {(currentministry !== '') &&
                            <>
                                {currentministry.mediatype==='uploadedvideo'?
                                    <div className='slider-video'>
                                        <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+currentministry.media} controls autoPlay muted />
                                    </div>
                                :
                                    <div className='slider-img'>
                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+currentministry.media} width="100%" />
                                    </div>
                                }
                                
                            </>
                        }
                    </div>
                    {loadingmemmbers?
                        <div className='container row justify-content-center p-5 opacity-none bg-primary '>
                            <div className="col-12 col-md-4 m-0 p-0">
                                <MembersDetailsSpinner />
                            </div>
                            <div className="col-12 col-md-4 m-0 p-0">
                                <MembersDetailsSpinner />
                            </div>
                            <div className="col-12 col-md-4 m-0 p-0">
                                <MembersDetailsSpinner />
                            </div>
                        </div>
                    :
                    <>
                        {(ministrymembers !== '') &&
                        <div>
                            <div className='m-2 p-2'>
                            <h2 className='bg-success m-1 p-3 border-ok text-great text-center text-bold text-xl'>Ministry Members</h2>
                                {(ministrymembers !== '') &&
                                    <div className="row justify-content-center p-2 pt-0">
                                        {ministrymembers && ministrymembers.map((verse,key) =>(
                                            <div className="col-12 col-md-4 m-0 p-0">
                                                <div className='bg-indigo border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                                    <h2 className='text-white text-center text-bold text-xl'>{verse.salutation} {verse.membername}</h2>
                                                    {verse.image !==null?
                                                        <div className="border-top text-center text-white m-0 p-0">
                                                            <p className='m-0 p-1'>
                                                                <span className='text-orange text-lg'>{verse.position}</span> <br/>
                                                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/member/'+verse.image} width="150px" height="150px" className="ministry-img-circle m-0 p-0 mx-auto"  alt="First Image" />
                                                            </p>
                                                        </div>
                                                    :""}
                                                </div>
                                            </div>
                                        ))}
                                    </div> 
                                }
                            </div>
                        </div>}
                        </>
                    }

                    {loadingprojects?
                    <LoadingDetailsSpinner />
                    :
                    <>
                        {(ministryprograms !== '') &&
                        <div>
                            <div className='m-2 p-2'>
                            <h2 className='bg-indigo m-1 p-3 border-ok text-great text-center text-bold text-xl'>Ministry Programs</h2>
                                {(ministryprograms !== '') &&
                                    <div className="row justify-content-center p-2 pt-0">
                                        {ministryprograms && ministryprograms.map((verse,key) =>(
                                            <div className="col-12 col-md-4 m-0 p-0">
                                                <div className='bg-info border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                                    <h2 className='text-white border-bottom text-center text-bold text-xl'>{verse.programname}</h2>
                                                    <p className='text-center m-0 p-1'>{verse.time}. at: {verse.venue}</p>
                                                    {verse.programdate?<p className='text-center m-0 p-1'>{verse.programdate}.</p>:""}
                                                    
                                                    <p className='text-center m-0 p-1'>{verse.summary}.</p>
                                                    {verse.image !==null?
                                                        <p className='m-0 p-1'>
                                                            <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryprogram/'+verse.image}  width="100%" height="180px"  alt="First Image" />
                                                        </p>
                                                        :""
                                                    }
                                                    
                                                </div>
                                            </div>
                                        ))}
                                    </div> 
                                }
                            </div>
                        </div>
                        }
                    </>
                    }

                    {loadingprojects?
                    <LoadingDetailsSpinner />
                    :
                    <>
                        {(ministryprojects !== '') &&
                        <div>
                            <div className='m-2 p-2'>
                            <h2 className='bg-info m-1 p-3 border-ok text-great text-center text-bold text-xl'>Ministry Projects</h2>
                                {(ministryprojects !== '') &&
                                    <div className="row justify-content-center p-2 pt-0">
                                        {ministryprojects && ministryprojects.map((verse,key) =>(
                                            <div className="col-12 col-md-4 m-0 p-0">
                                                <div className='bg-secondary border-ok m-1 mt-4 p-2' style={{"minHeight":"250px"}}>
                                                    <h2 className='text-white border-bottom text-center text-bold text-xl'>{verse.projectname}</h2>
                                                
                                                    <p className='text-center m-0 p-1'>{verse.summary}.</p>
                                                    {verse.image !==null?
                                                        <p className='m-0 p-1'>
                                                            <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministryproject/'+verse.image}  width="100%" height="180px"  alt="First Image" />
                                                        </p>
                                                        :""
                                                    }
                                                    
                                                </div>
                                            </div>
                                        ))}
                                    </div> 
                                }
                            </div>
                        </div>}
                        </>
                    }



                </div>
            }
            
        </div>

    </main>
    <HomeFooter />
      
    </>
  );
}

export default Ministries;