import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useLocation } from 'react-router';

function EmailSetup() {
    document.title="Email Verification";

    let par=useParams()
    let loc=useLocation()

    const [id,setID]=useState((par.id)?par.id:'')
    const [token,setToken]=useState((par.token)?par.token:'')

    const [expires,setExpires]=useState('')
    const [signature,setSignature]=useState('')

    // const [token,setToken]=useState((loc.search)?loc.search:'')

    // console.log(loc.search)

    
    useEffect( () =>{
        const {expires,signature}=queryString.parse(loc.search);
        setExpires(expires);
        setSignature(signature);
        // setToken(token)
    })

    
    
    // console.log(expires,signature)

    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    const [sendnewlink, setSendNewLink] =useState(false)
    
    const [setupverified,setSetupVerified]= useState(false)
    const [accountset,setAccountSet]= useState(false)
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [formdata,setFormData]=useState({
        email:'',
        username:'',
        password:'',
        confirmpassword:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            setLoading(true);
            setLoadingRes("")
            setLoadingResOk("")
        }
        const getPrevMonths = async (e) => { 
        let url=`/api/setup/verify/${id}/${token}`;
    
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setLoadingResOk(response.data.message)
                        setLoadingRes("")
                        
                        setFormData({
                            email:response.data.email,
                            password:'',
                            confirmpassword:'',
                            username:'',
                            error_list:[],
                        })
                        setSetupVerified(true)
                    }
                    else if(response.data.status=== 401){
                        setSetupVerified(false);
                        setLoadingRes(response.data.message)
                        setLoadingResOk("")
                    }
                    else if(response.data.status=== 500){
                        setSetupVerified(false);
                        setLoadingRes(response.data.message)
                        setLoadingResOk("")
                    }
                    else{
                        setSetupVerified(false);
                        setLoadingResOk(response.data.message)
                        setLoadingRes("")
                    }
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })
        };
        getPrevMonths();

        return ()=>{
            doneloading=false;
            
            setLoading(false)
        }
    },[id,token])

    const resetPassword= (e)=>{
        e.preventDefault();
        if((formdata.email).trim() ===''){
            Swal("Email Address is Required","Please Enter Your Email Address","error");
            return;
        }

        if((formdata.username).trim() ===''){
            Swal("Username is Required","Please Enter Your Username","error");
            return;
        }

        if((formdata.password).trim() ===''){
            Swal("New Password is Required","Please Enter New Password","error");
            return;
        }

        if((formdata.confirmpassword).trim() ===''){
            Swal("Confirm New Password is Required","Please Enter Confirm New Password","error");
            return;
        }

        if((formdata.password).trim() !== (formdata.confirmpassword).trim()){
            Swal("Password Must Match","Please Enter Matching password","error");
            return;
        }

        setSetupVerified(true);
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            id:id,
            token:token,
            email:formdata.email,
            password:formdata.password,
            username:formdata.username,
        }
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/setupAccount',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setAccountSet(true);
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setSetupVerified(false);
                    // if(response.data.message ==='Reset Code Expired.'){
                    //     setSetupVerified(false);
                    //     setAccountSet(false);
                    // }
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setSetupVerified(false);
                    // if(response.data.message ==='Reset Code Expired.'){
                    //     setSetupVerified(false);
                    //     setAccountSet(false);
                    // }
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setSetupVerified(false);
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);
            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const requestNewVerificationLink = (ev)=> {
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        setSendNewLink(true)
        let url=`/api/resendverification/${id}`;
    
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                }
                else{
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                }
                setLoading(false);
            })
            .catch(error=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })
    }

    
  return (
    <>
    <HomeNavBar active='login'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div class="container mt-3">
            <div class="row justify-content-center mt-3">
                <div class="col-md-8 mt-3">
                    <div class="card border-white-b">
                        <div class="m-2 mt-4 mb-0 p-3 text-center text-lg">
                            <h1 style={{"textAlign": "center"}}>Account Setup</h1>
                        </div>

                        <div class="card-body">
                            <form className='justify-content-center' onSubmit={resetPassword}>
                                {!loading && !accountset &&
                                    <>
                                    
                                     
                                        {setupverified && loadingresok !=='Email is Verified.' &&
                                            <>
                                                {/* <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                                    Proceed by filling all required details <br/>
                                                    <span className="help-block text-success">
                                                        <strong>{loadingresok!=='' && loadingresok}</strong>
                                                    </span>
                                                </p> */}
                                                    
                                                <div class="text-center p-2 pb-3 font-bold-700">
                                                    <p className='text-black text-center elevation-2 p-3 border-ok'>
                                                        <span className="help-block text-success mt-2">
                                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                                        </span>
                                                        <br/>
                                                        Please specify new Password and Username Details.
                                                    </p>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="username" class="col-md-4 col-form-label text-md-right">Email Address</label>
                                                    <div class="col-md-6 ">
                                                        <input id="Username" type="email" className="form-control" name="email"  readOnly placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                        {formdata.error_list && formdata.error_list.email && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.email}</strong>
                                                            </span>
                                                        }

                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="username" class="col-md-4 col-form-label text-md-right">Username</label>

                                                    <div class="col-md-6">
                                                        <input id="username" type="text" className="form-control @error('username') is-invalid @enderror" name="username" placeholder="Your Username" value={formdata.username} onChange={handleInputChange} required/>
                                                        {formdata.error_list && formdata.error_list.username && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.username}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="password" class="col-md-4 col-form-label text-md-right">New Password</label>

                                                    <div class="col-md-6">
                                                        <input id="password" type="password" className="form-control @error('password') is-invalid @enderror" name="password" placeholder="New Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                                        {formdata.error_list && formdata.error_list.password && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.password}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="confirmpassword" class="col-md-4 col-form-label text-md-right">Confirm New Password</label>

                                                    <div class="col-md-6">
                                                        <input id="confirmpassword" type="password" className="form-control @error('confirmpassword') is-invalid @enderror" name="confirmpassword" placeholder="Confirm New Password" value={formdata.confirmpassword} onChange={handleInputChange} required/>
                                                        {formdata.error_list && formdata.error_list.token && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.token}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </>
                                        }

                                    </>
                                }
                        
                                {/* {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>


                                        {!sendnewlink && 
                                        <div class="justify-items-center m-3 p-3 ml-auto">
                                            <Link className="btn btn-link " to="/login"> 
                                                <button type="submit" className="btn btn-success border-info pl-4 pr-4">
                                                    Click Here to Login
                                                </button>
                                            </Link>
                                        </div>
                                        }
                                    </div>
                                } */}

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>{sendnewlink?'Sending New Email Verifcation Link...':'Checking Setup Link...'}</span>
                                            
                                    </div>
                                }

                                {loadingres!=='' && !setupverified &&
                                    <div className="col-md-12 elevation-0 p-2 text-center  elevation-2 border-white-b">
                                        <span className="help-block text-danger">
                                            <strong>{loadingres!=='' && loadingres}</strong>
                                        </span>

                                        <p className='text-great text-center p-3'>Please Contact Support for Valid Link.</p>

                                        {/* <div class="justify-items-center m-3 p-3 ml-auto">
                                            <button type="button" className="btn btn-primary border-info pl-4 pr-4" onClick={()=>{requestNewVerificationLink()}}>
                                                Request Another Verification Link
                                            </button>
                                        </div> */}


                                        

                                        
                                    </div>
                                }

                                {loadingres!=='' && setupverified &&
                                    <div className="col-md-12 elevation-0 p-2 text-center  elevation-2 border-white-b">
                                        <span className="help-block text-danger">
                                            <strong>{loadingres!=='' && loadingres}</strong>
                                        </span>

                                        <div class="justify-items-center m-3 p-3 ml-auto">
                                            <button type="submit" className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                Set Account Password
                                            </button>
                                        </div>


                                        

                                        
                                    </div>
                                }

                                {!loading && loadingresok !=='' && setupverified && !accountset &&
                                    <div className="row text-center m-0 mb-3 p-0">
                                        <div className="col-12 m-0 p-0">
                                            <button type="submit" className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                Set Account Password
                                            </button>
                                        </div>
                                    </div>
                                }

                                {!loading && loadingresok ==='' && !accountset &&
                                    <div class="form-group mb-0 pt-3">
                                        <div class="d-flex justify-content-center">
                                            <span className='p-1 pr-0'>Remembered password?,</span>
                                            <Link className="btn btn-link" to="/login">
                                                Login Instead
                                            </Link>
                                        </div>
                                        
                                    </div>
                                }

                                {!loading && loadingresok !=='' && accountset &&
                                    <div class="form-group mb-0 pt-3">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <div class="d-flex justify-content-center">
                                            <Link className="btn btn-link" to="/login">
                                                Click Here to Login
                                            </Link>
                                        </div>
                                        
                                    </div>
                                }

                            </form>
                           

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
        
    </main>
    {/* <HomeFooter /> */}
      
    </>
  );
}

export default EmailSetup;