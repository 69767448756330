import Button from 'react-bootstrap/Button';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {LoginContext} from "../contexts/LoginContext";
import ReVerifyEmail from './ReVerifyEmail';

function ReLogin({setLoggedOff,loggedoff}) {

    document.title="Login";
    const {loggedname, setLoggedName, loggedtoken, setLoggedToken, loggedverified, setLoggedVerified ,loggedrole, setLoggedRole} =useContext(LoginContext);
    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [emailnotverified,setEmailNotVerified]=useState(false);


    const [formdata,setFormData]=useState({
        email:'',
        password:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const [authname,setAuthName]=useState('');
    
    // useEffect(()=>{
    //     if(localStorage.getItem("auth_token_ut")){
    //         setAuthName('')
    //         // setRedirect(true);
    //         // setUrl('/dashboard');
    //     }
    //     else{
    //         setAuthName(localStorage.getItem("auth_name_ut"))
    //     }
    //   },[authname])

    //   useEffect(()=>{
    //     if(localStorage.getItem("auth_token_ut")){
    //         setLoggedToken('');
    //         setLoggedName('');
    //         setAuthName('')
    //     }
    //     else{
    //         setLoggedToken(localStorage.getItem("auth_token_ut"));
    //         setLoggedName(localStorage.getItem("auth_name_ut"));
    //         setAuthName(localStorage.getItem("auth_name_ut"))
    //     }
    //   },[authname])
      
    // if(!redirect){
    //     localStorage.removeItem('auth_token');
    //     localStorage.removeItem('auth_name');
    // }

    

    const authUser= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
            password:formdata.password,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/login',form)
            .then(response=>{
                if(response.data.status=== 200){

                    if(response.data.message !=="Logged in"){
                        setEmailNotVerified(true)
                        setLoadingRes("")
                        setFormData({...formdata,error_list:[]});
                        localStorage.setItem('auth_image_ut',response.data.image);
                        localStorage.setItem('auth_token_ut',response.data.token);
                        localStorage.setItem('auth_name_ut',response.data.username);
                        setLoggedRole(response.data.role);
                        setLoggedToken(response.data.token);
                        setLoggedName(response.data.username);
                    }
                    else{
                        setLoadingResOk("Logged In. Redirecting...")
                        setLoadingRes("")
                        setFormData({...formdata,error_list:[]});
                        localStorage.setItem('auth_image_ut',response.data.image);
                        localStorage.setItem('auth_token_ut',response.data.token);
                        localStorage.setItem('auth_name_ut',response.data.username);
                        setLoggedRole(response.data.role);
                        setLoggedToken(response.data.token);
                        setLoggedName(response.data.username);
                        setLoggedOff(false);
                    }
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            setLoadingRes(""+error)
            setLoadingResOk("")
            setLoading(false);
        })

    }

    
  return (
    <>
    {/* <HomeNavBar active='login'/> */}
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
    {emailnotverified ?
        <ReVerifyEmail setEmailNotVerified={setEmailNotVerified} emailnotverified={emailnotverified} setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
    :
        <div class="">
            <div className="d-flex justify-content-center">
                <div className="mt-1 p-3">
                    <div className="card border-none m-2 p-2 ">
                        
                    <h4 className='text-center text-danger'>You Have been Signed Out.</h4>
                        <div className="card-body row border-none m-2 p-2" style={{"paddingTop": "10px"}}>
                            
                        <h4 className='text-info' style={{"textAlign":"center"}}>{loading ?"":"Login Here"}</h4>
                            <form onSubmit={authUser}>
                                {!loading && 
                                    <>
                                        <div className="form-group row p-1 m-0">
                                            <label htmlFor="Username" className="col-12 col-md-3 col-lg-3 m-0 p-1 col-form-label text-left">Username</label>

                                            <div className="col-12 col-md-9 col-lg-9 m-0 p-1">
                                                <input id="Username" type="text" className="form-control" name="email" placeholder="Username or Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }
                                                
                                            </div>
                                        </div>

                                        <div className="form-group row p-1 m-0">
                                            <label htmlFor="password" className="col-12 col-md-3 col-lg-3 m-0 p-1 col-form-label text-left">Password</label>

                                            <div className="col-12 col-md-9 col-lg-9 m-0 p-1">
                                                <input id="password" type="password" className="form-control" name="password" placeholder="Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                                {formdata.error_list && formdata.error_list.password && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.password}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="form-group row mb-0 justify-content-center">
                                    
                                    {loadingresok!=='' && 
                                        <div className="col-md-10 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                                
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>Signing In...</span>
                                                
                                                
                                             
                                        </div>
                                    }

                                    {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 mb-3 p-2 text-center">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }

                                    {!loading && loadingresok ==='' && 
                                        <div className="row text-center m-0 p-0">
                                            
                                            <div className="col-12 m-0 p-0">
                                                <button type="submit" className="btn btn-success border-info">
                                                    Continue <i className='fa fa-chevron-right'></i>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    
                                    
                                </div>
                            </form>


                            {!loading && 
                                <>
                                    <h2 className='border-bottom text-center text-great mt-3 p-2 pb-3 font-bold-700'>OR:</h2>
                                    <p className='border-bottom text-center text-great p-2 pb-3 font-bold-700'>Use other accounts to Login</p>
                                </>
                            }


                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    }
        
    </main>
    
      
    </>
  );
}

export default ReLogin;