import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext,useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from '../home/LoadPrograms';
import ReLogin from '../home/ReLogin';


import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'




function Dashprogram(props) {
    document.title="Dashboard Programs | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('allprograms');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');
    const [loadingpage,setLoadingPage]=useState(false);

    const [programs,setPrograms]=useState([]);
    const [addprogram, setAddprogram]=useState(false);
    const [currentprogram,setCurrentProgram]=useState('');


    const [formdata,setFormData]=useState({
        program:'',
        dateandtime:'',
        notices:'',
        venue:'',
        desc:'',
        expirydate:'',
        type:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newProgram= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            id:(currentprogram!=='')?currentprogram.id:'',
            type:formdata.type,
            program:formdata.program,
            venue:formdata.venue,
            dateandtime:formdata.dateandtime,
            desc:formdata.desc,
            expirydate:formdata.expirydate,
            notices:formdata.fromtime,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/program',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadPrograms();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                console.log(error)
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const loadPrograms= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/programs`)
        .then(response=>{
            if(response.data.status=== 200){
                setPrograms(response.data.programs);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setPrograms([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setPrograms([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setPrograms([]) 
            setLoadingPage(false)
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/programs`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setPrograms(response.data.programs);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setPrograms([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setPrograms([]);
                        setLoadingPage(false)
                    }
                    else{
                        setPrograms([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                        setLoadingPage(false)
                setPrograms([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateProgram = (program) => {
        // setShowAddTenant(true);
        setAddprogram(true)
        setCurrentProgram(program)

        setFormData({
            program:program.program,
            dateandtime:program.dateandtime,
            notices:program.notices,
            venue:program.venue,
            desc:program.desc,
            expirydate:program.expirydate,
            type:program.type,
            error_list:[],
        })
        
    };

    const setAddprograms = (ev)=> {
        setAddprogram(!addprogram);
        setCurrentProgram('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            program:'',
            dateandtime:'',
            notices:'',
            venue:'',
            desc:'',
            expirydate:'',
            type:'',
            error_list:[],
        })
    }

    const deleteProgram= (event)=>{
        const form={
            id:event.id,
        }

        let title='Delete '+event.event;
        let text="This will remove this Program from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/program',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadPrograms();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <Link to="/dashboard" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Home</Link>
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Programs</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {addprogram?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddprograms()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Show Programs 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddprograms()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Program 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addprogram?
                            <form className='justify-content-center' onSubmit={newProgram}>
                                {!loading && 
                                    <>
                                        <div className="form-group row">
                                            <label for="type" className="col-md-3 col-form-label text-md-right">Program Type <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-8">
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.type==="Witnessing"?"true":""} onChange={handleInputChange} className="" name="type" value="Witnessing" autoComplete="type"/> Witnessing
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.type==="Stewardship"?"true":""} onChange={handleInputChange} className="" name="type" value="Stewardship" autoComplete="type"/> Stewardship
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.type==="Health and Temperance"?"true":""} onChange={handleInputChange} className="" name="type" value="Health and Temperance" autoComplete="type"/> Health and Temperance
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.type==="Bible Prophecy"?"true":""} onChange={handleInputChange} className="" name="type" value="Bible Prophecy" autoComplete="type"/> Bible Prophecy
                                                </label>
                                                <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                    <input type="radio" required checked={formdata.type==="Bible Study"?"true":""} onChange={handleInputChange} className="" name="type" value="Bible Study" autoComplete="type"/> Bible Study
                                                </label>
                                                {formdata.error_list && formdata.error_list.type && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.type}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                    
                                    
                                        <div className="form-group row">
                                            <label for="program" className="col-md-3 col-form-label text-md-right">Program Name <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="program" type="text" className="form-control" name="program" placeholder="Program Name" value={formdata.program} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.program && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.program}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="dateandtime" className="col-md-3 col-form-label text-md-right">Date/ Time <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="dateandtime" type="text" className="form-control" name="dateandtime" placeholder="i.e. 8:30am - 12:00pm" value={formdata.dateandtime} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.dateandtime && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.dateandtime}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="venue" className="col-md-3 col-form-label text-md-right">Program Venue <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="venue" type="text" className="form-control" name="venue" placeholder="Program Venue" value={formdata.venue} onChange={handleInputChange} required autoFocus/>
                                                {formdata.error_list && formdata.error_list.venue && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.venue}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="desc" className="col-md-3 col-form-label text-md-right">Description <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                            <textarea id="desc" className="form-control" name='desc' placeholder="Describe your Program Here" value={formdata.desc} onChange={handleInputChange} required autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.desc && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.desc}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Expiry Date </label>
                                            <div className='col-md-7'>
                                                <input id="expirydate" type="date" className="form-control" name="expirydate" placeholder="Expiry Date" value={formdata.expirydate} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.expirydate && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.expirydate}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label for="notices" className="col-md-3 col-form-label text-md-right">Notice</label>
                                            <div className="col-md-7">
                                                <input id="notices" type="text" className="form-control" name="notices" placeholder="Optional" value={formdata.notices} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.notices && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.notices}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddprograms()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentprogram!==''?'Updating Program':'Creating Program'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentprogram!==''?'Update Program':'Save Program'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-3 pt-2">
                                <Tabs
                                    id="fill-tab-example"
                                    className="m-0 p-0 bg-success"
                                    activeKey={key}
                                    onSelect={(k)=> setKey(k)}
                                    fill>
                                        <Tab eventKey="allprograms"
                                            title="All programs">
                                            <div className="row m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-0'>
                                                        <LoadPrograms type='All Programs' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>   
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="witnessing"
                                            title="WITNESSING">
                                            <div className="row m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <LoadPrograms type='Witnessing' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>  
                                                </div>
                                            </div>

                                        </Tab>
                                        <Tab eventKey="stewardship"
                                            title="STEWARDSHIP">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <LoadPrograms type='Stewardship' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>  
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="health"
                                            title="HEALTH AND TEMPERANCE">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <LoadPrograms type='Health and Temperance' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>  
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="bibleprophecy"
                                            title="BIBLE PROPHECY">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <LoadPrograms type='Bible Prophecy' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>    
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="biblestudy"
                                            title="BIBLE STUDY">
                                            <div className="col-12 m-0 p-0">
                                                <div className="text-black text-center">
                                                    <div className='text-center m-0 p-3'>
                                                        <LoadPrograms type='Bible Study' data={programs} updateProgram={updateProgram} deleteProgram={deleteProgram} />
                                                    </div>  
                                                </div>
                                            </div>
                                        </Tab>
                                </Tabs>
                                </div>  

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default Dashprogram;