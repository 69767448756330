import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';
import { useEffect, useState, useContext } from 'react';

import kingjamesbible from '../../assets/img/kingjamesbible.jpeg';
import cover23a from '../../assets/img/cover23a.jpg';
import tithesandoffering from '../../assets/img/to.jpg';



import LoadWhatsHappening from './LoadWhatsHappening';
import SabbathSchoolAnimated from './SabbathSchoolAnimated';
import TheTruth from './TheTruth';
import Events from './Events';
import TheBelief from './TheBelief';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function Forum() {
    document.title="Forum | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

  return (
    <>
    <HomeNavBar active='forum'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events">
            <div className='events-container m-0 p-0'>
                
                <div className='row justify-content-center bg-white p-2'>
                    <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='justify-content-between m-0 mt-2 p-1 text-muted'>
                                <Link to='#' className='m-0 p-1 text-orange'>
                                    Categories
                                </Link>
                                <Link to='#' className='m-0 p-1 text-muted'>
                                    All Posts
                                </Link>
                                <Link to='#' className='m-0 p-1 text-muted'>
                                    My Posts
                                </Link>
                        </div>
                        <div>
                            <div className='p-2'>
                                <Form.Control
                                    type="search"
                                    placeholder="🔍Search"
                                    className="me-1 search border-white-b"
                                    aria-label="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center bg-pink text-white p-3' style={{"height":"250px"}}>
                    <div className='d-flex flex-column flex-fill align-items-center my-auto'>
                        <h1 className=''>Forum</h1>
                        <h4>Welcome! Have a look around and join the discussions.</h4>
                    </div>
                </div>

                <div className='row justify-content-center bg-white p-1'>
                    <div className='d-flex flex-column flex-fill align-items-end my-auto'>
                        <Link to='#' className='link-black p-2 '>
                            <button className='btn btn-orange p-1 pl-2 pr-2 font-bold-700'>Create New Post</button>
                        </Link>
                    </div>
                </div>

                <div className='row justify-content-center bg-white p-1'>
                    <div className='d-flex flex-column justify-content-between my-auto'>
                        <div className="border-white-b texts-black text-center m-1">
                            <div className='p-2 m-0'>
                                <div className='d-flex flex-column flex-sm-row justify-content-between'>
                                    <div className="m-0 p-0 forum-div-h">
                                        <img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" />
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-0'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span> General Discussion</span>
                                            </Link>
                                            <p className='p-0 pt-2 m-0 muted'> <i><small>Share stories, ideas, pictures and more! </small></i></p>
                                            
                                        </div>
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className='fa fa-eye'> </span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className='fa fa-comment'> </span> <span> 0</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className=''>Follow </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="border-white-b texts-black text-center m-1">
                            <div className='p-2 m-0'>
                                <div className='d-flex flex-column flex-sm-row justify-content-between'>
                                    <div className="m-0 p-0 forum-div-h">
                                        <img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" />
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-0'>
                                        
                                        
                                            <Link to='#' className='text-sm text-muted'>
                                                <span>Questions & Answers</span>
                                            </Link>
                                            <p className='p-0 pt-2 m-0 muted'> <i><small>Get answers and share knowledge</small></i></p>
                                            
                                        </div>
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className='fa fa-eye'> </span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className='fa fa-comment'> </span> <span> 0</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='m-0 p-0 text-muted my-auto'>
                                        <div className='text-left pl-2'>
                                            <Link to='#' className='text-sm text-muted'>
                                                <span className=''>Follow </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  

                    </div>
                </div>

                <div className='row justify-content-center bg-white p-1'>
                    <h4>New Posts</h4>
                </div>

                <div className="row justify-content-center bg-white p-3">
                    <div className='col-12 col-md-4 m-0 p-0'>
                        <div className="border-white-b texts-black text-center m-1">
                            
                            <div className='p-0'>
                                <div className='m-0 pt-2 pb-2 justify-content-between '>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex m-0 p-1 text-muted'>
                                            <div className='user-div bg-primary text-white pr-2'>
                                                <span>M</span>
                                            </div>
                                            <div className='text-left pl-2'>
                                                <p className='p-0 m-0'> <span className='fa fa-star'></span></p>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <i><small>January 15, 2023 </small></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-left m-2 pt-0'>
                                        <p className='m-0 p-0'>Welcome to the Forum!</p>
                                        <p className='text-muted m-0 p-0'><small>General Discussion</small></p>
                                    </div>
                                    <div className='text-left m-2 pt-2 border-bottom-b'>
                                        <p className='text-muted'>Share your thoughts. Feel free to add GIFs, videos, hashtags and more to your posts and</p>
                                    </div>

                                    <div className='text-left m-2 p-2'>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div>
                                                <Link to='#' className='text-sm text-muted p-2 '>
                                                    <span className='fa fa-thumbs-up'></span> <span className='text-xs'>Like</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <span className='fa fa-comment'></span> <span>0</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>  
                    </div>

                    <div className='col-12 col-md-4 m-0 p-0'>
                        <div className="border-white-b texts-black text-center m-1">
                            
                            <div className='p-0'>
                                <div className='m-0 pt-2 pb-2 justify-content-between '>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex m-0 p-1 text-muted'>
                                            <div className='user-div bg-primary text-white pr-2'>
                                                <span>M</span>
                                            </div>
                                            <div className='text-left pl-2'>
                                                <p className='p-0 m-0'> <span className='fa fa-star'></span></p>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <i><small>January 15, 2023 </small></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-left m-2 pt-0'>
                                        <p className='m-0 p-0'>Introduce yourself!</p>
                                        <p className='text-muted m-0 p-0'><small>General Discussion</small></p>
                                    </div>
                                    <div className='text-left m-2 pt-2 border-bottom-b'>
                                        <p className='text-muted'>We'd love to get to know you better. Take a moment to say hi to the community in the comments.</p>
                                    </div>

                                    <div className='text-left m-2 p-2'>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div>
                                                <Link to='#' className='text-sm text-muted p-2 '>
                                                    <span className='fa fa-thumbs-up'></span> <span className='text-xs'>Like</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <span className='fa fa-comment'></span> <span>0</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>  
                    </div>

                    <div className='col-12 col-md-4 m-0 p-0'>
                        <div className="border-white-b texts-black text-center m-1">
                            
                            <div className='p-0'>
                                <div className='m-0 pt-2 pb-2 justify-content-between '>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex m-0 p-1 text-muted'>
                                            <div className='user-div bg-primary text-white pr-2'>
                                                <span>M</span>
                                            </div>
                                            <div className='text-left pl-2'>
                                                <p className='p-0 m-0'> <span className='fa fa-star'></span></p>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <i><small>January 15, 2023 </small></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-left m-2 pt-0'>
                                        <p className='m-0 p-0'>Forum rules</p>
                                        <p className='text-muted m-0 p-0'><small>General Discussion</small></p>
                                    </div>
                                    <div className='text-left m-2 pt-2 border-bottom-b'>
                                        <p className='text-muted'>We want everyone to get the most out of this community, so we ask that you please read</p>
                                    </div>

                                    <div className='text-left m-2 p-2'>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div>
                                                <Link to='#' className='text-sm text-muted p-2 '>
                                                    <span className='fa fa-thumbs-up'></span> <span className='text-xs'>Like</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='#' className='text-sm text-muted'>
                                                    <span className='fa fa-comment'></span> <span>0</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>  
                    </div>

                </div>

                

            </div>
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default Forum;