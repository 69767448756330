
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useMemo, useState, useCallback } from 'react';

import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';

import kingjamesbible from '../../assets/img/kingjamesbible.jpeg';



import LoadWhatsHappening from './LoadWhatsHappening';
import SabbathSchoolAnimated from './SabbathSchoolAnimated';
import TheTruth from './TheTruth';
import Events from './Events';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function Home() {
    document.title="Home | Utawala Satellite";

    const [slides,setSlides]=useState([]);
    const [lessons,setLessons]=useState([]);
    const [tithes,setTithes]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);
    
    
    const [loadingslides,setLoadingSlides]=useState(false);
    
    const [loadinglessons,setLoadingLessons]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingSlides(true)
        
        const getSlides = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/slides`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setSlides(response.data.slides);
                            setLoadingSlides(false)
                        }
                        else{
                            setSlides([]);
                            setLoadingSlides(false)
                        }
                    }
                })
                .catch(error=>{
                    setSlides([]);
                    setLoadingSlides(false)
                })
            })
            .catch((error)=>{
                setSlides([]);
                setLoadingSlides(false)
            })
        };
        getSlides();

        return ()=>{
            doneloading=false;
            
        }
    },[])


    useEffect(()=>{
        let doneloading=true;
        setLoadingLessons(true)
        
        const getLessons = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/lesson`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setLessons(response.data.lessons);
                        setLoadingLessons(false)
                        }
                        else{
                            setLessons([]);
                        setLoadingLessons(false)
                        }
                    }
                })
                .catch(error=>{
                    setLessons([]);
                        setLoadingLessons(false)
                })
            })
            .catch((error)=>{
                setLessons([]);
                        setLoadingLessons(false)
            })
        };
        getLessons();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        
        const getTithes = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/tithe`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setTithes(response.data.tithes);
                            setLoadingPage(false)
                        }
                        else{
                            setTithes([]);
                            setLoadingPage(false)
                        }
                    }
                })
                .catch(error=>{
                    setTithes([]);
                    setLoadingPage(false)
                })
            })
            .catch((error)=>{
                setTithes([]);
                setLoadingPage(false)
            })
        };
        getTithes();

        return ()=>{
            doneloading=false;
            
        }
    },[])

  return (
    <>
    <HomeNavBar active='home'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div className="m-0 p-0" style={{"zIndex":"-9999"}}>
            {loadingslides?
                <LoadingDetailsSpinner />
            :
                <LoadWelcome slides={slides} />
            }
        </div>

        
        {/* <div className="container">
            <div className="">
                <div className=" text-great text-center" >
                    <h1 className='m-2 p-3'>Verse of the Day</h1>
                    <iframe src={'https://www.kingjamesbibleonline.org/popular-bible-verses-widget.php'} 
                            style={{"width":"200px","height": "240px", "border": "0px solid #ffffff",}}></iframe>

                </div>
            </div>
        </div> */}

        {/* <div className="container">
            <div className="">
                <div className=" text-great text-center" >
                    <h1 className='m-2 p-3'>Verse of the Day</h1>
                    <iframe src={'https://www.kingjamesbibleonline.org/inspiring-images-widget.php'} 
                            style={{"width":"344px","height": "344px", "border": "0px solid #ffffff",}}></iframe>

                </div>
            </div>
        </div> */}


        {/* https://www.kingjamesbibleonline.org/verse-of-day-json.php */}
        {/* https://www.kingjamesbibleonline.org/inspiring-images-json.php */}

        <div className="container">
            <div className="">
                <div className=" text-great text-center" >
                    <h1 className='m-2 p-3'>What's Happening?</h1>
                    <h4 className='text-success text-bold'>Learn Amazing Truths....watch the videos below</h4>
                </div>
            </div>
        </div>

        
       
        <div className="container p-3">
            <div className="row justify-content-center">
                <div className='container bg-indigo'>
                    <div className='welcome-center'>
                        <LoadWhatsHappening />
                    </div>

                    <div className=" text-white text-center" >
                        <h1 className='m-2 p-3'>Verse of the Day?</h1>
                    </div>

                    <div className='row m-2 p-2'>
                        <a href='https://www.kingjamesbibleonline.org/' target='_blank'>
                            <img src={kingjamesbible} width="100%"  alt="First Image" />
                        </a>

                        <div className='text-center m-0 p-1 mt-3'>
                            <a href='https://marchingonwardtozi.wixsite.com/utawala-satellite-sd/chhose' target='_blank'>
                                <button className='btn btn-home'>START YOUR JOURNEY HERE <span className='fa fa-arrow-right'></span></button>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        {loadinglessons?
        <LoadingDetailsSpinner />
        :
            <div className="container">
                {lessons &&
                    <div className="row justify-content-center pt-3">
                        <div className="col-12 col-md-6 m-0 p-0">
                            <div className="bg-purple texts-black text-center p-2" >
                                <div className='row'>
                                    <div className="col-6">
                                        <div>
                                            <h3 className="">{lessons['title']}</h3>

                                            <h3>{lessons['header']}</h3>

                                            <p className='p-2'>Memory Text: “{lessons['memorytext']}”</p>

                                            (<a href={lessons['verselink']} target='_blank'>
                                                {lessons['verse']}
                                            </a>).
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/lesson/'+lessons['image']} className='border-white-b' width="100%"  alt="First Image" />
                                    </div>
                                </div>
                                <div className='m-0 p-0 text-left'>
                                    <a href='https://www.sabbath.school/LessonBook' target='_blank'>
                                        <button className='btn btn-home ml-3'>Study Now</button>
                                    </a>
                                </div>
                                        
                            </div>
                        </div>
                        <div className="col-12 col-md-6 m-0 p-0">
                            <div className='m-1'>
                                <div className="video-responsive">
                                    <iframe
                                        width="100%"
                                        src={`https://www.youtube.com/embed/`+lessons['videoid']}
                                        frameborder="0"
                                        allow=""
                                        allowFullScreen
                                        title="Lesson Youtube Video"
                                    />
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                }
                
            </div>
        }

        <div className="container bg-indigo mt-3 pt-3">
            {/* <div className="pt-3">
                 <button className='btn'><span className='fa fa-ellipsis'></span> Sabbath School Animated Presentations</button>                           
            </div> */}
            <div className='m-0 p-0'>
                <SabbathSchoolAnimated />
            </div>
            {/* <div className='m-0 p-0'>
            <iframe width="560" height="315"
             src="https://www.youtube.com/embed/videoseries?si=bhISg82jV6jdte2t&amp;list=PLKejbZSBAyL_UhmFWf--6oy_cZq55GBTc" 
             title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin"
             allowfullscreen></iframe>
            </div> */}
        </div>

        
        <div className="container">
            <div className="">
                <div className=" text-purple text-center" >
                    <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>THE TRUTH THAT WILL SET YOU FREE</h1>
                </div>
            </div>
            <div className="row justify-content-center pt-3">
               <TheTruth />
            </div>
            
        </div>

        <div className="events">
            <div className='events-container'>
                <div className="m-2 p-2">
                    <div className=" text-white text-center" >
                        <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>Upcoming Events</h1>
                    </div>
                </div>
                <div className=" row justify-content-center p-1 pt-1">
                    <Events />
                </div>
                {loadingpage?
                <LoadingDetailsSpinner />
                :
                <>
                    {tithes &&
                        <div className='container m-0 p-2'>
                            <div className='bg-white text-center p-3'>
                                <h1 className='p-3'>Tithes and Offerings</h1>
                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/tithe/'+tithes['image']} className='border-light' width="50%"  alt="First Image" />
                                <h4 className='p-3 text-black text-xl font-bold-700'>{tithes['verse']}</h4>
                                <p className='p-3'> 
                                    “{tithes['text']}
                                </p>
                                <p>
                                    <Link to='tithes/give' target='_blank'>
                                        <button className='btn btn-outline-orange ml-3 pl-5 pr-5 font-bold-700'>Give Now</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    }
                </>
                }
            </div>
            
        </div>

        {/* import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameDay } from 'date-fns';

function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  // ... rest of the calendar logic and rendering
}

function Day({ day, onClick }) {
  // ... day rendering logic
}

function Week({ days }) {
  // ... week rendering logic
}

function Month({ currentMonth }) {
  // ... month rendering logic
}

export default Calendar; */}

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}


export default Home;