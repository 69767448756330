import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from '../home/LoadPrograms';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';
import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';


import {LoginContext} from "../contexts/LoginContext";

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function DashSabbathSchoolAnimated(props) {
    document.title="Dashboard Sabbath School Animated Slides | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [key,setKey] =useState('allslides');
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [slides,setSlides]=useState([]);
    const [addslide, setAddslide]=useState(false);
    const [currentslide,setCurrentSlide]=useState('');
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

    // title description videoid start end
    const [formdata,setFormData]=useState({
        title:'',
        videoid:'',
        description:'',
        start:'',
        end:'',
        error_list:[],
    });

    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newSlide= (e)=>{
        e.preventDefault();

        if(formdata.title ===''){
            Swal("Enter Slide Title","Please Enter the Title of the Slide to be display on the Slide","error");
            return;
        }

        if(formdata.videoid ===''){
            Swal("Missing Video ID","Please Specify Youtube Video ID to Use.","error");
            return;
        }

        
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            id:(currentslide!=='')?currentslide.id:'',
            title:formdata.title,
            start:formdata.start,
            end:formdata.end,
            videoid:formdata.videoid,
            description:formdata.description,
        }
            axios.post('/api/save/sabbathschoolanimation',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadSlides();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadSlides= ()=>{
        axios.get(`/api/load/sabbathschoolanimations`)
        .then(response=>{
            if(response.data.status=== 200){
                setSlides(response.data.sabbathschoolanimations);
            } 
            else if(response.data.status=== 401){
                setSlides([]) 
                Swal("Error",response.data.message,"error");
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setSlides([]) 
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setSlides([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/sabbathschoolanimations`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setSlides(response.data.sabbathschoolanimations);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setSlides([]);
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setSlides([]);
                    }
                    else{
                        setSlides([]);
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                setSlides([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateSlide = (slide) => {
        // setShowAddTenant(true);
        setAddslide(true)
        setCurrentSlide(slide)
        
        setFormData({
            title:slide.title,
            start:slide.start,
            end:slide.end,
            videoid:slide.videoid,
            description:slide.description,
            error_list:[],
        })
        
    };

    const setAddslides = (ev)=> {
        setAddslide(!addslide);
        setCurrentSlide('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            title:'',
            videoid:'',
            description:'',
            start:'',
            end:'',
            error_list:[],
        })
    }

    const deleteSlide= (slide)=>{
        const form={
            id:slide.id,
        }

        let title='Delete '+slide.title;
        let text="This will remove this Slide from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/sabbathschoolanimation',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadSlides();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Sabbath School Animated Playlists Slides</h1>
                    </div>

                    <div className="card-body">
                        {addslide?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                Please fill details below to Create or Update Playlist Slide
                                <button type="button" onClick={()=>{setAddslides()}} className="btn btn-outline-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-list'></span> Show Playlist Slides 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    Sabbath School Animated Playlist Slides List
                                    <button type="button" onClick={()=>{setAddslides()}} className="btn btn-outline-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Playlist Slide 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addslide?
                            <form className='justify-content-center' onSubmit={newSlide}>
                                {!loading && loadingresok==='' && 
                                    <>
                                        <div className="form-group row">
                                            <label for="title" className="col-md-3 col-form-label text-md-right">Title <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="title" type="text" className="form-control" name="title" placeholder="Title Name" value={formdata.title} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.title && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.title}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="videoid" className="col-md-3 col-form-label text-md-right">Video ID <sub className='text-red text-sm'>*</sub> </label>
                                            <div className="col-md-7">
                                                <input id="videoid" type="text" className="form-control" name="videoid" placeholder="Youtube Video id" value={formdata.videoid} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.videoid && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.videoid}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Start & End Date <sub className='text-red text-sm'>*</sub></label>
                                            <div className='col-md-7'>
                                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                    <div className=" ">
                                                        <input id="start" type="date" className="form-control" name="start" placeholder="Last Name" value={formdata.start} onChange={handleInputChange} required autoFocus/>
                                                        {formdata.error_list && formdata.error_list.start && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.start}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className='p-2'>
                                                        <p>TO:</p>
                                                    </div>
                                                    <div className=" ">
                                                        <input id="end" type="date" className="form-control" name="end" placeholder="end" value={formdata.end} onChange={handleInputChange} autoFocus/>
                                                        {formdata.error_list && formdata.error_list.end && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.end}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="form-group row">
                                            <label for="description" className="col-md-3 col-form-label text-md-right">Description </label>
                                            <div className="col-md-7">
                                            <textarea id="description" className="form-control" name='description' placeholder="Enter Description Here" value={formdata.description} onChange={handleInputChange} autoFocus rows="4"></textarea>
                                                {formdata.error_list && formdata.error_list.description && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.description}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Video Preview </label>
                                            <div className='col-md-7'>
                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    {formdata.videoid !=='' &&
                                                        <div className='m-0 p-1 border-bottom forum-div-h'>
                                                            <iframe
                                                                width="100%"
                                                                src={`https://www.youtube.com/embed/`+formdata.videoid}
                                                                frameborder="0"
                                                                allow=""
                                                                allowFullScreen
                                                                title="Whats Happening Youtube Videos"
                                                            />
                                                            
                                                        </div>
                                                    }   
                                                </div>
                                                
                                            </div>
                                        </div>


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddslides()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentslide!==''?'Updating Slide':'Creating Slide'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentslide!==''?'Update Slide':'Save Slide'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-0 pt-2">
                                {slides && slides.map((slide,key) => (
                                    <div className='col-12 col-md-4 m-0 p-0 text-black'>
                                        <div className='m-1 border-info'>
                                            <div className='bg-white m-0 p-0'>
                                                <iframe
                                                    width="100%"
                                                    src={`https://www.youtube.com/embed/`+slide.videoid}
                                                    frameborder="0"
                                                    title="Embedded youtube"
                                                />
                                            </div>
                                            <div className="bg-white m-0 p-2" >
                                                <p>{slide.title}</p>
                                                <i className="text-muted">Study this lesson for the week of {slide.start}, to {slide.end}.</i>
                                                {slide.description !==null?
                                                    <div className="border-bottom-top m-0 p-0">
                                                    <p className="text-left m-0 p-2">{slide.description}</p>
                                                </div>:""}
                                            </div>
                                            {deleteSlide && updateSlide &&
                                                <div className='m-0 p-0 text-muted my-auto'>
                                                    <div className='text-left pl-2'>
                                                        <div>
                                                            <button type="button" onClick={()=>{updateSlide(slide)}} className="btn btn-primary m-1 p-1">
                                                                <span className='fa fa-edit'></span> Edit
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteSlide(slide)}} className="btn btn-danger m-1 p-1">
                                                                <span className='fa fa-trash'></span> Del
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    // <div className="col-12 col-md-4 m-0 p-0">
                                    //     <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                                    //         <div className='text-center m-0 p-1'>
                                    //             <h4 className='font-bold-700'>
                                    //                <span className='text-left font-bold-700 text-xl'>{key+1}.</span> {slide.title}
                                    //             </h4>
                                    //             {slide.description !==null?<div className="border-bottom-top m-0 p-0">
                                    //                 <p className="text-left m-0 p-2">{slide.description}</p>
                                    //             </div>:""}
                                                
                                    //             {slide.videoid !=='' &&
                                    //                 <div className='m-0 p-1 border-bottom forum-div-h'>
                                    //                     <iframe
                                    //                         width="100%"
                                    //                         src={`https://www.youtube.com/embed/`+slide.videoid}
                                    //                         frameborder="0"
                                    //                         allow=""
                                    //                         allowFullScreen
                                    //                         title="Whats Happening Youtube Videos"
                                    //                     />
                                                        
                                    //                 </div>
                                    //             }  
                                    //             {deleteSlide && updateSlide &&
                                    //             <div className='m-0 p-0 text-muted my-auto'>
                                    //                 <div className='text-left pl-2'>
                                    //                     <div>
                                    //                         <button type="button" onClick={()=>{updateSlide(slide)}} className="btn btn-primary m-1 p-1">
                                    //                             <span className='fa fa-edit'></span> Edit
                                    //                         </button>
                                                            
                                    //                         <button type="button" onClick={()=>{deleteSlide(slide)}} className="btn btn-danger m-1 p-1">
                                    //                             <span className='fa fa-trash'></span> Del
                                    //                         </button>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //             }
                                                
                                    //         </div>   
                                    //     </div>
                                    // </div>
                                ))}
                                </div>  

                            </>
                        }

                        
                    </div>
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashSabbathSchoolAnimated;