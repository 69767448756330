import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useLocation } from 'react-router';

function VerifyEmail() {
    document.title="Email Verification";

    let par=useParams()
    let loc=useLocation()

    const [id,setID]=useState((par.id)?par.id:'')
    const [token,setToken]=useState((par.token)?par.token:'')

    const [expires,setExpires]=useState('')
    const [signature,setSignature]=useState('')

    // const [token,setToken]=useState((loc.search)?loc.search:'')

    // console.log(loc.search)

    
    useEffect( () =>{
        const {expires,signature}=queryString.parse(loc.search);
        setExpires(expires);
        setSignature(signature);
        // setToken(token)
    })
    // console.log(expires,signature)

    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    const [sendnewlink, setSendNewLink] =useState(false)
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');



    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            setLoading(true);
            setLoadingRes("")
            setLoadingResOk("")
        }
        const getPrevMonths = async (e) => { 
        let url=`/api/verify/${id}/${token}`;
    
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setLoadingResOk(response.data.message)
                        setLoadingRes("")
                    }
                    else if(response.data.status=== 401){
                        setLoadingRes(response.data.message)
                        setLoadingResOk("")
                    }
                    else if(response.data.status=== 500){
                        setLoadingRes(response.data.message)
                        setLoadingResOk("")
                    }
                    else{
                        setLoadingResOk(response.data.message)
                        setLoadingRes("")
                    }
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })
        };
        getPrevMonths();

        return ()=>{
            doneloading=false;
            
            setLoading(false)
        }
    },[id,token])

    const requestNewVerificationLink = (ev)=> {
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        setSendNewLink(true)
        let url=`/api/resendverification/${id}`;
    
        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.get(url)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                }
                else{
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                }
                setLoading(false);
            })
            .catch(error=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })
    }

    
  return (
    <>
    <HomeNavBar active='login'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div class="container mt-3">
            <div class="row justify-content-center mt-3">
                <div class="col-md-8 mt-3">
                    <div class="card border-white-b">
                        <div class="m-2 mt-4 mb-0 p-3 text-center text-lg">
                            <h1 style={{"textAlign": "center"}}>{sendnewlink?'New Email Verification Link':'Email Verification'}</h1>
                        </div>

                        <div class="card-body">
                        
                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>


                                        {!sendnewlink && 
                                        <div class="justify-items-center m-3 p-3 ml-auto">
                                            <Link className="btn btn-link " to="/login"> 
                                                <button type="submit" className="btn btn-success border-info pl-4 pr-4">
                                                    Click Here to Login
                                                </button>
                                            </Link>
                                        </div>
                                        }
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>{sendnewlink?'Sending New Email Verifcation Link...':'Verifying Email Address...'}</span>
                                            
                                    </div>
                                }
                                {loadingres!=='' && 
                                    <div className="col-md-12 elevation-0 p-2 text-center border-none">
                                        <span className="help-block text-danger">
                                            <strong>{loadingres!=='' && loadingres}</strong>
                                        </span>

                                        <div class="justify-items-center m-3 p-3 ml-auto">
                                            <button type="button" className="btn btn-primary border-info pl-4 pr-4" onClick={()=>{requestNewVerificationLink()}}>
                                                Request Another Verification Link
                                            </button>
                                        </div>


                                        

                                        
                                    </div>
                                }
                           

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
        
    </main>
    {/* <HomeFooter /> */}
      
    </>
  );
}

export default VerifyEmail;