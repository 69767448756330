import Carousel from 'react-bootstrap/Carousel';

import iwillgo from '../../assets/img/iwillgo2.png';
import churches from '../../assets/img/churches1.jpg';
import sdalogo from '../../assets/img/sdalogo2.png';


function LoadWelcome({slides}) {
  return (
    <Carousel keyboard={true} indicators={true}>
        {slides && slides.map((slide,key) =>(
            <Carousel.Item >
                {key===0 ?
                <div className="slider">
                    <div className='slider-video'>
                    {slide.media !==null &&
                        <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/slides/'+slide.media} autoPlay muted loop />
                    }
                    </div>
                    <div className='welcome-home welcome-first'>
                        <div className="container text-center " style={{"position":"relative"}}>
                            <div className='bg-indigo welcome-one d-flex pt-3 justify-content-center'>
                                <div className='ml-auto '>
                                    <img src={iwillgo} className='img-circle' alt="First Image" />
                                </div>
                                <div className='mx-auto '>
                                    <h1 className='text-muted texthead'>Welcome to</h1>
                                </div>
                                <div className='mr-auto '>
                                    <img src={churches} className='img-circle' alt="First Image" />
                                </div>
                            </div>
                            
                        </div>
                        <div className="container text-center" style={{"position":"relative"}}>
                            <div className='welcome-in bg-indigo welcome-one d-flex justify-content-center'>
                                <div className='mx-auto '>
                                    <img src={sdalogo} className='sdalogo' alt="First Image" />
                                    <h3 className='text-muted texthead text-sda'>SEVENTH-DAY ADVENTIST CHURCH.</h3>
                                    <h3 className='text-warning texthead'>UTAWALA SATELLITE</h3>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='container' style={{"position":"relative","marginTop":"-50px","paddingTop":"10px","paddingBottom":"30px"}}>
                        <h4 className={`${slide.color} ${slide.alignment} text-bold ml-3 pl-5 pt-2 texthead-title`}>{slide.title}</h4>
                        {/* <h4 className='text-left text-great '>{slide.title}</h4> */}
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"20px","paddingBottom":"50px"}}>
                        <div className='mt-2 pl-5 welcome-verse'>
                            <h1 className='text-dark' style={{"fontWeight":"700"}} >{slide.verse}</h1>
                                <h2 className='text-white text-bold text-xl'>
                                    {slide.versequote}
                                    
                                </h2>
                                <p>{slide.trailing}</p>
                        </div>
                        
                    </div>

                </div>
                :
                <div className="slider">
                    <div className='slider-video'>
                    {slide.media !==null &&
                        <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/slides/'+slide.media} autoPlay muted loop />
                    }
                    </div>
                    
                    <div className='container mx-auto' style={{"position":"relative","paddingTop":"10px","paddingBottom":"20px"}}>
                    <h4 className={`${slide.color} ${slide.alignment} text-bold ml-3 pl-5 pt-2 pb-4 texthead-title`}>{slide.title}</h4>
                    {/* <h4 className='text-left text-orange text-bold ml-3 pl-5 pt-5 texthead-title'>{slide.title}</h4> */}
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"10px","paddingBottom":"50px"}}>
                        <div className='mt-3 pl-5 welcome-verse'>
                                {slide.verse!==null && <h1 className='text-warning' style={{"fontWeight":"700"}} >{slide.verse}</h1>}
                                <h2 className='text-white text-bold text-xl'>
                                    {slide.type == 'Verse Quote' ?
                                        <>{slide.versequote}</>
                                        :
                                        <>
                                        {slide.verselinks !==null  && JSON.parse(slide.verselinks).map((versel) => (
                                            <a className='link-white p-1' href={versel.verselinks_link}><span className='font-bold-700'>{versel.verselinks_verse}</span> {versel.verselinks_text}.</a>
                                        ))}
                                        </>
                                    }
                                </h2>
                        </div>
                        
                    </div>

                </div>
                }
            </Carousel.Item>
            
        ))}
            {/* <Carousel.Item >
                <div className="slider">
                    <div className='slider-video'>
                        <video src={file1} autoPlay muted loop />
                    </div>
                    <div className='welcome-home welcome-first'>
                        <div className="container text-center " style={{"position":"relative"}}>
                            <div className='bg-indigo welcome-one d-flex pt-3 justify-content-center'>
                                <div className='ml-auto '>
                                    <img src={iwillgo} className='img-circle' alt="First Image" />
                                </div>
                                <div className='mx-auto '>
                                    <h1 className='text-muted texthead'>Welcome to</h1>
                                </div>
                                <div className='mr-auto '>
                                    <img src={churches} className='img-circle' alt="First Image" />
                                </div>
                            </div>
                            
                        </div>
                        <div className="container text-center" style={{"position":"relative"}}>
                            <div className='welcome-in bg-indigo welcome-one d-flex justify-content-center'>
                                <div className='mx-auto '>
                                    <img src={sdalogo} className='sdalogo' alt="First Image" />
                                    <h3 className='text-muted texthead text-sda'>SEVENTH-DAY ADVENTIST CHURCH.</h3>
                                    <h3 className='text-warning texthead'>UTAWALA SATELLITE</h3>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='container' style={{"position":"relative","marginTop":"-50px","paddingTop":"10px","paddingBottom":"30px"}}>
                        <h4 className='text-left text-great text-bold ml-3 pl-5 pt-2 texthead-title'>The Great  Commission</h4>
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"20px","paddingBottom":"50px"}}>
                        <div className='mt-2 pl-5 welcome-verse'>
                            <h1 className='text-dark' style={{"fontWeight":"700"}} >Matthew 28:19</h1>
                                <h2 className='text-white text-bold text-xl'>
                                    “Go ye therefore, and teach all nations, baptizing them in the name
                                     of the Father, and of the Son, and of the Holy Ghost:”
                                </h2>
                                <p>King James Version (KJV)</p>
                        </div>
                        
                    </div>

                </div>
            </Carousel.Item>

            <Carousel.Item >
                <div className="slider">
                    <div className='slider-video'>
                        <video src={file2} autoPlay muted loop />
                    </div>
                    
                    <div className='container mx-auto' style={{"position":"relative","paddingTop":"10px","paddingBottom":"30px"}}>
                        <h4 className='text-left text-orange text-bold ml-3 pl-5 pt-5 texthead-title'>The Worlds Greatest Want</h4>
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"20px","paddingBottom":"50px"}}>
                        <div className='mt-3 pl-5 welcome-verse'>
                                <h2 className='text-white text-bold text-xl'>
                                “The greatest want of the world is the want of men—men who will not be
                                 bought or sold, men who in their inmost souls are true and honest,
                                  men who do not fear to call sin by its right name, men whose conscience is as true 
                                  to duty as the needle to the pole, men who will stand for the right though the heavens fall.
                                </h2>
                        </div>
                        
                    </div>

                </div>
            </Carousel.Item>

            <Carousel.Item >
                <div className="slider">
                    <div className='slider-img'>
                        <img src={cover2} width="100%" />
                    </div>
                    
                    <div className='container mx-auto' style={{"position":"relative","paddingTop":"10px","paddingBottom":"30px"}}>
                        <h4 className='text-center text-white text-bold ml-3 pl-5 pt-5 texthead-title-center' >The Adventist  Home</h4>
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"20px","paddingBottom":"50px"}}>
                        <div className='mt-3 pl-5 welcome-verse'>
                                <h2 className='text-white text-bold text-xl'>
                                    The Sweetest Type of Heaven—Home should be made all that the word implies. It should be a little heaven upon earth, a place where the affections are cultivated instead of being studiously repressed. Our happiness depends upon this cultivation of love, sympathy, and true courtesy to one another. <u>ADH</u>.
                                </h2>
                        </div>
                        
                    </div>

                </div>
            </Carousel.Item>

            <Carousel.Item >
                <div className="slider">
                    <div className='slider-video'>
                        <video src={file1} autoPlay muted loop />
                    </div>
                    
                    <div className='container mx-auto' style={{"position":"relative","paddingTop":"10px","paddingBottom":"30px"}}>
                        <h4 className='text-left text-warning text-bold ml-3 pl-5 pt-5 texthead-title'>God's Final  Warning ....</h4>
                    </div>
                    <div className="container justify-content-center" style={{"position":"relative","paddingTop":"20px","paddingBottom":"50px"}}>
                        <div className='mt-3 pl-5 welcome-verse'>
                        <h1 className='text-warning' style={{"fontWeight":"700"}} >Revelation 14:6,7</h1>
                        <h2 className='text-white text-bold text-xl'>
                            <a className='link-white' href='https://www.kingjamesbibleonline.org/Revelation-14-6/'>And I saw another angel fly in the midst of heaven, having the everlasting gospel to preach unto them that dwell on the earth, and to every nation, and kindred, and tongue, and people,.</a>
                            <a className='link-white' href='https://www.kingjamesbibleonline.org/Revelation-14-7/'><span className='font-bold-700'>7</span> Saying with a loud voice, Fear God, and give glory to him; for the hour of his judgment is come: and worship him that made heaven, and earth, and the sea, and the fountains of waters.</a>
                        </h2>
                                
                        </div>
                        
                    </div>

                </div>
            </Carousel.Item> */}

    </Carousel>
  );
}

export default LoadWelcome;