
import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';
import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import ReLogin from '../home/ReLogin';
import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';



function EventsPg({loggedname,events}) {

  return (
    <>
        {events && events.map((eventt,key) =>(
            <div className="col-12 col-lg-10 m-1 p-1">
                <div className='row border-info'>
                    <div className='col-12 col-md-6 text-center border-dark bg-white m-0 p-0'>
                        {eventt.image ===null?
                            <div className='bg-indigo events-date border-light events-center'>
                                <p className='events-center events-date-day'>{eventt.fromday}</p>
                                <p className='events-center events-date-month'>{eventt.frommonth}</p>
                            </div>
                            :
                            <div className='bg-indigo border-light '>
                                <p className='p-1 '><span className='events-date-day'>{eventt.fromday}</span> ,<span className='events-date-day'>{eventt.frommonth}</span> </p>
                            </div>
                        }
                        <p className='p-2 text-xl'>{eventt.event}</p>
                        {eventt.link !==null &&
                        <div>
                            <div className='col-12 p-0 bg-opacity-6 events-center'>
                                <a href={eventt.link} target='_blank'>
                                    <button className='btn btn-home'>More Info <span className='fa fa-arrow-right'></span></button>
                                </a>
                            </div>
                        </div>}
                        {eventt.image ===null?'':<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+eventt.image} className='border-none m-0 p-2' width="100%" height="180px"  alt="First Image" />}
                    </div> 

                    <div className='col-12 col-md-6 text-center  bg-opacity-6 text-white mx-auto my-auto'>
                        {eventt.fromdate !== eventt.todate && eventt.todate !==null &&
                            <p className='bg-indigo p-2 border-ok'>From:<span className='p-2'>{eventt.fromdate}</span> To:<span className='p-2'>{eventt.todate}</span> </p>
                        }

                        {eventt.todate === null &&
                            <p className='bg-indigo p-2 border-ok'><span className='p-2'>{eventt.fromdate}</span> </p>
                        }

                        {eventt.fromdate === eventt.todate && 
                            <p className='bg-indigo p-2 border-ok'><span className='p-2'>{eventt.fromdate}</span> </p>
                        }
                        <div className='d-flex justify-content-between'>
                            <div className='m-0 p-0'>
                                <img src={time} className='img-events-venue' alt="Time" />
                            </div>
                            <div className='m-0 p-0 mx-auto my-auto'>
                                <span className='text-sm m-0 p-0'>
                                    {eventt.fromtime} - {eventt.totime}
                                </span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='m-0 p-0'>
                                <img src={venue} className='img-events-venue' alt="Venue" />
                            </div>
                            <div className='m-0 p-0 mx-auto my-auto text-sm'>
                                {eventt.venue}
                            </div>
                        </div>

                        
                        <div>
                            <div className='col-12 p-2 bg-opacity-6 events-center'>
                            <Link to={`/aboutus/event?event=${eventt.event}&id=${eventt.id}`}> <button className='btn btn-home'>View Event <span className='fa fa-arrow-right'></span></button> </Link>
                                {/* <Link to='#'> <button className='btn btn-home'>View Event <span className='fa fa-arrow-right'></span></button> </Link> */}
                            </div>
                        </div>
                    </div> 

                </div>
                
            </div>
        ))}

                
           
        
    </>
  );
}

export default EventsPg;