
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import LoadWelcome from './LoadWelcome';
import { useEffect, useState, useContext } from 'react';

import kingjamesbible from '../../assets/img/kingjamesbible.jpeg';
import cover23a from '../../assets/img/cover23a.jpg';
import tithesandoffering from '../../assets/img/to.jpg';



import LoadWhatsHappening from './LoadWhatsHappening';
import SabbathSchoolAnimated from './SabbathSchoolAnimated';
import TheTruth from './TheTruth';
import Events from './Events';
import TheBelief from './TheBelief';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function Opportunities() {
    document.title="Opportunities | Utawala Satellite";
    const [loadingpage,setLoadingPage]=useState(false);

  return (
    <>
    <HomeNavBar active='opportunities'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="events bg-primary">
            <div className='events-container'>
                <div className="">
                    <div className="pt-4 text-white text-center" >
                        <p className='m-2 p-3 mb-0 pb-0 beliefs-p'>Opportunity Listings</p>
                    </div>
                </div>
                <div className="row bg-primary justify-content-center pt-5">
                    <div className='d-flex flex-column flex-sm-row justify-content-between bg-white mt-2'>
                        <div className='d-flex m-0 p-3 text-muted'>
                            <div className='text-left pl-2' style={{"width":"100%","overflowX":"auto","overflow":"hidden","textOverflow":"ellipsis"}}>
                                <h3>CONSTRUCTION OF CHURCH WALL</h3>
                                <p><small className='p-0 m-0'>Road to Utawala Academy, Nairobi, Kenya</small></p>
                            </div>
                        </div>
                        <div className='p-3'>
                            <Link to='#' className='mr-3'>
                                <button className='btn btn-outline-secondary ml-3 p-3 pl-4 font-bold-700'>View Job</button>
                            </Link>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-sm-row justify-content-between bg-white mt-2'>
                        <div className='d-flex m-0 p-3 text-muted'>
                            <div className='text-left pl-2' style={{"width":"100%","overflowX":"auto","overflow":"hidden","textOverflow":"ellipsis"}}>
                                <h3>SECURITY SERVICES</h3>
                                <p><small className='p-0 m-0'>Embakasi, Nairobi, Kenya</small></p>
                            </div>
                        </div>
                        <div className='p-3'>
                            <Link to='#' className='mr-3'>
                                <button className='btn btn-outline-secondary ml-3 p-3 pl-4 font-bold-700'>View Job</button>
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default Opportunities;