import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadPrograms from '../home/LoadPrograms';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';
import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';
import DashManageMinistry from './DashManageMinistry';

import {LoginContext} from "../contexts/LoginContext";

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function DashMembers(props) {
    document.title="Dashboard The Truths | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [ministries,setMinistries]=useState([]);
    const [addministry, setAddministry]=useState(false);
    const [currentministry,setCurrentMinistry]=useState('');
    const [showmanageministry, setShowManageMinistry] = useState(false);
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

// sermon media mediatype medialength mediasize summary
    const [formdata,setFormData]=useState({
        sermon:'',
        media:'',
        mediatype:'',
        medialength:'',
        summary:[],
        mediasize:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'video/*,audio/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_text===''){
            Swal("Error","Please Fill Summary Paragraph");
        }
        
        else{
            const {verselinks_link,verselinks_text}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text ){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added.");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    // setLinkItems((prevState) => [...prevState,{verselinks_text}]);
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
        
        
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        setVerselinks({
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newSlide= (e)=>{
        e.preventDefault();
        // uploadedvideo uploadedaudio videourllink audiourllink youtubeid
        if(formdata.ministry ===''){
            Swal("Enter Ministry Name","Please Enter Ministry Name","error");
            return;
        }
        

        if((formdata.mediatype === 'uploadedvideo' || formdata.mediatype === 'uploadedmedia') && currentministry==='' && files[0] ===undefined){
            Swal("Upload Ministry Media","Please Upload Required Ministry Media","error");
            return;
        }

        if((formdata.mediatype === 'youtubeid') && formdata.media ===''){
            Swal("Enter Youtube Link","Please Enter Required Youtube Video ID","error");
            return;
        }

        if(linkitems ===''){
            Swal("About Ministry is Missing","Enter About Ministry.","error");
            return;
        }
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")

        const form={
            // (formdata.mediatype === 'videourllink' || formdata.mediatype === 'audiourllink'  || formdata.mediatype === 'youtubeid')
            id:(currentministry!=='')?currentministry.id:'',
            ministry:formdata.ministry,
            mediatype:formdata.mediatype,
            aboutus:linkitems,
            media:(formdata.mediatype === 'youtubeid')?formdata.media:files,
        }
            axios.post('/api/save/ministry',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    loadMinistries();
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadMinistries= ()=>{
        axios.get(`/api/load/ministries`)
        .then(response=>{
            if(response.data.status=== 200){
                setMinistries(response.data.ministry);
            } 
            else if(response.data.status=== 401){
                setMinistries([]) 
                Swal("Error",response.data.message,"error");
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setMinistries([]) 
            }
        })
        .catch((error)=>{
            if(!localStorage.getItem("auth_token_ut")){
                let ex=error['response'].data.message;
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true); 
                        setLoggedToken('');
                        setLoggedName(''); 
                    }  
                    else{ 
                        setLoggedOff(true); 
                        localStorage.removeItem('auth_token_ut');
                        localStorage.removeItem('auth_name_ut');
                        setLoggedToken('');
                        setLoggedName(''); 
                    }              
                }
                else{
                    Swal("Error",""+error,"error");
                    setMinistries([]);
                }
            }
            else{
                Swal("Error",""+error,"error");
            }
            setMinistries([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/ministries`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setMinistries(response.data.ministry);
                    }
                    else if(response.data.status=== 401){
                        Swal("Error1",response.data.message,"error");
                        setMinistries([]);
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setMinistries([]);
                    }
                    else{
                        setMinistries([]);
                    }
                    
                }
            })
            .catch(error=>{

                if(!localStorage.getItem("auth_token_ut")){
                    let ex=error['response'].data.message;
                    if(ex==='Unauthenticated.'){
                        if(!localStorage.getItem("auth_token_ut")){
                            setLoggedOff(true); 
                            setLoggedToken('');
                            setLoggedName(''); 
                        }  
                        else{ 
                            setLoggedOff(true); 
                            localStorage.removeItem('auth_token_ut');
                            localStorage.removeItem('auth_name_ut');
                            setLoggedToken('');
                            setLoggedName(''); 
                        }              
                    }
                    else{
                        Swal("Error",""+error,"error");
                        setMinistries([]);
                    }
                }
                else{
                    Swal("Error",""+error,"error");
                }

                setMinistries([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateMinistry = (ministry) => {
        // setShowAddTenant(true);
        setAddministry(true)
        setCurrentMinistry(ministry)
        if(ministry.aboutus===null){
            setLinkItems('');
        }
        else{
            setLinkItems(JSON.parse(ministry.aboutus))
        }

        setFormData({
            ministry:ministry.ministry,
            mediatype:ministry.mediatype,
            media:ministry.media,
            aboutus:ministry.aboutus,
            error_list:[],
        })
        
    };

    const manageMinistry = (ministry) => {
        setShowManageMinistry(true);
        setCurrentMinistry(ministry)
    };

    const setAddministrys = (ev)=> {
        setAddministry(!addministry);
        setCurrentMinistry('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            ministry:'',
            media:'',
            mediatype:'',
            aboutus:[],
            error_list:[],
        })
        setFiles([])
    }

    const deleteMinistry= (truth)=>{
        const form={
            id:truth.id,
            filename:truth.media,
        }

        let title='Delete '+truth.ministry;
        let text="This will remove this Ministry from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/ministry',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){ 
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadMinistries();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>{showmanageministry?'Manage '+currentministry.ministry:'Manage Ministries'}
                            {showmanageministry && <button type="button" onClick={()=>{setShowManageMinistry(false)}} className="btn btn-outline-danger border-info ml-4 p-1 pl-2 pr-2">
                                <span className='fa fa-times'></span> Close 
                            </button>}
                        </h1>
                    </div>

                    {showmanageministry?
                        <DashManageMinistry currentministry={currentministry} setCurrentMinistry={setCurrentMinistry} loadMinistries={loadMinistries} />
                    :
                    <div className="card-body">
                        {addministry?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                Please fill details below to Create or Update Ministry
                                <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-outline-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-list'></span> Show Ministries 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    The Ministries List
                                    <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-outline-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Ministry 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addministry?
                            <form className='justify-content-center' onSubmit={newSlide}>
                                {!loading && loadingresok==='' && 
                                    <>
                                {/* // sermon media mediatype medialength mediasize summary */}
                                    {/* {console.log(files[0])} */}
                                        <div className="form-group row">
                                            <label for="ministry" className="col-md-3 col-form-label text-md-right">Ministry Name<sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="ministry" type="text" className="form-control" name="ministry" placeholder="Ministry Name" value={formdata.ministry} required onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.ministry && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.ministry}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        {currentministry ==='' &&
                                            <div className="form-group row">
                                                <label for="mediatype" className="col-md-3 col-form-label text-md-right">Media  <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-8">
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                            <input type="radio" required checked={formdata.mediatype==="uploadedvideo"?"true":""} onChange={handleInputChange} className="" name="mediatype" value="uploadedvideo" autoComplete="mediatype"/> Upload Video
                                                    </label>
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                            <input type="radio" required checked={formdata.mediatype==="uploadedimage"?"true":""} onChange={handleInputChange} className="" name="mediatype" value="uploadedimage" autoComplete="mediatype"/> Upload Image
                                                    </label>
                                                    
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.mediatype==="youtubeid"?"true":""} onChange={handleInputChange} className="" name="mediatype" value="youtubeid" autoComplete="mediatype"/> Youtube Video ID
                                                    </label>
                                                    
                                                    {formdata.error_list && formdata.error_list.mediatype && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.mediatype}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        
                                        

                                        {formdata.mediatype ==='youtubeid' &&
                                            <>
                                                <div className="form-group row">
                                                    <label for="media" className="col-md-3 col-form-label text-md-right">Video ID <sub className='text-red text-sm'>*</sub></label>
                                                    <div className="col-md-7">
                                                        <input id="media" type="text" className="form-control" name="media" placeholder="You tube video ID" value={formdata.media} required onChange={handleInputChange} autoFocus/>
                                                        {formdata.error_list && formdata.error_list.media && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.media}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="" className="col-md-3 col-form-label text-md-right">Video Preview </label>
                                                    <div className='col-md-7'>
                                                        <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                            {formdata.videoid !=='' &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <iframe
                                                                        width="100%"
                                                                        src={`https://www.youtube.com/embed/`+formdata.videoid}
                                                                        frameborder="0"
                                                                        allow=""
                                                                        allowFullScreen
                                                                        title=" The Truths Youtube Videos"
                                                                    />
                                                                    
                                                                </div>
                                                            }   
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        
                                        {(formdata.mediatype ==='uploadedvideo' || formdata.mediatype==='uploadedimage') &&

                                            <div className="form-group row">
                                                <label for="" className="col-md-3 col-form-label text-md-right">{formdata.mediatype ==='uploadedvideo'?'Video Media':'Image Media'} <sub className='text-red text-sm'>*</sub> </label>
                                                <div className='col-md-7'>
                                                    <div className='dropArea' {...getRootProps({style})}>
                                                    {formdata.mediatype ==='uploadedvideo'?
                                                        <input {...getInputProps()} type='file' accept='video/*' />
                                                        :
                                                        <input {...getInputProps()} type='file' accept='image/*' />
                                                    }
                                                        <p>
                                                            Click or Drop {formdata.mediatype ==='uploadedvideo'?'Ministry Video Media':'Ministry Image Media'} Here
                                                        </p>
                                                        
                                                    </div>

                                                    {formdata.error_list && formdata.error_list.media && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.media}</strong>
                                                        </span>
                                                    }

                                                    <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                        <div>
                                                            {files && files.map((file,key) =>(
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Selected Media</p>
                                                                    {formdata.mediatype ==='uploadedvideo'?
                                                                        <video src={file.preview} width="90%" controls />
                                                                        :
                                                                        <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                                    }
                                                                </div>
                                                            ))}                                 
                                                        </div>

                                                        <div>
                                                            {currentministry !=='' &&
                                                            <>
                                                                {currentministry.media !==null &&
                                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                        <p className='m-0 p-0'>Uploaded Media</p>
                                                                        {currentministry.mediatype ==='uploadedvideo'?
                                                                            <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+currentministry.media} width="90%" controls loop />
                                                                            :
                                                                            
                                                                            <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+currentministry.media} width="90%" />
                                                                        }
                                                                    </div>
                                                                }
                                                            </>
                                                                
                                                            }                                 
                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        }
                                        
                                        
                                        <div className="form-group row">
                                            <label for="aboutus" className="col-md-3 col-form-label text-md-right">About Ministry <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7 p-1">
                                                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                                                    <textarea id="verselinks_text" className="form-control m-1" name="verselinks_text" placeholder="About Ministry Paragraph" value={verselinks.verselinks_text} onChange={handleLinksInputChange} autoFocus rows="3"></textarea>
                                                    <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={saveLink}>
                                                        {currentlinkitems!==''?'Update':'Add'}
                                                    </button>
                                                    {currentlinkitems!=='' &&
                                                        <button type="button" className="btn btn-outline-success border-info m-1 pl-2 pr-2" onClick={newLink}>
                                                            {currentlinkitems!==''?'New':''}
                                                        </button>
                                                    }

                                                    
                                                    {formdata.error_list && formdata.error_list.aboutus && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.aboutus}</strong>
                                                        </span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    {linkitems && linkitems.map((link,key) => (
                                                        <div className='d-flex flex-row justify-content-between border-bottom-top' key={key}>
                                                            <div>
                                                                ({key+1}.) : {link.verselinks_text}
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn btn-info p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{updateLink(link)}}>
                                                                    <span className='fa fa-edit'></span>
                                                                </button>
                                                                <button type="button" className="btn btn-danger p-0 m-0 pl-1 pr-1 ml-3" onClick={()=>{removeLink(link)}}>
                                                                    <span className='fa fa-times'></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                        </div>


                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>

                                        <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                        <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                        <br/>
                                        <Spinner
                                            as="span"
                                            variant='info'
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentministry!==''?'Updating Ministry':'Saving Ministry'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentministry!==''?'Update Ministry':'Save Ministry'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center p-0 pt-2">
                                {ministries && ministries.map((truth,key) => (
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <div className="bg-indigo texts-black text-center m-1 bg-opacity border-white-b">
                                            <div className='text-center m-0 p-1'>
                                            {/* title media type verse versequote verselinks trailing alignment */}
                                                <h4 className='font-bold-700'>
                                                   <span className='text-left text-warning font-bold-700 text-xl'></span> {truth.ministry}
                                                </h4>
                                                {/* uploadedvideo uploadedaudio videourllink audiourllink youtubeid */}
                                                
                                                
                                                {truth.aboutus !==null?
                                                    <div className="border-bottom-top text-left m-0 p-0">
                                                        About Ministry::
                                                        <div>
                                                            {truth.aboutus !==null  && JSON.parse(truth.aboutus).map((versel) => (
                                                                <p className='m-0 p-1'> {versel.verselinks_text} </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                :""}
                                                {(truth.mediatype ==='uploadedvideo' || truth.mediatype ==='uploadedimage' ) &&
                                                    <div className='m-0 p-1 border-bottom events-div-h'>
                                                        {truth.mediatype ==='uploadedvideo'?
                                                            <video src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+truth.media} width="90%" controls loop />
                                                            :
                                                            <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/ministry/'+truth.media} width="90%" />
                                                        }
                                                    </div>
                                                }

                                                {truth.mediatype ==='youtubeid' &&
                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                        <iframe
                                                            width="100%"
                                                            src={`https://www.youtube.com/embed/`+truth.media}
                                                            frameborder="0"
                                                            allow=""
                                                            allowFullScreen
                                                            title=" The Sermon Youtube Videos"
                                                        />
                                                        
                                                    </div>
                                                }

                                                {deleteMinistry && updateMinistry &&
                                                <div className='m-0 p-0 text-muted my-auto'>
                                                    <div className='bg-light p-3 mx-auto text-center'>
                                                        <div>

                                                            <button type="button" onClick={()=>{manageMinistry(truth)}} className="btn btn-success m-1 p-1">
                                                                <span className='fa fa-info-circle'></span> Manage
                                                            </button>
                                                            <button type="button" onClick={()=>{updateMinistry(truth)}} className="btn btn-primary m-1 p-1">
                                                                <span className='fa fa-edit'></span> Edit
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteMinistry(truth)}} className="btn btn-danger m-1 p-1">
                                                                <span className='fa fa-trash'></span> Del
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                            </div>   
                                        </div>
                                    </div>
                                ))}
                                </div>  

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashMembers;