import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

function PasswordReset() {
    document.title="Reset Password";

    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');
    const [codesent,setCodeSent]= useState(false)
    const [accountreset,setAccountReset]= useState(false)

    const [formdata,setFormData]=useState({
        email:'',
        code:'',
        password:'',
        confirmpassword:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const authUser= (e)=>{
        e.preventDefault();
        
        if((formdata.email).trim() ===''){
            Swal("Email Address is Required","Please Enter Your Email Address","error");
            return;
        }
        setCodeSent(false);
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/forgotPassword',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setCodeSent(true);
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});

                }
                else if(response.data.status=== 401){
                    setCodeSent(false)
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setAccountReset(false)
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setCodeSent(false)
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const resetPassword= (e)=>{
        e.preventDefault();
        if((formdata.email).trim() ===''){
            Swal("Email Address is Required","Please Enter Your Email Address","error");
            return;
        }

        if(isNaN(formdata.code)){
            Swal("Code Must be a Numeric Value","Please Enter Numeric digits for Reset Code","error");
            return;
        }

        if(((formdata.code).trim()).length !== 6){
            Swal("Reset Code Must be 6 Digits","Please Enter six Reset Code digits","error");
            return;
        }

        if((formdata.password).trim() ===''){
            Swal("New Password is Required","Please Enter New Password","error");
            return;
        }

        if((formdata.confirmpassword).trim() ===''){
            Swal("Confirm New Password is Required","Please Enter Confirm New Password","error");
            return;
        }

        if((formdata.password).trim() !== (formdata.confirmpassword).trim()){
            Swal("Password Must Match","Please Enter Matching password","error");
            return;
        }

        setCodeSent(true);
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
            password:formdata.password,
            code:formdata.code,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/resetPassword',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setAccountReset(true);
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});

                }
                else if(response.data.status=== 401){
                    if(response.data.message ==='Reset Code Expired.'){
                        setCodeSent(false);
                        setAccountReset(false);
                    }
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    if(response.data.message ==='Reset Code Expired.'){
                        setCodeSent(false);
                        setAccountReset(false);
                    }
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(" ds"+error)
            }
            
            setLoadingResOk("")
            setLoading(false);
        })

    }

    
  return (
    <>
    <HomeNavBar active='login'/>
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div class="container mt-3">
            <div class="row justify-content-center mt-3">
                <div class="col-md-8 mt-3">
                    <div class="card border-white-b">
                        <div class="card-header bg-indigo text-center text-lg p-0 m-0">
                            <h1 style={{"textAlign": "center"}}>Reset Password</h1>
                        </div>

                        <div class="card-body">
                        
                            <form className='justify-content-center' onSubmit={resetPassword}>
                                {!loading && !accountreset &&
                                    <>
                                    <p className='border-bottom text-center p-2 pb-3 font-bold-700'>Please Enter your account Email address to reset your password here</p>
                                        {!codesent &&<div class="form-group row">
                                            <label for="username" class="col-md-4 col-form-label text-md-right">Email Address</label>
                                            <div class="col-md-6 ">
                                                <input id="Username" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }

                                            </div>
                                        </div>}

                                        {codesent && loadingres !=='Reset Code Expired.' &&

                                            <>
                                                {codesent && 
                                                <div class="form-group row">
                                                    <div class="col-md-2"></div>
                                                    <div class="col-md-8">
                                                        <p className='text-success text-center elevation-2 p-3 border-ok'>Please Enter Code Sent to your email. Also specify new Password Details.</p>
                                                    </div>
                                                </div>
                                                }
                                                <div class="form-group row">
                                                    <label for="code" class="col-md-4 col-form-label text-md-right">Reset Code</label>

                                                    <div class="col-md-6">
                                                        <input id="code" type="text" className="form-control @error('code') is-invalid @enderror" name="code" placeholder="Six Digit Code" value={formdata.code} onChange={handleInputChange} required/>
                                                        {formdata.error_list && formdata.error_list.code && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.code}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="password" class="col-md-4 col-form-label text-md-right">New Password</label>

                                                    <div class="col-md-6">
                                                        <input id="password" type="password" className="form-control @error('password') is-invalid @enderror" name="password" placeholder="New Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                                        {formdata.error_list && formdata.error_list.password && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.password}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="confirmpassword" class="col-md-4 col-form-label text-md-right">Confirm New Password</label>

                                                    <div class="col-md-6">
                                                        <input id="confirmpassword" type="password" className="form-control @error('confirmpassword') is-invalid @enderror" name="confirmpassword" placeholder="Confirm New Password" value={formdata.confirmpassword} onChange={handleInputChange} required/>
                                                        
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </>
                                }

                                {loadingres!=='' && !accountreset &&
                                    <div className="col-md-12 elevation-0 mt-2 mb-2 p-2 text-center border-none">
                                        <span className="help-block text-danger">
                                            <strong>{loadingres!=='' && loadingres}</strong>
                                        </span>
                                    </div>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                    </div>
                                }

                                

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>{codesent?'Changing Password...':'Sending Reset Code...'}</span>
                                            
                                            
                                    </div>
                                }
                                    

                                {/* {!loading && loadingresok ==='' && !accountreset &&
                                    <div className="row text-center m-0 mb-3 p-0">
                                        <div className="col-12 m-0 p-0">
                                            <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                {codesent?'Set New Password':'Send Reset Link'}
                                            </button>
                                        </div>
                                    </div>
                                } */}

                                {!loading && (loadingresok !=='' || loadingres!=='') && !accountreset &&
                                <div className='m-0 p-0 text-muted mx-auto'>
                                    <div className='text-center p-1'>
                                        <div>
                                            {codesent &&
                                                <button type="submit" className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                    Set New Password
                                                </button>
                                            }

                                            <button type="button" onClick={(e)=>{authUser(e)}} className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                Resend Reset Link
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                

                                    // <div className="row text-center m-0 mb-3 p-0">
                                        
                                    //     <div className="col-12 m-0 p-0">
                                    //         <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                    //             {codesent?'Set New Password':'Send Reset Link'}
                                    //         </button>
                                    //     </div>
                                    // </div>
                                }

                                {!loading && loadingres ==='' && !codesent && 
                                    <div className="row text-center m-0 mb-3 p-0">
                                        <div className="col-12 m-0 p-0">
                                            <button type="button" onClick={(e)=>{authUser(e)}} className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                Send Reset Link
                                            </button>
                                        </div>
                                    </div>
                                    
                                }

                                {!loading && loadingresok ==='' && codesent && 
                                    <div className="row text-center m-0 mb-3 p-0">
                                        <div className="col-12 m-0 p-0">
                                            <button type="submit" className="btn btn-outline-primary border-info m-1 pl-4 pr-4">
                                                Set New Password
                                            </button>
                                        </div>
                                    </div>
                                }

                                {!loading && loadingresok ==='' && !accountreset &&
                                    <div class="form-group mb-0 pt-3">
                                        <div class="d-flex justify-content-center">
                                            <span className='p-1 pr-0'>Remembered password?,</span>
                                            <Link className="btn btn-link" to="/login">
                                                Login Instead
                                            </Link>
                                        </div>
                                        
                                    </div>
                                }

                                {!loading && loadingresok !=='' && accountreset &&
                                    <div class="form-group mb-0 pt-3">
                                        <div class="d-flex justify-content-center">
                                            <Link className="btn btn-link" to="/login">
                                                Click Here to Login
                                            </Link>
                                        </div>
                                        
                                    </div>
                                }


                                        

                                        

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
        
    </main>
    {/* <HomeFooter /> */}
      
    </>
  );
}

export default PasswordReset;