import Button from 'react-bootstrap/Button';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import Swal from 'sweetalert';
import { useEffect, useState, useContext } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {LoginContext} from "../contexts/LoginContext";

function ReVerifyEmail({setEmailNotVerified,emailnotverified, setLoggedOff,loggedoff}) {

    document.title="Login";
    
    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');


    const [formdata,setFormData]=useState({
        email:'',
        password:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const [authname,setAuthName]=useState('');
    
    useEffect(()=>{
        if(localStorage.getItem("auth_token_ut")){
            setAuthName('')
            // setRedirect(true);
            // setUrl('/dashboard');
        }
        else{
            setAuthName(localStorage.getItem("auth_name_ut"))
        }
      },[authname])
      
    // if(!redirect){
    //     localStorage.removeItem('auth_token');
    //     localStorage.removeItem('auth_name');
    // }

    const resetLogin= ()=>{
        localStorage.removeItem('auth_token_ut');
        localStorage.removeItem('auth_name_ut');
        setLoggedToken('');
        setLoggedName(''); 
        setEmailNotVerified(false)
        
    }

    const authUser= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
       

        Swal("Please Wait","Resending Email Verification Link....");
            axios.post('/api/resend')
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    Swal("Success",response.data.message,"success");
                }
                else if(response.data.status=== 400){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setEmailNotVerified(false)
                    Swal("Success",response.data.message,"success");
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    Swal("Failed",response.data.message,"error");
                }
                else{
                    Swal("Try again",response.data.message);
                    setLoadingRes("")
                    setLoadingResOk("")
                }
                setLoading(false);
            })
            .catch((error)=>{
                if(error['response'].data.message==='Unauthenticated.'){
                    Swal("Logged Out","Please Login Again....");
                    setLoggedOff(true);    
                    setEmailNotVerified(false);
                }
                else{
                    Swal("Could Not Send Link",""+error,"error");
                }
                
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })

    }

    
  return (
    <>
    {/* <HomeNavBar active='login'/> */}
    <main className='paddingtop' style={{"minHeight": "calc(100vh - 3rem)"}}>
        <div class="">
            {/* <div class="row justify-content-center mt-3">
                <div class="col-md-8 mt-3"> */}
            <div className="d-flex justify-content-center">
                <div className="mt-0 p-3">
                    <div className="card border-none m-2 p-2 ">
                        
                        <div class="card-header bg-indigo">
                            <h4 className='text-center'>Your Account has not been Verified.</h4>
                        </div>
                        <h4 className='text-center m-3 p-3'>Before proceeding, please check your email for a verification link</h4>
                        <div className="card-body row border-none m-2 p-2" >
                            <form onSubmit={authUser}>
                                {!loading && 
                                    <>
                                        <p className='text-lg p-3 text-center'>If you did not receive the email?</p>
                                    </>
                                }

                                <div className="form-group row mb-0 justify-content-center">
                                    
                                    {loadingresok!=='' && 
                                        <div className="col-md-10 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                                
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>Signing In...</span>
                                                
                                                
                                             
                                        </div>
                                    }

                                    {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 mb-3 p-2 text-center">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }

                                    {!loading && 
                                        <div className="row text-center m-0 p-0">
                                            
                                            <div className="col-12 m-0 p-0">
                                                <button type="submit" className="btn btn-outline-info">
                                                    click here to request another <i className='fa fa-chevron-right'></i>
                                                </button>
                                            </div>

                                            <div class="d-flex justify-content-center mt-4">
                                                <button className="btn btn-outline-success" type="button" onClick={()=>{resetLogin()}} >
                                                    <span className="fa fa-chevron-left "> Try Login Again</span>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    
                                    
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </main>
    
      
    </>
  );
}

export default ReVerifyEmail;