import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState,useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';

import time from '../../assets/img/time.png';
import venue from '../../assets/img/venue.png';
import tendaysofprayer from '../../assets/img/tendaysofplayers.jpg';
import ReLogin from '../home/ReLogin';

import {LoginContext} from "../contexts/LoginContext";
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}


function Dashevent(props) {
    document.title="Dashboard Events | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);
    const [loadingpage,setLoadingPage]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [events,setEvents]=useState([]);
    const [addevent, setAddevent]=useState(false);
    const [currentevent,setCurrentEvent]=useState('');


    const [formdata,setFormData]=useState({
        event:'',
        image:'',
        link:'',
        venue:'',
        fromdate:'',
        todate:'',
        fromtime:'',
        totime:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newEvent= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            id:(currentevent!=='')?currentevent.id:'',
            files:files,
            event:formdata.event,
            link:formdata.link,
            venue:formdata.venue,
            fromdate:formdata.fromdate,
            totime:formdata.totime,
            todate:formdata.todate,
            fromtime:formdata.fromtime,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/save/event',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    loadEvents();
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                console.log(error)
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            if(""+error==="AxiosError: Network Error"){
                setLoadingRes("Oops!!.\n We are not able to Contact the server at this point. Please try again later.")
            }
            else{
                setLoadingRes(""+error)
            }
            
            setFormData({...formdata,error_list:[]});
            setLoadingResOk("")
            setLoading(false);
        })

    }

    const loadEvents= ()=>{
        setLoadingPage(true)
        axios.get(`/api/load/events`)
        .then(response=>{
            if(response.data.status=== 200){
                setEvents(response.data.events);
                setLoadingPage(false)
            } 
            else if(response.data.status=== 401){
                setEvents([]) 
                Swal("Error",response.data.message,"error");
                setLoadingPage(false)
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setEvents([]) 
                setLoadingPage(false)
            }
        })
        .catch((error)=>{
            if(error.message==="Request failed with status code 401"){
                setLoggedOff(true);                    
            }
            else if(error.message==="Request failed with status code 500"){
                setLoggedOff(true);                    
            }
            else{
                Swal("Error",""+error,"error");
            }
            setLoadingPage(false)
            setEvents([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        
        setLoadingPage(true)
        const getEvents = async (e) => { 
            
            await axios.get(`/api/load/events`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setEvents(response.data.events);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error1",response.data.message,"error");
                        setEvents([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setEvents([]);
                        setLoadingPage(false)
                    }
                    else{
                        setEvents([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{
                if(error.message==="Request failed with status code 401"){
                    setLoggedOff(true);                    
                }
                else if(error.message==="Request failed with status code 500"){
                    setLoggedOff(true);                    
                }
                else{
                    Swal("Error",""+error,"error");
                }
                // console.log(error.message)
                        setLoadingPage(false)
                setEvents([]);
            })
        };
        getEvents();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'image/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );


    const updateEvent = (event) => {
        // setShowAddTenant(true);
        setAddevent(true)
        setCurrentEvent(event)

        setFormData({
            event:event.event,
            image:event.photo,
            link:event.link,
            venue:event.venue,
            fromdate:event.fromdate,
            todate:event.todate,
            fromtime:event.fromtime,
            totime:event.totime,
            error_list:[],
        })
        
    };

    const setAddevents = (ev)=> {
        setAddevent(!addevent);
        setCurrentEvent('')
        setLoadingResOk("")
        setLoadingRes("")
        setFormData({
            event:'',
            image:'',
            link:'',
            venue:'',
            fromdate:'',
            todate:'',
            fromtime:'',
            totime:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteEvent= (event)=>{
        const form={
            id:event.id,
        }

        let title='Delete '+event.event;
        let text="This will remove this Event from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/delete/event',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadEvents();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }


    const updateKeyEvent= (event)=>{
        const form={
            id:event.id,
        }

        let title='Make '+event.event+' Key event?';
        let text="This will Change this Event to Main Event.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Deleting....","Please Wait");
                axios.post('/api/update/event/makemain',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        setLoggedOff(true);    
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadEvents();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                <Link to="/dashboard" className="m-0 p-2"> <i className='fa fa-chevron-left'></i> Home</Link>
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>Manage Events</h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <div className="card-body">
                        {addevent?
                            <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                <button type="button" onClick={()=>{setAddevents()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                    <span className='fa fa-table'></span> Show Events 
                                </button>
                            </p>
                            :
                            <>
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddevents()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-plus-circle'></span> New Event 
                                    </button>
                                </p>
                                
                            </>
                        }
                        {addevent?
                            <form className='justify-content-center' onSubmit={newEvent}>
                                {!loading && 
                                    <>
                                        <div className="form-group row">
                                            <label for="event" className="col-md-3 col-form-label text-md-right">Event <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="event" type="text" className="form-control" name="event" placeholder="Event Name" value={formdata.event} onChange={handleInputChange} required autoComplete="event" autoFocus/>
                                                {formdata.error_list && formdata.error_list.event && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.event}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-md-3 col-form-label text-md-right">Date </label>
                                            <div className='col-md-7'>
                                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                    <div className=" ">
                                                        <input id="fromdate" type="date" className="form-control" name="fromdate" placeholder="Last Name" value={formdata.fromdate} onChange={handleInputChange} required autoFocus/>
                                                        {formdata.error_list && formdata.error_list.fromdate && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.fromdate}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className='p-2'>
                                                        <p>TO:</p>
                                                    </div>
                                                    <div className=" ">
                                                        <input id="todate" type="date" className="form-control" name="todate" placeholder="todate" value={formdata.todate} onChange={handleInputChange} autoFocus/>
                                                        {formdata.error_list && formdata.error_list.todate && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.todate}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="fromtime" className="col-md-3 col-form-label text-md-right">Time <sub className='text-red text-sm'>*</sub></label>
                                            <div className='col-md-7'>
                                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                    <div className=" ">
                                                        <input id="fromtime" type="time" className="form-control" name="fromtime" placeholder="from time" value={formdata.fromtime} onChange={handleInputChange} required autoFocus/>
                                                        {formdata.error_list && formdata.error_list.fromtime && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.fromtime}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className='p-2'>
                                                        <p>TO:</p>
                                                    </div>
                                                    <div className=" ">
                                                        <input id="totime" type="time" className="form-control" name="totime" placeholder="totime" value={formdata.totime} onChange={handleInputChange} required autoComplete="totime" autoFocus/>
                                                        {formdata.error_list && formdata.error_list.totime && 
                                                            <span className="help-block text-danger">
                                                                <strong>{formdata.error_list.totime}</strong>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    
                                        <div className="form-group row">
                                            <label for="link" className="col-md-3 col-form-label text-md-right">Link</label>
                                            <div className="col-md-7">
                                                <input id="link" type="text" className="form-control" name="link" placeholder="Full Link Address" value={formdata.link} onChange={handleInputChange} autoFocus/>
                                                {formdata.error_list && formdata.error_list.link && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.link}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="venue" className="col-md-3 col-form-label text-md-right">Venue <sub className='text-red text-sm'>*</sub></label>
                                            <div className="col-md-7">
                                                <input id="venue" type="text" className="form-control" name="venue" placeholder="Venue" value={formdata.venue} onChange={handleInputChange} required autoFocus/>
                                                {formdata.error_list && formdata.error_list.venue && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.venue}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="image" className="col-md-3 col-form-label text-md-right">Photo</label>

                                            <div className="col-md-7">

                                                <div className='dropArea' {...getRootProps({style})}>
                                                    <input {...getInputProps()} type='file' accept='image/*' />
                                                    <p>
                                                        Click or Drop Event Image Here
                                                    </p>
                                                    
                                                </div>
                                                    

                                                <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                    <div>
                                                        {files && files.map((file,key) =>(
                                                            <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                <p className='m-0 p-0'>Selected Image</p>
                                                                <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                            </div>
                                                        ))}                                 
                                                    </div>

                                                    <div>
                                                        {currentevent !=='' &&
                                                        <>
                                                            {currentevent.image !==null &&
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Uploaded Image</p>
                                                                    <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+currentevent.image} alt='Does Not Have Image' className='' width="100%" />
                                                                </div>
                                                            }
                                                        </>
                                                            
                                                        }                                 
                                                    </div>

                                                </div>


                                                {/* <input id="image" type="file"  className="form-control" name="image" placeholder="image" value={formdata.image} onChange={handleInputChange} />
                                                {formdata.error_list && formdata.error_list.image && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.image}</strong>
                                                    </span>
                                                } */}
                                            </div>
                                        </div>
                                    </>
                                }

                                {loadingresok!=='' && 
                                    <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                        <span className="help-block text-success">
                                            <strong>{loadingresok!=='' && loadingresok}</strong>
                                        </span>
                                        <button type="button" onClick={()=>{setAddevents()}} className="btn ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-chevron-left'></span> Back 
                                        </button>
                                    </div>
                                }

                                {loading && 
                                    <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentevent!==''?'Updating event':'Creating Event'}...</span>
                                            
                                    </div>
                                }

                                        {loadingres!=='' && 
                                            <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-none">
                                                <span className="help-block text-danger">
                                                    <strong>{loadingres!=='' && loadingres}</strong>
                                                </span>
                                            </div>
                                        }
                                        {!loading && loadingresok ==='' && 
                                            <div className="form-group d-flex mb-0">
                                                <div className="mx-auto">
                                                    <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                        {currentevent!==''?'Update Event':'Save Event'}
                                                    </button>
                                                </div>
                                            </div>
                                        }


                            </form>
                            :
                            <>
                                <div className="row justify-content-center">
                                    <div className="texts-black text-center p-1 pb-3" >
                                        <div className='row p-0 border-white-b m-3'>
                                            <div className="col-12 col-md-3 m-0 p-0 events-div-h ">
                                                <a className='link-image' href='https://tendaysofprayer.org/' target='_blank'><img src={tendaysofprayer} className='border-white-b' width="100%"  alt="First Image" /></a>
                                            </div>
                                            <div className="col-12 col-md-9 m-0 p-0">
                                                <div className='row text-center bg-gray text-white border-white-b mx-auto my-auto events-div-h'>
                                                    <div className='col-4 bg-opacity-6 events-center'>
                                                        <a href='https://tendaysofprayer.org/' target='_blank'>
                                                            <button className='btn btn-home'>JOIN US <span className='fa fa-arrow-right'></span></button>
                                                        </a>
                                                    </div>
                                                    <div className='col-4 row events-center'>
                                                        <div className='col-3 text-left'>
                                                            <img src={time} className='img-events-time' alt="First Image" />
                                                        </div>
                                                        <div className='col-9 m-0 p-0 text-left'>
                                                            <span className='text-sm m-0 p-0'>
                                                                5:00PM - 
                                                                <br/>
                                                                6:00PM
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-4 row events-center border-left'>
                                                        <div className='col-3 text-left'>
                                                            <img src={venue} className='img-events-time' alt="First Image" />
                                                        </div>
                                                        <div className='col-9 m-0 p-0 text-left text-sm'>
                                                            UTAWALA SATELLITE SDA CHURCH
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {events && events.map((eventt,key) =>(
                                            <div className="border-white-b bg-gray bg-opacity-6 texts-black text-center m-1">
                                                <div className='p-2 m-0'>
                                                    <div className='d-flex flex-column flex-sm-row justify-content-between'>
                                                        <div className="m-0 p-0 forum-div-h">
                                                            {eventt.image ===null?
                                                                <div className='bg-indigo events-date border-light events-center' >
                                                                    <p className='events-center events-date-day'>{eventt.fromday}</p>
                                                                    <p className='events-center events-date-month'>{eventt.frommonth}</p>
                                                                </div>
                                                                :
                                                                <div className='bg-indigo border-light p-1' style={{"position":"relative","marginBottom":"-35px","zIndex":"999999"}}>
                                                                    <p className='events-center' >{eventt.fromday} ,{eventt.frommonth} </p>
                                                                </div>
                                                            }
                                                            <div className='slider-img'>
                                                                {eventt.image !==null?<img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/events/'+eventt.image} className='border-white-b' width="100%"  alt="First Image" />:''}
                                                            </div>
                                                        </div>
                                                        <div className='m-0 p-0 text-muted my-auto'>
                                                            <div className='text-left p-2'>
                                                               
                                                                <p className='p-0 pt-2 m-0 muted flex-nowrap'> {eventt.event}</p>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className='m-0 p-0 text-muted my-auto' style={{"zIndex":"999999"}}>
                                                            <div className='d-flex flex-row text-left pl-2'>
                                                                <div className=''>
                                                                    <img src={time} className='img-circle' alt="First Image" />
                                                                </div>
                                                                <div className='m-0 p-0 pt-2'>
                                                                    <span className='text-sm m-0 p-0'>
                                                                        {eventt.fromtime} - 
                                                                        {/* <br/> */}
                                                                        {eventt.totime}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className='d-flex flex-row text-left pl-2'>
                                                                <div className='text-left'>
                                                                    <img src={venue} className='img-circle' alt="First Image" />
                                                                </div>
                                                                <div className='m-0 p-0 pt-2 text-left text-sm'>
                                                                    {eventt.venue}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='m-0 p-0 text-muted my-auto' style={{"zIndex":"999999"}}>
                                                            <div className='text-left pl-2'>
                                                            <div>
                                                                {eventt.status !== 'Main' &&
                                                                <button type="button" onClick={()=>{updateKeyEvent(eventt)}} className="btn btn-success m-1 p-1">
                                                                    <span className='fa fa-check'></span> Make Key Event
                                                                </button>}
                                                                <button type="button" onClick={()=>{updateEvent(eventt)}} className="btn btn-primary m-1 p-1">
                                                                    <span className='fa fa-edit'></span> Edit
                                                                </button>
                                                                <button type="button" onClick={()=>{deleteEvent(eventt)}} className="btn btn-danger m-1 p-1">
                                                                    <span className='fa fa-trash'></span> Del
                                                                </button>
                                                            </div>
                                                            </div>
                                                        </div>
                    
                                                    </div>
                                                </div>
                                            </div> 
                                             
                                        ))}

                                        

                                        
                                                
                                    </div>
                                </div>

                            </>
                        }

                        
                    </div>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default Dashevent;