import { Link, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useContext, useCallback } from 'react';

import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import userlogo from '../../assets/img/avatar.png';

import ReLogin from '../home/ReLogin';
import Programs from '../home/Programs';
import DashManageUser from './DashManageUser';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner'

import {LoginContext} from "../contexts/LoginContext";

const baseStyle={
    flex:1,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"6px",
    borderWidth:2,
    borderRadius:2,
    border:"4px dotted #007bff",
    backgroundColor:"#ffffff",
    color:"#bdbdbd",
    outline:"none",
    transition:"border .24s ease-in-out"
}

const activeStyle={
    border:"2px dotted #6f42c1"
}

const acceptStyle={
    border:"2px dotted #00e676"
}

const rejectStyle={
    border:"2px dotted #ff1744"
}



function DashUsers(props) {
    document.title="Dashboard The Truths | Utawala Satellite";

    const {loggedname, setLoggedName, loggedtoken, setLoggedToken} =useContext(LoginContext);
    const [loading,setLoading]=useState(false);
    
    const [loadingpage,setLoadingPage]=useState(false);
    const [authname,setAuthName]=useState('');
    const [redirect,setRedirect]=useState(false);
    const [url,setUrl]=useState('');
    const [loggedoff,setLoggedOff]=useState(false);

    const [preview, setPreview] = useState(false);
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [users,setUsers]=useState([]);
    const [addministry, setAddministry]=useState(false);
    const [currentuser,setCurrentUser]=useState('');
    const [showmanageuser, setShowManageUser] = useState(false);
    const [linkitems,setLinkItems]= useState([]);
    const [currentlinkitems,setCurrentLinkItems]= useState('');

// sermon media mediatype medialength mediasize summary
    const [formdata,setFormData]=useState({
        fname:'',
        lname:'',
        userrole:'',
        email:'',
        image:'',
        phone:'',
        error_list:[],
    });

    const [verselinks,setVerselinks]=useState({
        verselinks_text:'',
    })

    const [files,setFiles] =useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    }= useDropzone({ 
        accept:'video/*,audio/*',
        onDrop:(acceptedFiles) =>{
            setFiles(
                acceptedFiles.map(file => Object.assign(file, {
                    preview:URL.createObjectURL(file)
                }))
            )
            // console.log(files)
        }
    });

    const style=useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle:{}),
            ...(isDragAccept ? acceptStyle:{}),
            ...(isDragReject ? rejectStyle:{}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleLinksInputChange=(e)=>{
        e.persist();
        setVerselinks({...verselinks,[e.target.name]:e.target.value})
        setLoadingRes('');
    }
    

    const saveLink= (e)=>{
        if(verselinks.verselinks_text===''){
            Swal("Error","Please Fill Summary Paragraph");
        }
        
        else{
            const {verselinks_link,verselinks_text}=verselinks;
            let saved=true;
            if(currentlinkitems===''){
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text ){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added.");
                        return;
                    }
                });
    
                if(saved){
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                }
            }
            else{
                linkitems && linkitems.map((lk) => {
                    if(verselinks.verselinks_text===lk.verselinks_text){
                        saved=false;
                        Swal("Error","Sermon Paragraph already Added");
                        return;
                    }
                });
    
                if(saved){
                    const arr = [];
                    linkitems && linkitems.map((lk) => {
                        if(currentlinkitems===lk){
                        }
                        else{
                            arr.push(lk)
                        }
                    });
                    setLinkItems(arr)

                    // setLinkItems((prevState) => [...prevState,{verselinks_text}]);
                    setLinkItems((prevState) =>  [...prevState,{verselinks_text}]);
                    
                    setVerselinks({
                        verselinks_text:'',
                    });
                    setCurrentLinkItems('');
                }
            }
            
        }
        
        
    }

    const removeLink= (link)=>{
        const arr = [];
        linkitems && linkitems.map((lk) => {
            if(link===lk){
            }
            else{
                arr.push(lk)
            }
        });
        setLinkItems(arr)
        setVerselinks({
            verselinks_text:'',
        });
        setCurrentLinkItems('');
    }

    const updateLink= (link)=>{
        setCurrentLinkItems(link);
        setVerselinks({
            verselinks_text:link.verselinks_text,
        });
    }

    const newLink= ()=>{
        setCurrentLinkItems('');
        setVerselinks({
            verselinks_text:'',
        });
    }
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
        setLoadingRes('');
    }

    const newUser= (e)=>{
        e.preventDefault();
        if(formdata.userrole ===''){
            Swal("Enter User Role","Please Enter Users User Role","error");
            return;
        }
        if(formdata.fname ===''){
            Swal("Enter First Name","Please Enter Users First Name","error");
            return;
        }
        if(formdata.lname ===''){
            Swal("Enter Last Name","Please Enter Users Last Name","error");
            return;
        }
        if(formdata.username ===''){
            Swal("Enter Username Name","Please Enter Users Username Name","error");
            return;
        }

        if(formdata.email ===''){
            Swal("Enter Email Address","Please Enter Users Email Address","error");
            return;
        }

        if((formdata.phone !=='' && (formdata.phone).trim() !=='' && isNaN(formdata.phone) && ((formdata.phone).trim()).length !== 12) ){
            Swal("Correct Phone Number","Please Specify Phone Number like 254712345678","error");
            return;
        }
        
        
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")

        const form={
            id:(currentuser!=='')?currentuser.id:'',
            fname:formdata.fname,
            lname:formdata.lname,
            userrole:formdata.userrole,
            email:formdata.email,
            phone:formdata.phone,
            image:files,
        }
            axios.post('/api/save/user',form,{
                headers:{
                    'content-type':'multipart/form-data'
                }
            })
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk(response.data.message)
                    setLoadingRes("")
                    loadUsers();
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                    Swal("Error","Please Update the Following errors","error");
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setFormData({...formdata,error_list:[]});
                setLoadingResOk("")
                setLoading(false);
            })
    }

    const loadUsers= ()=>{
        axios.get(`/api/load/users`)
        .then(response=>{
            if(response.data.status=== 200){
                setUsers(response.data.users);
            } 
            else if(response.data.status=== 401){
                setUsers([]) 
                Swal("Error",response.data.message,"error");
            }
            
            else if(response.data.status=== 500){
                Swal("Error",response.data.message,"error");
                setUsers([]) 
            }
        })
        .catch((error)=>{
            if(!localStorage.getItem("auth_token_ut")){
                let ex=error['response'].data.message;
                if(ex==='Unauthenticated.'){
                    if(!localStorage.getItem("auth_token_ut")){
                        setLoggedOff(true); 
                        setLoggedToken('');
                        setLoggedName(''); 
                    }  
                    else{ 
                        setLoggedOff(true); 
                        localStorage.removeItem('auth_token_ut');
                        localStorage.removeItem('auth_name_ut');
                        setLoggedToken('');
                        setLoggedName(''); 
                    }              
                }
                else{
                    Swal("Error",""+error,"error");
                    setUsers([]);
                }
            }
            else{
                Swal("Error",""+error,"error");
            }
            setUsers([]) 
        })

    }


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true)
        const getPrograms = async (e) => { 
            
            await axios.get(`/api/load/users`)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setUsers(response.data.users);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 401){
                        Swal("Error1",response.data.message,"error");
                        setUsers([]);
                        setLoadingPage(false)
                    }
                    else if(response.data.status=== 500){
                        Swal("Error2",response.data.message,"error");
                        setUsers([]);
                        setLoadingPage(false)
                    }
                    else{
                        setUsers([]);
                        setLoadingPage(false)
                    }
                    
                }
            })
            .catch(error=>{

                if(!localStorage.getItem("auth_token_ut")){
                    let ex=error['response'].data.message;
                    if(ex==='Unauthenticated.'){
                        if(!localStorage.getItem("auth_token_ut")){
                            setLoggedOff(true); 
                            setLoggedToken('');
                            setLoggedName(''); 
                        }  
                        else{ 
                            setLoggedOff(true); 
                            localStorage.removeItem('auth_token_ut');
                            localStorage.removeItem('auth_name_ut');
                            setLoggedToken('');
                            setLoggedName(''); 
                        }              
                    }
                    else{
                        Swal("Error",""+error,"error");
                        setUsers([]);
                    }
                }
                else{
                    Swal("Error",""+error,"error");
                }
                
                setLoadingPage(false)

                setUsers([]);
            })
        };
        getPrograms();

        return ()=>{
            doneloading=false;
            
        }
    },[loggedoff])



    const updateMinistry = (user) => {
        // setShowAddTenant(true);
        setAddministry(true)
        setCurrentUser(user)
        setFormData({
            fname:user.fname,
            image:user.image,
            lname:user.lname,
            email:user.email,
            phone:user.phone,
            userrole:user.userrole,
            error_list:[],
        })
        
    };

    const manageUser = (user) => {
        setShowManageUser(true);
        setCurrentUser(user)
    };

    const setAddministrys = (ev)=> {
        setAddministry(!addministry);
        setCurrentUser('')
        setLoadingResOk("")
        setLoadingRes("")
        setLinkItems('')
        setFormData({
            fname:'',
            lname:'',
            userrole:'',
            email:'',
            image:'',
            phone:'',
            error_list:[],
        })
        setFiles([])
    }

    const deleteUser= (truth,action)=>{
        const form={
            id:truth.id,
            action:action,
            filename:truth.image,
        }

        let title=action+" "+truth.fname+" "+truth.lname;
        let text="This will "+action+" this User from the system.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Taking Action....","Please Wait");
                axios.post('/api/delete/user',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){ 
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadUsers();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    const changeUserRole= (truth,action)=>{
        const form={
            id:truth.id,
            action:action,
        }

        let title="Change User to "+action+" "+truth.fname+" "+truth.lname;
        let text="This will Change this User Role from: "+truth.userrole+" to: "+action+" .";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                Swal("Updating....","Please Wait");
                axios.post('/api/update/user',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){ 
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    loadUsers();
                    setLoading(false);
                })
                .catch((error)=>{
                    if(error.message==="Request failed with status code 401"){
                        setLoggedOff(true);                    
                    }
                    else if(error.message==="Request failed with status code 500"){
                        setLoggedOff(true);                    
                    }
                    else{
                        Swal("Error",""+error,"error");
                    }
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

    


  return (
    <>
        {loggedoff ? 
            <ReLogin setLoggedOff={setLoggedOff} loggedoff={loggedoff} />
        :
        <div className="">
            <div className="m-3">
                
                <div className="">
                    <div className="p-3 text-center text-lg">
                        <h1 className='text-center'>{showmanageuser?'Manage '+currentuser.fname+' '+currentuser.lname:'Manage Users'}
                            {showmanageuser && <button type="button" onClick={()=>{setShowManageUser(false)}} className="btn btn-outline-danger border-info ml-4 p-1 pl-2 pr-2">
                                <span className='fa fa-times'></span> Close 
                            </button>}
                        </h1>
                    </div>
                    {loadingpage?
                    <LoadingDetailsSpinner />
                    :
                    <>
                    {showmanageuser?
                        <DashManageUser currentuser={currentuser} setCurrentUser={setCurrentUser} loadUsers={loadUsers} />
                    :
                        <div className="card-body">
                            {addministry?
                                <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                    <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                        <span className='fa fa-table'></span> Show Users 
                                    </button>
                                </p>
                                :
                                <>
                                    <p className='border-bottom text-center p-2 pb-3 font-bold-700'>
                                        <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-primary border-info ml-4 p-1 pl-2 pr-2">
                                            <span className='fa fa-plus-circle'></span> New User 
                                        </button>
                                    </p>
                                    
                                </>
                            }
                            {addministry?
                                <form className='justify-content-center' onSubmit={newUser}>
                                    {!loading && loadingresok==='' && 
                                        <>
                                            <div className="form-group row">
                                                <label for="userrole" className="col-md-4 col-form-label text-md-right">User Role  <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-6 d-flex justify-content-center">
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                            <input type="radio" required checked={formdata.userrole==="Admin"?"true":""} onChange={handleInputChange} className="" name="userrole" value="Admin" autoComplete="userrole"/> Admin
                                                    </label>
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                            <input type="radio" required checked={formdata.userrole==="Member"?"true":""} onChange={handleInputChange} className="" name="userrole" value="Member" autoComplete="userrole"/> Member
                                                    </label>
                                                    
                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.userrole==="User"?"true":""} onChange={handleInputChange} className="" name="userrole" value="User" autoComplete="userrole"/> User
                                                    </label>

                                                    <label className='m-0 p-1 text-left' style={{"cursor": "pointer","float":"left"}}>
                                                        <input type="radio" required checked={formdata.userrole==="Guest"?"true":""} onChange={handleInputChange} className="" name="userrole" value="Guest" autoComplete="userrole"/> Guest
                                                    </label>
                                                    
                                                    {formdata.error_list && formdata.error_list.userrole && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.userrole}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="Fname" className="col-md-4 col-form-label text-md-right">First Name <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-6 ">
                                                    <input id="Fname" type="text" className="form-control" name="fname" placeholder="First Name" value={formdata.fname} onChange={handleInputChange} required autoComplete="Fname" autoFocus/>
                                                    {formdata.error_list && formdata.error_list.fname && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.fname}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="Lname" className="col-md-4 col-form-label text-md-right">Last Name <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-6 ">
                                                    <input id="Lname" type="text" className="form-control" name="lname" placeholder="Last Name" value={formdata.lname} onChange={handleInputChange} required autoComplete="Lname" autoFocus/>
                                                    {formdata.error_list && formdata.error_list.lname && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.lname}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            {/* 
                                            <div className="form-group row">
                                                <label for="Username" className="col-md-4 col-form-label text-md-right">Username</label>
                                                <div className="col-md-6 ">
                                                    <input id="Username" type="text" className="form-control" name="username" placeholder="Username" value={formdata.username} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                    {formdata.error_list && formdata.error_list.username && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.username}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div> */}
                                        
                                            <div className="form-group row">
                                                <label for="Email" className="col-md-4 col-form-label text-md-right">Email Address <sub className='text-red text-sm'>*</sub></label>
                                                <div className="col-md-6 ">
                                                    <input id="Email" type="email" className="form-control" name="email" placeholder="Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Email" autoFocus/>
                                                    {formdata.error_list && formdata.error_list.email && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.email}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="phone" className="col-md-4 col-form-label text-md-right">Phone Number <sub className='text-red text-sm'>*</sub> </label>
                                                <div className="col-md-6 ">
                                                    <input id="phone" type="text" className="form-control" name="phone" placeholder="254700000000" value={formdata.phone} onChange={handleInputChange} required autoComplete="Phone" autoFocus/>
                                                    {formdata.error_list && formdata.error_list.phone && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.phone}</strong>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label for="image" className="col-md-4 col-form-label text-md-right">Photo/ Image </label>

                                                <div className="col-md-6">

                                                    <div className='dropArea' {...getRootProps({style})}>
                                                        <input {...getInputProps()} type='file' accept='image/*' />
                                                        <p>
                                                            Click or Drop Photo / Image Here
                                                        </p>
                                                        
                                                    </div>

                                                    {formdata.error_list && formdata.error_list.image && 
                                                        <span className="help-block text-danger">
                                                            <strong>{formdata.error_list.image}</strong>
                                                        </span>
                                                    }
                                                        

                                                    <div className='m-1 p-1 d-flex flex-column flex-md-row justify-content-between'>
                                                        <div>
                                                            {files && files.map((file,key) =>(
                                                                <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                    <p className='m-0 p-0'>Selected Image</p>
                                                                    <img src={file.preview} alt='No Photo Selected for Upload' className='' width="100%" />
                                                                </div>
                                                            ))}                                 
                                                        </div>

                                                        <div>
                                                            {currentuser !=='' &&
                                                            <>
                                                                {currentuser.image !==null &&
                                                                    <div className='m-0 p-1 border-bottom forum-div-h'>
                                                                        <p className='m-0 p-0'>Uploaded Image</p>
                                                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/users/'+currentuser.image} alt='Does Not Have Image' className='' width="100%" />
                                                                    </div>
                                                                }
                                                            </>
                                                                
                                                            }                                 
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }

                                    {loadingresok!=='' && 
                                        <div className="col-md-12 elevation-0 mb-2 p-2 text-center">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>

                                            <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-block ml-4 p-1 pl-2 pr-2">
                                                <span className='fa fa-chevron-left'></span> Back 
                                            </button>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                            <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                            <br/>
                                            <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='text-info p-4' style={{"padding": "10px","display":"inline-block"}}>{currentuser!==''?'Updating User':'Creating User'}...</span>
                                                
                                        </div>
                                    }

                                    {loadingres!=='' && 
                                        <div className="col-md-12 elevation-0 mt-2 p-2 text-center border-none">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }
                                    {!loading && loadingresok ==='' && 
                                        <div className="form-group d-flex mb-0">
                                            <div className="mx-auto">
                                                <button type="button" onClick={()=>{setAddministrys()}} className="btn btn-defualt ml-4 p-1 pl-2 pr-2">
                                                    <span className='fa fa-chevron-left'></span> Back 
                                                </button>
                                                <button type="submit" className="btn btn-outline-primary border-info pl-4 pr-4">
                                                    {currentuser!==''?'Update User':'Save User'}
                                                </button>
                                            </div>
                                        </div>
                                    }


                                </form>
                                :
                                <>
                                    <div className="row justify-content-center p-0 pt-0">
                                    {users && users.map((verse,key) => (
                                        <div className="col-12 col-lg-4 col-md-6 m-0 p-0">
                                            <div className='bg-white border-ok m-2 mt-4 p-0' style={{"maxHeight":"280px","overflowY":"auto"}}>
                                                <div className='d-flex justify-content-center border-bottom p-2'>
                                                    <div className='text-center bg-light p-0 border-white-b' style={{"minWidth":"80px","height":"65px"}}>
                                                            {verse.image !== null?
                                                                <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/users/'+verse.image} width="30px" height="30px" className="user-circle m-0 p-0 mx-auto"  alt="Profile Image" />
                                                            :
                                                                <img src={userlogo} width="30px" height="30px" className="user-circle m-0 p-0 mx-auto"  alt="Avatar Image" />
                                                            }
                                                            <div className="text-center m-0 p-0">
                                                                <p className='m-0 p-1'>
                                                                    {/* <span className='text-muted text-lg'>{verse.userrole}</span>  */}
                                                                    {verse.user_online?<span className='text-success'>{verse.userrole} <span className='online'>.</span></span> :<span className='text-muted'>{verse.userrole} <span className='offline'>.</span></span> }
                                                                </p>
                                                            </div>
                                                    </div>
                                                    <div className='text-center mx-auto my-auto'>
                                                            <h2 className='text-center'>{verse.fname} {verse.lname}</h2>
                                                                <span className="text-muted">{verse.status}</span>
                                                                {verse.status === 'Active' && 
                                                                <button type="button" onClick={()=>{deleteUser(verse,'Disable')}} className="btn btn-outline-orange btn-sm border-orange m-1 p-1 pl-2 pr-2">
                                                                    <span className='fa fa-times'></span> Disable
                                                                </button>}
                                                                {verse.status === 'Disabled' && 
                                                                <button type="button" onClick={()=>{deleteUser(verse,'Activate')}} className="btn btn-outline-success btn-sm border-ok m-1 p-1 pl-2 pr-2">
                                                                    <span className='fa fa-check'></span> Activate
                                                                </button>}
                                                            
                                                    </div>
                                                
                                                </div>

                                                

                                                <div className="text-center m-1 p-1">
                                                    <p className='d-flex flex-column flex-sm-row justify-content-between m-1 p-1'>
                                                        <b>Email:</b> <span className="text-muted">{verse.email}</span>
                                                    </p>
                                                </div>
                                                {/* 
                                                {verse.phone !== null && 
                                                <div className="text-center m-1 p-1">
                                                    <p className='d-flex justify-content-between m-1 p-1'>
                                                        <b>Phone:</b> <span className="text-muted">{verse.phone}</span>
                                                    </p>
                                                </div>} */}

                                                {/* <div className="text-center m-1 p-1">
                                                    <p className='d-flex justify-content-between m-1 p-1'>
                                                        <b>Last Login:</b> 
                                                            <span className="text-muted">
                                                                {verse.current_login_at !== null ?verse.current_login_at:'Not Yet'}
                                                            </span>
                                                    </p>
                                                </div> */}

                                                {/* <div className="text-center m-1 p-1">
                                                    <p className='d-flex justify-content-between m-1 p-1'>
                                                        <b>Last Activity:</b>
                                                            <span className="text-muted">
                                                                {verse.current_activity_at !== null ?verse.current_activity_at:'Not Yet'}
                                                            </span>
                                                    </p>
                                                </div> */}

                                                {verse.email_verified_at !== null &&
                                                <div className="text-center m-1 p-1">
                                                    <p className='d-flex justify-content-between m-1 p-1'>
                                                        <b>Active Since:</b>
                                                            <span className="text-muted">
                                                                {verse.email_verified_at !== null ?<span className="text-muted">{verse.email_verified_at !== null ?verse.email_verified_at:'Not Yet'}</span>:''}
                                                            </span>
                                                    </p>
                                                </div>}


                                                <div className='m-1 mb-3 p-2 border-top text-muted my-auto'>
                                                    <div className='mx-auto text-center'>
                                                        <div>
                                                            {verse.status === 'Active' && 
                                                            <>
                                                                {verse.userrole === 'Admin' && 
                                                                    <>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Member')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Member
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'User')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make User
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Guest')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Guest
                                                                        </button>
                                                                    </>
                                                                }

                                                                {verse.userrole === 'Member' && 
                                                                    <>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Admin')}} className="btn btn-outline-success btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Admin
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'User')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make User
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Guest')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Guest
                                                                        </button>
                                                                    </>
                                                                }

                                                                {verse.userrole === 'User' && 
                                                                    <>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Admin')}} className="btn btn-outline-success btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Admin
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Member')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Member
                                                                        </button>
                                                                        
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Guest')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Guest
                                                                        </button>
                                                                    </>
                                                                }

                                                                {verse.userrole === 'Guest' && 
                                                                    <>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Admin')}} className="btn btn-outline-success btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Admin
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'Member')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make Member
                                                                        </button>
                                                                        <button type="button" onClick={()=>{changeUserRole(verse,'User')}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                            <span className='fa fa-check'></span> Make User
                                                                        </button>
                                                                    </>
                                                                }
                                                                </>
                                                            }

                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='m-1 mb-3 p-2 border-top text-muted my-auto'>
                                                    <div className='mx-auto text-center'>
                                                        <div>
                                                            <button type="button" onClick={()=>{manageUser(verse)}} className="btn btn-outline-info btn-sm border-none m-1 p-1">
                                                                <span className='fa fa-info-circle'></span> View
                                                            </button>
                                                            <button type="button" onClick={()=>{updateMinistry(verse)}} className="btn btn-outline-primary btn-sm border-none m-1 p-1">
                                                                <span className='fa fa-edit'></span> Edit
                                                            </button>
                                                            
                                                            <button type="button" onClick={()=>{deleteUser(verse,'Delete')}} className="btn btn-outline-danger btn-sm border-none m-1 p-1">
                                                                <span className='fa fa-trash'></span> Delete
                                                            </button>

                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                

                                                
                                            </div>
                                        </div>

                                        
                                    ))}
                                    </div>  

                                </>
                            }

                            
                        </div>
                    }
                    </>
                    }
                </div>


            </div>
        </div>
        }
    </>
  );
}


export default DashUsers;