import { useEffect, useState,useContext } from 'react';
import {LoginContext} from "../contexts/LoginContext";
import axios from 'axios';

import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';


import discover_sabbath_truth from '../../assets/img/discover_sabbath-truth.png';


import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';



function BibleStudyBibles() {
    document.title="Bible Study Bibles | Utawala Satellite";

    const [ministries,setMinistries]=useState([]);
    const [bibles,setBibles]=useState([]);
    const [books,setBooks]=useState([]);

    const [verseoftheday,setVerseOfTheDay]=useState([]);
    const [nextafterverseoftheday,setNextAfterVerse]=useState([]);
    const [hopeinGodsword,setHopeinGodsWord]=useState([]);
    const [featuredwebsites,setFeaturedWebsites]=useState([]);
    const [sabbathtruth,setSabbathTruth]=useState([]);
    const [others,setOthers]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);


    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getMins = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/biblestudysections`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            if(response.data.verseoftheday ===null){
                                setVerseOfTheDay('')
                            }
                            else{
                                setVerseOfTheDay(response.data.verseoftheday);
                            }
                            if(response.data.sabbathtruth ===null){
                                setSabbathTruth('')
                            }
                            else{
                                setSabbathTruth(response.data.sabbathtruth);
                            }
                            setNextAfterVerse([response.data.nextafterverseoftheday]);
                            setHopeinGodsWord([response.data.hopeinGodsword]);
                            setFeaturedWebsites([response.data.featuredwebsites]);
                            setOthers([response.data.others]);
                                setLoadingPage(false);
                        }
                        else if(response.data.status=== 401){
                            setVerseOfTheDay('');
                            setNextAfterVerse([]);
                            setHopeinGodsWord([]);
                            setFeaturedWebsites([]);
                            setSabbathTruth('');
                            setOthers([]);
                                setLoadingPage(false);
                        }
                        else if(response.data.status=== 500){
                            setVerseOfTheDay('');
                            setNextAfterVerse([]);
                            setHopeinGodsWord([]);
                            setFeaturedWebsites([]);
                            setSabbathTruth('');
                            setOthers([]);
                                setLoadingPage(false);
                        }
                        else{
                            setVerseOfTheDay('');
                            setNextAfterVerse([]);
                            setHopeinGodsWord([]);
                            setFeaturedWebsites([]);
                            setSabbathTruth('');
                            setOthers([]);
                                setLoadingPage(false);
                        }
                        
                    }
                })
                .catch(error=>{
                    setVerseOfTheDay('');
                    setNextAfterVerse([]);
                    setHopeinGodsWord([]);
                    setFeaturedWebsites([]);
                    setSabbathTruth('');
                    setOthers([]);
                                setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setVerseOfTheDay('');
                setNextAfterVerse([]);
                setHopeinGodsWord([]);
                setFeaturedWebsites([]);
                setSabbathTruth('');
                setOthers([]);
                                setLoadingPage(false);
            })
        };
        getMins();

        return ()=>{
            doneloading=false;
            
        }
    },[])

    // useEffect(()=>{
    //     let doneloading=true;
        
    //     const getMins = async (e) => { 
    //         axios.get('/sanctum/csrf-cookie').then(res =>{
    //             axios.get(`/api/get/load/biblestudybooks`)
    //             .then(response=>{
    //                 if (doneloading) {
    //                     if(response.data.status=== 200){
    //                         setBooks(response.data.biblestudybooks);
    //                     }
    //                     else{
    //                         setBooks([]);
    //                     }
                        
    //                 }
    //             })
    //             .catch(error=>{
    //                 setBooks([]);
    //             })
    //         })
    //         .catch((error)=>{
    //             setBooks([]);
    //         })
    //     };
    //     getMins();

    //     return ()=>{
    //         doneloading=false;
            
    //     }
    // },[])

  return (
    <>
    <HomeNavBar active='biblestudy'/>
    <main className='paddingtop'  style={{"minHeight": "calc(100vh - 3rem)"}}>

        <div className="">
            <div className='bg-black pb-5'>
                <div className="">
                    {(verseoftheday !== '') &&
                        <div className="text-white text-center" >
                            <div className="bg-success p-1" style={{"opacity":".6","marginBottom":'-80px'}}>
                                <h1 className='m-0 p-1' style={{"fontWeight":"700"}}>Bible Study</h1>
                            </div>
                            <div className="bible-cover">
                                {(verseoftheday !== '') &&
                                    <>
                                        <div className='slider-img'>
                                            <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verseoftheday['image']} width="100%" />
                                        </div>
                                    </>
                                }
                            </div>

                            <div className='container mt-3 p-2'>
                                <div className="row justify-content-between m-0 p-2 pt-0" style={{"opacity":".6"}}>
                                    <div className="col-6 col-md-4 m-0 p-0">
                                        <div className='bg-white border-none m-1 mt-4 p-2'>
                                            <h2 className='text-black text-center text-bold text-xl'>{verseoftheday['title']}</h2>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 m-0 p-0">
                                        <div className='bg-white border-none m-1 mt-4 p-2'>
                                            <h2 className='text-black text-center text-bold text-xl'>VERSE OF THE DAY</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='container mt-3 p-2'>
                                <div className='bg-white border-none m-1 mt-4 p-2'>
                                    <h2 className='text-success text-center text-bold ministryheader-title-center'>{verseoftheday['verse']}</h2>
                                </div>
                            </div>

                            <div className="row justify-content-center p-5 pt-3">
                                <div className='col-10 col-lg-6 bg-primary m-2 p-3 border-info'>
                                    <h3 className='text-center font-bold-700 text-white'>
                                        {verseoftheday['verse']}
                                    </h3>
                                    <p className='text-center'>
                                        {verseoftheday['versequote']}
                                    </p>
                                </div>
                            </div>

                        </div>
                    }
                </div>

                <div className='container mt-0 p-2'>
                    <div className='m-0 p-0'>
                        <div className="row justify-content-between p-2 pt-0">
                            {nextafterverseoftheday && nextafterverseoftheday[0] && nextafterverseoftheday[0].map((verse,key) =>(
                                <div className="col-6 col-lg-5 m-0 p-0">
                                    <div className='text-center border-dark m-3 p-0'>
                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='border-none m-0 p-0' width="100%" height="180px"  alt="First Image" />
                                        <a href={verse.verse} className='p-0 m-0' target='_blank'><p className='p-2 text-xl'>{verse.title}</p></a>
                                    </div>  
                                </div>
                            ))}
                        </div> 
                    </div>
                </div>

                <div className="bg-secondary p-2 pt-4 pb-4">
                    <div className="text-white text-center" >
                        <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>Finding Hope In Gods Word</h1>
                    </div>
                </div>

                <div className='container mt-0 p-2'>
                    <div className='m-0 p-0'>
                        <div className="row justify-content-center p-2 pt-0">
                            {hopeinGodsword && hopeinGodsword[0] && hopeinGodsword[0].map((verse,key) =>(
                                <div className="col-9 col-md-5 col-lg-4 m-0 p-0">
                                    <div className='text-center border-white-b m-3 p-0'>
                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='border-none m-0 p-0' width="100%" height="180px"  alt="First Image" />
                                        <a href={verse.verse} className='p-0 m-0' target='_blank'><p className='p-2 text-xl'>{verse.title}</p></a>
                                    </div>  
                                </div>
                            ))}
                        </div> 
                    </div>
                </div>
                
            </div>
            
            <div className="m-0 mt-5 p-2 pt-4 pb-4">
                <div className="text-black text-center" >
                    <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>Featured Websites</h1>
                </div>
            </div>

                
            <div className='mt-0 p-2'>
                <div className='m-0 p-0'>
                    <div className="row justify-content-center p-2 pt-0">
                        {featuredwebsites && featuredwebsites[0] && featuredwebsites[0].map((verse,key) =>(
                            <div className="col-6 col-md-4 col-lg-3 m-0 p-0">
                                <div className='text-center border-white-b m-3 p-0'>
                                    <h1 className='text-black border-bottom'>{verse.title}</h1>
                                    <div className='m-1 p-2'>
                                        <div className='pb-2'>{verse.versequote}</div>
                                        <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+verse.image} className='border-white-b m-0 p-0' width="100%" height="180px"  alt="First Image" />
                                        <a href={verse.verse} className='p-0 m-0' target='_blank'>
                                            <button className='btn btn-block border-none btn-outline-info m-1 p-2'>Visit Now <span className='fa fa-arrow-right'></span></button>
                                        </a>
                                    </div>
                                    
                                </div>  
                            </div>
                        ))}
                    </div> 
                </div>
            </div>


            {sabbathtruth !== '' &&
                <div className="m-0 mt-5 p-2 pt-4 pb-4">
                    <div className="text-black text-center" >
                        <h1 className='m-2 p-3' style={{"fontWeight":"700"}}>{sabbathtruth['title']}</h1>
                    </div>
                </div>
            }

            {sabbathtruth !== '' &&
                <div className='mt-0 p-2'>
                    <div className='m-0 p-0'>
                        <div className="row justify-content-center p-2 pt-0">
                            
                                <div className="col-9 m-0 p-0">
                                    <div className='text-center border-none m-3 p-0'>
                                        <div className='m-1 p-2'>
                                            
                                            <a className='link-image' href={sabbathtruth['verse']}  target='_blank'>
                                                {/* <img src={process.env.REACT_APP_BACKEND_API_URL_MEDIA+'/biblestudy/'+sabbathtruth['image']} className='border-dark' width="100%" height="350px"  alt="First Image" /> */}
                                                <img src={discover_sabbath_truth} className='border-dark' width="100%" height="350px"  alt="First Image" />
                                            </a>
                                            <a href={sabbathtruth['verse']} className='p-2 m-2 text-lg' target='_blank'>
                                                <u className='p-3 m-2'> click here to discover <span className='fa fa-arrow-right'></span></u>
                                            </a>
                                        </div>
                                        
                                    </div>  
                                </div>
                        </div> 
                    </div>
                </div>
            }
           
            
        </div>

        

        {/* <Spinner animation="grow" variant="primary" role="status">
            <span className="visually-hidden" >Loading...</span>
        </Spinner> */}
    </main>
    <HomeFooter />
      
    </>
  );
}

export default BibleStudyBibles;