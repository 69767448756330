import eveningcourse from '../../assets/img/evening_course.jpg';
import pathfinderday from '../../assets/img/worldpathfinderday.jpeg';
import campmeeting from '../../assets/img/campmeeting.jpeg';
import amocrudase from '../../assets/img/amocrusade.jpg';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function EventsUpcoming() {
    
  return (
    <>
        <div className="col-md-3 m-0 p-0">
            <div className="bg-white texts-black text-center m-1"  style={{"minHeight":"250px"}}>
                <h1 className='bg-gray text-indigo text-center p-2'>
                    Evening
                    Course
                </h1>
                <div className='text-center m-0 p-3'>
                   <img src={eveningcourse} className='border-white-b' width="100%"  alt="First Image" />
                </div>   
            </div>
        </div>

        <div className="col-md-3 m-0 p-0">
            <div className="bg-white texts-black text-center m-1"  style={{"minHeight":"250px"}}>
                <h1 className='bg-gray text-indigo text-center p-2'>
                    World PathfinderDay
                </h1>
                <div className='text-center m-0 p-3'>
                    <img src={pathfinderday} className='border-white-b' width="100%"  alt="First Image" />
                </div>   
            </div>
        </div>

        <div className="col-md-3 m-0 p-0">
            <div className="bg-white texts-black text-center m-1"  style={{"minHeight":"250px"}}>
                <h1 className='bg-gray text-indigo text-center p-2'>
                    Camp Meeting
                </h1>
                <div className='text-center m-0 p-3'>
                    <img src={campmeeting} className='border-white-b' width="100%"  alt="First Image" />
                </div>  
            </div>
        </div>

        <div className="col-md-3 m-0 p-0">
            <div className="bg-white texts-black text-center m-1"  style={{"minHeight":"250px"}}>
                <h1 className='bg-gray text-indigo text-center p-2'>
                    AMO CRUSDAE
                </h1>
                <div className='text-center m-0 p-3'>
                    <img src={amocrudase} className='border-white-b' width="100%"  alt="First Image" />
                </div>  
            </div>
        </div>

    </>
  );
}

export default EventsUpcoming;