import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useMemo, useState, useCallback } from 'react';
import LoadingDetailsSpinner from '../spinners/LoadingDetailsSpinner';


function LoadWhatsHappening() {

    const [slides,setSlides]=useState([]);
    const [loadingpage,setLoadingPage]=useState(false);

    useEffect(()=>{
        let doneloading=true;
        setLoadingPage(true);
        
        const getSlides = async (e) => { 
            axios.get('/sanctum/csrf-cookie').then(res =>{
                axios.get(`/api/get/load/whathslides`)
                .then(response=>{
                    if (doneloading) {
                        if(response.data.status=== 200){
                            setSlides(response.data.slides);
                                setLoadingPage(false);
                        }
                        else{
                            setSlides([]);
                                setLoadingPage(false);
                        }
                    }
                })
                .catch(error=>{
                    setSlides([]);
                                setLoadingPage(false);
                })
            })
            .catch((error)=>{
                setSlides([]);
                                setLoadingPage(false);
            })
        };
        getSlides();

        return ()=>{
            doneloading=false;
            
        }
    },[])
  return (
    <>
    {loadingpage?
    <LoadingDetailsSpinner />
    :
        <Carousel keyboard={true} indicators={true}>
            {slides && slides.map((slide,key) =>(
                <Carousel.Item >
                    <div className="slider-1">
                    
                        <div className='slider-1-video'>
                            <div className="video-responsive">
                                <iframe
                                    width="853"
                                    src={`https://www.youtube.com/embed/`+slide.videoid}
                                    frameborder="0"
                                    allow=""
                                    allowFullScreen
                                    title="Whats Happening Youtube Videos"
                                />
                            </div>
                        </div>
                        <div className='m-0 p-0'>
                            <div className="text-center " style={{"position":"relative"}}>
                                <div className='bg-light text-black welcome-one m-0 p-0 pt-2 d-flex justify-content-center'>
                                    <p>{slide.title}?</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Carousel.Item>
            ))}
                {/* <Carousel.Item >
                    <div className="slider-1">
                    
                        <div className='slider-1-video'>
                            <div className="video-responsive">
                                <iframe
                                width="853"
                                src={`https://www.youtube.com/embed/wR6-VS2Y_2A`}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                                />
                            </div>
                            
                        </div>
                        <div className='m-0 p-0'>
                            <div className="text-center " style={{"position":"relative"}}>
                                <div className='bg-light text-black welcome-one m-0 p-0 pt-2 d-flex justify-content-center'>
                                    <p>What Happens When You Die?</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Carousel.Item>
                <Carousel.Item >
                    <div className="slider-1">
                        <div className='slider-1-video'>
                            <div className="video-responsive">
                                <iframe
                                src={`https://www.youtube.com/embed/EJDvU44WdE0`}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                                />
                            </div>
                        </div>
                        <div className='m-0 p-0'>
                            <div className="text-center " style={{"position":"relative"}}>
                                <div className='bg-light text-black welcome-one m-0 pt-2 d-flex justify-content-center'>
                                    <p>01 Unlocking the Mysteries of the Apocalypse (1 of 35)</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Carousel.Item>

                <Carousel.Item >
                    <div className="slider-1">
                    
                        <div className='slider-1-video'>
                            <div className="video-responsive">
                            <iframe 
                                width="660" 
                                height="371" 
                                src="https://www.youtube.com/embed/n3AeTsFWtGs" 
                                title="Where is the Adventist Church Headed? - Launch of I Will Go Part 1A" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
                                allowfullscreen>
                            </iframe>
                            </div>
                        </div>
                        <div className='m-0 p-0'>
                            <div className="text-center " style={{"position":"relative"}}>
                                <div className='bg-light text-black welcome-one m-0 p-0 pt-2 d-flex justify-content-center'>
                                    <p>What Happens When You Die?</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Carousel.Item> */}

                
        </Carousel>
    }
    </>
  );
}

export default LoadWhatsHappening;