
import { useState } from 'react';
import PageNotFound from '../home/PageNotFound';
import Dashnotfound from '../admin/Dashnotfound';



function NotFound() {
    var notfoundcmp='';
    if(!localStorage.getItem("auth_token_ut")){
        notfoundcmp=(
            <PageNotFound />
        )
    }
    else{
        notfoundcmp=(
            <Dashnotfound />
        )
    }


  return (
    <>
        {notfoundcmp}
    </>
  );
}

export default NotFound;