import React ,{useState,useEffect, useLayoutEffect} from 'react'
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter , Route, Routes} from 'react-router-dom';

import Home from './components/home/Home';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import 'react-calendar/dist/Calendar.css';

import './assets/plugins/fontawesome-free/css/all.min.css';

import './css/app.css';
import './css/home.css';
import './css/allcss.css';

// import 'react-select/dist/css/react-select.css';


// import './assets/dist/css/adminlte.min.css'
import './js/app.js';



import './App.css';

import {LoginContext} from "./components/contexts/LoginContext.js";

import Beliefs from './components/home/Beliefs';
import VisitUs from './components/home/VisitUs';
import Events from './components/home/Events';
import Login from './components/home/Login';
import PasswordReset from './components/home/PasswordReset';
import axios from 'axios';

import NotFound from './components/routes/NotFound';
import AboutEvents from './components/home/AboutEvents.js';
import Groups from './components/home/Groups.js';
import Members from './components/home/Members.js';
import Forum from './components/home/Forum.js';
import ProgramLists from './components/home/ProgramList.js';
import Opportunities from './components/home/Opportunities.js';
import Blog from './components/home/Blog.js';
import Ministries from './components/home/Ministries.js';
import BibleStudyResources from './components/home/BibleStudyResources.js';
import Sermons from './components/home/Sermons.js';
import SignUp from './components/home/SignUp.js';
import Dashhome from './components/admin/Dashhome.js';
import Dashevent from './components/admin/Dashevent.js';
import Dashwelcome from './components/admin/Dashwelcome.js';
import Dashnotfound from './components/admin/Dashnotfound.js';
import Dashprogram from './components/admin/Dashprogram.js';
import Dashwelcomeslides from './components/admin/Dashwelcomeslides.js';
import Dashwhappeningslides from './components/admin/Dashwhappeningslides.js';
import DashLesson from './components/admin/DashLesson.js';
import DashSabbathSchoolAnimated from './components/admin/DashSabbathSchoolAnimated.js';
import DashTheTruths from './components/admin/DashTheTruths.js';
import DashTithes from './components/admin/DashTithes.js';
import DashVisitUs from './components/admin/DashVisitUs.js';
import DashBelief from './components/admin/DashBelief.js';
import DashBibleStudy from './components/admin/DashBibleStudy.js';
import DashBibleResources from './components/admin/DashBibleResources.js';
import BibleStudyBibles from './components/home/BibleStudyBibles.js';
import DashSermons from './components/admin/DashSermons.js';
import DashMinistry from './components/admin/DashMinistry.js';
import VerifyEmail from './components/home/VerifyEmail.js';
import DashChangePassword from './components/admin/DashChangePassword.js';
import DashUsers from './components/admin/DashUsers.js';
import DashMembers from './components/admin/DashMembers.js';
import EmailSetup from './components/home/EmailSetup.js';
import AboutEvent from './components/home/AboutEvent.js';
import DashProfile from './components/admin/DashProfile.js';
import ConnectionManager from './components/home/ConnectionManager.js';
import DashContactus from './components/admin/DashContactus.js';
import DashOpportunity from './components/admin/DashOpportunity.js';






  axios.defaults.baseURL=process.env.REACT_APP_BACKEND_API_URL;
  axios.defaults.headers.post['Content-Type']='application/json';
  axios.defaults.headers.post['Accept']='application/json';
  // axios.defaults.headers.post['content-type']='multipart/form-data';
  axios.defaults.withCredentials=true;

  axios.interceptors.request.use(function (config){
    const token=localStorage.getItem('auth_token_ut');
    config.headers.Authorization=token ? `Bearer ${token}` : ``;
    return config;
  });
  
function App() {
  const [loggedname, setLoggedName] =useState(localStorage.getItem('auth_name_ut'));
  const [loggedtoken, setLoggedToken] =useState(localStorage.getItem('auth_token_ut'));
  const [loggedrole, setLoggedRole] =useState(localStorage.getItem('auth_role_ut'));
  const [loggedverified,setLoggedVerified]=useState(false);
  const [spinning,setSpinning]=useState(true)

  

  useLayoutEffect(()=>{
    setLoggedName(localStorage.getItem('auth_name_ut'));
    setLoggedToken(localStorage.getItem('auth_token_ut'));
    setLoggedRole(localStorage.getItem('auth_role_ut'));
    setTimeout(()=>setSpinning(false))
  },[])



  return (
    <LoginContext.Provider value={{loggedname, setLoggedName,loggedtoken, setLoggedToken, loggedverified, setLoggedVerified, loggedrole, setLoggedRole}} >
      {spinning=== false ?(
        <BrowserRouter>
          <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/beliefs' element={<Beliefs />} />
                {/* <Route path='/aboutus' element={<HomeAboutus />} /> */}
                <Route path='/aboutus/visitus' element={<VisitUs />} />
                <Route path='/aboutus/events' element={<AboutEvents />} />
                <Route path='/aboutus/event' element={<AboutEvent />} />
                <Route path='/aboutus/programlist' element={<ProgramLists />} />
                <Route path='/aboutus/groups' element={<Groups />} />
                <Route path='/aboutus/forum' element={<Forum />} />
                <Route path='/aboutus/members' element={<Members />} />

                <Route path='/ministries/' element={<Ministries />} />

                <Route path='/biblestudy/resources' element={<BibleStudyResources />} />
                <Route path='/biblestudy/sermons' element={<Sermons />} />
                <Route path='/biblestudy/bibles' element={<BibleStudyBibles />} />

                <Route path='/blog' element={<Blog />} />
                <Route path='/opportunities' element={<Opportunities />} />

                <Route path='/connection' element={<ConnectionManager />} />


              

                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/password/request' element={<PasswordReset />} />
                <Route path='/email/verify/:id/:token' element={<VerifyEmail />} />
                <Route path='/email/setup/:id/:token' element={<EmailSetup />} />

                
                <Route path='/dashboard' element={<Dashhome />} >
                  <Route path='' element={<Dashwelcome />} />
                  <Route path='events' element={<Dashevent />} />
                  <Route path='programs' element={<Dashprogram />} />
                  <Route path='homeslides' element={<Dashwelcomeslides />} />
                  <Route path='whatshappeningslides' element={<Dashwhappeningslides />} />
                  <Route path='lessons' element={<DashLesson />} />
                  <Route path='sabathschool' element={<DashSabbathSchoolAnimated />} />
                  <Route path='thetruth' element={<DashTheTruths />} />
                  <Route path='tithes' element={<DashTithes />} />
                  <Route path='visitus' element={<DashVisitUs />} />
                  <Route path='beliefs' element={<DashBelief />} />
                  <Route path='bibleresources' element={<DashBibleResources />} />
                  <Route path='biblestudy' element={<DashBibleStudy />} />
                  <Route path='sermons' element={<DashSermons />} />
                  <Route path='ministries' element={<DashMinistry />} />
                  <Route path='profile' element={<DashProfile />} />
                  <Route path='profile/change-password' element={<DashChangePassword />} />
                  <Route path='users' element={<DashUsers />} />
                  <Route path='members' element={<DashMembers/>} />
                  <Route path='contactus' element={<DashContactus/>} />
                  <Route path='opportunities' element={<DashOpportunity/>} />
                  

                  <Route path='*' element={<Dashnotfound />} />
                </Route>
                

                <Route path='/*' element={<NotFound />} />

                
          </Routes>
        </BrowserRouter>
      ):(
        <main className='' style={{"margin": "2%","padding":"10%","minHeight": "calc(70vh - 3rem)","borderRadius":"10px"}}>
          <div className="container text-cennter elevation-2" style={{"margin": "1%","padding":"5%","borderRadius":"10px"}}>
            <div className="row justify-content-center">
                <Spinner animation="grow" variant="primary" size="lg" role="status"></Spinner>
                <h5 className='text-center' style={{"marginTop": "5%"}}>Please Wait...</h5>
                <Card.Body>
                  
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder className='border-info' xs={12} style={{"padding": "2%"}} /> 
                    <Placeholder className='border-info' xs={4} /> <Placeholder className='border-info' xs={4} />{' '}
                    <Placeholder className='border-info' xs={12} /> <Placeholder  className='border-info'xs={8} />
                  </Placeholder>
                </Card.Body>
            </div>
          </div>
        </main>
      )}
      
    </LoginContext.Provider>
  );
}

export default App;
